<template>
  <div class="page-container">
    <h1>You need to login in order to visit this page</h1>
    <v-row class="button-container">
      <v-btn to="/login" color="primary">Login</v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    pageHeader: String,
  },
};
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
