import { initializeApp } from 'firebase/app';
import { getFirestore, serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// PROD
const firebaseConfig = {
  apiKey: 'AIzaSyDqyQQevLm6Fz0mGP3-8lkP5AjTaH7CGAI',
  authDomain: 'dfkcombat-prod.firebaseapp.com',
  projectId: 'dfkcombat-prod',
  storageBucket: 'dfkcombat-prod.appspot.com',
  messagingSenderId: '271451512509',
  appId: '1:271451512509:web:8403acd7c9e05924ada1d4',
  measurementId: 'G-HL500FW6RH',
};

// initialize firebase
const app = initializeApp(firebaseConfig);

// initialize firebase service
const db = getFirestore(app);
const auth = getAuth(app);

export { auth, db, serverTimestamp };
