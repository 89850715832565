<template>
  <div>
    <v-row>
      <v-col cols="12" md="2"></v-col>
      <v-col cols="12" md="8">
        <h1 class="d-flex justify-center">View Builds</h1>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <v-list-item>
          You can view saved hero builds at "My Hero Builds" page and team
          builds at the "My Team Builds" page. Both pages are similar, they have
          a search panel, where you can search by word, tags, class, and
          subclass. The results are displayed as a cards list or there is a
          message "No results found", if you do not have saved builds or the
          search does not match any results. Clicking on a build card will open
          the build.
        </v-list-item>
        <v-list-item>
          This feature requires an account, because you need to have saved
          builds, in order to view them.
        </v-list-item>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <h2 class="d-flex justify-center">Step by Step Guide</h2>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <image-with-description
          v-for="(item, index) in stepByStepData"
          :key="index"
          :imagePath="item.imagePath"
          :altText="item.altText"
          :description="item.description"
        ></image-with-description>
      </v-col>
      <v-col cols="12" md="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import ImageWithDescription from './ImageWithDescription.vue';
import viewBuildsData from '../../../data/get-started/how-to/viewBuildsData';

export default {
  components: {
    ImageWithDescription,
  },
  data() {
    return {
      stepByStepData: viewBuildsData,
    };
  },
};
</script>

<style scoped>
.h1 {
  font-size: 2.2rem;
}

.h2 {
  font-size: 1.7rem;
}

.h4 {
  font-size: 1.2rem;
}
</style>
