import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      selectedClass: null,
      selectedSubclass: null,
      selectedClassSkills: {
        tier1: null,
        tier2: null,
        tier3: null,
        tier4: null,
        tier5: null,
        tier6: [],
        tier7: [],
        tier8: null,
      },
      selectedSubclassSkills: {
        tier1: null,
        tier2: null,
        tier3: null,
        tier4: null,
        tier5: null,
        tier6: [],
        tier7: [],
      },
    };
  },
  mutations,
  actions,
  getters,
};
