<template>
  <div>
    <v-app-bar height="60">
      <v-text-field v-model="searchWord" label="Search Word"> </v-text-field>
      <v-select
        v-model="classSearch"
        clearable
        chips
        label="Select Main Class"
        :items="heroClasses"
        multiple
      ></v-select>
      <v-select
        v-model="subclassSearch"
        clearable
        chips
        label="Select Subclass"
        :items="heroClasses"
        multiple
      >
      </v-select>
      <v-select
        v-model="tagsSearch"
        clearable
        chips
        label="Select Tags"
        :items="buildTags"
        multiple
      >
      </v-select>
      <v-btn variant="plain" color="primary" @click="clearSearch()">
        Clear Filters
      </v-btn>
      <span id="builds-count">{{ buildsCount }}</span>
    </v-app-bar>
    <v-progress-circular
      v-if="isDataLoading"
      indeterminate
      :size="100"
      :width="8"
    ></v-progress-circular>
    <v-container v-else>
      <div v-if="getFilteredBuilds().length !== 0">
        <v-card
          v-for="build in getFilteredBuilds()"
          :key="build.id"
          @click="openBuild(build.id)"
        >
          <v-card-title>{{ build.title }}</v-card-title>
          <v-card-title>{{ getCardSecondaryTitle(build) }}</v-card-title>
          <v-card-subtitle>{{ getCardSubtitle(build) }}</v-card-subtitle>
          <v-card-text>{{ getHumanReadableDate(build.updatedAt) }}</v-card-text>
          <v-card-text v-if="build.tags.length !== 0">
            <v-chip v-for="tag in build.tags" :key="tag">{{ tag }}</v-chip>
          </v-card-text>
        </v-card>
      </div>
      <div v-else>
        <v-row>
          <v-col class="d-flex justify-center align-center">
            No results found
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import classesList from '../../data/skill-calculator/class-objects.js';
import myBuildsSearchHelper from '../../utils/myBuildsSearchHelper.js';
import dateHelper from '../../utils/dateHelper.js';

export default {
  props: {
    isHeroBuild: Boolean,
    isDataLoading: Boolean,
    buildsData: Array,
  },
  data() {
    return {
      searchWord: '',
      classSearch: [],
      subclassSearch: [],
      tagsSearch: [],
    };
  },
  computed: {
    heroClasses() {
      const classObjects = Object.values(classesList);

      return classObjects.map((c) => c.className);
    },
    buildsCount() {
      return `Results: ${this.getFilteredBuilds().length}`;
    },
    buildTags() {
      let tagsList = [];

      this.buildsData.forEach(
        (build) => (tagsList = tagsList.concat(build.tags))
      );

      // Remove duplicated tags
      const uniqueTags = Array.from(new Set(tagsList));

      return uniqueTags;
    },
  },
  methods: {
    openBuild(id) {
      const routeName = this.isHeroBuild ? 'hero-build' : 'team-build';

      this.$router.push({ name: routeName, params: { id: id } });
    },
    getCardSecondaryTitle(build) {
      if (this.isHeroBuild) {
        return `${build.heroClass} / ${build.heroSubclass}`;
      } else {
        let result = '';

        const orderedPositions = build.heroPositions.sort(
          (a, b) => b.position - a.position
        );

        orderedPositions.forEach((p) => {
          result += `(P${p.position}) ${p.heroClass} / ${p.heroSubclass} `;
        });

        return result;
      }
    },
    getCardSubtitle(build) {
      if (this.isHeroBuild) {
        return `Required level: ${build.requiredLevel}`;
      } else {
        return `Team size: ${build.teamSize}`;
      }
    },
    getHumanReadableDate(timestamp) {
      return dateHelper.getHumanReadableDate(timestamp);
    },
    getFilteredBuilds() {
      if (
        this.searchWord.length === 0 &&
        this.classSearch.length === 0 &&
        this.subclassSearch.length === 0 &&
        this.tagsSearch.length === 0
      ) {
        return this.buildsData;
      } else {
        let buildsList = [];

        this.buildsData.forEach((build) => {
          if (
            myBuildsSearchHelper.hasSearchWord(
              build,
              this.searchWord,
              this.isHeroBuild
            ) &&
            myBuildsSearchHelper.hasClass(
              build,
              this.classSearch,
              this.isHeroBuild
            ) &&
            myBuildsSearchHelper.hasSubclass(
              build,
              this.subclassSearch,
              this.isHeroBuild
            ) &&
            myBuildsSearchHelper.hasTag(build.tags, this.tagsSearch)
          ) {
            buildsList.push(build);
          }
        });

        return buildsList;
      }
    },
    clearSearch() {
      this.searchWord = '';
      this.classSearch = [];
      this.subclassSearch = [];
      this.tagsSearch = [];
    },
  },
};
</script>

<style scoped>
.v-col {
  margin-top: 20%;
}

.v-progress-circular {
  margin-left: 45%;
  margin-top: 20%;
}

.v-card {
  margin: 5px;
  cursor: pointer;
}

.v-text-field {
  margin-left: 10px;
  margin-right: 10px;
  max-width: 30%;
}

.v-select {
  margin-left: 10px;
  margin-right: 10px;
  max-width: 18%;
}

.v-chip {
  cursor: pointer;
  margin-right: 5px;
}

#builds-count {
  color: orangered;
}
</style>
