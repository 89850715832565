<template>
  <v-row>
    <v-col cols="12" md="3"></v-col>
    <v-col cols="12" md="6">
      <release-notes :releaseNotesList="releaseNotes"></release-notes>
    </v-col>
    <v-col cols="12" md="3"></v-col>
  </v-row>
</template>

<script>
import ReleaseNotes from '../components/shared/ReleaseNotes.vue';
import dfkCombatNews from '../data/releaseNotes.js';

export default {
  components: {
    ReleaseNotes,
  },
  data() {
    return {
      releaseNotes: dfkCombatNews,
    };
  },
};
</script>
