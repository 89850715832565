<template>
  <div>
    <div v-if="isSkillSelected">
      <v-row no-gutters>
        <v-col cols="2" v-if="!isMobile"></v-col>
        <v-col :cols="smallAnalyticColumnSize">
          <v-sheet>
            <skill-types :skillTypes="skillTypes"></skill-types>
          </v-sheet>
        </v-col>
        <v-col :cols="smallAnalyticColumnSize">
          <v-sheet>
            <active-passive
              :skillsActivePassive="skillsActivePassive"
            ></active-passive>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col :cols="smallAnalyticColumnSize">
          <v-sheet>
            <attack-range :skillsAttackRange="skillsAttackRange"></attack-range>
          </v-sheet>
        </v-col>
        <v-col :cols="smallAnalyticColumnSize">
          <v-sheet>
            <attack-area :skillsAttackArea="skillsAttackArea"></attack-area>
          </v-sheet>
        </v-col>
        <v-col :cols="smallAnalyticColumnSize">
          <v-sheet>
            <attack-damage-type
              :skillsDamageType="skillsDamageType"
            ></attack-damage-type>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col :cols="largeAnalyticColumnSize">
          <v-sheet>
            <buff-skills :skillBuffs="skillBuffs"></buff-skills>
          </v-sheet>
        </v-col>
        <v-col :cols="largeAnalyticColumnSize">
          <v-sheet>
            <debuff-skills :skillDebuffs="skillDebuffs"></debuff-skills>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col :cols="largeAnalyticColumnSize">
          <v-sheet>
            <combo-skills :selectedSkills="selectedSkills"></combo-skills>
          </v-sheet>
        </v-col>
        <v-col :cols="largeAnalyticColumnSize">
          <v-sheet>
            <combo-effects
              :skillsWithComboEffects="skillsWithComboEffects"
              :buffsAndAilments="buffsAndAilments"
            >
            </combo-effects>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
    <v-container v-else
      >There are no heroes with skills selected. Please select heroes with
      skills in order to view analytics!</v-container
    >
  </div>
</template>

<script>
import SkillTypes from '../shared/analytics/SkillTypes.vue';
import ActivePassive from '../shared/analytics/ActivePassive.vue';
import BuffSkills from '../shared/analytics/BuffSkills.vue';
import DebuffSkills from '../shared/analytics/DebuffSkills.vue';
import AttackArea from '../shared/analytics/AttackArea.vue';
import AttackRange from '../shared/analytics/AttackRange.vue';
import AttackDamageType from '../shared/analytics/AttackDamageType.vue';
import ComboSkills from '../shared/analytics/ComboSkills.vue';
import ComboEffects from '../shared/analytics/ComboEffects.vue';

import heroSkillsHelper from '../../utils/heroSkillsHelper.js';
import teamBuildHelper from '../../utils/teamBuildHelper.js';
import resolutionHelper from '@/utils/resolutionHelper';

export default {
  components: {
    BuffSkills,
    ActivePassive,
    DebuffSkills,
    SkillTypes,
    AttackArea,
    AttackRange,
    AttackDamageType,
    ComboSkills,
    ComboEffects,
  },
  computed: {
    isMobile() {
      return resolutionHelper.isMobile();
    },
    heroPositions() {
      return this.$store.getters['teamBuild/getHeroPositions'];
    },
    selectedSkills() {
      let skillObjectsList = [];

      this.heroPositions.forEach((hero) => {
        const classSkillsObject = teamBuildHelper.getClassSkills(hero);
        const classSkillsList =
          heroSkillsHelper.getSkillsAsList(classSkillsObject);

        skillObjectsList = skillObjectsList.concat(classSkillsList);

        const subclassSkillsObject = teamBuildHelper.getSubclassSkills(hero);
        const subclassSkillsList =
          heroSkillsHelper.getSkillsAsList(subclassSkillsObject);

        skillObjectsList = skillObjectsList.concat(subclassSkillsList);
      });

      // list with dependent skills that selected tier6 skills have
      const dependentSkills = skillObjectsList.map(
        (skill) => skill.dependentSkill
      );
      // list with filtered basic skills from tier1-5 which have an upgrade in tier6
      const filteredSkills = skillObjectsList.filter(
        (skill) => !dependentSkills.includes(skill.id)
      );

      return filteredSkills;
    },
    isSkillSelected() {
      return this.selectedSkills.length !== 0;
    },
    attackSkills() {
      return heroSkillsHelper.getAttackSkills(this.selectedSkills);
    },
    skillTypes() {
      return heroSkillsHelper.getSkillTypes(this.selectedSkills);
    },
    skillsActivePassive() {
      return heroSkillsHelper.getActivePassiveSkills(this.selectedSkills);
    },
    skillsAttackRange() {
      return heroSkillsHelper.getSkillsAttackRange(this.attackSkills);
    },
    skillsAttackArea() {
      return heroSkillsHelper.getSkillsAttackArea(this.attackSkills);
    },
    skillsDamageType() {
      return heroSkillsHelper.getSkillsAttackDamageType(this.attackSkills);
    },
    skillBuffs() {
      return heroSkillsHelper.getSkillBuffs(this.selectedSkills);
    },
    skillDebuffs() {
      return heroSkillsHelper.getSkillDebuffs(this.selectedSkills);
    },
    skillsWithComboEffects() {
      return heroSkillsHelper.getSkillsWithComboEffects(this.selectedSkills);
    },
    buffsAndAilments() {
      const buffs = this.skillBuffs;
      const ailments = this.skillDebuffs;

      return { ...buffs, ...ailments };
    },
    smallAnalyticColumnSize() {
      return this.isMobile ? 6 : 4;
    },
    largeAnalyticColumnSize() {
      return this.isMobile ? 12 : 6;
    },
  },
};
</script>

<style scoped>
.v-sheet {
  margin: 10px;
}
</style>
