<template>
  <div>
    <v-navigation-drawer permanent location="right">
      <v-list v-model:opened="open">
        <div class="d-flex justify-center">
          <v-list-item :title="navigationTitle"></v-list-item>
        </div>
        <v-divider></v-divider>
        <v-list-group
          v-for="(section, i) in navigationSections"
          :key="i"
          :value="section.title"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              :append-icon="getSectionIcon(section.title)"
            >
              {{ section.title }}
            </v-list-item>
          </template>

          <v-list-item
            v-for="(subsection, y) in section.subsections"
            :key="y"
            :title="subsection.title"
            :to="subsection.to"
          ></v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    navigationTitle: {
      type: String,
      default: () => 'Table of Contents',
    },
    navigationSections: Array,
    openSections: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      open: this.openSections,
    };
  },
  methods: {
    getSectionIcon(sectionTitle) {
      return this.open.includes(sectionTitle)
        ? 'mdi-chevron-up'
        : 'mdi-chevron-down';
    },
  },
};
</script>

<style scoped>
.v-list-item__content .v-icon {
  float: right;
}
.subsection {
  margin-left: 15px;
}
</style>
