<template>
  <v-dialog :value="showSearchDialog" :max-width="isMobile ? '50%' : '30%'">
    <v-card>
      <v-card-title>Select Hero Build</v-card-title>
      <v-divider></v-divider>
      <v-container v-if="isDataLoading">
        <v-row justify="center">
          <v-col cols="auto">
            <v-progress-circular
              indeterminate
              color="primary"
              size="48"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
      <v-autocomplete
        v-else
        v-model="selectedHeroBuildId"
        :items="filteredHeroBuilds"
        color="blue-grey-lighten-2"
        item-title="title"
        item-value="id"
        label="Select"
      >
        <template v-slot:item="{ props, item }">
          <v-list-item
            v-bind="props"
            :title="item?.raw?.title"
            :subtitle="getCardSubtitle(item?.raw)"
            lines="three"
          >
          </v-list-item>
        </template>
      </v-autocomplete>
      <v-divider></v-divider>
      <v-container class="action-buttons">
        <v-row justify="center">
          <v-btn
            min-width="40%"
            :disabled="selectedHeroBuildId === ''"
            variant="tonal"
            color="primary"
            @click="submitSelectedHeroBuild"
          >
            Select
          </v-btn>
          <v-btn
            min-width="40%"
            variant="tonal"
            color="secondary"
            @click="closeSearchDialog()"
          >
            Cancel
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { auth, db } from '@/firebase/config';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { useStore } from 'vuex';
import { ref } from 'vue';
import resolutionHelper from '../../utils/resolutionHelper.js';

export default {
  setup() {
    const store = useStore();
    const isDataLoading = ref(true);
    const user = auth.currentUser;

    if (user) {
      const q = query(
        collection(db, 'heroBuilds'),
        where('createdBy', '==', user.uid),
        orderBy('updatedAt', 'desc')
      );

      getDocs(q).then((querySnapshot) => {
        if (querySnapshot.length !== 0) {
          let heroBuilds = [];

          querySnapshot.forEach((doc) => {
            heroBuilds.push({
              ...doc.data(),
              id: doc.id,
            });
          });

          store.dispatch('myHeroBuilds/setHeroBuilds', heroBuilds);
        }

        isDataLoading.value = false;
      });
    } else {
      isDataLoading.value = false;
    }

    return { isDataLoading };
  },
  props: {
    showSearchDialog: Boolean,
  },
  emits: ['closeSearchDialog', 'heroBuildSelected'],
  data() {
    return {
      selectedHeroBuildId: '',
    };
  },
  computed: {
    heroBuildsData() {
      return this.$store.getters['myHeroBuilds/getHeroBuilds'];
    },
    filteredHeroBuilds() {
      const heroPositions = this.$store.getters['teamBuild/getHeroPositions'];

      if (heroPositions.length === 0) {
        return this.heroBuildsData;
      } else {
        const usedBuildIds = heroPositions.map((p) => p.heroBuild.id);
        const filteredBuilds = this.heroBuildsData.filter(
          (build) => !usedBuildIds.includes(build.id)
        );

        return filteredBuilds;
      }
    },
    isMobile() {
      return resolutionHelper.isMobile();
    },
  },
  methods: {
    submitSelectedHeroBuild() {
      let selectedBuild = this.heroBuildsData.find(
        (build) => build.id === this.selectedHeroBuildId
      );

      this.$emit('heroBuildSelected', selectedBuild);
      this.selectedHeroBuildId = '';
      this.closeSearchDialog();
    },
    closeSearchDialog() {
      this.$emit('closeSearchDialog');
    },
    getCardSubtitle(heroBuild) {
      return `${heroBuild.heroClass} / ${
        heroBuild.heroSubclass
      }\n${heroBuild.tags.toString()}`;
    },
  },
};
</script>

<style scoped>
.v-card-title {
  text-align: center;
}

.v-progress-circular {
  margin-left: 48%;
}

.v-autocomplete {
  margin: 20px;
}

.v-list-item {
  white-space: pre-line;
}

.v-divider {
  margin-bottom: 10px;
}

.action-buttons {
  margin-bottom: 10px;
}

.action-buttons .v-btn {
  margin: 5px;
}
</style>
