export default {
  setTeamSize(state, payload) {
    state.teamSize = payload;
  },
  addHeroPosition(state, payload) {
    state.heroBuildPositions.push(payload);
  },
  removeHeroPosition(state, payload) {
    state.heroBuildPositions = state.heroBuildPositions.filter(
      (p) => p.position !== payload
    );
  },
  resetTeamBuild(state) {
    state.heroBuildPositions = [];
  },
};
