export default {
  className: 'Archer',
  disciplines: [
    {
      name: 'Flurry',
      description:
        'The Flurry path honors those who seek to deliver death through a thousand cuts.',
    },
    {
      name: 'Precision',
      description:
        'The Precision path speaks to the patient Heroes who wait for the optimal moment to strike.',
    },
    {
      name: 'Marksmanship',
      description:
        'For those with piercing minds and more than a sprinkle of Luck on their side, the Marksmanship path offers unique rewards.',
    },
  ],
  statGrowth: {
    STR: 55,
    DEX: 80,
    AGI: 50,
    VIT: 50,
    END: 60,
    INT: 40,
    WIS: 25,
    LCK: 40,
  },
  healthGrowth: {
    Small: 25,
    Medium: 50,
    Large: 25,
  },
  manaGrowth: {
    Small: 30,
    Medium: 40,
    Large: 30,
  },
  basicAttackStats: [],
  accuracyStats: [],
  defenseStats: [],
  criticalStrikeStats: [],
  skills: {
    tier1: [
      {
        id: 'archer-t1-1',
        tier: 1,
        skillPoints: 1,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Basic Attack Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Flurry',
        name: 'Flurry',
        imageSrc:
          'https://game.defikingdoms.com/assets/Attacks/archer/flurry.png',
        description: [
          'Increase effective Basic Attack damage by 5% in combat.',
        ],
      },
      {
        id: 'archer-t1-2',
        tier: 1,
        skillPoints: 1,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Basic Attack Critical Strike'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Precision',
        name: 'Precision',
        imageSrc:
          'https://game.defikingdoms.com/assets/Attacks/archer/precision.png',
        description: [
          'All Basic Attacks have an X% (X = 0.5*DEX) increased chance to Critical Strike.',
        ],
      },
      {
        id: 'archer-t1-3',
        tier: 1,
        skillPoints: 1,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Luck', 'Pierce'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Marksmanship',
        name: 'Marksmanship',
        imageSrc:
          'https://game.defikingdoms.com/assets/Attacks/archer/giant-slayer.png',
        description: [
          'Gain X% effective LCK in combat (X = 0.2*INT).',
          'All Basic Attacks gain 10% Pierce.',
        ],
      },
    ],
    tier2: [
      {
        id: 'archer-t2-1',
        tier: 2,
        skillPoints: 2,
        range: 3,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target', 'Multi-Hit'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Flurry',
        name: 'Multi-Shot',
        imageSrc:
          'https://game.defikingdoms.com/assets/Attacks/archer/multi-shot.png',
        description: [
          'Fire 3 arrows at target enemy.',
          'Each arrow deals damage equal to (0.5*Basic).',
          'Each arrow has an independent chance to Critical Strike.',
        ],
      },
      {
        id: 'archer-t2-2',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Evasion'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Precision',
        name: 'Shroud',
        imageSrc:
          'https://game.defikingdoms.com/assets/Attacks/archer/shroud.png',
        description: ['While Channeling, this Hero gains 50% EVA.'],
      },
      {
        id: 'archer-t2-3',
        tier: 2,
        skillPoints: 2,
        range: 3,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Pierce'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Marksmanship',
        name: 'Piercing Shot',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/archer/bow-mastery.png',
        description: [
          'Deal damage to target enemy equal to (1.0*Basic + 3.0*DEX).',
          'This attack has 10% Pierce.',
        ],
      },
    ],
    tier3: [
      {
        id: 'archer-t3-1',
        tier: 3,
        skillPoints: 3,
        range: 3,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['archer-t2-1'],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Critical Strike'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Flurry',
        name: 'Rapid Shot',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/archer/rapid-shot.png',
        description: [
          'Fire 3 arrows randomly targeting up to 3 enemies.',
          'Each arrow deals damage equal to (1.25*Basic).',
          'COMBO: If this Hero used Multi-Shot the previous Round, increase Crit Chance by 30%.',
        ],
      },
      {
        id: 'archer-t3-2',
        tier: 3,
        skillPoints: 3,
        range: 3,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['archer-t2-2', 'archer-t6-3'],
        comboEffects: [],
        scalingStats: ['DEX', 'DEX'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Damage'],
        buffsDuration: null,
        debuffs: ['Channel'],
        debuffsDuration: 1,
        channelDuration: '1 Round',
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Precision',
        name: 'Sharpshooter',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/archer/sharpshooter.png',
        description: [
          'Channel for 1 Rounds.',
          'Deal damage to target enemy equal to (4.0*Basic + 6.0*DEX).',
          "COMBO: If Shroud is active, this Hero's next Basic Attack does additional damage equal to (10.0*DEX).",
        ],
      },
      {
        id: 'archer-t3-3',
        tier: 3,
        skillPoints: 3,
        range: 3,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Pierce'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Marksmanship',
        name: 'Piercing Arrow',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/archer/piercing-arrows.png',
        description: [
          'Deal damage to target enemy in P1 equal to (1.7*Basic).',
          'Deal damage to target enemy P2 equal to (1.0*Basic).',
          'Deal damage to target enemy in P3 equal to (0.7*Basic).',
          'This attack has 10% Pierce.',
          'If Blocked, the Arrow stops its trajectory.',
        ],
      },
    ],
    tier4: [
      {
        id: 'archer-t4-1',
        tier: 4,
        skillPoints: 4,
        range: 3,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['archer-t3-1'],
        comboEffects: [],
        scalingStats: ['DEX'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target', 'Multi-Hit'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Flurry',
        name: 'Repeating Shot',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/archer/repeating-shot.png',
        description: [
          'Fire 3 arrows at target enemy.',
          'Each arrow deals damage equal to (1.5*Basic).',
          'COMBO: If you targeted the same enemy with Rapid Shot the previous Round, deal an additional (4.0*DEX) for each instance of damage dealt.',
        ],
      },
      {
        id: 'archer-t4-2',
        tier: 4,
        skillPoints: 4,
        range: 3,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['archer-t2-2', 'archer-t6-3'],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Only Stun Interrupts'],
        buffsDuration: null,
        debuffs: ['Channel'],
        debuffsDuration: 1,
        channelDuration: '1 Round',
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Precision',
        name: 'Dead Aim',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/archer/dead-aim.png',
        description: [
          'Channel for 1 Rounds.',
          'Next Round, this Hero must use Basic Attack, deals damage equal to (2.2*Basic).',
          'COMBO: If Shroud is active, this Channel can only be Interrupted by Stun',
        ],
      },
      {
        id: 'archer-t4-3',
        tier: 4,
        skillPoints: 4,
        range: 3,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Pierce'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Marksmanship',
        name: 'One Small Stone',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/archer/one-small-stone.png',
        description: [
          'Deal damage to 2 target enemies equal to (0.4*Basic*(1+(0.1*LCK)) each.',
          'This attack has 10% Pierce.',
        ],
      },
    ],
    tier5: [
      {
        id: 'archer-t5-1',
        tier: 5,
        skillPoints: 5,
        range: 3,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Flurry',
        name: 'Barrage',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/archer/barrage.png',
        description: [
          'Fire 6 arrows arching high into the air, dealing damage randomly amongst all enemy Positions.',
          'Each arrow deals damage equal to (0.7*Basic).',
          'However, each additional arrow to strike the same enemy deals additional physical LCK damage.',
          'If there is no Hero in the Position, the damage for that arrow fizzles.',
          'Example: 2nd Arrow = 2.0*LCK; 3rd Arrow = 4.0*LCK; 4th Arrow = 6.0*LCK; 5th Arrow = 12.0*LCK.',
        ],
      },
      {
        id: 'archer-t5-2',
        tier: 5,
        skillPoints: 5,
        range: 3,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['archer-t4-2'],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Channel'],
        debuffsDuration: 1,
        channelDuration: '1 Round',
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Precision',
        name: 'Twin Arrows',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/archer/twin-arrows.png',
        description: [
          'Channel for 1 Rounds.',
          'Next Round, this Hero must use Basic Attack. Deal damage to the two closest enemies equal to (1.5*Basic) each.',
          'COMBO: If you cast Dead Aim this Battle, deal damage equal to (3.0*Basic).',
        ],
      },
      {
        id: 'archer-t5-3',
        tier: 5,
        skillPoints: 5,
        range: 3,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'LCK'],
        ailments: ['Enemies Receive Additional Damage'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Marksmanship',
        name: "Hunter's Mark",
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/archer/hunters-mark.png',
        description: [
          'Deal damage to all enemies equal to (1.0*Basic).',
          'All enemies receive X% (X = 0.1*LCK + 0.1*DEX) additional physical damage for a Duration of 2 Rounds.',
        ],
      },
    ],
    tier6: [
      {
        id: 'archer-t6-1',
        tier: 6,
        skillPoints: 8,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'archer-t2-1',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target', 'Multi-Hit'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Flurry',
        name: 'Multi-Shot +',
        imageSrc: '',
        description: [
          'Fire X arrows at target enemy. Each arrow deals damage equal to (X*Basic).',
          'Each arrow has an independent chance to Critical Strike.',
        ],
      },
      {
        id: 'archer-t6-2',
        tier: 6,
        skillPoints: 5,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'archer-t5-1',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Flurry',
        name: 'Barrage +',
        imageSrc: '',
        description: [
          'Fire a full quiver of arrows arching high into the air, the 6 arrows rain down and deal damage randomly amongst all enemies.',
          'Each arrow deals (X*Basic), however, each additional arrow to strike the same enemy deals additional LCK damage.',
          'Example: 2nd Arrow = X*LCK; 3rd Arrow = X*LCK; 4th Arrow = X*LCK; 5th Arrow = X*LCK.',
        ],
      },
      {
        id: 'archer-t6-3',
        tier: 6,
        skillPoints: 8,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: 'archer-t2-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Evasion',
          'Cannot be Pushed',
          'Cannot be Pulled',
          'Cannot be Stunned',
        ],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Marksmanship',
        name: 'Shroud +',
        imageSrc: '',
        description: [
          'While Channeling, this Hero gains X% EVA and cannot be Pushed, Pulled, or Stunned.',
        ],
      },
      {
        id: 'archer-t6-4',
        tier: 6,
        skillPoints: 6,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'archer-t4-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Daze', 'Slow'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Channel'],
        debuffsDuration: null,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Precision',
        name: 'Dead Aim +',
        imageSrc: '',
        description: [
          'Channel for X Rounds.',
          'Next Round, this Hero must Basic Attack and deals damage equal to (X*Basic).',
          'COMBO: If Hero has Shroud active, Daze target and Slow target by X% for X Rounds.',
        ],
      },
      {
        id: 'archer-t6-5',
        tier: 6,
        skillPoints: 6,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'archer-t4-3',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Pierce'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Marksmanship',
        name: 'One Small Stone +',
        imageSrc: '',
        description: [
          'Pick up a stone from the ground and knock it.',
          'Deal damage to X target enemies equal to (X*Basic*(X+(X*LCK)) each.',
          'This attack has X% Pierce.',
        ],
      },
      {
        id: 'archer-t6-6',
        tier: 6,
        skillPoints: 7,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'archer-t3-3',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Pierce'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Marksmanship',
        name: 'Piercing Arrows +',
        imageSrc: '',
        description: [
          'Deal damage to target enemy in P1 equal to (X*Basic).',
          'Deal damage to target enemy P2 equal to (X*Basic).',
          'Deal damage to target enemy in P3 equal to (X*Basic).',
          'This attack has X% Pierce.',
          'If Blocked, the Arrow stops its trajectory.',
        ],
      },
    ],
    tier7: [
      {
        id: 'archer-t7-1',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'DEX'],
        ailments: ['Slow'],
        ailmentsDuration: 999,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Precision',
        name: 'Pin',
        imageSrc: '',
        description: [
          'Fire a heavy arrow through the foot of target enemy, dealing damage equal to (X*Basic + X*STR + X*DEX).',
          'Slow target by X% for the remainder of Battle.',
        ],
      },
      {
        id: 'archer-t7-2',
        tier: 7,
        skillPoints: 10,
        range: 2,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Flurry',
        name: 'Detonating Arrow',
        imageSrc: '',
        description: [
          'Shoot enemy target in P2 dealing damage equal to (X*Basic).',
          'Deal damage to each enemy in P1 and P3 equal to (X*Basic).',
        ],
      },
      {
        id: 'archer-t7-3',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'DEX'],
        ailments: ['Poison'],
        ailmentsDuration: 999,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Precision',
        name: 'Poison Arrow',
        imageSrc: '',
        description: [
          'Deal damage equal to (X*Basic + X*STR + X*DEX).',
          'Poison target enemy.',
        ],
      },
      {
        id: 'archer-t7-4',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['archer-t2-2', 'archer-t6-3'],
        comboEffects: [],
        scalingStats: ['STR', 'DEX'],
        ailments: ['Daze', 'Slow'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Precision',
        name: 'Debilitating Shot',
        imageSrc: '',
        description: [
          'Deal damage equal to (X*Basic + X*STR + X*DEX).',
          'Daze target enemy.',
          'COMBO: If Hero has Shroud active, also Slow target enemy by X%.',
        ],
      },
      {
        id: 'archer-t7-5',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Marksmanship',
        name: 'Giant Killer',
        imageSrc: '',
        description: [
          'If target enemy possess greater current HP than this Hero, deal 100% bonus attack damage.',
        ],
      },
      {
        id: 'archer-t7-6',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Flurry',
        name: 'Trick Shot',
        imageSrc: '',
        description: [
          'Deal damage to target enemy in P1 (X*Basic + X*DEX), P2 (X*Basic + X*DEX), P3 (X*Basic + X*DEX).',
        ],
      },
      {
        id: 'archer-t7-7',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: ['archer-t2-2', 'archer-t6-3'],
        comboEffects: [],
        scalingStats: ['DEX', 'END'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Agility', 'Agility'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Precision',
        name: 'Swift Quiver',
        imageSrc: '',
        description: [
          'Gain effective AGI equal to (X*DEX) in combat.',
          'COMBO: If Shroud is active, gain additional AGI equal to (X*END).',
        ],
      },
      {
        id: 'archer-t7-8',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Damage'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Marksmanship',
        name: 'Relentless Volley',
        imageSrc: '',
        description: [
          'Activate to put Archer in Turret Mode.',
          'Deal damage equal to (X*Basic) after each Turn to a random enemy for X Rounds.',
        ],
      },
      {
        id: 'archer-t7-9',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Flurry',
        name: 'Flick Shot',
        imageSrc: '',
        description: [
          'Whenever this Hero receives damage, deal damage to source equal to (X*Basic).',
        ],
      },
    ],
    tier8: [
      {
        id: 'archer-t8-1',
        tier: 8,
        skillPoints: 15,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Execute',
        damageType: 'Physical',
        discipline: 'Marksmanship',
        name: 'Masterful Shot',
        imageSrc: '',
        description: [
          'Execute target with less than X% HP.',
          'Degree of Difficulty: -X% Accuracy',
        ],
      },
    ],
  },
};
