<template>
  <div>
    <div v-if="skillsList.length !== 0">
      <v-row>
        <v-col
          v-for="skill in skillsList"
          :key="skill.name"
          class="d-flex child-flex"
          cols="12"
        >
          <skill-card
            :skill="skill"
            :dependentSkill="getDependentSkill(skill)"
          ></skill-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import heroSkillsHelper from '../../../../utils/heroSkillsHelper';
import SkillCard from '../../../shared/skills/SkillCard.vue';

export default {
  components: {
    SkillCard,
  },
  props: {
    isMainClass: Boolean,
    selectedSkills: Object,
  },
  computed: {
    skillsList() {
      return heroSkillsHelper.getSkillsAsList(this.selectedSkills);
    },
  },
  methods: {
    getDependentSkill(skill) {
      let skillName = '';

      if (skill.dependentSkill) {
        const dependentSkill = this.skillsList.find(
          (s) => s.id === skill.dependentSkill
        );
        skillName = dependentSkill.name;
      }
      return skillName;
    },
  },
};
</script>

<style scoped>
.v-col {
  padding: 1px 4px;
}

.v-card-title,
.v-card-subtitle,
.v-card-text {
  padding: 1px 5px;
  font-size: 0.8rem;
}
</style>
