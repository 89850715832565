import { openHeaderMenu } from './commonData';

export default [
  openHeaderMenu,
  {
    imagePath: require('../../../assets/how-to/view-builds/select-my-build-from-menu.png'),
    altText: 'Select to view saved builds',
    description: [
      'You can select "My Hero Builds" to view your saved hero builds.',
      'Selecting "My Team Builds" will display the list of your saved team builds.',
    ],
  },
  {
    imagePath: require('../../../assets/how-to/view-builds/search-panel.png'),
    altText: 'Search panel',
    description: [
      'By default, builds are ordered by the recently updated date in descending order, so the most recently updated build is on the top.',
      'You can search by keyword',
      'You can filter by class, subclass, and tags, which are all multi selects.',
      'The relation between selected filter options is "OR", but the relation between all search filters is "AND".',
      'The tags filter will display only the tags that you have used for your builds.',
      'The "CLEAR FILTERS" button will reset all filters.',
      'Matching results count is displayed at the far right side of the search panel.',
    ],
  },
  {
    imagePath: require('../../../assets/how-to/view-builds/hero-build.png'),
    altText: 'Hero build card',
    description: [
      'The hero build card has the build title at the top, followed by the selected class / subclass, the required level, the date when the build was last modified, and applied build tags.',
      'If you click the build it will open in the same window.',
    ],
  },
  {
    imagePath: require('../../../assets/how-to/view-builds/team-build.png'),
    altText: 'Team build card',
    description: [
      'The team build card has the build title at the top, followed by information about the selected heroes at their respective positions, the team size, the date when the build was last modified, and applied build tags.',
      'If you click the build it will open in the same window.',
    ],
  },
];
