import archer from './classes/archer.js';
//import bard from './classes/bard.js'
import berserker from './classes/berserker.js';
//import darkKnight from './classes/dark-knight.js'
//import dragoon from './classes/dragoon.js'
//import dreadKnight from './classes/dread-knight.js'
import knight from './classes/knight.js';
import legionnaire from './classes/legionnaire.js';
import monk from './classes/monk.js';
import pirate from './classes/pirate.js';
import priest from './classes/priest.js';
//import ninja from './classes/ninja.js'
//import paladin from './classes/paladin.js'
//import sage from './classes/sage.js'
import scholar from './classes/scholar.js';
import seer from './classes/seer.js';
//import shapeshifter from './classes/shapeshifter.js'
//import spellbow from './classes/spellbow.js'
//import summoner from './classes/summoner.js'
import thief from './classes/thief.js';
import warrior from './classes/warrior.js';
import wizard from './classes/wizard.js';

export default {
  archer: archer,
  //bard,
  berserker: berserker,
  //darkKnight,
  //dragoon,
  //dreadKnight,
  knight: knight,
  legionnaire,
  monk,
  //ninja,
  //paladin,
  pirate: pirate,
  priest: priest,
  //sage,
  scholar: scholar,
  seer: seer,
  //shapeshifter,
  //spellbow,
  //summoner,
  thief: thief,
  warrior: warrior,
  wizard: wizard,
};
