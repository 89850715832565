<template>
  <div>
    <v-card>
      <v-card-title class="skill-title">
        <v-btn
          v-if="skill.imageSrc"
          variant="plain"
          :style="{
            'background-image': 'url(' + skill.imageSrc + ')',
            'background-size': '50px',
          }"
        >
        </v-btn>
        {{ `T${skill.tier} ${skill.name}` }}
      </v-card-title>
      <v-card-title class="skill-subtitle" v-if="skill.isPassive"
        >PASSIVE
      </v-card-title>
      <v-card-title v-else class="skill-subtitle">
        <span class="skill-mana">{{ `MANA COST: ${skill.mana}` }}</span>
        <span class="skill-range">{{ `RANGE: ${skill.range}` }}</span>
      </v-card-title>
      <v-card-subtitle>{{ skillPointCost }}</v-card-subtitle>
      <v-card-subtitle>{{ skill.discipline }}</v-card-subtitle>
      <v-card-subtitle>{{ castTime }}</v-card-subtitle>
      <v-card-text
        class="skill-description"
        v-for="(paragraph, i) in skill.description"
        :key="i"
      >
        <v-icon icon="mdi-circle-small"></v-icon>
        {{ paragraph }}
      </v-card-text>
      <v-card-subtitle v-if="dependentSkill">{{
        ` Requires: ${this.dependentSkill}`
      }}</v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    skill: Object,
    dependentSkill: String,
  },
  computed: {
    skillPointCost() {
      return `Skill Points: ${this.skill.skillPoints}`;
    },
    castTime() {
      return this.skill.channelDuration === null
        ? 'Instant'
        : `Channelled (${this.skill.channelDuration})`;
    },
  },
};
</script>

<style scoped>
.v-card-title {
  padding-top: 3px;
  padding-bottom: 3px;
}

.v-btn {
  min-width: 50px;
  width: 50px;
  min-height: 50px;
  height: 50px;
  cursor: auto;
}

.skill-description {
  color: #ffff00;
  white-space: pre-line;
}

.skill-subtitle {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.8rem;
}

.skill-range {
  margin-left: 10px;
}

.v-card-text {
  padding-top: 0;
  padding-bottom: 0;
}

.v-card {
  padding-bottom: 8px;
}
</style>
