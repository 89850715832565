export default [
  {
    title: 'Beginners Guide to DeFi Kingdoms',
    description: 'Created by the great community member Samichpunch',
    url: 'https://medium.com/@Samichpunch/beginners-guide-to-defi-kingdoms-how-to-start-playing-defi-kingdoms-88e9041467d2',
  },
  {
    title: 'Official DeFi Kingdoms documentation',
    description: 'The website with the official DeFi Kingdoms documentation',
    url: 'https://docs.defikingdoms.com/',
  },
  {
    title: 'DeFi Kingdoms Discord channel',
    description:
      'Enjoy the great DFK community and stay in touch with all updates',
    url: 'https://discord.gg/defikingdoms',
  },
];
