<template>
  <v-row>
    <v-col cols="12" md="6" lg="5">
      <v-container>
        <h2>{{ `${classObject.className} Class` }}</h2>
      </v-container>
      <v-list>
        <div v-for="(discipline, i) in classDisciplines" :key="i">
          <v-divider v-if="i !== 0"></v-divider>
          <v-list-item>
            <v-list-item-subtitle class="discipline-name">
              <strong>{{ `${discipline.name} Discipline:` }}</strong>
            </v-list-item-subtitle>
            <p>{{ discipline.description }}</p>
          </v-list-item>
        </div>
      </v-list>
    </v-col>
    <v-col cols="12" md="6" lg="3">
      <v-container>
        <h2>Class Analytics</h2>
      </v-container>

      <v-sheet>
        <Radar :data="chartData" :options="chartOptions" />
      </v-sheet>

      <v-container>
        <v-row>
          <v-col cols="6"><h2>Health Growth</h2></v-col>
          <v-col cols="6"><h2>Mana Growth</h2></v-col>
        </v-row>
      </v-container>
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-list>
              <v-list-item v-for="(value, key) in classHealthGrowth" :key="key">
                <span>{{ `${key}:` }}</span>
                <span class="text-right-aligned">{{ `${value}%` }}</span>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-list>
              <v-list-item v-for="(value, key) in classManaGrowth" :key="key">
                <span>{{ `${key}:` }}</span>
                <span class="text-right-aligned">{{ `${value}%` }}</span>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="6" lg="4">
      <v-container>
        <h2>Main Class Stats Growth</h2>
      </v-container>
      <v-card>
        <v-row>
          <v-col v-for="(value, key) in classStatGrowth" :key="key" cols="6">
            <v-row>
              <v-col cols="6">
                <v-container>
                  <strong>{{ `${key}:` }}</strong>
                </v-container>
              </v-col>
              <v-col cols="6"
                ><v-container>{{ `${value}%` }}</v-container>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <v-container>
        <h2>Subclass Stats Growth</h2>
      </v-container>
      <v-card>
        <v-row>
          <v-col v-for="(value, key) in classStatGrowth" :key="key" cols="6">
            <v-row>
              <v-col cols="6">
                <v-container>
                  <strong>{{ `${key}:` }}</strong>
                </v-container>
              </v-col>
              <v-col cols="6"
                ><v-container>{{ `${value / 4}%` }}</v-container>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>

  <v-divider class="border-opacity-75 divider-header" color="info"></v-divider>

  <v-row>
    <v-col cols="6">
      <v-container>
        <h3>Codex</h3>
      </v-container>
    </v-col>
    <v-col cols="6">
      <v-container> </v-container>
    </v-col>
  </v-row>

  <v-divider class="border-opacity-75 divider-header" color="info"></v-divider>

  <v-row v-for="skill in classSkillsList" :key="skill.id">
    <v-divider
      v-if="dividerSkills.some((item) => skill.id.includes(item))"
      class="border-opacity-75"
      color="warning"
    ></v-divider>
    <v-col cols="6">
      <skill-card
        :skill="skill"
        :dependentSkill="getDependentSkill(skill)"
      ></skill-card>
    </v-col>
    <v-col cols="6"></v-col>
  </v-row>
</template>

<script>
import { useRoute } from 'vue-router';
import heroSkillsHelper from '../../utils/heroSkillsHelper';
import SkillCard from '../shared/skills/SkillCard.vue';

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'vue-chartjs';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
export default {
  components: {
    SkillCard,
    Radar,
  },
  setup() {
    const route = useRoute();
    const className = route.params.id;

    const classObject = heroSkillsHelper.getClassObject(className);

    return { classObject };
  },
  data() {
    return {
      dividerSkills: ['t2-1', 't3-1', 't4-1', 't5-1', 't6-1', 't7-1', 't8-1'],
    };
  },
  computed: {
    classSkillsList() {
      return heroSkillsHelper.getSkillsAsList(this.classObject.skills);
    },
    classDisciplines() {
      return this.classObject.disciplines;
    },
    classStatGrowth() {
      return this.classObject.statGrowth;
    },
    classHealthGrowth() {
      return this.classObject.healthGrowth;
    },
    classManaGrowth() {
      return this.classObject.manaGrowth;
    },
    skillTypes() {
      return heroSkillsHelper.getSkillTypes(this.classSkillsList);
    },
    chartData() {
      return {
        labels: Object.keys(this.skillTypes),
        datasets: [
          {
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            pointBackgroundColor: 'rgba(255,99,132,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255,99,132,1)',
            data: Object.values(this.skillTypes),
          },
        ],
      };
    },
    chartOptions() {
      return {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          r: {
            angleLines: {
              display: false,
            },
            suggestedMin: 1,
            suggestedMax: 25,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
  },
  methods: {
    getDependentSkill(skill) {
      let skillName = '';

      if (skill.dependentSkill) {
        const dependentSkill = this.classSkillsList.find(
          (s) => s.id === skill.dependentSkill
        );
        skillName = dependentSkill.name;
      }
      return skillName;
    },
  },
};
</script>

<style scoped>
.divider-header {
  margin: 20px 0 20px 0;
}

.text-right-aligned {
  float: right;
}

.discipline-name {
  font-size: 1.2rem;
  padding-top: 5px;
  padding-bottom: 8px;
  color: yellow;
}
</style>
