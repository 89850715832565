<template>
  <div>
    <v-progress-circular
      v-if="isHeroDataLoading"
      indeterminate
      :size="100"
      :width="8"
      color="primary"
    ></v-progress-circular>
    <div v-else-if="heroBuildExists">
      <select-skills
        :isSavedBuild="true"
        :userIsBuildOwner="userIsBuildOwner"
        :buildTitle="heroBuildTitle"
        :buildTags="heroBuildTags"
      >
      </select-skills>
    </div>
    <under-construction
      v-else
      :pageHeader="`Hero build with id: '${heroBuildId}' was not found!`"
    ></under-construction>
  </div>
</template>

<script>
import { db } from '@/firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import getUser from '../composables/getUser';

import heroSkillsHelper from '../utils/heroSkillsHelper.js';

import SelectSkills from '../components/skill-calculator/select-skills/SelectSkills.vue';
import UnderConstruction from '../components/ui/UnderConstruction.vue';

export default {
  components: {
    SelectSkills,
    UnderConstruction,
  },
  setup() {
    const user = getUser();

    const route = useRoute();
    const store = useStore();
    const heroBuildId = ref(route.params.id);
    const heroBuildExists = ref(false);
    const isHeroDataLoading = ref(true);
    const heroBuildOwner = ref(null);
    const heroBuildTitle = ref('');
    const heroBuildTags = ref([]);

    // Reset store hero skills data
    store.dispatch('heroBuild/resetHeroBuild');

    const docRef = doc(db, 'heroBuilds', heroBuildId.value);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        heroBuildExists.value = true;

        // Get the hero build data and load the heroBuild store
        const heroBuildData = docSnap.data();
        heroBuildOwner.value = heroBuildData.createdBy;
        heroBuildTitle.value = heroBuildData.title;
        heroBuildTags.value = heroBuildData.tags;

        // The class object
        const heroClass = heroSkillsHelper.getClassObject(
          heroBuildData.heroClass
        );
        store.dispatch('heroBuild/selectClass', { heroClass: heroClass });

        // The subclass object
        const heroSubclass = heroSkillsHelper.getClassObject(
          heroBuildData.heroSubclass
        );
        store.dispatch('heroBuild/selectSubclass', {
          heroSubclass: heroSubclass,
        });

        // The class skills
        heroBuildData.selectedClassSkills.forEach((skill) => {
          const tierNumber = parseInt(skill.match(/\d+/)[0]);

          const heroSkill = heroClass.skills[`tier${tierNumber}`].find(
            (s) => s.id === skill
          );

          store.dispatch('heroBuild/addSelectedSkill', {
            isMainClass: true,
            tierNumber: tierNumber,
            tier: `tier${heroSkill.tier}`,
            skill: heroSkill,
          });
        });

        // The subclass skills
        heroBuildData.selectedSubclassSkills.forEach((skill) => {
          const tierNumber = parseInt(skill.match(/\d+/)[0]);

          const heroSkill = heroSubclass.skills[`tier${tierNumber}`].find(
            (s) => s.id === skill
          );

          store.dispatch('heroBuild/addSelectedSkill', {
            isMainClass: false,
            tierNumber: tierNumber,
            tier: `tier${heroSkill.tier}`,
            skill: heroSkill,
          });
        });
      } else {
        console.error(
          `Hero build with id: '${heroBuildId.value}' was not found!`
        );
      }

      isHeroDataLoading.value = false;
    });

    return {
      heroBuildId,
      heroBuildExists,
      isHeroDataLoading,
      user,
      heroBuildOwner,
      heroBuildTitle,
      heroBuildTags,
    };
  },
  computed: {
    userIsBuildOwner() {
      return this.user !== null && this.user.uid === this.heroBuildOwner;
    },
  },
};
</script>

<style scoped>
.v-toolbar h3 {
  float: left;
}

.v-toolbar span {
  margin-left: 15px;
}

.v-toolbar-title span.total-skill-points {
  color: orangered;
}

.v-progress-circular {
  margin-left: 45%;
  margin-top: 20%;
}
</style>
