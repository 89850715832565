export const openHeaderMenu = {
  imagePath: require('../../../assets/how-to/common/open-header-menu.png'),
  altText: 'Open header menu',
  description: [
    'Click on the hamburger menu in the header.',
    'It is located in the top left part of the page.',
  ],
};

export const deleteBuildConfirmation = {
  imagePath: require('../../../assets/how-to/common/delete-confirmation-dialog.png'),
  altText: 'Open header menu',
  description: [
    'This is the "Delete Build" confirmation dialog.',
    'Clicking the "CANCEL" button will close the dialog and get you back to the saved build.',
    'You can confirm and permanently delete the build by clicking the "DELETE" action button.',
  ],
};

export const saveBuildDialog = {
  imagePath: require('../../../assets/how-to/common/save-build-dialog.png'),
  altText: 'Save Build Dialog',
  description: [
    'You have to enter a title to enable the "Save" button and save the build.',
    'Optionally, you can enter a tag name and press "Enter" keyboard key to add it. You can add as many tags as you want.',
    'You can remove tags by clicking their "X" button.',
    'Clicking the "SAVE" button will save the build.',
    'You can return back to edit your build by clicking the "CANCEL" button.',
  ],
};

export const savedBuildActionButtons = {
  imagePath: require('../../../assets/how-to/common/action-buttons-saved-build.png'),
  altText: 'Action Buttons for Saved Hero Builds',
  description: [
    'When you are viewing a saved build, there are different action buttons.',
    '"SHARE" button copies a shareable link to the build in your clipboard. You can give the link to anyone you want, so they can view the build.',
    '"NEW" button will help you start from the beginning.',
    'Clicking "UPDATE" button shows the "Update Build" dialog, where you can update the build with changes made.',
    '"DELETE" button can be used to delete the saved build, after additional dialog confirmation.',
  ],
};

export const updateBuildDialog = {
  imagePath: require('../../../assets/how-to/common/update-build-dialog.png'),
  altText: 'Update Build Dialog',
  description: [
    'The build title and list of tags are loaded and displayed.',
    'You could keep the same title and tags or change/modify them.',
    'You can remove tags by clicking their "X" button.',
    'Clicking the "UPDATE" button will update the build with your changes.',
    'You can return back to edit your build by clicking the "CANCEL" button.',
  ],
};
