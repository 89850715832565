export default {
  className: 'Berserker',
  disciplines: [
    {
      name: 'Bear',
      description:
        'Berserkers who follow the Bear path embody raw strength and resilience, shrugging off blows that would fell lesser warriors and even growing stronger with each injury they sustain. They are the embodiment of what it means to be fierce and with each blow struck and each wound sustained, the Bear Berserker weaponizes their fury to amplify their strength and steady their feet.',
    },
    {
      name: 'Wolf',
      description:
        'The path of the Wolf is one of stealth and guile, striking with surprising speed and unleashing their wrath upon their unsuspecting foes. Their rage is fueled by the fear of their enemies, and with each terror-filled howl, they draw upon the strength of their ancestral spirit, the "Evening Wolf" Berserker known as the Kvelduf. As they channel the ferocity of the wolf, they grow stronger still, becoming a force of nature on the battlefield, leaving nothing but destruction in their wake.',
    },
    {
      name: 'Boar',
      description:
        'These warriors embody the raw, wild madness of the beast, their very being driven by the desire to cause chaos and mayhem. They were known to habitually partake in the consumption of Henbane mushroom, which further heightened their bloodlust and brought them to the brink of madness and from this madness, the Jöfurr derived great strength and power. The Boar path is for those who seek to harness that power of unbridled chaos and bring it to the battlefield.',
    },
  ],
  statGrowth: {
    STR: 80,
    DEX: 60,
    AGI: 55,
    VIT: 65,
    END: 60,
    INT: 20,
    WIS: 20,
    LCK: 40,
  },
  healthGrowth: {
    Small: 15,
    Medium: 45,
    Large: 40,
  },
  manaGrowth: {
    Small: 45,
    Medium: 40,
    Large: 15,
  },
  basicAttackStats: [],
  accuracyStats: [],
  defenseStats: [],
  criticalStrikeStats: [],
  skills: {
    tier1: [
      {
        id: 'berserker-t1-1',
        tier: 1,
        skillPoints: 1,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Basic Attack Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Bear',
        name: 'Berserkir Mushroom',
        imageSrc: '',
        description: [
          'Gain X% Basic Attack damage, where X = X% of missing HP.',
        ],
      },
      {
        id: 'berserker-t1-2',
        tier: 1,
        skillPoints: 1,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Basic Attack Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Wolf',
        name: 'Ulfheðnar Mushroom',
        imageSrc: '',
        description: [
          'For each instance this Hero deals damage, gain X% increased Basic Attack damage. This amount Stacks up to a maximum of X%.',
        ],
      },
      {
        id: 'berserker-t1-3',
        tier: 1,
        skillPoints: 1,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Basic Attack Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Boar',
        name: 'Jöfurr Mushroom',
        imageSrc: '',
        description: [
          'For each instance this Hero takes damage, gain X% Basic Attack damage. This amount Stacks up to a maximum of X%.',
        ],
      },
    ],
    tier2: [
      {
        id: 'berserker-t2-1',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Physical Defense'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Bear',
        name: 'Bear Skin',
        imageSrc: '',
        description: ['X% effective P.DEF increase in combat.'],
      },
      {
        id: 'berserker-t2-2',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Evasion'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Wolf',
        name: 'Wolf Pelt',
        imageSrc: '',
        description: ['X% effective EVA increase in combat.'],
      },
      {
        id: 'berserker-t2-3',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Basic Attack Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Boar',
        name: 'Boar Hide',
        imageSrc: '',
        description: ['X% effective Basic Attack increase in combat.'],
      },
    ],
    tier3: [
      {
        id: 'berserker-t3-1',
        tier: 3,
        skillPoints: 3,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Basic Attack Damage'],
        buffsDuration: 0,
        debuffs: ['Self Damage'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Bear',
        name: 'Bloodlust',
        imageSrc: '',
        description: [
          'Self-inflict damage equal to (X*Basic + X*VIT).',
          'Gain increased Basic Attack damage equal to X% (X = X + X*VIT) for a Duration of X.',
        ],
      },
      {
        id: 'berserker-t3-2',
        tier: 3,
        skillPoints: 3,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Reduce Damage Dealt by All Enemies'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Debuff',
        damageType: '-',
        discipline: 'Wolf',
        name: 'Howl',
        imageSrc: '',
        description: [
          'Unleash a furious howl that terrifies all enemies.',
          'Reduce damage dealt by all enemies by X% for a Duration of X.',
        ],
      },
      {
        id: 'berserker-t3-3',
        tier: 3,
        skillPoints: 3,
        range: 3,
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE', 'Multi-Hit'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Boar',
        name: 'Blind Rage',
        imageSrc: '',
        description: [
          'Enter a blind rage and slash X times, hitting all enemies randomly and dealing damage equal to (X*Basic) per slash.',
        ],
      },
    ],
    tier4: [
      {
        id: 'berserker-t4-1',
        tier: 4,
        skillPoints: 4,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Strength'],
        buffsDuration: null,
        debuffs: ['Endurance'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Bear',
        name: 'Primal Strength',
        imageSrc: '',
        description: [
          'Increase effective STR by X% in combat.',
          'Decrease effective END by X% in combat.',
        ],
      },
      {
        id: 'berserker-t4-2',
        tier: 4,
        skillPoints: 4,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Wolf',
        name: 'Wolf Pack',
        imageSrc: '',
        description: ['For each living ally, gain X% increased damage.'],
      },
      {
        id: 'berserker-t4-3',
        tier: 4,
        skillPoints: 4,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Boar',
        name: 'Wild Frenzy',
        imageSrc: '',
        description: [
          'Each instance this Hero Basic Attacks, gain a chance equal to X% (X = X + X*LCK) to deal X additional Basic Attacks to enemy target.',
        ],
      },
    ],
    tier5: [
      {
        id: 'berserker-t5-1',
        tier: 5,
        skillPoints: 5,
        range: 2,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Poison', 'Receive Additional Damage'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Poison'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Bear',
        name: 'Spore-Coated Blade',
        imageSrc: '',
        description: [
          'Deal damage equal to (X*Basic) to target enemy.',
          'This attack has a X% chance to Poison target, as well as this Hero.',
          'Target receives X% additional damage from this Hero for a Duration of X.',
        ],
      },
      {
        id: 'berserker-t5-2',
        tier: 5,
        skillPoints: 5,
        range: 2,
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Ally Takes Damage'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Wolf',
        name: 'Brutal Act',
        imageSrc: '',
        description: [
          'Deal damage to a random party member equal to (X*Basic + X*STR).',
          'Deal damage to a random enemy equal to (X*Basic + X*STR).',
        ],
      },
      {
        id: 'berserker-t5-3',
        tier: 5,
        skillPoints: 5,
        range: 3,
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Exhaust'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Boar',
        name: 'Blood Rage',
        imageSrc: '',
        description: [
          'Unleash hell, swirling and swinging axes in all directions dealing AOE damage to all combatants equal to (X*Basic + X*STR + X*LCK).',
          'Become Exhausted.',
        ],
      },
    ],
    tier6: [
      {
        id: 'berserker-t6-1',
        tier: 6,
        skillPoints: 8,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: 'berserker-t2-1',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Physical Defense', 'Evasion'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Bear',
        name: 'Bear Skin +',
        imageSrc: '',
        description: [
          'X% effective P.DEF increase in combat.',
          'Increase EVA by X%.',
        ],
      },
      {
        id: 'berserker-t6-2',
        tier: 6,
        skillPoints: 7,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'berserker-t3-1',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['VIT', 'END'],
        ailments: ['Silence'],
        ailmentsDuration: null,
        buffs: ['Damage'],
        buffsDuration: 0,
        debuffs: ['Self Damage'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Bear',
        name: 'Bloodlust +',
        imageSrc: '',
        description: [
          'Self-inflict damage equal to (X*Basic + X*VIT).',
          'Gain increased Basic Attack damage equal to X% (X = X + X*VIT) for a Duration of X.',
          '(X + X*END) chance to Silence a random enemy.',
        ],
      },
      {
        id: 'berserker-t6-3',
        tier: 6,
        skillPoints: 7,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: 'berserker-t4-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: [],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Wolf',
        name: 'Wolf Pack +',
        imageSrc: '',
        description: ['For each dead ally, gain X% increased damage.'],
      },
      {
        id: 'berserker-t6-4',
        tier: 6,
        skillPoints: 7,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'berserker-t3-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR'],
        ailments: ['Reduce Damage Dealt by All Enemies', 'Fear'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Debuff',
        damageType: '-',
        discipline: 'Wolf',
        name: 'Howl +',
        imageSrc: '',
        description: [
          'Unleash a furious howl that terrifies all enemies.',
          'Reduce damage dealt by all enemies by X% for a Duration of X.',
          '(X + X*STR) chance to Fear a random enemy.',
        ],
      },
      {
        id: 'berserker-t6-5',
        tier: 6,
        skillPoints: 5,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'berserker-t5-3',
        comboSkills: [],
        comboEffects: ['Bleed'],
        scalingStats: ['STR', 'LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Critical Strike'],
        buffsDuration: 0,
        debuffs: ['Exhaust', 'Damage Allies'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Boar',
        name: 'Blood Rage +',
        imageSrc: '',
        description: [
          'Unleash hell, swirling and swinging axes in all directions dealing AOE damage to both teams equal to (X*Basic + X*STR + X*LCK).',
          'If target is inflicted with Bleed, increase CSC by X%.',
          'Become Exhausted.',
        ],
      },
      {
        id: 'berserker-t6-6',
        tier: 6,
        skillPoints: 7,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'berserker-t3-3',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Blind'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Exhaust'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE', 'Multi-Hit'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Boar',
        name: 'Blind Rage +',
        imageSrc: '',
        description: [
          'Enter a blind rage and slash X times, hitting all enemies randomly and dealing damage equal to (X*Basic) per slash.',
          'If a target is hit at least X times, X% chance to Blind target for a Duration of X.',
          'Become Exhausted.',
        ],
      },
    ],
    tier7: [
      {
        id: 'berserker-t7-1',
        tier: 7,
        skillPoints: 10,
        range: 1,
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'berserker-t1-1',
          'berserker-t2-1',
          'berserker-t3-1',
          'berserker-t4-1',
          'berserker-t5-1',
          'berserker-t6-1',
          'berserker-t6-2',
          'berserker-t7-1',
          'berserker-t7-2',
          'berserker-t7-3',
        ],
        comboEffects: ['Bleed'],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Critical Strike', 'Lifesteal'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Bear',
        name: 'Blood of Our Enemies',
        imageSrc: '',
        description: [
          'Activate to Basic Attack target Hero.',
          'If target is inflicted with Bleed, increase CSC by X%.',
          'COMBO: If X or more Bear abilities have been cast by this Hero this battle, this attack gains 30% Lifesteal.',
        ],
      },
      {
        id: 'berserker-t7-2',
        tier: 7,
        skillPoints: 10,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Cannot Die', 'Damage'],
        buffsDuration: 0,
        debuffs: ['Reduce Health to 1', 'Amnesia Living Rage'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Bear',
        name: 'Living Rage',
        imageSrc: '',
        description: [
          "Activate to reduce this hero's HP to 1.",
          'This Hero cannot die for a Duration of X and deals X% additional damage.',
          'Amnesia X: Living Rage',
        ],
      },
      {
        id: 'berserker-t7-3',
        tier: 7,
        skillPoints: 10,
        range: 2,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['berserker-t5-3'],
        comboEffects: [],
        scalingStats: ['STR', 'DEX'],
        ailments: ['Slow', 'Stun'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Bear',
        name: 'Decimation',
        imageSrc: '',
        description: [
          'Deal damage to enemies in P1 and P2 equal to (X*Basic + X*STR + X*DEX).',
          '(X + X*STR)% chance to Slow each target by X% for a Duration of X.',
          'COMBO: if "Blood Rage" was cast in the last X Durations, (X + X*STR)% chance to Stun both targets.',
        ],
      },
      {
        id: 'berserker-t7-4',
        tier: 7,
        skillPoints: 10,
        range: 2,
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['berserker-t7-3'],
        comboEffects: ['Bleed'],
        scalingStats: ['STR', 'STR'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Critical Strike'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Wolf',
        name: 'Primal Rend',
        imageSrc: '',
        description: [
          'Deal damage equal to (X*Basic + X*STR).\n' +
            'If target is inflicted with Bleed, increase CSC by X%.',
          'COMBO, Decimation:\n' +
            'If target enemy has less than X% HP.\n' +
            'Deal additional damage equal to (X*Basic + X*STR).',
        ],
      },
      {
        id: 'berserker-t7-5',
        tier: 7,
        skillPoints: 10,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'STR'],
        ailments: ['Stun'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Exhaust'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Wolf',
        name: 'Kveldulf Blow',
        imageSrc: '',
        description: [
          'Deal damage equal to (X*Basic + X*STR) to target Hero in P1.',
          '(X + X*STR)% chance to Stun target for a Duration of X.',
          '(X + X*STR)% chance to Stun target for an additional Duration of X.',
          '(X + X*STR)% chance to Stun target for an additional Duration of X.',
          'Become Exhausted for a Duration of X (X = number of Durations target was Stunned).',
        ],
      },
      {
        id: 'berserker-t7-6',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Reduce Healing'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Debuff',
        damageType: '-',
        discipline: 'Wolf',
        name: 'Wood Ear Extract',
        imageSrc: '',
        description: [
          'Whenever this Hero deals Basic Attack damage, target Hero has a X% chance to receive X% less healing for a Duration of X.',
          'Can refresh, does not stack.',
        ],
      },
      {
        id: 'berserker-t7-7',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR'],
        ailments: ['Daze', 'Stun', 'Banish'],
        ailmentsDuration: null,
        buffs: ['Additional Damage to Dragoons'],
        buffsDuration: null,
        debuffs: ['Exhaust'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Boar',
        name: 'Dragonslayer',
        imageSrc: '',
        description: [
          'Deal damage to target enemy in equal to (X*Basic).',
          '(X + X*STR)% chance to Daze target.',
          '(X + X*STR)% chance to Stun target.',
          '(X + X*STR)% chance to Banish target.',
          'If target enemy is a Dragoon, deal additional damage equal to (X*Basic + X*STR).',
          'Become Exhausted.',
        ],
      },
      {
        id: 'berserker-t7-8',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK', 'END'],
        ailments: ['Berserk'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Debuff',
        damageType: '-',
        discipline: 'Boar',
        name: 'Henbane Spores',
        imageSrc: '',
        description: [
          "At the end of each of this Hero's Turns, this Hero has a chance equal to X% (X = X*LCK) to expel mushroom spores.",
          'If spores are expelled, (X + X*END) chance to inflict a random enemy with Berserk for a Duration of X.',
        ],
      },
      {
        id: 'berserker-t7-9',
        tier: 7,
        skillPoints: 10,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Cannot Be Controlled'],
        debuffsDuration: 999,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Boar',
        name: "Bödvar's Mushroom",
        imageSrc: '',
        description: [
          'This Hero ate the wrong mushroom and cannot be controlled for the remainder of Battle.',
          'This Hero deals damage equal to (X*Basic) to a random target:\n' +
            'X% Enemies\n' +
            'X% Allies\n' +
            'If no allies remain, X% enemies.',
        ],
      },
    ],
    tier8: [
      {
        id: 'berserker-t8-1',
        tier: 8,
        skillPoints: 15,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['X Actions Upon Death'],
        debuffs: ['Cannot Heal'],
        debuffsDuration: null,
        channelDuration: null,
        buffsDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Wolf',
        name: 'Funeral Rites',
        imageSrc: '',
        description: [
          "Upon death, this Hero may make X additional actions as if they haven't died. They recover 0 HP from healing while in this state.",
        ],
      },
    ],
  },
};
