<template>
  <div>
    <team-header
      :isSavedBuild="false"
      :userIsBuildOwner="false"
      :teamBuildTitle="''"
      :teamBuildTags="[]"
      @toggleTab="toggleTab"
    ></team-header>
    <v-window v-model="tab">
      <v-window-item value="select-team">
        <select-team-tab></select-team-tab>
      </v-window-item>
      <v-window-item value="team-skills">
        <team-skills-tab></team-skills-tab>
      </v-window-item>
      <v-window-item value="team-analytics">
        <team-analytics-tab></team-analytics-tab>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import TeamHeader from '../components/compose-team/TeamHeader.vue';
import SelectTeamTab from '../components/compose-team/SelectTeamTab.vue';
import TeamSkillsTab from '../components/compose-team/TeamSkillsTab.vue';
import TeamAnalyticsTab from '../components/compose-team/TeamAnalyticsTab.vue';

export default {
  components: {
    TeamHeader,
    SelectTeamTab,
    TeamSkillsTab,
    TeamAnalyticsTab,
  },
  created() {
    this.$store.dispatch('teamBuild/resetTeamBuild');
  },
  data() {
    return {
      tab: null,
    };
  },
  methods: {
    toggleTab(value) {
      this.tab = value;
    },
  },
};
</script>
