<template>
  <div>
    <login-form v-if="showLogin" @toggle="toggleForm"></login-form>
    <sign-up-form v-else @toggle="toggleForm"></sign-up-form>
  </div>
</template>

<script>
import LoginForm from '../components/login/LoginForm.vue';
import SignUpForm from '../components/login/SignUpForm.vue';

export default {
  components: {
    LoginForm,
    SignUpForm,
  },
  data() {
    return {
      showLogin: true,
    };
  },
  methods: {
    toggleForm() {
      this.showLogin = !this.showLogin;
    },
  },
};
</script>
