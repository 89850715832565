<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col cols="11">
          {{ `Attack Area (${attackSkillsCount})` }}
        </v-col>
        <v-col cols="1">
          <tooltip-icon class="information-tooltip" :text="tooltipText">
          </tooltip-icon>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="attackSkillsCount !== 0">
      <Doughnut :data="attackAreaChartData" :options="attackAreaChartOptions" />
    </div>
    <div v-else>There are no attack skills selected</div>
  </div>
</template>

<script>
import TooltipIcon from '../common/TooltipIcon.vue';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'vue-chartjs';

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  components: {
    TooltipIcon,
    Doughnut,
  },
  props: {
    skillsAttackArea: Object,
  },
  data() {
    return {
      tooltipText:
        'The chart shows the ratio between Single Target and Area of Effect(AOE) attack skills.\n\nTips:\n- emphasizing on Single Target attack skills may help you "nuke" a target in the enemy team\n- notice that some Single Target skills are doing multiple hits on the same target, which can be useful to stack debuffs e.g. hero with a Toxic passive trait\n- Single Target attack skills that Execute a target are very nice addition to a Single Target damage focused build\n- AOE attack skills applying debuff or damage over time effects to the whole enemy team are quite nice\n- short range heroes could use an AOE attack skill from their arsenal to do damage to the backline and contribute to finish an enemy opponent',
    };
  },
  computed: {
    attackSkillsCount() {
      let count = 0;

      Object.values(this.skillsAttackArea).forEach((c) => (count += c));

      return count;
    },
    attackAreaChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
      };
    },
    attackAreaChartData() {
      return {
        labels: Object.keys(this.skillsAttackArea),
        datasets: [
          {
            backgroundColor: ['#cc0000', '#ff6600'],
            data: Object.values(this.skillsAttackArea),
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
@media (max-width: 900px) {
  .information-tooltip {
    font-size: 0.7rem;
  }
}
</style>
