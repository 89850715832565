<template>
  <v-container>
    <v-card v-for="releaseNote in releaseNotesList" :key="releaseNote.title">
      <v-card-title>{{ releaseNote.title }}</v-card-title>
      <div v-for="item in releaseNote.items" :key="item.subtitle">
        <v-card-subtitle>{{ item.subtitle }}</v-card-subtitle>
        <v-card-text>
          <v-list
            v-for="description in item.descriptionItems"
            :key="description"
          >
            <v-list-item>
              <v-icon icon="mdi-circle-small"></v-icon>
              {{ description }}
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    releaseNotesList: Array,
  },
};
</script>

<style scoped>
.v-card-title,
.v-card-subtitle,
.v-card-text {
  padding: 1px 5px;
}

.v-card {
  margin-bottom: 15px;
}

.v-list {
  padding: 0;
}

.v-list-item--density-default {
  min-height: 0 !important;
}
</style>
