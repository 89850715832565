<template>
  <v-app-bar>
    <v-app-bar-nav-icon @click="drawer = !drawer">
      <v-icon :icon="drawer ? 'mdi-chevron-left' : 'mdi-menu'"></v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title class="mx-auto" @click="$router.push('/')">
      <v-icon icon="mdi-sword-cross"></v-icon>
      {{ title }}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn to="/login" v-if="!user">Login / Sign Up</v-btn>
      <v-menu v-else>
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" append-icon="mdi-chevron-down">
            {{ `Welcome ${getUserDisplayName()}` }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="showProfileDialog = true">
            <v-icon>mdi-account-edit-outline</v-icon>
            Profile
          </v-list-item>
          <v-list-item class="dropdown-button" @click="logout">
            <v-icon>mdi-logout</v-icon>
            Logout
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
  <v-navigation-drawer v-model="drawer" app>
    <v-list>
      <v-list-item
        v-for="(item, index) in menuItems"
        :key="index"
        :to="item.to"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

  <profile-dialog
    v-model="showProfileDialog"
    :currentDisplayName="getUserDisplayName()"
    @closeProfileSettings="closeProfileSettings"
    @saveProfileSettings="saveProfileSettings"
  >
  </profile-dialog>
</template>

<script>
import { auth } from '@/firebase/config';
import getUser from '../../composables/getUser';
import ProfileDialog from '../dialogs/ProfileDialog.vue';
import { getAuth, updateProfile } from 'firebase/auth';

export default {
  setup() {
    const user = getUser();

    return { user };
  },
  components: {
    ProfileDialog,
  },
  data() {
    return {
      title: 'DFK Combat',
      drawer: false,
      menuItems: [
        { title: 'Home', to: '/' },
        { title: 'Getting Started', to: '/getting-started' },
        { title: 'Skill Calculator', to: '/skill-calculator/new' },
        { title: 'My Hero Builds', to: '/my-hero-builds' },
        { title: 'Compose Team', to: '/compose-team/new' },
        { title: 'My Team Builds', to: '/my-team-builds' },
        { title: 'Contests', to: '/contests' },
      ],
      showProfileDialog: false,
    };
  },
  methods: {
    logout() {
      auth.signOut().then(() => {
        this.$store.dispatch('snackbar/showMessage', {
          content: 'You have successfully logged out!',
          color: 'success',
        });

        this.$router.push({ name: 'home' });
      });
    },
    getUserDisplayName() {
      if (this.user) {
        return this.user.displayName;
      } else {
        return '';
      }
    },
    closeProfileSettings() {
      this.showProfileDialog = false;
    },
    saveProfileSettings(value) {
      const auth = getAuth();

      updateProfile(auth.currentUser, {
        displayName: value,
      })
        .then(() => {
          this.showProfileDialog = false;

          this.$store.dispatch('snackbar/showMessage', {
            content: 'You have successfully changed your Display Name!',
            color: 'success',
          });
        })
        .catch((error) => {
          this.$store.dispatch('snackbar/showMessage', {
            content: error.message,
            color: 'red',
          });
        });
    },
  },
};
</script>

<style scoped>
.v-toolbar-title {
  max-width: 190px;
  cursor: pointer;
}
</style>
