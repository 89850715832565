export default {
  selectClass(state, payload) {
    state.selectedClass = payload;
  },
  selectSubclass(state, payload) {
    state.selectedSubclass = payload;
  },
  addSelectedSkill(state, payload) {
    // Add class skill
    if (payload.isMainClass === true) {
      if (payload.tierNumber < 6 || payload.tierNumber === 8) {
        state.selectedClassSkills[payload.tier] = payload.skill;
      } else {
        state.selectedClassSkills[payload.tier].push(payload.skill);
      }
    } else {
      // Add subclass skill
      if (payload.tierNumber < 6 || payload.tierNumber === 8) {
        state.selectedSubclassSkills[payload.tier] = payload.skill;
      } else {
        state.selectedSubclassSkills[payload.tier].push(payload.skill);
      }
    }
  },
  removeSelectedSkill(state, payload) {
    // Remove class skill
    if (payload.isMainClass === true) {
      // Process tiers 1-5
      if (payload.tierNumber < 6) {
        // Remove class skill
        state.selectedClassSkills[payload.tier] = null;
        // Clean up selected subclass skills on the same tier
        state.selectedSubclassSkills[payload.tier] = null;
      } else if (payload.tierNumber === 8) {
        state.selectedClassSkills.tier8 = null;
      } else {
        // Process tiers 6 and 7
        state.selectedClassSkills[payload.tier] = state.selectedClassSkills[
          payload.tier
        ].filter((skill) => skill.id !== payload.skillId);
        // If this tier contains no skills, then remove the subclass skills at the same tier
        if (state.selectedClassSkills[payload.tier].length === 0) {
          state.selectedSubclassSkills[payload.tier] = [];
        }
      }
    } else {
      // Remove subclass skill
      // Process tiers 1-5
      if (payload.tierNumber < 6) {
        // Save the skill before removing it
        const skillToRemove = state.selectedSubclassSkills[payload.tier];
        // Remove the skill
        state.selectedSubclassSkills[payload.tier] = null;
        // Check if a tier 6 skill depends on the removed skill and remove it as well
        state.selectedSubclass.skills.tier6.forEach((skill) => {
          if (skill.dependentSkill === skillToRemove.id) {
            state.selectedSubclassSkills.tier6 =
              state.selectedSubclassSkills.tier6.filter(
                (s) => s.id !== skill.id
              );
          }
        });
      } else {
        // Process tiers 6 and 7
        state.selectedSubclassSkills[payload.tier] =
          state.selectedSubclassSkills[payload.tier].filter(
            (skill) => skill.id !== payload.skillId
          );
      }
    }
  },
  resetSelectedSkills(state) {
    state.selectedClassSkills = {
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: null,
      tier5: null,
      tier6: [],
      tier7: [],
      tier8: null,
    };

    state.selectedSubclassSkills = {
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: null,
      tier5: null,
      tier6: [],
      tier7: [],
    };
  },
};
