<template>
  <v-container fluid>
    <v-row dense v-for="(tier, _, tierIndex) in skills" :key="tierIndex">
      <v-divider inset></v-divider>
      <strong>Tier {{ tierIndex + 1 }}</strong>
      <v-col
        v-for="skill in tier"
        :key="skill.id"
        class="d-flex child-flex"
        cols="auto"
      >
        <v-tooltip
          activator="parent"
          location="end"
          max-width="300"
          theme="light"
          :arrow="true"
        >
          <strong class="skill-name">{{ skill.name }}</strong>
          <div class="skill-subtitle" v-if="skill.isPassive">PASSIVE</div>
          <div class="skill-subtitle" v-else>
            <span class="skill-mana">{{ `MANA COST: ${skill.mana}` }}</span>
            <span class="skill-range">{{ `RANGE: ${skill.range}` }}</span>
          </div>
          <p class="skill-cast-time" v-if="!skill.isPassive">
            {{ getCastTime(skill) }}
          </p>
          <v-card-text
            class="skill-description"
            v-for="(paragraph, i) in skill.description"
            :key="i"
          >
            <v-icon icon="mdi-circle-small"></v-icon>
            {{ paragraph }}
          </v-card-text>
          <p
            class="skill-requirement"
            v-if="skill.dependentSkill !== null"
            :style="{ color: getRequiredSkillColor(skill.dependentSkill) }"
          >
            {{ getRequiredSkillText(skill) }}
          </p>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="tonal"
              :disabled="isSkillDisabled(skill)"
              :class="[{ selected: isSkillSelected(skill.id) }, 'skill-button']"
              @click="toggleSkill(skill)"
              :style="{
                'background-image': 'url(' + getSkillImage(skill) + ')',
                'background-size': '50px',
              }"
            >
              <span class="skill-cost">{{ skill.skillPoints }}</span>
            </v-btn>
          </template>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    skills: Object,
    isMainClass: Boolean,
  },
  computed: {
    selectedSkillIds() {
      return this.$store.getters['heroBuild/selectedSkillIds'];
    },
    selectedClassSkills() {
      return this.$store.getters['heroBuild/selectedClassSkills'];
    },
    selectedSubclassSkills() {
      return this.$store.getters['heroBuild/selectedSubclassSkills'];
    },
    subclassSkillPointsSpent() {
      return this.$store.getters['heroBuild/subclassSkillPointsSpent'];
    },
    totalSkillPointsSpent() {
      const classPoints =
        this.$store.getters['heroBuild/classSkillPointsSpent'];

      return classPoints + this.subclassSkillPointsSpent;
    },
  },
  methods: {
    getSkillName(skill) {
      if (skill.isPassive) {
        return skill.name + ' (passive)';
      } else {
        return skill.name;
      }
    },
    getCastTime(skill) {
      return skill.channelDuration === null
        ? 'Instant'
        : `Channelled (${skill.channelDuration})`;
    },
    getRequiredSkillText(skill) {
      const tierNumber = parseInt(skill.dependentSkill.match(/\d+/)[0]);
      const requiredSkill = this.skills[`tier${tierNumber}`].find(
        (s) => s.id === skill.dependentSkill
      );

      return `Required: ${requiredSkill.name}`;
    },
    isSkillSelected(skillId) {
      return this.selectedSkillIds.includes(skillId);
    },
    getRequiredSkillColor(skillId) {
      return this.isSkillSelected(skillId) ? '#13ec13' : '#ec1313';
    },
    isSkillDisabled(skill) {
      // First check if by adding this skill we will surpass the allowed maximum of 100 SP
      if (
        this.totalSkillPointsSpent + skill.skillPoints > 100 &&
        !this.isSkillSelected(skill.id)
      ) {
        return true;
      }

      // Specific checks for main and subclass skill tiers
      if (this.isMainClass === true) {
        switch (skill.tier) {
          case 1:
            if (
              this.selectedClassSkills.tier1 !== null &&
              this.selectedClassSkills.tier1.id !== skill.id
            ) {
              return true;
            } else if (
              this.selectedClassSkills.tier2 !== null &&
              this.selectedClassSkills.tier1.id === skill.id
            ) {
              return true;
            } else {
              return false;
            }
          case 2:
            if (this.selectedClassSkills.tier1 === null) {
              return true;
            } else if (
              this.selectedClassSkills.tier2 !== null &&
              this.selectedClassSkills.tier2.id !== skill.id
            ) {
              return true;
            } else if (
              this.selectedClassSkills.tier3 !== null &&
              this.selectedClassSkills.tier2.id === skill.id
            ) {
              return true;
            } else {
              return false;
            }
          case 3:
            if (this.selectedClassSkills.tier2 === null) {
              return true;
            } else if (
              this.selectedClassSkills.tier3 !== null &&
              this.selectedClassSkills.tier3.id !== skill.id
            ) {
              return true;
            } else if (
              this.selectedClassSkills.tier4 !== null &&
              this.selectedClassSkills.tier3.id === skill.id
            ) {
              return true;
            } else {
              return false;
            }
          case 4:
            if (this.selectedClassSkills.tier3 === null) {
              return true;
            } else if (
              this.selectedClassSkills.tier4 !== null &&
              this.selectedClassSkills.tier4.id !== skill.id
            ) {
              return true;
            } else if (
              this.selectedClassSkills.tier5 !== null &&
              this.selectedClassSkills.tier4.id === skill.id
            ) {
              return true;
            } else {
              return false;
            }
          case 5:
            if (this.selectedClassSkills.tier4 === null) {
              return true;
            } else if (
              this.selectedClassSkills.tier5 !== null &&
              this.selectedClassSkills.tier5.id !== skill.id
            ) {
              return true;
            } else if (
              (this.selectedClassSkills.tier6.length !== 0 ||
                this.selectedClassSkills.tier7.length !== 0 ||
                this.selectedClassSkills.tier8 !== null) &&
              this.selectedClassSkills.tier5.id === skill.id
            ) {
              return true;
            } else {
              return false;
            }
          case 6:
            if (
              this.selectedClassSkills.tier5 === null ||
              !this.isSkillSelected(skill.dependentSkill)
            ) {
              return true;
            } else {
              return false;
            }
          case 7:
            if (this.selectedClassSkills.tier5 === null) {
              return true;
            } else {
              return false;
            }
          case 8:
            if (this.selectedClassSkills.tier5 === null) {
              return true;
            } else {
              return false;
            }
          default:
            return true;
        }
      } else {
        // Check if by adding this skill we will surpass the maximum of 15 SP
        if (
          this.subclassSkillPointsSpent + skill.skillPoints > 15 &&
          !this.isSkillSelected(skill.id)
        ) {
          return true;
        }

        switch (skill.tier) {
          case 1:
            if (this.selectedClassSkills.tier1 === null) {
              return true;
            } else if (
              this.selectedSubclassSkills.tier1 !== null &&
              this.selectedSubclassSkills.tier1.id !== skill.id
            ) {
              return true;
            } else {
              return false;
            }
          case 2:
            if (this.selectedClassSkills.tier2 === null) {
              return true;
            } else if (
              this.selectedSubclassSkills.tier2 !== null &&
              this.selectedSubclassSkills.tier2.id !== skill.id
            ) {
              return true;
            } else {
              return false;
            }
          case 3:
            if (this.selectedClassSkills.tier3 === null) {
              return true;
            } else if (
              this.selectedSubclassSkills.tier3 !== null &&
              this.selectedSubclassSkills.tier3.id !== skill.id
            ) {
              return true;
            } else {
              return false;
            }
          case 4:
            if (this.selectedClassSkills.tier4 === null) {
              return true;
            } else if (
              this.selectedSubclassSkills.tier4 !== null &&
              this.selectedSubclassSkills.tier4.id !== skill.id
            ) {
              return true;
            } else {
              return false;
            }
          case 5:
            if (this.selectedClassSkills.tier5 === null) {
              return true;
            } else if (
              this.selectedSubclassSkills.tier5 !== null &&
              this.selectedSubclassSkills.tier5.id !== skill.id
            ) {
              return true;
            } else {
              return false;
            }
          case 6:
            if (
              this.selectedClassSkills.tier6.length === 0 ||
              !this.selectedSkillIds.includes(skill.dependentSkill)
            ) {
              return true;
            } else {
              return false;
            }
          case 7:
            if (this.selectedClassSkills.tier7.length === 0) {
              return true;
            } else {
              return false;
            }
          default:
            return true;
        }
      }
    },
    toggleSkill(skill) {
      if (this.isSkillSelected(skill.id)) {
        this.$store.dispatch('heroBuild/removeSelectedSkill', {
          isMainClass: this.isMainClass,
          tierNumber: skill.tier,
          tier: `tier${skill.tier}`,
          skillId: skill.id,
        });
      } else {
        this.$store.dispatch('heroBuild/addSelectedSkill', {
          isMainClass: this.isMainClass,
          tierNumber: skill.tier,
          tier: `tier${skill.tier}`,
          skill: skill,
        });
      }
    },
    getSkillImage(skill) {
      if (skill.imageSrc) {
        return skill.imageSrc;
      } else {
        switch (skill.skillType) {
          case 'Attack':
          case 'Execute':
            if (skill.damageType === 'Physical') {
              return 'https://defi-kingdoms.b-cdn.net/art-assets/items/finesse-crystal-greater.gif';
            } else {
              return 'https://defi-kingdoms.b-cdn.net/art-assets/items/wit-crystal-greater.gif';
            }
          case 'Barrier':
            return 'https://defi-kingdoms.b-cdn.net/art-assets/items/insight-crystal-greater.gif';
          case 'Buff':
            return 'https://defi-kingdoms.b-cdn.net/art-assets/items/swiftness-stone-greater.gif';
          case 'Heal':
            return 'https://defi-kingdoms.b-cdn.net/art-assets/items/vigor-crystal-greater.gif';
          case 'Debuff':
            return 'https://defi-kingdoms.b-cdn.net/art-assets/items/might-stone-greater.gif';
          case 'Resurrect':
            return 'https://defi-kingdoms.b-cdn.net/art-assets/items/crystal-heroGen0.gif';
          case 'Defense':
            return 'https://defi-kingdoms.b-cdn.net/art-assets/items/fortitude-stone.gif';
          case 'Utility':
            return 'https://defi-kingdoms.b-cdn.net/art-assets/items/fortune-crystal-greater.gif';
          default:
            return '';
        }
      }
    },
  },
};
</script>

<style scoped>
strong.skill-name {
  color: white;
  font-size: 0.9rem;
}

p.skill-cast-time {
  color: white;
  font-size: 0.75rem;
}

p.skill-requirement {
  color: white;
  font-size: 0.7rem;
}

.v-row strong {
  margin-right: 10px;
  font-size: 0.9rem;
}

.v-tooltip p {
  white-space: pre-wrap;
}

.skill-button {
  min-width: 50px;
  width: 50px;
  min-height: 50px;
  height: 50px;
  border: 2px solid #00ff00;
  border-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
}

.skill-button span.skill-cost {
  position: absolute;
  right: 0;
  bottom: 0;
  color: white;
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
    1px 1px 0 black;
  font-weight: 700;
}

.skill-button.selected span.skill-cost {
  color: yellow;
}

.skill-button.selected {
  border: 3px solid yellow !important;
}

/* removes grey hover effect */
.skill-button::before {
  background-color: transparent;
}

button:disabled,
button[disabled] {
  border: 2px solid grey;
}

.skill-button.selected:disabled {
  opacity: 1;
}

.skill-subtitle {
  font-size: 0.7rem;
}

.skill-range {
  float: right;
}

.skill-description {
  color: #ffff00;
  font-size: 0.8rem;
  padding: 0;
  white-space: pre-line;
}
</style>
