<template>
  <v-dialog :value="showDeleteDialog" :max-width="isMobile ? '50%' : '30%'">
    <v-card>
      <v-card-title>Delete Build</v-card-title>
      <v-divider></v-divider>
      <v-container>
        <p>
          Deleting the build is permanent for your account. Other people you
          have share the build with a link will no longer be able to view it.
        </p>
        <p>Are you sure you want to delete your build?</p>
      </v-container>
      <v-divider></v-divider>
      <v-container class="action-buttons">
        <v-row justify="center">
          <v-btn
            min-width="40%"
            variant="tonal"
            color="primary"
            @click="closeDeleteDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
            min-width="40%"
            variant="tonal"
            color="red"
            @click="confirmDelete()"
          >
            Delete
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import resolutionHelper from '../../utils/resolutionHelper.js';

export default {
  props: {
    showDeleteDialog: Boolean,
  },
  emits: ['closeDeleteDialog', 'confirmDeleteBuild'],
  computed: {
    isMobile() {
      return resolutionHelper.isMobile();
    },
  },
  methods: {
    closeDeleteDialog() {
      this.$emit('closeDeleteDialog');
    },
    confirmDelete() {
      this.$emit('confirmDeleteBuild');
    },
  },
};
</script>

<style scoped>
.v-card-title {
  text-align: center;
}

.v-divider {
  margin-bottom: 10px;
}

.action-buttons {
  margin-bottom: 10px;
}

.action-buttons .v-btn {
  margin: 5px;
}

.v-container p {
  margin-bottom: 10px;
}
</style>
