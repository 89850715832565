import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import VueGtag from 'vue-gtag';

let app;
const auth = getAuth();

onAuthStateChanged(auth, () => {
  if (!app) {
    app = createApp(App);

    app.use(store);
    app.use(router);
    app.use(vuetify);
    app.use(
      VueGtag,
      {
        config: { id: 'G-HL500FW6RH' },
      },
      router
    );

    app.mount('#app');
  }
});
