import {
  openHeaderMenu,
  saveBuildDialog,
  savedBuildActionButtons,
  updateBuildDialog,
  deleteBuildConfirmation,
} from './commonData';

export default [
  openHeaderMenu,
  {
    imagePath: require('../../../assets/how-to/skill-calculator/select-skill-calculator-from-menu.png'),
    altText: 'Select the skill calculator menu option',
    description: ['Select the "Skill Calculator" menu option'],
  },
  {
    imagePath: require('../../../assets/how-to/skill-calculator/select-hero-main-class.png'),
    altText: "Select hero's main class",
    description: [
      'You should see a page, where you need to make a class and subclass selection',
      "Select hero's main class first",
    ],
  },
  {
    imagePath: require('../../../assets/how-to/skill-calculator/select-hero-subclass.png'),
    altText: "Select hero's subclass",
    description: ["Then select hero's subclass"],
  },
  {
    imagePath: require('../../../assets/how-to/skill-calculator/skills-tab.png'),
    altText: 'Select skills tab',
    description: [
      'You should see a page, where you can select the hero skills, based on your class and subclass selection',
      'If your hero has matching class and subclass, only one codex will be displayed',
      'If the class and subclass do not match, there will be two hero codices. The main class codex is on the left and the subclass codex is on the right.',
      'You can select skills from the class/subclass codex following the Restrictions, mentioned above.',
      'There is a tooltip with information about each skill on hover.',
      'Skill point cost is displayed on each skill button in the bottom right corner.',
      'Once you select a skill, it is displayed in the selected skills section on the right with information about the skill.',
    ],
  },
  {
    imagePath: require('../../../assets/how-to/skill-calculator/analytics-tab.png'),
    altText: 'Skill analytics tab',
    description: [
      'Once you select the hero skills you want, you can switch to the "Analytics" tab.',
      'There are various analytics widgets with tooltip information about their purpose and some useful tips.',
      'Based on the analytics, you can tweak your hero build and optimize it, depending on your goals and strategy.',
    ],
  },
  {
    imagePath: require('../../../assets/how-to/skill-calculator/skill-calculator-action-buttons-not-saved.png'),
    altText: 'Action Buttons for Not Saved Hero Builds',
    description: [
      'The action buttons are in the top right part of the page in the subheader.',
      '"NEW" button takes you back to class and subclass selection and clears any unsaved changes to the build.',
      'Clicking "SAVE" button shows the "Save Build" dialog. ',
      'The "RESET" button will preserve the main class and subclass selection and will clear all selected skills, so you can start from scratch.',
    ],
  },
  saveBuildDialog,
  savedBuildActionButtons,
  updateBuildDialog,
  deleteBuildConfirmation,
];
