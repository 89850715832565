<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col cols="11">
          {{ `Active vs Passive (${skillsCount})` }}
        </v-col>
        <v-col cols="1">
          <tooltip-icon class="information-tooltip" :text="tooltipText">
          </tooltip-icon>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="skillsCount !== 0">
      <Bar
        :data="activePassiveChartData"
        :options="activePassiveChartOptions"
      />
    </div>
    <div v-else>There are no skills selected</div>
  </div>
</template>

<script>
import TooltipIcon from '../common/TooltipIcon.vue';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { Bar } from 'vue-chartjs';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default {
  components: {
    TooltipIcon,
    Bar,
  },
  props: {
    skillsActivePassive: Object,
  },
  data() {
    return {
      tooltipText:
        'The chart shows the ratio between selected active and passive skills.\n\nTips:\n- hero mana is a limited resource for each battle, that can be replenished ONLY by mana potions\n- certain skills and the Efficient passive trait can help spending mana more efficiently\n- if you expect mana issues in general, especially in prolonged fights, you can emphasize on selecting more passive skills and using basic attacks',
    };
  },
  computed: {
    skillsCount() {
      let count = 0;

      Object.values(this.skillsActivePassive).forEach((c) => (count += c));

      return count;
    },
    activePassiveChartOptions() {
      return {
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
    activePassiveChartData() {
      return {
        labels: Object.keys(this.skillsActivePassive),
        datasets: [
          {
            backgroundColor: ['#0099cc', '#ffff4d'],
            data: Object.values(this.skillsActivePassive),
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
@media (max-width: 900px) {
  .information-tooltip {
    font-size: 0.7rem;
  }
}
</style>
