<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col cols="11">
          {{ `Combo Skills: (${skillsCount})` }}
        </v-col>
        <v-col cols="1">
          <tooltip-icon class="information-tooltip" :text="tooltipText">
          </tooltip-icon>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="subheader" v-if="skillsCount !== 0">
      <v-row>
        <v-col cols="3"> Selected Skill </v-col>
        <v-col cols="9"> Combo Skills </v-col>
      </v-row>
      <v-row v-for="(skill, index) in skillsWithComboSkills" :key="index">
        <v-col cols="3">
          <v-chip variant="text">
            {{ skill.name }}
          </v-chip>
        </v-col>
        <v-col class="data" cols="9">
          <v-chip
            v-for="(comboSkill, index) in skill.comboSkills"
            :key="index"
            variant="outlined"
            :color="getChipColor(comboSkill)"
          >
            {{ getComboSkillName(comboSkill) }}
          </v-chip>
        </v-col>
      </v-row>
    </v-container>
    <div v-else>There are no combo skills selected</div>
  </div>
</template>

<script>
import TooltipIcon from '../common/TooltipIcon.vue';
import heroSkillsHelper from '../../../utils/heroSkillsHelper';

export default {
  components: {
    TooltipIcon,
  },
  props: {
    selectedSkills: Array,
  },
  data() {
    return {
      tooltipText:
        'The table shows a list of selected skills that have combo interactions and additional effects with other skills. The combo skills are color coded as selected(green) or not(red) for this build.\nA NOTE: If you have selected Tier 6 skill that "upgrades" a basic Tier 1-5 skill, it will be marked with green color, covering the combo, and its basic version will be marked with red color.\n\nTips:\n- you need to decide if the combo effects are worth doing the whole combo, especially if more than one active skills with a cost are involved in a complex order\n- easy complexity combos that are actually very efficient(does not cost mana and/or DoD) can be done with a passive skill. You just need to have the passive skill selected in your build\n- medium complexity combos require to cast one skill the previous turn or one or more skills anytime during the battle\n- high complexity combos are high risk, high gain. Usually they require casting more than one skill in a SPECIFIC order and an experienced opponent will notice and try to interrupt the combo\n- beware of medium and high complexity combos that require casting two or more skills, sometimes in a SPECIFIC order, to make a combo. This may be very mana heavy, you may not have the right setup in the battle for this combo and if you go for it you may put yourself at a big disadvantage. It is very likely that you will be forced to select the required skills for the combo, that are not optimal for the team composition as a whole',
    };
  },
  computed: {
    skillsCount() {
      return this.skillsWithComboSkills.length;
    },
    skillsWithComboSkills() {
      let comboSkills = [];

      this.selectedSkills.forEach((skill) => {
        if (skill.comboSkills.length !== 0) {
          comboSkills.push(skill);
        }
      });

      return comboSkills;
    },
  },
  methods: {
    getComboSkillName(skillId) {
      return heroSkillsHelper.getSkillName(skillId);
    },
    getChipColor(skillId) {
      let isCovered = false;

      for (const skill of this.selectedSkills) {
        if (skill.id === skillId) {
          isCovered = true;
          break;
        }
      }

      if (isCovered) {
        return 'green';
      } else {
        return 'red';
      }
    },
  },
};
</script>

<style scoped>
.v-chip {
  font-size: 1rem !important;
  margin: 5px 5px 5px 0;
}

.data {
  padding: 5px;
}

.subheader {
  margin-top: 0;
  padding-top: 0;
}

@media (max-width: 900px) {
  .information-tooltip {
    font-size: 0.7rem;
  }
}
</style>
