<template>
  <div>
    <v-row>
      <v-col cols="12" md="2"></v-col>
      <v-col cols="12" md="8">
        <h1 class="d-flex justify-center">Compose Team</h1>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <v-list-item>
          This feature allows users to compose teams of 3 heroes, by selecting a
          hero build for Positions 1, 2, and 3. You can experiment with
          different hero combinations and look for synergies for the optimal
          hero team for your combat strategy.
        </v-list-item>
        <v-list-item>
          "Skills" tab contains on overview of all selected heros' skills.
        </v-list-item>
        <v-list-item>
          The "Analytics" tab includes many widgets, displaying various team
          build aspects like selected team skill types, attack damage type and
          area, etc.
        </v-list-item>
        <v-list-item>
          This feature requires an account, because you need to use saved hero
          builds, in order to build your team composition.
        </v-list-item>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <h2 class="d-flex justify-center">Step by Step Guide</h2>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <image-with-description
          v-for="(item, index) in stepByStepData"
          :key="index"
          :imagePath="item.imagePath"
          :altText="item.altText"
          :description="item.description"
        ></image-with-description>
      </v-col>
      <v-col cols="12" md="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import ImageWithDescription from './ImageWithDescription.vue';
import composeTeamData from '../../../data/get-started/how-to/composeTeamData';

export default {
  components: {
    ImageWithDescription,
  },
  data() {
    return {
      stepByStepData: composeTeamData,
    };
  },
};
</script>

<style scoped>
.h1 {
  font-size: 2.2rem;
}

.h2 {
  font-size: 1.7rem;
}

.h4 {
  font-size: 1.2rem;
}
</style>
