<template>
  <v-footer elevation="4">
    <v-container>
      <v-row class="d-flex justify-center" no-gutters>
        <v-col cols="12" sm="auto" class="text-center">
          <p>
            <strong>Tip Jar: </strong>0x409C983796a1440F862c361C056C4304a78f983e
          </p>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center" no-gutters>
        <v-col cols="12" sm="auto" class="text-center">
          {{ new Date().getFullYear() }} —
          <v-icon icon="mdi-sword-cross"></v-icon> <strong>DFK Combat</strong>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<style scoped>
.v-footer {
  max-height: 8%;
}
</style>
