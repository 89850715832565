<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col cols="11">
          <strong>{{ `Attack Skill Range (${skillsCount})` }}</strong>
        </v-col>
        <v-col cols="1">
          <tooltip-icon class="information-tooltip" :text="tooltipText">
          </tooltip-icon>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="skillsCount !== 0">
      <Bar :data="attackRangeChartData" :options="attackRangeChartOptions" />
    </div>
    <div v-else>There are no attack skills selected</div>
  </div>
</template>

<script>
import TooltipIcon from '../common/TooltipIcon.vue';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { Bar } from 'vue-chartjs';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default {
  components: {
    TooltipIcon,
    Bar,
  },
  props: {
    skillsAttackRange: Object,
  },
  data() {
    return {
      tooltipText:
        'The chart shows the range of selected attack skills.\n\nRange 1 attack skills can target only the frontline hero in P1(position 1) in the enemy team.\nRange 2 attack skills can target enemy heroes in P1 and P2.\nRange 3 attack skills can target any of the enemy heroes and are the only ones that can reach the backline in P3.\n\nTips:\n- if your build has too short range, you may not be able to reach a squishy enemy hero in the backline, that you could otherwise easily "nuke"\n- short range builds may benefit from repositioning effects like Push and Pull, in order to disturb the structure of the enemy team and position squishier enemy heroes in range\n- a build with emphasize on range 3 attack skills and basic attacks can target any hero in the enemy team, but usually long range heroes are squishy and need a solid frontline that provides resistance or completely negates Push and Pull effects',
    };
  },
  computed: {
    skillsCount() {
      let count = 0;

      Object.values(this.skillsAttackRange).forEach((c) => (count += c));

      return count;
    },
    attackRangeChartOptions() {
      return {
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
    attackRangeChartData() {
      return {
        labels: Object.keys(this.skillsAttackRange),
        datasets: [
          {
            backgroundColor: ['#ffffff', '#ffff4d', '#ff6600'],
            data: Object.values(this.skillsAttackRange),
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
@media (max-width: 900px) {
  .information-tooltip {
    font-size: 0.7rem;
  }
}
</style>
