export default [
  {
    title: '09/07/2023 Contests Feature Release',
    items: [
      {
        subtitle: 'Contests Release',
        descriptionItems: [
          "this feature comes just in time to celebrate the release of all Basic classes AND DeFi Kingdoms partnership with Dragon's Crossing",
          'check out our first contest with awesome DFK and DC prizes!',
          'hurry up, entries for the contest will be accepted until 09/12/2003 11:59 PM',
        ],
      },
      {
        subtitle: 'Bug Fixes',
        descriptionItems: ['fixed class codices data issues'],
      },
    ],
  },
  {
    title: '09/06/2023 Scholar Codex Release',
    items: [
      {
        subtitle: 'Scholar Codex Release',
        descriptionItems: [
          'import the data related to the Scholar codex',
          'the Scholar is available at DFK Combat',
        ],
      },
      {
        subtitle: 'Change Display Name',
        descriptionItems: [
          'you can now change your display name, by clicking on your profile in the top right corner of the header',
        ],
      },
      {
        subtitle: 'Critical Bug Fix',
        descriptionItems: ['fixed infinite page reloading after login/signup'],
      },
    ],
  },
  {
    title: '07/01/2023 Monk Codex Release',
    items: [
      {
        subtitle: 'Monk Codex Release',
        descriptionItems: [
          'import the data related to the Monk codex',
          'the Monk is available at DFK Combat',
        ],
      },
    ],
  },
  {
    title: '04/24/2023 DFK Combat is Officially DeFi Kingdoms partner',
    items: [
      {
        subtitle: 'Home Page Modifications',
        descriptionItems: [
          'information about the DeFi Kingdoms partnership is added',
          'added information for easier DFK Combat onboarding with a link to the "How to Use This Tool" section on the "Getting Started" page',
          'removed invalid resources',
        ],
      },
      {
        subtitle: 'How to Use This Tool',
        descriptionItems: [
          'a new section is added on the "Getting Started" page, that should help with onboarding with DFK Combat',
          'there is an overview and detailed explanation of all currently deployed features',
        ],
      },
      {
        subtitle: 'Fixes',
        descriptionItems: ['minor UI fixes and improvements'],
      },
    ],
  },
  {
    title: '04/11/2023 Legionnaire Codex Release',
    items: [
      {
        subtitle: 'Legionnaire Codex Release',
        descriptionItems: [
          'import the data related to the Legionnaire codex',
          'the Legionnaire is available at DFK Combat',
        ],
      },
      {
        subtitle: 'Fixes',
        descriptionItems: ['minor UI fixes', 'minor Hero data fixes'],
      },
    ],
  },
  {
    title: '03/23/2023 Updates Related to Combat Testing Release',
    items: [
      {
        subtitle: 'Hero Data Updates',
        descriptionItems: [
          'Update the codex data of the Archer, Priest, and Knight classes',
          'Update skill images for the Archer, Priest, and Knight classes, Tiers 1-5',
          'Modify all heroes data structure to be closer to what the DFK team released for Combat Testing',
        ],
      },
      {
        subtitle: 'UI Improvements',
        descriptionItems: [
          "Align the DFK Combat's UI to what the DFK team released for Combat Testing",
          'Some general UI improvements for common app components',
        ],
      },
    ],
  },
  {
    title: '03/21/2023 Getting Started Page Release',
    items: [
      {
        subtitle: 'Getting Started Hero Classes Section',
        descriptionItems: [
          'contains information about hero classes with released combat codices',
          'the first hero class section includes information about skill disciplines, scaling stats, and class analytics',
          'the second hero class section is about the class codex',
        ],
      },
      {
        subtitle: 'Improved Analytics',
        descriptionItems: [
          'Hero skills that increase the defense or protect team members are classified as Defense skills',
          'Defense hero skills look like greater fortitude stone',
        ],
      },
    ],
  },
  {
    title: '03/16/2023 Optimize DFK Combat for Mobile Devices',
    items: [
      {
        subtitle: 'Mobile Device Optimization',
        descriptionItems: [
          'almost all app components were optimized to provide a much better experience for the mobile device users',
          'one of the major changes for both mobile and PC is moving the navigation from the header in a side navigation menu, opened from the hamburger menu',
          'the application title in the header is clickable and navigates to the home page',
          'many minor UX/UI changes to colors, font sizes, spacing, etc.',
        ],
      },
      {
        subtitle: 'Add Sample Visualization for Hero Skills',
        descriptionItems: [
          'Added temporary visualizations, for the skills to reflect their type, until we have official images',
          'Physical Attack and Execute hero skills look like greater finesse crystal',
          'Magical Attack and Execute hero skills look like greater wit crystal',
          'Barrier hero skills look like greater insight crystal',
          'Heal hero skills look like greater vigor crystal',
          'Buff hero skills look like greater swiftness stone',
          'Debuff hero skills look like greater might stone',
          'Resurrection hero skills look like gen0 crystal',
        ],
      },
    ],
  },
  {
    title: '03/14/2023 Thief Codex Release',
    items: [
      {
        subtitle: 'Thief Codex Release',
        descriptionItems: [
          'import the data related to the Thief codex',
          'the Thief is available at DFK Combat',
        ],
      },
    ],
  },
  {
    title: '03/12/2023 Team Analytics Release',
    items: [
      {
        subtitle: 'Team Analytics feature',
        descriptionItems: [
          'analytics tab is showing team build analytics when composing a team or viewing a saved team',
          'select at least one hero for the team in order to view the charts in the analytics tab',
          'general analytics: Skill Types and Active vs Passive',
          'attack skills analytics: Attack Skill Range, Attack Area, Attack Damage Type',
          'utility skills analytics: Buffs and Enemy Debuffs',
          'combo analytics: Combo Skills and Skills with Combo Effects',
        ],
      },
      {
        subtitle: 'Extended Hero Analytics',
        descriptionItems: [
          'hero analytics includes the combo analytics group',
          'combo analytics: Combo Skills and Skills with Combo Effects',
        ],
      },
      {
        subtitle: 'Information Tooltip',
        descriptionItems: [
          'add information tooltips in the Skill Calculator and when we view a saved hero',
          'information tooltips are available for skill codices, selected skills, and analytics',
          'restrictions and requirements are explained in information tooltips for skill codices',
          'each analytics widget has an information tooltip with explanations AND TIPS from iknowkungfu',
        ],
      },
    ],
  },
  {
    title: '03/02/2023 Compose Team Feature Release',
    items: [
      {
        subtitle: 'Compose Team feature',
        descriptionItems: [
          'logged users will be able to compose a team of 3 heroes',
          'save, update, share, and delete hero teams',
          'you need to select heroes for all positions (P1, P2, and P3) to be able to save/update the team',
          'Team, Skills, and Analytics tabs are available when composing or viewing saved or shared team',
          'Team tab: select heroes for your team. In order to do that, you need to have saved hero builds',
          'Skills tab: a summary of the skills of the selected heroes',
          'Analytics tab: coming soon...',
        ],
      },
      {
        subtitle: 'Team Builds feature',
        descriptionItems: [
          'the main app header contains My Builds dropdown button with options: Hero Builds and Team Builds',
          'Team Builds contains a list of your saved hero teams',
        ],
      },
      {
        subtitle: 'QOL Improvements',
        descriptionItems: [
          'added Delete Confirmation Dialog',
          'Clear Filters button on Hero Builds and Team Builds',
          'toned down bright colors of some elements',
          'small UI improvements on the Hero Analytics tab',
        ],
      },
    ],
  },
  {
    title: '02/28/2023 Quality of Life Improvements',
    items: [
      {
        subtitle: 'Add Modal Dialog when Saving/Updating Hero Builds',
        descriptionItems: [
          'the dialog provides ability to enter a build title and tags',
          'multiple tags can be added by entering a tag label and pressing Enter',
          'the hero build cards at My Builds feature are updated with the build title and tags',
        ],
      },
      {
        subtitle: 'Enhanced Search at My Builds feature',
        descriptionItems: [
          'search by word/text for partial matches in build title, hero class and subclass, tags, and level',
          'tags multi-select added that contains all your tags',
          'the search enhancements should help a lot when hero builds start piling up',
        ],
      },
    ],
  },
  {
    title: '02/26/2023 Hero Build Analytics Release',
    items: [
      {
        subtitle: 'Review and Update Class Skills Data',
        descriptionItems: [
          'review the class skills data for incorrect entries',
          'update skills data with additional properties to facilitate the Hero Build Analytics feature',
        ],
      },
      {
        subtitle: 'Hero build analytics feature',
        descriptionItems: [
          'skills and analytics tab in the Skill Calculator and when viewing a saved hero build',
          'select at least one skill in order to view the charts in the analytics tab',
          'general analytics: Skill Scaling Stats, Skill Types, Active vs Passive',
          'attack skills analytics: Attack Skill Range, Attack Area, Attack Damage Type',
          'utility skills analytics: Buffs, Enemy Debuffs',
        ],
      },
      {
        subtitle: 'Bug Fixes',
        descriptionItems: [
          'a couple class skills were updated to show correct information',
          'saved hero builds are not affected and should display the correct information',
          'a couple of UI bugs squashed',
          '100 level cap for hero builds',
          '15 skill points cap for subclass codex',
        ],
      },
    ],
  },
  {
    title: '02/22/2023 Official DFK Combat App Release',
    items: [
      {
        subtitle: 'Login and Sign up feature',
        descriptionItems: [
          'skill calculator can be viewed without registration',
          'registration is required to save and share hero builds, and view them from My Builds feature later',
        ],
      },
      {
        subtitle: 'Skill Calculator feature',
        descriptionItems: [
          'create a hero build selecting hero skills',
          'save hero builds',
          'share a link with saved hero build',
        ],
      },
      {
        subtitle: 'My Builds feature',
        descriptionItems: ['access your saved hero builds'],
      },
    ],
  },
];
