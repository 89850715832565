<template>
  <v-app>
    <the-header></the-header>
    <the-snackbar></the-snackbar>
    <v-main>
      <v-container>
        <router-view :key="$route.path" />
      </v-container>
    </v-main>
    <the-footer></the-footer>
  </v-app>
</template>

<script>
import TheHeader from '../src/components/layout/TheHeader.vue';
import TheFooter from '../src/components/layout/TheFooter.vue';
import TheSnackbar from '../src/components/layout/TheSnackbar.vue';

export default {
  components: {
    TheHeader,
    TheFooter,
    TheSnackbar,
  },
};
</script>

<style>
body::-webkit-scrollbar {
  display: none;
}
</style>
