<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col v-for="index in teamSize" :key="index" cols="12" md="4">
          <h3>{{ `Position ${index}` }}</h3>
          <div v-if="isHeroSelectedForPosition(index)">
            <v-container>
              <header>
                <h3 class="class-name">
                  {{ getHeroClassForPosition(index) + ' Class:' }}
                </h3>
              </header>
              <selected-skills
                :isMainClass="true"
                :selectedSkills="getSelectedClassSkills(index)"
              >
              </selected-skills>
            </v-container>
            <v-container v-if="showSubclassForPosition(index)">
              <header>
                <h3 class="class-name">
                  {{ getHeroSubclassForPosition(index) + ' Subclass:' }}
                </h3>
              </header>
              <selected-skills
                :isMainClass="false"
                :selectedSkills="getSelectedSubclassSkills(index)"
              >
              </selected-skills>
            </v-container>
          </div>
          <v-container class="no-hero-selected" v-else>
            {{ `You have not selected a hero for P${index}` }}
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import teamBuildHelper from '../../utils/teamBuildHelper.js';

import SelectedSkills from '../skill-calculator/select-skills/skills/SelectedSkills.vue';

export default {
  components: {
    SelectedSkills,
  },
  computed: {
    teamSize() {
      return this.$store.getters['teamBuild/getTeamSize'];
    },
    teamHeroPositions() {
      return this.$store.getters['teamBuild/getHeroPositions'];
    },
  },
  methods: {
    isHeroSelectedForPosition(position) {
      let isFound = false;

      this.teamHeroPositions.forEach((p) => {
        if (p.position === position) {
          isFound = true;
        }
      });

      return isFound;
    },
    getHeroClassForPosition(position) {
      const heroPosition = this.teamHeroPositions.find(
        (p) => p.position === position
      );

      return heroPosition.heroBuild.heroClass;
    },
    getHeroSubclassForPosition(position) {
      const heroPosition = this.teamHeroPositions.find(
        (p) => p.position === position
      );

      return heroPosition.heroBuild.heroSubclass;
    },
    showSubclassForPosition(position) {
      const notMatchingClassAndSubclass =
        this.getHeroClassForPosition(position) !==
        this.getHeroSubclassForPosition(position);

      let subclassHasSkills = false;

      this.teamHeroPositions.forEach((p) => {
        if (
          p.position === position &&
          p.heroBuild.selectedSubclassSkills.length !== 0
        ) {
          subclassHasSkills = true;
        }
      });

      return notMatchingClassAndSubclass && subclassHasSkills;
    },
    getSelectedClassSkills(position) {
      const hero = this.teamHeroPositions.find((p) => p.position === position);

      return teamBuildHelper.getClassSkills(hero);
    },
    getSelectedSubclassSkills(position) {
      const hero = this.teamHeroPositions.find((p) => p.position === position);

      return teamBuildHelper.getSubclassSkills(hero);
    },
  },
};
</script>

<style scoped>
h3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-hero-selected,
header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.class-name {
  color: orangered;
}
</style>
