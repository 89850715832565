<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col cols="11">
          {{ `Skills with Combo Effects: (${skillsCount})` }}
        </v-col>
        <v-col cols="1">
          <tooltip-icon class="information-tooltip" :text="tooltipText">
          </tooltip-icon>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="subheader" fluid v-if="skillsCount !== 0">
      <v-row dense>
        <v-col cols="4"> Selected Skill </v-col>
        <v-col cols="4"> Combo Effects </v-col>
        <v-col cols="4"> Covered Effects </v-col>
      </v-row>
      <v-row v-for="(skill, index) in skillsWithComboEffectsData" :key="index">
        <v-col class="data" cols="4">
          <v-chip variant="text">
            {{ skill.name }}
          </v-chip>
        </v-col>
        <v-col class="data" cols="4">
          <v-chip
            v-for="(comboEffect, index) in skill.comboEffects"
            :key="index"
            variant="outlined"
            :color="getChipColor(comboEffect, skill)"
          >
            {{ comboEffect }}
          </v-chip>
        </v-col>
        <v-col class="data" cols="4">
          <v-chip
            v-if="skill.coveredEffects.length === 0"
            variant="text"
            color="red"
          >
            0
          </v-chip>
          <v-chip
            v-else
            v-for="(coveredEffect, index) in skill.coveredEffects"
            :key="index"
            variant="outlined"
            color="green"
          >
            {{ `x${coveredEffect.effectCount} ${coveredEffect.effectName}` }}
          </v-chip>
        </v-col>
      </v-row>
    </v-container>
    <div v-else>There are no skills with combo effects selected</div>
  </div>
</template>

<script>
import TooltipIcon from '../common/TooltipIcon.vue';

export default {
  components: {
    TooltipIcon,
  },
  props: {
    skillsWithComboEffects: Array,
    buffsAndAilments: Object,
  },
  data() {
    return {
      tooltipText:
        'The table shows a list of selected skills that have combo interactions and additional effects with specific Effects - buffs and enemy debuffs.\n\nThe first table column Selected Skill displays the skill name.\nThe second column Combo Effects contains information about the required effects for the combo, which are color coded as covered(green) or not covered(red) for this build.\nThe last third column Covered Effects displays the Effect that covers the requirements for the combo and the count of selected skills that provide this effect. If there is no Effect covering the combo, 0 is displayed.\n\nTips:\n- it is possible that a hero has a skill with combo Effect that cannot be covered by the same hero. The Effect may be covered by another hero in the same team, but think carefully if it is worth having that team composition.\n- there may be more than one hero on your team that can cover required Effect for a combo. This is a very good scenario, if it does not hurt the overall team composition.\n- there may be team compositions with great synergies regarding skills with combo Effects, because one hero may apply the effect for X rounds, and then all team hero members can cast skills that have a combo with this Effect',
    };
  },
  computed: {
    skillsCount() {
      return this.skillsWithComboEffectsData.length;
    },
    skillsWithComboEffectsData() {
      let data = [];

      this.skillsWithComboEffects.forEach((skill) => {
        let skillObject = {
          ...skill,
          coveredEffects: [],
        };

        skill.comboEffects.forEach((effect) => {
          for (let key in this.buffsAndAilments) {
            if (key.toString().includes(effect)) {
              skillObject.coveredEffects.push({
                effectName: key,
                effectCount: this.buffsAndAilments[key],
              });
              break;
            }
          }
        });

        data.push(skillObject);
      });

      return data;
    },
  },
  methods: {
    getChipColor(comboEffect, skill) {
      let isCovered = false;

      for (const coveredEffect of skill.coveredEffects) {
        if (coveredEffect.effectName.toString().includes(comboEffect)) {
          isCovered = true;
          break;
        }
      }

      if (isCovered) {
        return 'green';
      } else {
        return 'red';
      }
    },
  },
};
</script>

<style scoped>
.v-chip {
  font-size: 1rem !important;
  margin: 5px 5px 5px 0;
}

.data {
  padding: 5px;
}

.subheader {
  margin-top: 0;
  padding-top: 0;
}

@media (max-width: 900px) {
  .information-tooltip {
    font-size: 0.7rem;
  }
}
</style>
