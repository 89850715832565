<template>
  <div>
    <v-progress-circular
      v-if="isTeamDataLoading"
      indeterminate
      :size="100"
      :width="8"
      color="primary"
    ></v-progress-circular>
    <div v-else-if="teamBuildExists">
      <team-header
        :isSavedBuild="true"
        :userIsBuildOwner="userIsBuildOwner"
        :teamBuildTitle="teamBuildTitle"
        :teamBuildTags="teamBuildTags"
        @toggleTab="toggleTab"
      ></team-header>
      <v-window v-model="tab">
        <v-window-item value="select-team">
          <select-team-tab></select-team-tab>
        </v-window-item>
        <v-window-item value="team-skills">
          <team-skills-tab></team-skills-tab>
        </v-window-item>
        <v-window-item value="team-analytics">
          <team-analytics-tab></team-analytics-tab>
        </v-window-item>
      </v-window>
    </div>
    <under-construction
      v-else
      :pageHeader="`Team build with id: '${teamBuildId}' was not found!`"
    ></under-construction>
  </div>
</template>

<script>
import { db } from '@/firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import getUser from '../composables/getUser';

import TeamHeader from '../components/compose-team/TeamHeader.vue';
import SelectTeamTab from '../components/compose-team/SelectTeamTab.vue';
import TeamSkillsTab from '../components/compose-team/TeamSkillsTab.vue';
import TeamAnalyticsTab from '../components/compose-team/TeamAnalyticsTab.vue';
import UnderConstruction from '../components/ui/UnderConstruction.vue';

export default {
  components: {
    TeamHeader,
    SelectTeamTab,
    TeamSkillsTab,
    TeamAnalyticsTab,
    UnderConstruction,
  },
  setup() {
    const user = getUser();

    const route = useRoute();
    const store = useStore();
    const teamBuildId = ref(route.params.id);
    const teamBuildExists = ref(false);
    const isTeamDataLoading = ref(true);
    const teamBuildOwner = ref(null);
    const teamBuildTitle = ref('');
    const teamBuildTags = ref([]);

    // reset the current hero build
    store.dispatch('teamBuild/resetTeamBuild');

    const docRef = doc(db, 'teamBuilds', teamBuildId.value);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        teamBuildExists.value = true;

        // Reset store team build data
        store.dispatch('teamBuild/resetTeamBuild');

        // Get the team build data and load the store
        const teamBuildData = docSnap.data();
        teamBuildOwner.value = teamBuildData.createdBy;
        teamBuildTitle.value = teamBuildData.title;
        teamBuildTags.value = teamBuildData.tags;

        // The team size
        store.dispatch('teamBuild/setTeamSize', {
          teamSize: teamBuildData.teamSize,
        });

        // The hero positions
        teamBuildData.heroPositions.forEach((p) => {
          const docRef = doc(db, 'heroBuilds', p.heroBuildId);
          getDoc(docRef).then((docSnap) => {
            if (docSnap.exists()) {
              const heroBuildData = {
                ...docSnap.data(),
                id: docSnap.id,
              };

              const heroPositionData = {
                heroBuild: heroBuildData,
                position: p.position,
              };

              store.dispatch('teamBuild/addHeroPosition', heroPositionData);
            }
          });
        });
      }

      isTeamDataLoading.value = false;
    });

    return {
      teamBuildId,
      teamBuildExists,
      isTeamDataLoading,
      user,
      teamBuildOwner,
      teamBuildTitle,
      teamBuildTags,
    };
  },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    userIsBuildOwner() {
      return this.user !== null && this.user.uid === this.teamBuildOwner;
    },
  },
  methods: {
    toggleTab(value) {
      this.tab = value;
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin-left: 45%;
  margin-top: 20%;
}
</style>
