import classesList from '../data/skill-calculator/class-objects.js';

export default {
  /* takes as parameter an object, processes it and returns a list of skill objects e.g.
    {
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: null,
      tier5: null,
      tier6: [],
      tier7: [],
      tier8: null
    }
  */
  getSkillsAsList(selectedSkills) {
    let skillObjectsList = [];

    // Add all selected class skill objects
    Object.values(selectedSkills).forEach((tier) => {
      if (Array.isArray(tier)) {
        tier.forEach((skill) => {
          skillObjectsList.push(skill);
        });
      } else {
        if (tier !== null) {
          skillObjectsList.push(tier);
        }
      }
    });

    return skillObjectsList;
  },
  // takes as parameter list of hero skill objects and returns an object
  getSkillTypes(skills) {
    let skillTypesObject = {};

    skills.forEach((skill) => {
      const skillType = skill.skillType;

      if (Object.hasOwn(skillTypesObject, skillType)) {
        skillTypesObject[skillType] += 1;
      } else {
        skillTypesObject[skillType] = 1;
      }
    });

    return skillTypesObject;
  },
  // takes as parameter list of hero skill objects and returns an object
  getAttackSkills(skills) {
    let attackSkills = [];

    skills.forEach((skill) => {
      if (skill.skillType === 'Attack' || skill.skillType === 'Execute') {
        attackSkills.push(skill);
      }
    });

    return attackSkills;
  },
  // takes as parameter list of hero skill objects and returns an object
  getSkillsAttackArea(skills) {
    let attackAreaObject = {
      'Single Target': 0,
      AOE: 0,
    };

    skills.forEach((skill) => {
      const attackArea = skill.areaOfEffect.includes('AOE')
        ? 'AOE'
        : 'Single Target';
      attackAreaObject[attackArea] += 1;
    });

    return attackAreaObject;
  },
  // takes as parameter list of hero skill objects and returns an object
  getSkillsAttackDamageType(skills) {
    let attackDamageTypeObject = {
      Physical: 0,
      Magical: 0,
    };

    skills.forEach((skill) => {
      if (Object.hasOwn(attackDamageTypeObject, skill.damageType)) {
        attackDamageTypeObject[skill.damageType] += 1;
      } else {
        attackDamageTypeObject[skill.damageType] = 1;
      }
    });

    return attackDamageTypeObject;
  },
  // takes as parameter list of hero skill objects and returns an object
  getSkillsAttackRange(skills) {
    let attackRangeObject = {
      1: 0,
      2: 0,
      3: 0,
    };

    skills.forEach((skill) => {
      switch (skill.range) {
        case 1:
          attackRangeObject['1'] += 1;
          break;
        case 2:
          attackRangeObject['2'] += 1;
          break;
        case 3:
          attackRangeObject['3'] += 1;
          break;
        default:
          break;
      }
    });

    return attackRangeObject;
  },
  // takes as parameter list of hero skill objects and returns an object
  getSkillBuffs(skills) {
    let buffsObject = {};

    skills.forEach((skill) => {
      skill.buffs.forEach((buff) => {
        if (Object.hasOwn(buffsObject, buff)) {
          buffsObject[buff] += 1;
        } else {
          buffsObject[buff] = 1;
        }
      });
    });

    return buffsObject;
  },
  // takes as parameter list of hero skill objects and returns an object
  getSkillDebuffs(skills) {
    let debuffsObject = {};

    skills.forEach((skill) => {
      skill.ailments.forEach((debuff) => {
        if (Object.hasOwn(debuffsObject, debuff)) {
          debuffsObject[debuff] += 1;
        } else {
          debuffsObject[debuff] = 1;
        }
      });
    });

    return debuffsObject;
  },
  // takes as parameter list of hero skill objects and returns an object
  getActivePassiveSkills(skills) {
    let activePassiveObject = {
      Active: 0,
      Passive: 0,
    };

    skills.forEach((skill) => {
      if (skill.isPassive === true) {
        activePassiveObject['Passive'] += 1;
      } else {
        activePassiveObject['Active'] += 1;
      }
    });

    return activePassiveObject;
  },
  // takes as parameter list of hero skill objects and returns a list with objects
  getSkillsWithComboEffects(skills) {
    let skillsList = [];

    skills.forEach((skill) => {
      if (skill.comboEffects.length !== 0) {
        skillsList.push({
          name: skill.name,
          comboEffects: skill.comboEffects,
        });
      }
    });

    return skillsList;
  },
  getClassObject(className) {
    return classesList[className.toLowerCase()];
  },
  getClassSkill(id) {
    const heroClass = id.substring(0, id.indexOf('-'));
    const tierNumber = parseInt(id.match(/\d+/)[0]);
    const classObject = this.getClassObject(heroClass);
    const skill = classObject.skills[`tier${tierNumber}`].find(
      (s) => s.id === id
    );

    return skill;
  },
  getSkillName(id) {
    return this.getClassSkill(id).name;
  },
};
