<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-container>
          <v-img
            class="image-border"
            :src="imagePath"
            :alt="altText"
            cover
            @click="showDialog = true"
          ></v-img>
          <v-dialog v-model="showDialog" max-width="60%">
            <v-img :src="imagePath" :alt="altText" cover></v-img>
          </v-dialog>
        </v-container>
      </v-col>
      <v-col cols="12" md="8">
        <v-container>
          <v-list-item v-for="(item, index) in description" :key="index">
            {{ item }}
          </v-list-item>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    imagePath: String,
    altText: String,
    description: Array,
  },
  data() {
    return {
      showDialog: false,
    };
  },
};
</script>

<style scoped>
.image-border {
  border: 1px solid #ccc;
}
</style>
