<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col cols="11">
          {{ `Skill Scaling Stats (${scalingStatsCount})` }}
        </v-col>
        <v-col cols="1">
          <tooltip-icon class="information-tooltip" :text="tooltipText">
          </tooltip-icon>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <Bar :data="scalingStatsChartData" :options="scalingStatsChartOptions" />
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../../shared/common/TooltipIcon.vue';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { Bar } from 'vue-chartjs';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default {
  components: {
    TooltipIcon,
    Bar,
  },
  data() {
    return {
      tooltipText:
        'The chart shows which hero stats the selected skills scale the best with.\n\nTips:\n- a quick overview of the stats this build scales the best with and you can make tweaks to your liking\n- if you like certain build you may have particular hero(s) in mind, that you can level up having the scaling stats information in mind\n- we are yet to see the impact of hero stats on skills that scale with them and I think it is closely related to the Basic Attack and Spell formulas',
    };
  },
  computed: {
    skillScalingStats() {
      return this.$store.getters['heroBuild/skillScalingStats'];
    },
    scalingStatsCount() {
      let count = 0;

      Object.values(this.skillScalingStats).forEach((v) => (count += v));

      return count;
    },
    scalingStatsChartOptions() {
      return {
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
    scalingStatsChartData() {
      return {
        labels: Object.keys(this.skillScalingStats),
        datasets: [
          {
            backgroundColor: [
              '#cc0000',
              '#009933',
              '#ff6600',
              '#ffffff',
              '#996633',
              '#0033cc',
              '#0099cc',
              '#ffff4d',
            ],
            data: Object.values(this.skillScalingStats),
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
@media (max-width: 900px) {
  .information-tooltip {
    font-size: 0.7rem;
  }
}
</style>
