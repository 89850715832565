<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <v-container>
          <v-row>
            <v-col cols="1"></v-col>
            <v-col class="class-name" cols="10">
              {{
                `${this.selectedClass.className} Codex: ${classSkillPointsSpent}`
              }}
            </v-col>
            <v-col cols="1">
              <tooltip-icon
                class="information-tooltip"
                :maxWidth="500"
                :text="mainClassTooltipText"
              >
              </tooltip-icon>
            </v-col>
          </v-row>
          <skill-tree :skills="classSkills" :isMainClass="true"></skill-tree>
        </v-container>
      </v-col>
      <v-col cols="12" md="4" v-if="!isClassMatchingSubclass">
        <v-container>
          <v-row>
            <v-col cols="1"></v-col>
            <v-col class="class-name" cols="10">
              {{
                `${this.selectedSubclass.className} Codex: ${subclassSkillPointsSpent}`
              }}
            </v-col>
            <v-col cols="1">
              <tooltip-icon
                class="information-tooltip"
                :maxWidth="500"
                :text="subclassTooltipText"
              >
              </tooltip-icon>
            </v-col>
          </v-row>
          <skill-tree
            :skills="subclassSkills"
            :isMainClass="false"
          ></skill-tree>
        </v-container>
      </v-col>
      <v-col cols="12" md="4" v-if="isSkillSelected">
        <v-container>
          <v-row>
            <v-col cols="1"></v-col>
            <v-col class="class-name" cols="10">
              {{ `Selected ${this.selectedClass.className} Skills:` }}
            </v-col>
            <v-col cols="1">
              <tooltip-icon
                class="information-tooltip"
                :text="selectedSkillsTooltipText"
              >
              </tooltip-icon>
            </v-col>
          </v-row>
        </v-container>
        <selected-skills
          :isMainClass="true"
          :selectedSkills="selectedClassSkills"
        >
        </selected-skills>
        <v-row v-if="subclassSkillPointsSpent !== 0">
          <v-col class="class-name" cols="12">
            {{ `Selected ${this.selectedSubclass.className} Skills:` }}
          </v-col>
        </v-row>
        <selected-skills
          :isMainClass="false"
          :selectedSkills="selectedSubclassSkills"
        >
        </selected-skills>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SkillTree from './skills/SkillTree.vue';
import SelectedSkills from './skills/SelectedSkills.vue';
import TooltipIcon from '../../shared/common/TooltipIcon.vue';

export default {
  components: {
    SkillTree,
    SelectedSkills,
    TooltipIcon,
  },
  data() {
    return {
      mainClassTooltipText:
        "Hero's class codex.\n\nThe requirements for the pick order and various restrictions are implemented in a way that the system will disable skills which are not available for selection.\n\nTHE MAIN RESTRICTION\n- maximum hero level cap of 100 and exactly 100 skill points to spend\n- you can spend all skill points on hero's main class codex or optionally spend up to 15 skill points on hero's subclass codex, if your hero's class and subclass are not matching\n\nTIERS 1-5\n- only one skill can be selected from each tier\n- tier selection goes from Tier 1 to Tier 5\n\nTIER 6\n- the tier is unlocked once a Tier 5 skill is selected\n- you need to have the basic version of the skill selected in Tiers 1-5, in order to have its upgraded version available\n- you can select as many Tier 6 skills as you have available\n- skill cost varies between 5 and 9 skill points, depending on the cost of the basic skill, and the earliest you can have a Tier 6 skill is at hero level 20, if you have an \"upgradeable\" Tier 5 skill selected\n\nTIER 7\n- the tier is unlocked once a Tier 5 skill is selected, you do not have to select Tier 6 skill\n- you can select as many Tier 7 skills as you want\n- skills cost 10 skill points and the earliest you can have it is at hero level 25\n\nTIER 8\n- this tier is available only for heroes that have matching class and subclass (pure class heroes)\n- the tier is unlocked once a Tier 5 skill is selected, you do not have to select Tier 6 or 7 skill first\n- the skill costs 15 skill points and the earliest you can have it is at hero level 30",
      subclassTooltipText:
        'Hero\'s subclass codex.\n\nThe requirements for the various restrictions are implemented in a way that the system will disable skills which are not available for selection.\n\nRESTRICTIONS\n- maximum hero level cap of 100 and exactly 100 skill points to spend\n- you can optionally spend up to 15 skill points on hero\'s subclass codex\n- there is no pick order for the subclass codex skills and to "unlock" picking skills from subclass Tiers 1-7, you need to have at least 1 skill selected in the class codex, corresponding to the same tier\n\nTIERS 1-7\n- only one skill can be selected from each tier up to 15 skill points spent on the subclass codex\n- you need to have the basic version of the skill selected in Tiers 1-5, in order to have its upgraded version available in Tier 6',
      selectedSkillsTooltipText:
        'A list of the selected hero skills and their description.\n\nNotice that Tier 6 skills are displayed together with their required basic skill version from Tiers 1-5, for informational purpose. In reality, Tier 6 skills overwrite their basic versions from Tiers 1-5!',
    };
  },
  computed: {
    selectedClassSkills() {
      return this.$store.getters['heroBuild/selectedClassSkills'];
    },
    selectedSubclassSkills() {
      return this.$store.getters['heroBuild/selectedSubclassSkills'];
    },
    isSkillSelected() {
      return this.$store.getters['heroBuild/selectedSkillObjects'].length !== 0;
    },
    classSkillPointsSpent() {
      return this.$store.getters['heroBuild/classSkillPointsSpent'];
    },
    subclassSkillPointsSpent() {
      return this.$store.getters['heroBuild/subclassSkillPointsSpent'];
    },
    selectedClass() {
      return this.$store.getters['heroBuild/selectedClass'];
    },
    selectedSubclass() {
      return this.$store.getters['heroBuild/selectedSubclass'];
    },
    classSkills() {
      const skills = this.selectedClass.skills;

      if (!this.isClassMatchingSubclass) {
        return {
          tier1: skills.tier1,
          tier2: skills.tier2,
          tier3: skills.tier3,
          tier4: skills.tier4,
          tier5: skills.tier5,
          tier6: skills.tier6,
          tier7: skills.tier7,
        };
      }

      return skills;
    },
    subclassSkills() {
      const skills = this.selectedSubclass.skills;

      return {
        tier1: skills.tier1,
        tier2: skills.tier2,
        tier3: skills.tier3,
        tier4: skills.tier4,
        tier5: skills.tier5,
        tier6: skills.tier6,
        tier7: skills.tier7,
      };
    },
    isClassMatchingSubclass() {
      return this.$store.getters['heroBuild/classAndSubclassMatch'];
    },
  },
};
</script>

<style scoped>
header {
  margin-bottom: 20px;
}

.class-name {
  color: orangered;
  display: flex;
  justify-content: center;
  align-items: center;
}

.class-name strong {
  margin-left: 20px;
}

.information-tooltip {
  float: right;
}
</style>
