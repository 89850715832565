<template>
  <v-snackbar
    v-model="isDisplayed"
    :color="color"
    :timeout="2500"
    location="top right"
  >
    <span>{{ message }}</span>
    <v-btn variant="text" @click="isDisplayed = false">
      <v-icon large>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      isDisplayed: false,
      color: '',
      message: '',
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'snackbar/showMessage') {
        this.message = state.snackbar.content;
        this.color = state.snackbar.color;
        this.isDisplayed = true;
      }
    });
  },
};
</script>

<style scoped>
.v-btn {
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
