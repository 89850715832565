import { createStore } from 'vuex';

import heroBuildModule from './modules/heroBuild/index.js';
import snackbarModule from './modules/snackbar/index.js';
import myHeroBuildsModule from './modules/myHeroBuilds/index.js';
import myTeamBuildsModule from './modules/myTeamBuilds/index.js';
import teamBuildModule from './modules/teamBuild/index.js';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    heroBuild: heroBuildModule,
    snackbar: snackbarModule,
    myHeroBuilds: myHeroBuildsModule,
    myTeamBuilds: myTeamBuildsModule,
    teamBuild: teamBuildModule,
  },
});
