export default {
  className: 'Legionnaire',
  disciplines: [
    {
      name: 'Live by the Shield',
      description:
        "Live by the Shield concentrates on the Legionnaire's defensive aptitude, enhancing their ability to deflect, parry, and withstand the relentless assaults of their enemies. The path of the Shielded Hero proffers heightened protection for both the Legionnaire and their brethren, rendering them nigh impervious to harm. As they refine their prowess, they evolve into towering citadels, poised to shift the balance of battle and endure the fiercest of onslaughts.",
    },
    {
      name: 'Die by the Spear',
      description:
        "Die by the Spear accentuates the Legionnaire's offensive capabilities, sharpening their mastery of the spear to deliver ruinous blows upon their adversaries. These ferocious combatants brandish the spear with elegance and precision, felling their enemies with the fluidity of a coursing river and the fury of a tempest unleashed. As the Legionnaire's skills with the spear evolve, the spear itself transmutes into an extension of their very essence, a conduit for their indomitable spirit. These adept warriors invoke both intimidation and reverence for their ability to penetrate enemy lines and scatter the ranks of their foes. With each attainment of spear mastery, they unlock devastating techniques, empowering them to strike from afar, unleash maelstroms of devastation, and cleave multiple adversaries asunder with a singular, well-aimed thrust.",
    },
    {
      name: 'Born Leader',
      description:
        'The Born Leader discipline path molds its disciples into rousing figures, capable of marshaling their comrades and commanding their forces with sagacity, audacity, and unwavering resolve. These individuals possess an innate gift for stratagem, discerning the strengths and vulnerabilities of their allies and enemies alike. As their mastery of this discipline flourishes, so too does their ability to embolden and fortify their comrades. Their mere presence emerges as a beacon of hope amidst the heat of battle, raising the spirits of their allies and instilling terror in the hearts of their enemies. Born Leaders harness the might of their charisma and conviction to bolster the defenses of their brethren and even alter the course of battle through sheer force of will. Ultimately, Born Leaders ascend to the heights of virtue, rallying their forces from the precipice of defeat and guiding them to resplendent victory. Through their unyielding devotion to their comrades and their cause, these individuals truly embody the essence of leadership, proving that even in the darkest of times, hope shall never falter.',
    },
  ],
  statGrowth: {
    STR: 75,
    DEX: 65,
    AGI: 35,
    VIT: 75,
    END: 80,
    INT: 20,
    WIS: 25,
    LCK: 25,
  },
  healthGrowth: {
    Small: 15,
    Medium: 35,
    Large: 50,
  },
  manaGrowth: {
    Small: 50,
    Medium: 35,
    Large: 15,
  },
  basicAttackStats: [],
  accuracyStats: [],
  defenseStats: [],
  criticalStrikeStats: [],
  skills: {
    tier1: [
      {
        id: 'legionnaire-t1-1',
        tier: 1,
        skillPoints: 1,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Riposte'],
        buffsDuration: 0,
        debuffs: ['Reduced Physical Damage Dealt'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Live by the Shield',
        name: 'Get Even',
        imageSrc: '',
        description: [
          'Activate for a Duration of X.',
          'All physical damage dealt by this Hero is decreased by X%.',
          'Gain X% Riposte.',
        ],
      },
      {
        id: 'legionnaire-t1-2',
        tier: 1,
        skillPoints: 1,
        range: 2,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['END', 'STR', 'DEX'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Retaliate', 'Pierce'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Die by the Spear',
        name: 'Thrust',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*BASIC + X*STR + X*DEX).',
          'This attack has X% Pierce.',
          'For a Duration of X, gain (X + X*END)% chance to Retaliate X.',
        ],
      },
      {
        id: 'legionnaire-t1-3',
        tier: 1,
        skillPoints: 1,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Evasion for Allies'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Born Leader',
        name: 'Inspire',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*BASIC + X*DEX).',
          'If this attack is successful, Allies gain (X + X*VIT)% EVA for a Duration of X.',
        ],
      },
    ],
    tier2: [
      {
        id: 'legionnaire-t2-1',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['END', 'VIT', 'DEX'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Block', 'Physical Defense', 'Health'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Live by the Shield',
        name: 'Live by the Shield',
        imageSrc: '',
        description: ['While in combat, gain:', 'X% BLK', 'X% P.DEF', 'X% HP'],
      },
      {
        id: 'legionnaire-t2-2',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Bleed'],
        scalingStats: ['DEX'],
        ailments: ['Bleed'],
        ailmentsDuration: null,
        buffs: ['Physical Accuracy'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Die by the Spear',
        name: 'Die by the Spear',
        imageSrc: '',
        description: [
          'Each successful attack by this Hero gains a (X + X*DEX)% chance to inflict target enemy with Bleed.',
          'For each active stack of Bleed on the Battlefield, this Hero gains X% P.ACC to a max of X%.',
        ],
      },
      {
        id: 'legionnaire-t2-3',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Sabotage Resistance',
          'Consumable May not Reduce Battle Budget',
        ],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Born Leader',
        name: 'Born Leader',
        imageSrc: '',
        description: [
          'Your Party gains X% resistance to Sabotage.',
          'This effect can be stacked.',
          "When a consumable is used on this Hero's Turn, gain a X% chance to gain the effect without reducing the Battle Budget.",
        ],
      },
    ],
    tier3: [
      {
        id: 'legionnaire-t3-1',
        tier: 3,
        skillPoints: 3,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR'],
        ailments: ['Daze', 'Disarm'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Live by the Shield',
        name: 'Scutum Shove',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*BASIC + X*STR).',
          '(X + X*STR)% chance to Daze target enemy for a Duration of X.',
          '(X + X*STR)% chance to Disarm target enemy for a Duration of X.',
        ],
      },
      {
        id: 'legionnaire-t3-2',
        tier: 3,
        skillPoints: 3,
        range: 2,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'DEX'],
        ailments: ['Bleed', 'Stun'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Die by the Spear',
        name: 'Herculean Heave',
        imageSrc: '',
        description: [
          'Channel for a Duration of X.',
          'Deal physical damage to target enemy in P2 equal to (X*BASIC + X*DEX + X*STR).',
          '(X + X*DEX)% chance to inflict target enemy with Bleed.',
          '(X + X*DEX)% chance to Stun target enemy.',
        ],
      },
      {
        id: 'legionnaire-t3-3',
        tier: 3,
        skillPoints: 3,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'DEX', 'END'],
        ailments: ['Push'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Born Leader',
        name: 'The Boot',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*BASIC + X*STR + X*DEX).',
          '(X + X*END + X*STR)% chance to Push target enemy X.',
          'If successful, gain (X + X*END + X*STR)% chance to Push target enemy an additional X.',
        ],
      },
    ],
    tier4: [
      {
        id: 'legionnaire-t4-1',
        tier: 4,
        skillPoints: 4,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['legionnaire-t3-1'],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Redirect Attacks Targeting Allies', 'Riposte'],
        buffsDuration: null,
        debuffs: ['Amnesia "Look at Me"'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Live by the Shield',
        name: 'Look at Me',
        imageSrc: '',
        description: [
          'Redirect X% of all attacks targeting Allies to this Hero for a Duration of X.',
          'Gain X% Riposte for a Duration X.',
          "COMBO: If 'Scutum Shove' was used on this Hero's last Turn, gain an additional X% Riposte for a Duration of X.",
          'Amnesia X.',
        ],
      },
      {
        id: 'legionnaire-t4-2',
        tier: 4,
        skillPoints: 4,
        range: 2,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['legionnaire-t1-2'],
        comboEffects: [],
        scalingStats: ['DEX'],
        ailments: ['Daze', 'Stun'],
        ailmentsDuration: 0,
        buffs: ['Retaliate'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Die by the Spear',
        name: 'Leg Sweep',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemies in P1 and P2 for (X*BASIC + X*DEX) each.',
          '(X + X*DEX)% chance to Daze target in P1.',
          '(X + X*DEX)% chance to Daze target in P2.',
          'For a Duration of X, gain (X + X*END)% chance to Retaliate X.',
          'COMBO: If this Hero used "Thrust" last Turn, gain a (X + X*DEX)% chance to Stun target hero in P1 for a Duration of X.',
        ],
      },
      {
        id: 'legionnaire-t4-3',
        tier: 4,
        skillPoints: 4,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'legionnaire-t1-3',
          'legionnaire-t3-3',
          'legionnaire-t4-3',
          'legionnaire-t5-3',
          'legionnaire-t6-5',
          'legionnaire-t6-6',
          'legionnaire-t7-8',
          'legionnaire-t7-9',
        ],
        comboEffects: [],
        scalingStats: ['STR', 'DEX', 'INT'],
        ailments: ['Take Additional Damage'],
        ailmentsDuration: 0,
        buffs: [
          'Pull Resistance for Allies',
          'Push Resistance for Allies',
          'Riposte',
        ],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Born Leader',
        name: 'On Me',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*BASIC + X*STR).',
          '(X + X*DEX + X*INT)% chance to Mark target enemy for a Duration of X.',
          'Allies deal X% additional damage to target marked enemy for a Duration of X.',
          'Allies gain (X + X*STR)% resistance to Push and Pull for a Duration of X.',
          "COMBO: If a 'Born Leader' Discipline skill was used on this Hero's last Turn, gain X% Riposte for a Duration of 2.",
        ],
      },
    ],
    tier5: [
      {
        id: 'legionnaire-t5-1',
        tier: 5,
        skillPoints: 5,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'legionnaire-t1-1',
          'legionnaire-t3-1',
          'legionnaire-t4-1',
          'legionnaire-t5-1',
          'legionnaire-t6-1',
          'legionnaire-t6-2',
          'legionnaire-t7-2',
          'legionnaire-t7-3',
        ],
        comboEffects: [],
        scalingStats: ['STR'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Riposte',
          'Damage is Spread Evenly Between Allies',
          'Resistance to Push for Allies',
          'Resistance to Pull for Allies',
          'Riposte for Allies',
          'Backfire for Allies',
        ],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Live by the Shield',
        name: 'Shield Wall',
        imageSrc: '',
        description: [
          'Gain X% Riposte for a Duration of X.',
          'All damage that is taken by Allies is spread evenly amongst all living party members for a Duration of X.',
          'All Party Members gain (X + X*STR)% resistance to Push and Pull for a Duration of X.',
          "COMBO: If a 'Live by the Shield' Discipline skill was used on this Hero's last Turn, each Ally gains:",
          'X% Riposte for a Duration of X.',
          'X% Backfire for a Duration of X.',
        ],
      },
      {
        id: 'legionnaire-t5-2',
        tier: 5,
        skillPoints: 5,
        range: 2,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Bleed'],
        scalingStats: ['DEX'],
        ailments: ['Pull'],
        ailmentsDuration: null,
        buffs: ['Reposition Legionnaire to P1'],
        buffsDuration: 0,
        debuffs: ['Exhausted', 'Amnesia "Charge"'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Die by the Spear',
        name: 'Charge',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to [X*(Y*BASIC + Y*DEX)].',
          'The value of X is dependent on the Position of the acting Hero:',
          'P1: X = 1.0',
          'P2: X = 1.2',
          'P3: X = 1.4',
          'If this Hero is not in P1, reposition this Hero to P1.',
          'Become Exhausted.',
          'Amnesia X.',
          'COMBO: If the target is inflicted with Bleed, gain a (X + X*DEX)% chance to Pull target X.',
        ],
      },
      {
        id: 'legionnaire-t5-3',
        tier: 5,
        skillPoints: 5,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'legionnaire-t1-3',
          'legionnaire-t3-3',
          'legionnaire-t4-3',
          'legionnaire-t5-3',
          'legionnaire-t6-5',
          'legionnaire-t6-6',
          'legionnaire-t7-8',
          'legionnaire-t7-9',
        ],
        comboEffects: [],
        scalingStats: ['STR', 'VIT'],
        ailments: ['Intimidate All Enemies'],
        ailmentsDuration: 0,
        buffs: ['Agility for Allies', 'Evasion for Allies'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Born Leader',
        name: 'Heroic Roar',
        imageSrc: '',
        description: [
          'Deal physical damage to all target enemies equal to (0).',
          '(X + X*STR)% chance to Intimidate all enemies for X% for a Duration of X.',
          'Allies gain AGI equal to (X*VIT) for a Duration of X.',
          "COMBO: If a 'Born Leader' Discipline skill was used on this Hero's last Turn, Allies gain (X + X*AGI)% EVA for a Duration of X.",
        ],
      },
    ],
    tier6: [
      {
        id: 'legionnaire-t6-1',
        tier: 6,
        skillPoints: 9,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'legionnaire-t1-1',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Slow'],
        ailmentsDuration: 0,
        buffs: ['Riposte'],
        buffsDuration: null,
        debuffs: ['Reduced Physical Damage Dealt'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Live by the Shield',
        name: 'Get Even +',
        imageSrc: '',
        description: [
          'Activate for a Duration of X.',
          'All physical damage dealt is decreased by X%.',
          'Gain X% Riposte.',
          'Retaliate attacks gain a X% chance to inflict X% Slow to target enemy for a Duration of X.',
        ],
      },
      {
        id: 'legionnaire-t6-2',
        tier: 6,
        skillPoints: 7,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'legionnaire-t3-1',
        comboSkills: [],
        comboEffects: ['Block'],
        scalingStats: ['STR', 'END'],
        ailments: ['Exhaust', 'Stun', 'Push'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Live by the Shield',
        name: 'Scutum Shove +',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*BASIC + X*STR).',
          '(X + X*STR)% chance to Exhaust target enemy for a Duration of X.',
          '(X + X*STR)% chance to Stun target enemy for a Duration of X.',
          'COMBO: If this Hero successfully Blocked since its last Turn, this skill gains a (X + X*END + X*STR)% chance to Push target enemy X.',
        ],
      },
      {
        id: 'legionnaire-t6-3',
        tier: 6,
        skillPoints: 7,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'legionnaire-t3-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'DEX', 'STR'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: 0,
        debuffs: ['Channel'],
        debuffsDuration: null,
        channelDuration: 'X Rounds',
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Die by the Spear',
        name: 'Herculean Heave +',
        imageSrc: '',
        description: [
          'Channel for a Duration of X.',
          'Deal physical damage to target enemy in P2 equal to (X*BASIC + X*DEX + X*STR).',
          'This attack has X% Pierce.',
          '(X + X*DEX)% chance to inflict target P2 enemy with Bleed.',
          '(X + X*DEX)% chance to Stun target P2 enemy.',
          'Deal physical damage to target enemy in P3 equal to (X*BASIC + X*DEX + X*STR).',
          '(X + X*DEX)% chance to Silence target P3 enemy.',
        ],
      },
      {
        id: 'legionnaire-t6-4',
        tier: 6,
        skillPoints: 5,
        range: 2,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'legionnaire-t5-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Reposition Legionnaire to P1'],
        buffsDuration: null,
        debuffs: ['Exhausted', 'Amnesia "Charge +"'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Die by the Spear',
        name: 'Charge +',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to [X*(Y*BASIC + Y*DEX)].',
          'The value of X is dependent on the Position of the acting Hero:',
          'P1: X = 1.0',
          'P2: X = 1.2',
          'P3: X = 1.4',
          'If this Hero is not in P1, reposition this Hero to P1.',
          'Become Exhausted.',
          'Amnesia Y.',
          'COMBO: If the target is inflicted with Bleed, gain a (Y + Y*DEX)% chance to Pull target Y.',
        ],
      },
      {
        id: 'legionnaire-t6-5',
        tier: 6,
        skillPoints: 9,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'legionnaire-t1-3',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['VIT'],
        ailments: ['Taunt'],
        ailmentsDuration: null,
        buffs: ['Block', 'Evasion for Allies'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Born Leader',
        name: 'Inspire +',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (0).',
          'Taunt target enemy for a Duration of X.',
          'Gain X% BLK for a Duration of X.',
          'Allies gain (X + X*VIT)% EVA for a Duration of X.',
        ],
      },
      {
        id: 'legionnaire-t6-6',
        tier: 6,
        skillPoints: 6,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'legionnaire-t4-3',
        comboSkills: [
          'legionnaire-t1-3',
          'legionnaire-t3-3',
          'legionnaire-t4-3',
          'legionnaire-t5-3',
          'legionnaire-t6-5',
          'legionnaire-t6-6',
          'legionnaire-t7-8',
          'legionnaire-t7-9',
        ],
        comboEffects: [],
        scalingStats: ['STR', 'DEX', 'INT'],
        ailments: ['Take Additional Damage'],
        ailmentsDuration: null,
        buffs: [
          'Pull Resistance for Allies',
          'Push Resistance for Allies',
          'Riposte',
        ],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Born Leader',
        name: 'On Me +',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*BASIC + X*STR).',
          '(X + X*DEX + X*INT)% chance to Mark target enemy for a Duration of X.',
          'Allies deal X% additional damage to target marked enemy for a Duration of X.',
          'Allies gain (X + X*STR)% resistance to Push and Pull for a Duration of X.',
          "COMBO: If a 'Born Leader' Discipline skill was used on this Hero's last Turn, gain X% Riposte for a Duration of X.",
        ],
      },
    ],
    tier7: [
      {
        id: 'legionnaire-t7-1',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Block', 'Parry', 'Dodge'],
        scalingStats: ['DEX', 'AGI', 'LCK', 'END', 'VIT', 'WIS'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Evasion', 'Physical Barrier'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Live by the Shield',
        name: 'Parry and Protect',
        imageSrc: '',
        description: [
          'Gain X% EVA.',
          'Each instance this Hero successfully Blocks, Parries, or Dodges an incoming attack, gain:',
          '(X + X*LCK)% chance to give a random Party Member a Physical Barrier with HP equal to (X*END + X*VIT + X*WIS).',
        ],
      },
      {
        id: 'legionnaire-t7-2',
        tier: 7,
        skillPoints: 10,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Block'],
        scalingStats: ['STR', 'STR', 'END', 'END', 'VIT'],
        ailments: ['Push'],
        ailmentsDuration: 0,
        buffs: [
          'Physical Barrier',
          'Physical Defense',
          'Riposte',
          'Resistance to Pull',
          'Resistance to Push',
        ],
        buffsDuration: 0,
        debuffs: ['Amnesia "Walking Fortress"'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Live by the Shield',
        name: 'Walking Fortress',
        imageSrc: '',
        description: [
          'Activate to gain a Physical Barrier with HP equal to (X*STR + X*END + X*VIT).',
          'Gain (X + X*END)% P.DEF for a Duration of X.',
          'Gain X% Riposte for a Duration of X.',
          'Gain (X + X*STR)% resistance to Push and Pull for a Duration of X.',
          'Amnesia X.',
          'COMBO: If this Hero successfully Blocked since its last Turn, this skill gains:',
          '(X + X*END + X*STR)% chance to Push target enemy X.',
        ],
      },
      {
        id: 'legionnaire-t7-3',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'legionnaire-t1-3',
          'legionnaire-t3-3',
          'legionnaire-t4-3',
          'legionnaire-t5-3',
          'legionnaire-t6-5',
          'legionnaire-t6-6',
          'legionnaire-t7-8',
          'legionnaire-t7-9',
        ],
        comboEffects: [],
        scalingStats: ['STR', 'LCK'],
        ailments: ['Stun'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: 0,
        debuffs: ['Ally Repositions to P1', 'Exhaust Ally'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Live by the Shield',
        name: 'Shield Ramp',
        imageSrc: '',
        description: [
          'Activate to target an Ally that is in a Position behind this Hero.',
          'Ally deals physical damage divided evenly amongst enemies in P2 and P3 equal to [(X*Ally_BASIC + X*Ally_VIT + X*Ally_END) + (X*STR)].',
          '(X + X*LCK)% chance to Push target in P2 to P3.',
          'Ally repositions to P1 and becomes Exhausted.',
          'COMBO: If any "Born Leader" Discipline skill was used last Turn by this Hero, gain:',
          '(X + X*LCK)% chance to Stun target in P3.',
        ],
      },
      {
        id: 'legionnaire-t7-4',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Recovery',
          'Evasion',
          'Resistance to Fear',
          'Resistance to Push',
          'Resistance to Pull',
        ],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Ally P2'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Die by the Spear',
        name: 'On Your Six',
        imageSrc: '',
        description: [
          'Party Members in P2 gain:',
          'X% REC',
          'X% EVA',
          '(X + X*STR)% Resistance to Fear.',
          '(X + X*STR)% Resistance to Push and Pull.',
        ],
      },
      {
        id: 'legionnaire-t7-5',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'DEX'],
        ailments: ['Silence', 'Stun'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Channel'],
        debuffsDuration: null,
        channelDuration: 'X Rounds',
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Die by the Spear',
        name: 'Spear from the Gods',
        imageSrc: '',
        description: [
          'Channel for a Duration of X.',
          'Deal physical damage equal to (X*BASIC + X*DEX) to target enemy.',
          '(X + X*DEX)% chance to Silence target enemy for Duration of X.',
          '(X + X*DEX)% chance to Stun target Enemy for Duration of X.',
          'Deal physical damage equal to (X*BASIC + X*DEX) to each adjacent enemy.',
        ],
      },
      {
        id: 'legionnaire-t7-6',
        tier: 7,
        skillPoints: 10,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'legionnaire-t1-1',
          'legionnaire-t3-1',
          'legionnaire-t4-1',
          'legionnaire-t5-1',
          'legionnaire-t6-1',
          'legionnaire-t6-2',
          'legionnaire-t7-2',
          'legionnaire-t7-3',
        ],
        comboEffects: ['Bleed'],
        scalingStats: ['STR', 'DEX', 'END'],
        ailments: ['Stun', 'Push'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Die by the Spear',
        name: 'Press the Advantage',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*BASIC + X*STR + X*DEX).',
          '(X + X*END)% chance to Retaliate X for a Duration of X.',
          'If the target is inflicted with Bleed, gain:',
          '(X + X*DEX)% chance to Stun target for a Duration of X.',
          "COMBO: If a 'Live by the Shield' Discipline skill was used on this Hero's last Turn, gain:",
          '(X + X*DEX)% to Push target enemy X.',
        ],
      },
      {
        id: 'legionnaire-t7-7',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['END'],
        ailments: ['Push', 'Pull', 'Daze', 'Silence', 'Stun'],
        ailmentsDuration: null,
        buffs: [
          'Retaliate',
          'Physical Defense for Allies',
          'Resistance to Push',
          'Resistance to Pull',
        ],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Born Leader',
        name: 'Hold Steady',
        imageSrc: '',
        description: [
          'This Hero gains:',
          'X% chance to gain Retaliate X on successful incoming attacks targeting Allies.',
          'Allies gain:',
          'X% P.DEF.',
          '(X + X*END)% Resistance to Push and Pull.',
          "The END listed here is the Legionnaire's END, not the target's.",
        ],
      },
      {
        id: 'legionnaire-t7-8',
        tier: 7,
        skillPoints: 10,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Reposition',
          'Block',
          'Retaliate for Allies Behind',
          'Resistance to Push',
          'Resistance to Pull',
        ],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Born Leader',
        name: 'Take the Point',
        imageSrc: '',
        description: [
          'Reposition this Hero.',
          'Gain X% BLK for a Duration of X.',
          'Allies behind this Hero gain (X + X*END)% chance to Retaliate X for a Duration of X.',
          'Amnesia X.',
          "COMBO: If any 'Live by the Spear' Discipline skill was used on this Hero's last Turn, gain:",
          '(X + X*END)% resistance to Push and Pull for a Duration of X.',
        ],
      },
      {
        id: 'legionnaire-t7-9',
        tier: 7,
        skillPoints: 10,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['legionnaire-t5-3'],
        comboEffects: [],
        scalingStats: ['VIT', 'AGI', 'LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Evasion',
          'Reposition Ally',
          'Physical Barrier for Ally',
          'Increased Damage Dealt for Ally',
        ],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Born Leader',
        name: 'Fall Back',
        imageSrc: '',
        description: [
          'Gain X% EVA for a Duration of X.',
          'Reposition target Ally.',
          'Target Allied Hero gains a Physical Barrier with HP equal to (X*Max_HP).',
          "COMBO: If 'Heroic Roar' was used on this Hero's last Turn, target Ally gains X% increased damage dealt for a Duration of X.",
        ],
      },
    ],
    tier8: [
      {
        id: 'legionnaire-t8-1',
        tier: 8,
        skillPoints: 15,
        range: 'ALLIES',
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Physical Accuracy for Allies',
          'Focus for Allies',
          'Critical Strike for Allies',
          'Pierce for Allies',
          'Attack Damage for Allies',
        ],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Born Leader',
        name: 'Coordinated Attack',
        imageSrc: '',
        description: [
          'Every X Durations, all party members gain X% P.ACC and Focus for a Duration of X.',
          'Every X Durations, all party members gain X% CSC for a Duration of X.',
          'Every X Durations, all party members gain X% Pierce for a Duration of X.',
          'Every X Durations, all party members gain X% Attack Damage for a Duration of X.',
        ],
      },
    ],
  },
};
