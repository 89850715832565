<template>
  <div>
    <builds-list
      :isHeroBuild="false"
      :isDataLoading="isDataLoading"
      :buildsData="teamBuildsData"
    >
    </builds-list>
  </div>
</template>

<script>
import { auth, db } from '@/firebase/config';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { useStore } from 'vuex';
import { ref } from 'vue';

import BuildsList from '../components/my-builds/BuildsList.vue';

export default {
  setup() {
    const store = useStore();
    const isDataLoading = ref(true);
    const userId = auth.currentUser.uid;

    const q = query(
      collection(db, 'teamBuilds'),
      where('createdBy', '==', userId),
      orderBy('updatedAt', 'desc')
    );

    getDocs(q).then((querySnapshot) => {
      if (querySnapshot.length !== 0) {
        let teamBuilds = [];

        querySnapshot.forEach((doc) => {
          teamBuilds.push({
            ...doc.data(),
            id: doc.id,
          });
        });

        store.dispatch('myTeamBuilds/setTeamBuilds', teamBuilds);
      }

      isDataLoading.value = false;
    });

    return { isDataLoading };
  },
  components: {
    BuildsList,
  },
  computed: {
    teamBuildsData() {
      return this.$store.getters['myTeamBuilds/getTeamBuilds'];
    },
  },
};
</script>
