export default {
  className: 'Priest',
  disciplines: [
    {
      name: 'Prayer',
      description:
        "The Prayer path allows them to invoke the power of the divine to heal their allies, imbuing them with powerful wards that protect them from the foul magics of fear and silence. They create auras of holy light that surround their allies, providing clarity and safety within the warmth of the Priest's protection.",
    },
    {
      name: 'Holy',
      description:
        'The Holy path empowers the Priest to channel the gifts of the divine to heal their allies, and deal damage to their enemies. Channeling radiant energy from the gods is not small feat, but the Priest shapes the light to restore the vitality of their party members and with each application, gain access to greater power. ',
    },
    {
      name: 'Blessing',
      description:
        'The Blessings path allows the Priest to purify and bestow blessings to their allies, soothing the pains of war and granting gifts of legendary might. Each blessing is imbued with the power of the divine, granting their allies potent boons in battle that only a Priest can provide.',
    },
  ],
  statGrowth: {
    STR: 30,
    DEX: 30,
    AGI: 40,
    VIT: 50,
    END: 60,
    INT: 70,
    WIS: 80,
    LCK: 40,
  },
  healthGrowth: {
    Small: 35,
    Medium: 40,
    Large: 25,
  },
  manaGrowth: {
    Small: 15,
    Medium: 35,
    Large: 50,
  },
  basicAttackStats: [],
  accuracyStats: [],
  defenseStats: [],
  criticalStrikeStats: [],
  skills: {
    tier1: [
      {
        id: 'priest-t1-1',
        tier: 1,
        skillPoints: 1,
        range: 'ALLIES',
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal', '80% Fear Resistance', '80% Silence Resistance'],
        buffsDuration: 2,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Prayer',
        name: 'Prayer of Clarity',
        imageSrc:
          'https://game.defikingdoms.com/assets/Attacks/priest/prayer-of-clarity.png',
        description: [
          'Heal target ally for (1.0*Spell + 1.0*WIS + 0.5*INT).',
          'Target ally gains 80% resistance to Fear and Silence for a Duration of 2.',
        ],
      },
      {
        id: 'priest-t1-2',
        tier: 1,
        skillPoints: 1,
        range: 'ALLIES',
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: '-',
        discipline: 'Holy',
        name: 'Holy Light',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/priest/holy-light.png',
        description: ['Heal target Hero for (1.0*Spell + 3.0*WIS + 1.0*INT).'],
      },
      {
        id: 'priest-t1-3',
        tier: 1,
        skillPoints: 1,
        range: 'ALLIES',
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal', 'Remove Negative Status Effects'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Blessing',
        name: 'Cleansing Gift',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/priest/cleansing-gift.png',
        description: [
          'Heal target Hero for (1.0*Spell + 0.5*WIS + 0.5*VIT).',
          'Remove any negative Status Effects from target.',
        ],
      },
    ],
    tier2: [
      {
        id: 'priest-t2-1',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Intelligence'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Prayer',
        name: 'Divine Prayer',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/priest/divine-prayer.png',
        description: ['5% effective INT increase in combat.'],
      },
      {
        id: 'priest-t2-2',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Wisdom'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Holy',
        name: 'Holy Guardian',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/priest/holy-guardian.png',
        description: ['5% effective WIS increase in combat.'],
      },
      {
        id: 'priest-t2-3',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Health'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Blessing',
        name: 'Blessings of Light',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/priest/blessing-of-light.png',
        description: ['5% effective HP increase in combat.'],
      },
    ],
    tier3: [
      {
        id: 'priest-t3-1',
        tier: 3,
        skillPoints: 3,
        range: 'ALLIES',
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal', 'Strength'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Prayer',
        name: 'Prayer of Might',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/priest/prayer-of-might.png',
        description: [
          'Heal target Hero for (1.0*Spell + 1.0*WIS + 0.5*INT).',
          'Target ally gains effective STR equal to X% (X = 1.0*INT) for a Duration of 2.',
        ],
      },
      {
        id: 'priest-t3-2',
        tier: 3,
        skillPoints: 3,
        range: 'ALLIES',
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Team'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Holy',
        name: 'Holy Circle',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/priest/holy-circle.png',
        description: [
          'Heal instantly all party members for 15% of their max HP.',
        ],
      },
      {
        id: 'priest-t3-3',
        tier: 3,
        skillPoints: 3,
        range: 'ALLIES',
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal', 'Remove all Negative Status Effects', 'Unstoppable'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Blessing',
        name: 'Freedom',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/priest/freedom.png',
        description: [
          'Heal target Hero for (X*Spell + X*WIS + X*VIT).',
          'Remove all negative Status Effects from target.',
          'Target becomes unstoppable for X Rounds.',
        ],
      },
    ],
    tier4: [
      {
        id: 'priest-t4-1',
        tier: 4,
        skillPoints: 4,
        range: 'ALLIES',
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Spell Barrier'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Barrier',
        damageType: 'Magical',
        discipline: 'Prayer',
        name: 'Prayer of Protection',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/priest/prayer-of-protection.png',
        description: [
          "Target ally gains a Spell Barrier of holy light equal to 15% of target's max HP.",
        ],
      },
      {
        id: 'priest-t4-2',
        tier: 4,
        skillPoints: 4,
        range: 'ALLIES',
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal', 'Heal'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Holy',
        name: 'Holy Beacon',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/priest/holy-beacon.png',
        description: [
          'Heal target ally for (1.0*Spell + 2.0*WIS).',
          'For the next 4 Durations, each time this Priest casts a heal spell, Heal this target for 30% of the value healed.',
        ],
      },
      {
        id: 'priest-t4-3',
        tier: 4,
        skillPoints: 4,
        range: 'ALLIES',
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'VIT', 'VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Agility'],
        buffsDuration: 3,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Blessing',
        name: 'Swiftness',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/priest/swiftness.png',
        description: [
          'Heal target Hero for (1.0*Spell + 1.0*WIS + 0.5*VIT).',
          'Target Hero gains effective AGI equal to (1.0*VIT) for a Duration of 3.',
        ],
      },
    ],
    tier5: [
      {
        id: 'priest-t5-1',
        tier: 5,
        skillPoints: 5,
        range: 'ALLIES',
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Spell Barrier'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Barrier',
        damageType: 'Magical',
        discipline: 'Prayer',
        name: 'Divine Barrier',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/priest/divine-barrier.png',
        description: [
          "All allies gain a Spell Barrier of holy light equal to 10% of targets' max HP.",
        ],
      },
      {
        id: 'priest-t5-2',
        tier: 5,
        skillPoints: 5,
        range: 3,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'priest-t1-2',
          'priest-t6-1',
          'priest-t3-2',
          'priest-t6-2',
          'priest-t4-2',
          'priest-t5-2',
          'priest-t6-3',
          'priest-t7-3',
        ],
        comboEffects: [],
        scalingStats: ['INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Critical Chance'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Holy',
        name: 'Holy Wrath',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/priest/holy-wrath.png',
        description: [
          'Deal damage equal to (1.0*Spell + 2.0*INT) to target enemy.',
          'COMBO: If this Hero used a Holy spell on its last Turn, Holy Wrath has a 99% chance to Critical Strike.',
        ],
      },
      {
        id: 'priest-t5-3',
        tier: 5,
        skillPoints: 5,
        range: 'ALLIES',
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal'],
        buffsDuration: null,
        debuffs: ['Exhaust'],
        debuffsDuration: 2,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Blessing',
        name: 'Full Heal',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/priest/full-heal.png',
        description: [
          "Heal target ally for 100% of target's missing HP.",
          'Become Exhausted for a Duration of 2.',
        ],
      },
    ],
    tier6: [
      {
        id: 'priest-t6-1',
        tier: 6,
        skillPoints: 9,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'priest-t1-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Holy',
        name: 'Holy Light +',
        imageSrc: '',
        description: ['Heal target Hero for (X*Spell + X*WIS + X*INT).'],
      },
      {
        id: 'priest-t6-2',
        tier: 6,
        skillPoints: 7,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'priest-t3-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal'],
        buffsDuration: 0,
        debuffs: ['Exhaust'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Holy',
        name: 'Holy Circle +',
        imageSrc: '',
        description: [
          'Heal instantly all party members for X% of their max HP.',
          'Become Exhausted.',
        ],
      },
      {
        id: 'priest-t6-3',
        tier: 6,
        skillPoints: 5,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'priest-t5-2',
        comboSkills: [
          'priest-t1-2',
          'priest-t6-1',
          'priest-t3-2',
          'priest-t6-2',
          'priest-t4-2',
          'priest-t5-2',
          'priest-t6-3',
          'priest-t7-3',
        ],
        comboEffects: [],
        scalingStats: ['INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Critical Strike', 'Lifesteal'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Holy',
        name: 'Holy Wrath +',
        imageSrc: '',
        description: [
          'Deal damage equal to (X*Spell + X*INT) to target enemy.',
          'COMBO: If you cast a Holy spell last Round, Holy Wrath has a X% chance to Crit and self_heals for X% of damage dealt.',
        ],
      },
      {
        id: 'priest-t6-4',
        tier: 6,
        skillPoints: 9,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'priest-t1-1',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Heal',
          'First Strike',
          'Cannot be Feared',
          'Cannot be Silenced',
        ],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Prayer',
        name: 'Prayer of Clarity +',
        imageSrc: '',
        description: [
          'Heal target Hero for (X*Spell + X*WIS + X*INT).',
          'Target ally gains First Strike next Round.',
          'Target ally cannot be Feared or Silenced for X Rounds.',
        ],
      },
      {
        id: 'priest-t6-5',
        tier: 6,
        skillPoints: 7,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'priest-t3-1',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal', 'Strength'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Prayer',
        name: 'Prayer of Might +',
        imageSrc: '',
        description: [
          'Heal target Hero for (X*Spell + X*WIS + X*INT).',
          'Target ally gains effective STR equal to X% (X = X*INT) for X Rounds.',
        ],
      },
      {
        id: 'priest-t6-6',
        tier: 6,
        skillPoints: 6,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'priest-t4-1',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Barrier'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Barrier',
        damageType: 'Magical',
        discipline: 'Prayer',
        name: 'Prayer of Protection +',
        imageSrc: '',
        description: [
          "Target ally gains a holy shield equal to X% of target's max HP.",
        ],
      },
    ],
    tier7: [
      {
        id: 'priest-t7-1',
        tier: 7,
        skillPoints: 10,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['VIT', 'WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Health', 'Wisdom', 'Intelligence', 'Vitality'],
        buffsDuration: 0,
        debuffs: ['Dead'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Prayer',
        name: 'Divine Martyr',
        imageSrc: '',
        description: [
          'Sacrifice this Hero.',
          'Allied Heroes gain several effective buffs: HP, WIS, INT, and VIT equal to X.\n' +
            "X = % of remaining HP relative to the Priest's Base Stat value.\n" +
            'For example, if at the moment of sacrifice, the Priest has X% of its remaining HP, X = X%.\n' +
            "In turn, each ally gains X% of the Priest's max HP, WIS, INT, and VIT.",
        ],
      },
      {
        id: 'priest-t7-2',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Allies Cannot Die'],
        buffsDuration: 0,
        debuffs: ['Target Enemy Cannot Die'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Blessing',
        name: 'Mercy',
        imageSrc: '',
        description: [
          'Target enemy cannot die this turn.',
          'All allies cannot die this turn.',
        ],
      },
      {
        id: 'priest-t7-3',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'priest-t1-2',
          'priest-t6-1',
          'priest-t3-2',
          'priest-t6-2',
          'priest-t4-2',
          'priest-t5-2',
          'priest-t6-3',
          'priest-t7-3',
        ],
        comboEffects: [],
        scalingStats: ['INT'],
        ailments: ['Stun'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Holy',
        name: 'Holy Shock',
        imageSrc: '',
        description: [
          'Deal damage equal to (X*Spell + X*INT) to target enemy.',
          'COMBO: If you cast a Holy spell last Round, Stun target.',
        ],
      },
      {
        id: 'priest-t7-4',
        tier: 7,
        skillPoints: 10,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Resurrect'],
        buffsDuration: null,
        debuffs: ['Exhaust'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Resurrect',
        damageType: '-',
        discipline: 'Prayer',
        name: 'Prayer of Resurrection',
        imageSrc: '',
        description: [
          'Resurrect target Hero.',
          'Hero returns Exhausted and with X% of max HP.',
        ],
      },
      {
        id: 'priest-t7-5',
        tier: 7,
        skillPoints: 10,
        range: 'SELF',
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Health', 'Evasion', 'Reduce Magical Damage Taken'],
        buffsDuration: null,
        debuffs: ['Reduce Agility'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: 'Magical',
        discipline: 'Holy',
        name: 'Warpriest',
        imageSrc: '',
        description: [
          'Gain X% effective HP.',
          'Gain X% effective EVA.',
          'Reduce Magical damage taken by X%.',
          'Reduce effective AGI by X% in combat.',
        ],
      },
      {
        id: 'priest-t7-6',
        tier: 7,
        skillPoints: 10,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Cannot Die'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Defense',
        damageType: 'Magical',
        discipline: 'Miracle',
        name: 'Miracle of Intervention',
        imageSrc: '',
        description: ['Target ally cannot die for X Rounds.'],
      },
      {
        id: 'priest-t7-7',
        tier: 7,
        skillPoints: 10,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Miracle',
        name: 'Miracle of Faith',
        imageSrc: '',
        description: ["Restore X% of target's max HP per Round over X Rounds."],
      },
      {
        id: 'priest-t7-8',
        tier: 7,
        skillPoints: 10,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Exhausted'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Miracle',
        name: 'Miracle of Benediction',
        imageSrc: '',
        description: [
          'Heal target Hero for (X*Spell + X*WIS + X*VIT).',
          'Become Exhausted.',
        ],
      },
      {
        id: 'priest-t7-9',
        tier: 7,
        skillPoints: 10,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'priest-t7-6',
          'priest-t7-8',
          'priest-t7-7',
          'priest-t7-6',
        ],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal', 'Resurrect'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Miracle',
        name: 'Miracle of Devotion',
        imageSrc: '',
        description: [
          'Heal all allies equal to (X*Spell + X*WIS + X*INT).',
          'COMBO: If this Hero cast two Miracles this Battle, Resurrect all dead allies with X% max HP.',
        ],
      },
    ],
    tier8: [
      {
        id: 'priest-t8-1',
        tier: 8,
        skillPoints: 15,
        range: 'ALLIES',
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT', 'VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Blessing',
        name: 'Life After Death',
        imageSrc: '',
        description: [
          'Upon death, heal all living allies for (X*Spell + X*WIS + X*INT + X*VIT).',
        ],
      },
    ],
  },
};
