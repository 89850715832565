<template>
  <div>
    <skills-header
      :isSavedBuild="isSavedBuild"
      :userIsBuildOwner="userIsBuildOwner"
      :buildTitle="buildTitle"
      :buildTags="buildTags"
      @toggleTab="toggleTab"
    ></skills-header>
    <v-window v-model="tab">
      <v-window-item value="Skills">
        <skills-tab></skills-tab>
      </v-window-item>
      <v-window-item value="Analytics">
        <hero-analytics-tab></hero-analytics-tab>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import SkillsHeader from './SkillsHeader.vue';
import SkillsTab from './SkillsTab.vue';
import HeroAnalyticsTab from './HeroAnalyticsTab.vue';

export default {
  props: {
    isSavedBuild: Boolean,
    userIsBuildOwner: Boolean,
    buildTitle: String,
    buildTags: Array,
  },
  components: {
    SkillsHeader,
    SkillsTab,
    HeroAnalyticsTab,
  },
  data() {
    return {
      tab: null,
    };
  },
  methods: {
    toggleTab(value) {
      this.tab = value;
    },
  },
};
</script>
