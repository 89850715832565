<template>
  <div>
    <v-row>
      <v-col cols="12" md="2"></v-col>
      <v-col cols="12" md="8">
        <h1 class="d-flex justify-center">Tool Overview</h1>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <h2 class="d-flex justify-center">Skill Calculator</h2>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <v-list-item>
          The "Skill Calculator" is a kind of a "sandbox" where you can
          experiment with hero codex builds. It provides information about the
          codex skills and enforces the rules and limitations of picking hero
          skills.
        </v-list-item>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <h2 class="d-flex justify-center">Compose Team</h2>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <v-list-item>
          This feature allows users to compose teams of 3 heroes, by selecting a
          hero build for Positions 1, 2, and 3. You can experiment with
          different hero combinations and look for synergies for the optimal
          hero team for your combat strategy.
        </v-list-item>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <h2 class="d-flex justify-center">View Builds</h2>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <v-list-item>
          Saved hero builds can be viewed at "My Hero Builds" page and team
          builds - at the "My Team Builds" page. You can easily search your
          builds by word, tags, class, and subclass to find what you need.
        </v-list-item>
      </v-col>
      <v-col cols="12" md="3"></v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.h1 {
  font-size: 2.2rem;
}

.h2 {
  font-size: 1.7rem;
}

.h4 {
  font-size: 1.2rem;
}
</style>
