export default {
  className: 'Monk',
  disciplines: [
    {
      name: 'Closed Fist',
      description:
        'Those who walk the way of the Closed Fist are artisans of unyielding force. These Monks transcribe their tale with devastating strikes and unassailable defenses, each impact a stanza of power that resonates across the battlefield. They channel their ki to withstand onslaughts, and with a single, focused strike, they can topple even the mightiest foe. This discipline enables Monks to stand firm in the frontlines, an unyielding force of strength amidst the fires of combat.',
    },
    {
      name: 'Open Palm',
      description:
        'Followers of the way of the Open Palm weave their tale with gentler, yet equally potent strokes. They master the ebb and flow of energy, their martial gifts are leveraged to redirect hostile forces and send adversaries tumbling. Utilizing their ki as both a shield and a spring, they defend and deflect with an ease that belies their profound skill. This path allows Monks to dictate the rhythm of battle, a dance in which they are both the choreographer and the lead.',
    },
    {
      name: 'Drunken Sway',
      description:
        "The way of the Drunken Sway is an esoteric verse in the Monk's saga. These seemingly unsteady warriors find harmony in imbalance, their movements unpredictable and yet purposeful. They channel their ki to mimic the unpredictable sway of the inebriated, causing their enemies to misjudge their movements and falter. This path transforms the Monk into an elusive enigma on the battlefield, seemingly stumbling yet always a step ahead.",
    },
  ],
  statGrowth: {
    STR: 60,
    DEX: 60,
    AGI: 60,
    VIT: 60,
    END: 55,
    INT: 25,
    WIS: 50,
    LCK: 30,
  },
  healthGrowth: {
    Small: 25,
    Medium: 35,
    Large: 40,
  },
  manaGrowth: {
    Small: 30,
    Medium: 40,
    Large: 30,
  },
  basicAttackStats: [],
  accuracyStats: [],
  defenseStats: [],
  criticalStrikeStats: [],
  skills: {
    tier1: [
      {
        id: 'monk-t1-1',
        tier: 1,
        skillPoints: 1,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'DEX'],
        ailments: ['Daze'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Closed Fist',
        name: 'Crippling Strike',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*BASIC + X*STR + X*DEX).',
          '(X + X*STR + X*DEX)% chance to Daze target enemy.',
        ],
      },
      {
        id: 'monk-t1-2',
        tier: 1,
        skillPoints: 1,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'DEX'],
        ailments: ['Blind'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Open Palm',
        name: 'Blinding Weave',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*BASIC + X*DEX + X*WIS).',
          '(X + X*DEX + X*WIS)% chance to Blind target enemy.',
        ],
      },
      {
        id: 'monk-t1-3',
        tier: 1,
        skillPoints: 1,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'END', 'AGI', 'LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Physical Defense', 'Evasion'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Drunken Sway',
        name: 'Swig',
        imageSrc: '',
        description: [
          'Channel for a Duration of X.',
          'Gain (X + X*END)% P.DEF for a Duration of X.',
          'Gain (X + X*DEX)% EVA for a Duration of X.',
        ],
      },
    ],
    tier2: [
      {
        id: 'monk-t2-1',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR'],
        ailments: ['Push'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Closed Fist',
        name: 'Closed Fist',
        imageSrc: '',
        description: [
          'All successful attacks by this Hero gain a (X + X*STR)% chance to Push target enemy 1.',
        ],
      },
      {
        id: 'monk-t2-2',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Retaliate'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Open Palm',
        name: 'Open Palm',
        imageSrc: '',
        description: [
          'While in battle, this Hero gains a (X + X*DEX)% chance to Retaliate X.',
        ],
      },
      {
        id: 'monk-t2-3',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['END', 'VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Physical Barrier'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Barrier',
        damageType: 'Physical',
        discipline: 'Drunken Sway',
        name: 'Drunken Sway',
        imageSrc: '',
        description: [
          "While Channeling, this Hero gains a Physical Barrier equal to (X + X*END)% of this Hero's current HP.",
          'When Channel is complete, the Physical Barrier is destroyed.',
        ],
      },
    ],
    tier3: [
      {
        id: 'monk-t3-1',
        tier: 3,
        skillPoints: 3,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['monk-t4-1', 'monk-t6-2'],
        comboEffects: [],
        scalingStats: ['STR', 'STR', 'DEX'],
        ailments: ['Push', 'Daze'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Amnesia "Five Fists"'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Closed Fist',
        name: 'Five Fists',
        imageSrc: '',
        description: [
          'Reposition to P1.',
          'Deal X instances of physical damage to target enemy, each instance is equal to (X*BASIC + X*STR + X*DEX).',
          'For each successful Critical Strike, each instance gains a (X + X*STR)% chance to Daze target enemy.',
          'COMBO: If "Power Stance" is active, gain:',
          '(X + X*STR)% chance to Push target enemy 1.',
          'Amnesia X.',
        ],
      },
      {
        id: 'monk-t3-2',
        tier: 3,
        skillPoints: 3,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['monk-t4-2', 'monk-t6-4'],
        comboEffects: [],
        scalingStats: ['STR', 'DEX', 'DEX', 'WIS'],
        ailments: ['Disarm'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Amnesia "Soothing Palm"'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Open Palm',
        name: 'Soothing Palm',
        imageSrc: '',
        description: [
          'Reposition to P1.',
          'Deal physical damage to each target enemy equal to (X*BASIC + X*DEX + X*STR).',
          '(X + X*DEX + X*WIS)% chance to Disarm target enemy in P1 for a Duration of X.',
          '(X + X*DEX + X*WIS)% chance to Disarm target enemy in P2 for a Duration of X.',
          '(X + X*DEX + X*WIS)% chance to Disarm target enemy in P3 for a Duration of X.',
          'COMBO: If "Ready Stance" is active, gain;',
          'Cleanse all Party Members.',
          'Amnesia X.',
        ],
      },
      {
        id: 'monk-t3-3',
        tier: 3,
        skillPoints: 3,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['monk-t4-3', 'monk-t6-6'],
        comboEffects: [],
        scalingStats: ['STR', 'LCK', 'END', 'VIT'],
        ailments: ['Taunt'],
        ailmentsDuration: 0,
        buffs: ['Physical Barrier'],
        buffsDuration: null,
        debuffs: ['Exhaust', 'Amnesia "Stumble"'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Drunken Sway',
        name: 'Stumble',
        imageSrc: '',
        description: [
          'Reposition to P1.',
          'Deal physical damage equal to each enemy equal to (X*BASIC + X*STR + X*END).',
          '(X + X*LCK) chance to Taunt P1 enemy for a Duration of X.',
          '(X + X*LCK) chance to Taunt P2 enemy for a Duration of X.',
          '(X + X*LCK) chance to Taunt P3 enemy for a Duration of X.',
          'COMBO: If "Flow Stance" is active, gain a Physical Barrier equal to X% of this Hero\'s current HP.',
          'Become Exhausted',
          'Amnesia X.',
        ],
      },
    ],
    tier4: [
      {
        id: 'monk-t4-1',
        tier: 4,
        skillPoints: 4,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'AGI', 'DEX', 'LCK'],
        ailments: ['Push'],
        ailmentsDuration: null,
        buffs: ['Agility', 'Block', 'Critical Strike'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Closed Fist',
        name: 'Power Stance',
        imageSrc: '',
        description: [
          'Activate to gain "Power Stance" for a Duration of X.',
          'Reposition this Hero 1.',
          'While active, gain the below effects:',
          '+X% AGI.',
          '+X% BLK.',
          '+(X + X*DEX)% CSC.',
          'Each instance this Hero successfully hits a Critical Strike, gain:',
          '(X + X*STR)% chance to Push target enemy 1.',
          'Refreshes and does not stack.',
        ],
      },
      {
        id: 'monk-t4-2',
        tier: 4,
        skillPoints: 4,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'AGI', 'LCK', 'WIS'],
        ailments: ['Disarm'],
        ailmentsDuration: 0,
        buffs: ['Retaliate', 'Block', 'Evasion'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Open Palm',
        name: 'Ready Stance',
        imageSrc: '',
        description: [
          'Activate to gain "Ready Stance" for a Duration of X.',
          'Reposition this Hero 1.',
          'While active, gain the below effects:',
          '+X% EVA.',
          '+(X + X*WIS)% BLK.',
          '(X + X*DEX)% chance to Retaliate X.',
          'Each instance this Hero Blocks an incoming attack, gain a (X + X*WIS)% chance to Disarm attacking target enemy for a Duration of X.',
          'Refreshes and does not stack.',
        ],
      },
      {
        id: 'monk-t4-3',
        tier: 4,
        skillPoints: 4,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['END', 'AGI', 'LCK'],
        ailments: ['Taunt'],
        ailmentsDuration: 0,
        buffs: ['Evasion', 'Physical Defense'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Drunken Sway',
        name: 'Flow Stance',
        imageSrc: '',
        description: [
          'Activate to gain "Flow Stance" stance for a Duration of X.',
          'Reposition this Hero 1.',
          'While active, gain the below effects:',
          '+(X + X*LCK)% EVA.',
          '+(X + X*END)% P.DEF.',
          'Each instance this Hero Evades an attack, gain a (X + X*LCK)% chance to Taunt a random enemy for a Duration of X.',
          'Refreshes and does not stack.',
        ],
      },
    ],
    tier5: [
      {
        id: 'monk-t5-1',
        tier: 5,
        skillPoints: 5,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['monk-t3-1'],
        comboEffects: [],
        scalingStats: ['STR', 'DEX'],
        ailments: ['Push', 'Exhaust'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: 0,
        debuffs: ['Channel', 'Amnesia "Grand Slam"'],
        debuffsDuration: 0,
        channelDuration: 1,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Closed Fist',
        name: 'Grand Slam',
        imageSrc: '',
        description: [
          'Channel for a Duration of 1.',
          'Deal physical damage equal to (X*BASIC + X*STR) to target enemy in P1.',
          '(X + X*DEX)% chance to Push target enemy in P1 to P2.',
          'If Push is successful, deal physical damage equal to (X*BASIC + X*STR) to target enemy that has now moved to P1.',
          'COMBO: If this Hero used "Five Fists" on this Hero\'s last Turn, gain:',
          '(X + X*DEX)% chance to Exhaust target enemy in P1.',
          'Amnesia X.',
        ],
      },
      {
        id: 'monk-t5-2',
        tier: 5,
        skillPoints: 5,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['monk-t3-2'],
        comboEffects: [],
        scalingStats: ['VIT', 'WIS', 'DEX'],
        ailments: ['Banish'],
        ailmentsDuration: 0,
        buffs: ['Heal'],
        buffsDuration: 0,
        debuffs: ['Channel', 'Amnesia "Ki Blast"'],
        debuffsDuration: 0,
        channelDuration: 1,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Open Palm',
        name: 'Ki Blast',
        imageSrc: '',
        description: [
          'Channel for a Duration of 1.',
          'Deal physical damage equal to (X*BASIC + X*VIT + X*WIS) to target enemy.',
          '(X + X*DEX)% chance to Banish target enemy in P1 for Duration X.',
          'COMBO: If this Hero used "Soothing Palm" on its last Turn, gain:',
          'While Channeling, Heal for (X*VIT) on each enemy Turn.',
          'Amnesia X',
        ],
      },
      {
        id: 'monk-t5-3',
        tier: 5,
        skillPoints: 5,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['monk-t3-3'],
        comboEffects: [],
        scalingStats: ['END', 'LCK'],
        ailments: ['Taunt All Enemies'],
        ailmentsDuration: 0,
        buffs: ['Physical Defense'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: 'X Rounds',
        areaOfEffect: ['AOE'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Drunken Sway',
        name: 'Stagger',
        imageSrc: '',
        description: [
          'Reposition to P1.',
          '(X + X*LCK)% chance to Taunt each enemy.',
          'COMBO: If this Hero used "Stumble" on this Hero\'s last Turn, gain:',
          'X% P.DEF for a Duration of X.',
          'Channel for a Duration of X.',
          'At the end of the Channel, deal damage equal to X% of damage received during Channel to target enemy in P1.',
          'Amnesia X.',
          'Note: Effects occur in the order written.',
        ],
      },
    ],
    tier6: [
      {
        id: 'monk-t6-1',
        tier: 6,
        skillPoints: 9,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'monk-t1-1',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Daze'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Closed Fist',
        name: 'Crippling Strike +',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*X*DEX).',
          '(X + X*STR + X*DEX)% chance to Daze target enemy.',
          'COMBO: If this Hero used "Basic Attack" on its last Turn, immediately cast "Crippling Strike" again on the same target.',
        ],
      },
      {
        id: 'monk-t6-2',
        tier: 6,
        skillPoints: 6,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'monk-t4-1',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'AGI', 'DEX', 'LCK'],
        ailments: ['Push'],
        ailmentsDuration: 0,
        buffs: ['Agility', 'Block', 'Critical Strike'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Closed Fist',
        name: 'Power Stance +',
        imageSrc: '',
        description: [
          'Activate to gain "Power Stance +" for a Duration of X.',
          'Reposition this Hero 1.',
          'While active, gain the below effects:',
          '+X% AGI.',
          '+X% BLK.',
          '+(X + X*DEX)% CSC.',
          'Each instance this Hero successfully hits a Critical Strike, gain:',
          '(X + X*STR)% chance to Push target enemy 1.',
          'Refreshes and does not stack.',
        ],
      },
      {
        id: 'monk-t6-3',
        tier: 6,
        skillPoints: 9,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'monk-t1-2',
        comboSkills: [],
        comboEffects: ['Blind'],
        scalingStats: ['STR', 'WIS', 'DEX', 'INT'],
        ailments: ['Blind', 'Disarm'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Open Palm',
        name: 'Blinding Weave +',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*BASIC + X*DEX + X*WIS).',
          '(X + X*STR + X*WIS)% chance to Blind target enemy.',
          'COMBO: If target is inflicted with Blind, gain:',
          '(X + X*INT)% chance to Disarm target enemy for a Duration of X.',
        ],
      },
      {
        id: 'monk-t6-4',
        tier: 6,
        skillPoints: 6,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'monk-t4-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'AGI', 'LCK', 'WIS'],
        ailments: ['Disarm'],
        ailmentsDuration: 0,
        buffs: ['Retaliate', 'Block', 'Evasion'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Open Palm',
        name: 'Ready Stance +',
        imageSrc: '',
        description: [
          'Activate to gain "Ready Stance +" for a Duration of X.',
          'Reposition this Hero 1.',
          'While active, gain the below effects:',
          '+X% EVA.',
          '+(X + X*WIS)% BLK.',
          '(X + X*DEX)% chance to Retaliate X.',
          'Each instance this Hero Blocks an incoming attack, gain a (X + X*WIS)% chance to Disarm attacking target enemy for a Duration of X.',
          'Refreshes and does not stack.',
        ],
      },
      {
        id: 'monk-t6-5',
        tier: 6,
        skillPoints: 9,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'monk-t1-3',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'END', 'END', 'AGI'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Physical Defense', 'Evasion', 'Physical Defense'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Drunken Sway',
        name: 'Swig +',
        imageSrc: '',
        description: [
          'Channel for a Duration of X.',
          'Gain (X + X*END)% P.DEF for a Duration of X.',
          'Gain (X + X*DEX)% EVA for a Duration of X.',
          'COMBO: If this Hero used "Basic Attack" on its last Turn, gain an additional X% P.DEF for a Duration of X.',
        ],
      },
      {
        id: 'monk-t6-6',
        tier: 6,
        skillPoints: 6,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'monk-t4-3',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['END', 'AGI', 'LCK', 'END'],
        ailments: ['Taunt'],
        ailmentsDuration: 0,
        buffs: ['Evasion', 'Physical Defense'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Drunken Sway',
        name: 'Flow Stance +',
        imageSrc: '',
        description: [
          'Activate to gain "Flow Stance +" stance for a Duration of X.',
          'Reposition this Hero 1.',
          'While active, gain the below effects:',
          '+(X + X*LCK)% EVA.',
          '+(X + X*END)% P.DEF.',
          'Each instance this Hero Evades an attack, gain a (X + X*LCK)% chance to Taunt a random enemy for a Duration of X.',
          'Refreshes and does not stack.',
        ],
      },
    ],
    tier7: [
      {
        id: 'monk-t7-1',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: ['monk-t4-1', 'monk-t6-2'],
        comboEffects: [],
        scalingStats: ['DEX', 'END', 'WIS'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Physical Defense',
          'Magic Defense',
          'Resistance to all Status Effects',
          'Block',
        ],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Closed Fist',
        name: 'Unwavering Resolve',
        imageSrc: '',
        description: [
          'While in "Power Stance", gain:',
          'X% P.DEF.',
          'X% M.DEF.',
          'X% resistance to all Status Effects.',
          'X% BLK.',
        ],
      },
      {
        id: 'monk-t7-2',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['monk-t4-1', 'monk-t6-2', 'monk-t7-6'],
        comboEffects: [],
        scalingStats: ['STR', 'DEX', 'WIS'],
        ailments: ['Amnesia', 'Silence'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Closed Fist',
        name: 'Disrupt Ki',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*BASIC + X*STR + X*DEX).',
          '(X + X*WIS)% chance to inflict Amnesia X on a random Target Hero skill.',
          'COMBO: If this Hero used "Meditate" on its last Turn, gain:',
          '(X + X*WIS)% chance to Silence target enemy for a Duration of X.',
        ],
      },
      {
        id: 'monk-t7-3',
        tier: 7,
        skillPoints: 10,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'DEX'],
        ailments: ['Banish'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Channel', 'Exhaust', 'Amnesia "One Punch"'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Closed Fist',
        name: 'One Punch',
        imageSrc: '',
        description: [
          'Channel for a Duration of X.',
          'Deal physical damage to target enemy equal to (X*BASIC + X*STR + X*DEX)',
          '(X + X*STR)% chance to Banish target enemy for a Duration of X.',
          'Become Exhausted.',
          'Amnesia X.',
        ],
      },
      {
        id: 'monk-t7-4',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: ['monk-t4-2', 'monk-t6-4'],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Daze', 'Pull', 'Exhaust'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Debuff',
        damageType: '-',
        discipline: 'Open Palm',
        name: 'Guide Momentum',
        imageSrc: '',
        description: [
          'While in "Ready Stance", each instance this Hero Evades, Blocks, or Retaliates an incoming attack, gain:',
          'X% chance to Daze attacker.',
          'X% chance to Pull attacker 1.',
          'X% chance to Exhaust attacker for a Duration of X.',
        ],
      },
      {
        id: 'monk-t7-5',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['monk-t4-2', 'monk-t6-4'],
        comboEffects: [],
        scalingStats: ['WIS'],
        ailments: ['Destroy Barrier'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Open Palm',
        name: 'Shatter',
        imageSrc: '',
        description: [
          'This Skill is only available while in "Ready Stance".',
          'Deal physical damage to target enemy equal to (0).',
          '(X + X*WIS)% chance to destroy target Barriers on target enemy.',
          'For each Barrier destroyed, deal physical damage to target enemy equal to X% of the current HP value of each Barrier destroyed.',
        ],
      },
      {
        id: 'monk-t7-6',
        tier: 7,
        skillPoints: 10,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal', 'Physical Accuracy', 'Evasion', 'Cleanse'],
        buffsDuration: 0,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Open Palm',
        name: 'Meditate',
        imageSrc: '',
        description: [
          'Reposition up to 2 Positions.',
          'Channel for a Duration of X.',
          'Heal this Hero equal to (X*WIS + X*VIT).',
          'Purify this Hero.',
          'Gain X% P.ACC for a Duration of X.',
          'Gain X% EVA for a Duration of X.',
        ],
      },
      {
        id: 'monk-t7-7',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: ['monk-t4-3', 'monk-t6-6'],
        comboEffects: [],
        scalingStats: ['LCK'],
        ailments: ['Taunt'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Debuff',
        damageType: '-',
        discipline: 'Drunken Sway',
        name: 'Liquid Courage',
        imageSrc: '',
        description: [
          'While in "Flow Stance" and at the start of each enemy\'s Turn, gain a (X + X*LCK)% chance to Taunt enemy for its current Turn',
        ],
      },
      {
        id: 'monk-t7-8',
        tier: 7,
        skillPoints: 10,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['monk-t7-6'],
        comboEffects: [],
        scalingStats: ['END', 'LCK'],
        ailments: ['Stun'],
        ailmentsDuration: 0,
        buffs: ['Unstoppable', 'Repeat'],
        buffsDuration: 0,
        debuffs: ['AMnesia "Stunning Momentum"'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Drunken Sway',
        name: 'Stunning Momentum',
        imageSrc: '',
        description: [
          'This Skill is only available while in "Flow Stance".',
          'Become Unstoppable for a Duration of X.',
          'Deal physical damage to target enemy equal to (X*BASIC + X*END).',
          '(X + X*LCK)% chance to Stun target enemy.',
          'COMBO: If this Hero used "Meditate" on its last Turn, gain:',
          '(X + X*LCK)% chance to Repeat X, up to a Max of X.',
          'Amnesia X.',
        ],
      },
      {
        id: 'monk-t7-9',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Confuse', 'Blind', 'Retaliate', 'Evasion'],
        scalingStats: ['STR', 'LCK', 'LCK', 'AGI', 'DEX', 'END'],
        ailments: ['Confuse', 'Blind', 'Pull'],
        ailmentsDuration: null,
        buffs: ['Retaliate', 'Evasion', 'Block'],
        buffsDuration: 0,
        debuffs: ['Channel', 'Physical Defense'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Drunken Sway',
        name: 'Into the Fray',
        imageSrc: '',
        description: [
          'Channel for a Duration of X.',
          'During Channel, gain:',
          '+(X + X*LCK)% EVA',
          '+(X + X*LCK)% BLK',
          '-X% P.DEF',
          'Whenever this Hero receives damage, gain a X% chance to Retaliate X.',
          'Whenever this Hero Retaliates, gain a X% chance to Confuse target enemy.',
          'Whenever this Hero Evades an attack, gain a X% chance to Blind attacking enemy.',
          'Whenever this Hero Blocks an attack, gain a X% chance to Pull attacking enemy 1.',
          'When the Channel is successfully completed, deal physical damage equal to (X*BASIC + X*STR + X*LCK) to each enemy.',
          'COMBO: If target enemy is Confused or inflicted with Blind, deal additional physical damage equal to (X*BASIC + X*STR + X*LCK).',
        ],
      },
    ],
    tier8: [
      {
        id: 'monk-t8-1',
        tier: 8,
        skillPoints: 15,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['VIT', 'VIT', 'WIS', 'AGI'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Prevent Fatal Damage', 'Heal', 'Cleanse', 'Evasion'],
        buffsDuration: 0,
        debuffs: ['Exhaust'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Open Palm',
        name: 'Sleeping Dragon',
        imageSrc: '',
        description: [
          "Whenever this Hero's HP would be reduced to 0 for the first time in Battle, prevent that damage and immediately gain an array of effects:",
          'Heal this Hero equal to (X*WIS + X*VIT).',
          'Purify this Hero.',
          'Gain X% EVA for a Duration of X.',
          'Become Exhausted for a Duration of X.',
        ],
      },
    ],
  },
};
