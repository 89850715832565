<template>
  <div>
    <v-container>
      <h1>Compose a team of 3 heroes</h1>
      <h3>
        Select a hero from your saved hero builds for each position: P1, P2, and
        P3.
      </h3>
    </v-container>
    <v-container>
      <v-row>
        <v-col v-for="index in teamSize" :key="index" cols="12" md="4">
          <header class="position-header">
            <h2>
              {{
                `Position ${teamSize - index + 1} (P${teamSize - index + 1})`
              }}
            </h2>
            <p>
              Select a hero for your team's
              <strong>{{
                getPositionDescription(teamSize - index + 1)
              }}</strong>
              position
            </p>
          </header>
          <v-btn
            v-if="showSelectHeroButton(teamSize - index + 1)"
            height="170"
            width="100%"
            @click="openSearchDialog(teamSize - index + 1)"
          >
            <v-icon size="50" color="success">mdi-plus-circle-outline</v-icon>
          </v-btn>
          <v-card v-else height="170">
            <v-card-title>{{
              selectedHeroCardTitle(teamSize - index + 1)
            }}</v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle>{{
              selectedHeroCardSubTitle(teamSize - index + 1)
            }}</v-card-subtitle>
            <v-divider></v-divider>
            <v-container class="action-buttons">
              <v-row justify="center">
                <v-col cols="4">
                  <v-btn
                    min-width="100%"
                    color="blue"
                    variant="tonal"
                    @click="openHeroBuildInNewTab(teamSize - index + 1)"
                  >
                    View
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    :disabled="!user"
                    min-width="100%"
                    color="orange"
                    variant="tonal"
                    @click="selectDifferentHeroBuild(teamSize - index + 1)"
                  >
                    Change
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    :disabled="!user"
                    min-width="100%"
                    color="red"
                    variant="tonal"
                    @click="removeHeroPosition(teamSize - index + 1)"
                  >
                    Remove
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <search-hero-dialog
      v-model="showSearchDialog"
      @closeSearchDialog="showSearchDialog = false"
      @heroBuildSelected="updateHeroPosition"
    >
    </search-hero-dialog>
  </div>
</template>

<script>
import SearchHeroDialog from '../dialogs/SearchHeroDialog.vue';
import getUser from '../../composables/getUser.js';

export default {
  components: {
    SearchHeroDialog,
  },
  data() {
    return {
      showSearchDialog: false,
      positionNumber: null,
      user: getUser(),
    };
  },
  computed: {
    teamSize() {
      return this.$store.getters['teamBuild/getTeamSize'];
    },
    teamHeroPositions() {
      return this.$store.getters['teamBuild/getHeroPositions'];
    },
  },
  methods: {
    openSearchDialog(position) {
      this.positionNumber = position;
      this.showSearchDialog = true;
    },
    updateHeroPosition(value) {
      this.removeHeroPosition(this.positionNumber);

      const data = {
        heroBuild: value,
        position: this.positionNumber,
      };

      this.$store.dispatch('teamBuild/addHeroPosition', data);
    },
    showSelectHeroButton(position) {
      let isDisplayed = true;

      this.teamHeroPositions.forEach((p) => {
        if (p.position === position) {
          isDisplayed = false;
        }
      });

      return isDisplayed;
    },
    selectedHeroCardTitle(position) {
      const heroPosition = this.teamHeroPositions.find(
        (p) => p.position === position
      );

      return `${heroPosition.heroBuild.heroClass} / ${heroPosition.heroBuild.heroSubclass}`;
    },
    selectedHeroCardSubTitle(position) {
      const heroPosition = this.teamHeroPositions.find(
        (p) => p.position === position
      );

      return `Level ${heroPosition.heroBuild.requiredLevel}`;
    },
    getPositionDescription(position) {
      switch (position) {
        case 1:
          return 'FRONTLINE';
        case 2:
          return 'MIDLINE';
        case 3:
          return 'BACKLINE';
        default:
          return position.toString();
      }
    },
    openHeroBuildInNewTab(position) {
      const heroBuildPosition = this.teamHeroPositions.find(
        (p) => p.position === position
      );

      const routeData = this.$router.resolve({
        name: 'hero-build',
        params: { id: heroBuildPosition.heroBuild.id },
      });
      window.open(routeData.href, '_blank');
    },
    removeHeroPosition(position) {
      this.$store.dispatch('teamBuild/removeHeroPosition', {
        position: position,
      });
    },
    selectDifferentHeroBuild(position) {
      this.openSearchDialog(position);
    },
  },
};
</script>

<style scoped>
section {
  margin-bottom: 50px;
}

.position-header {
  margin-bottom: 20px;
}

.v-card-subtitle {
  margin: 10px 0 20px 0;
}
</style>
