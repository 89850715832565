<template>
  <section>
    <h3>{{ showHeaderMessage }}</h3>
    <v-container>
      <v-row>
        <v-col
          v-for="heroClass in heroClasses"
          :key="heroClass"
          class="d-flex child-flex"
          cols="2"
        >
          <v-btn large @click="selectClass(heroClass)">
            {{ heroClass }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import classesList from '../../../data/skill-calculator/class-objects.js';
import heroSkillsHelper from '../../../utils/heroSkillsHelper.js';

export default {
  data() {
    return {
      selectClassMessage: "Please select hero's main class",
      selectSubclassMessage: 'Please select the subclass',
    };
  },
  computed: {
    heroClasses() {
      const classObjects = Object.values(classesList);

      return classObjects.map((c) => c.className);
    },
    selectedClass() {
      return this.$store.getters['heroBuild/selectedClass'];
    },
    showHeaderMessage() {
      if (this.selectedClass === null) {
        return this.selectClassMessage;
      } else {
        return this.selectSubclassMessage;
      }
    },
  },
  methods: {
    selectClass(heroClass) {
      const classObject = heroSkillsHelper.getClassObject(heroClass);

      if (this.selectedClass === null) {
        this.$store.dispatch('heroBuild/selectClass', {
          heroClass: classObject,
        });
      } else {
        this.$store.dispatch('heroBuild/selectSubclass', {
          heroSubclass: classObject,
        });
      }
    },
  },
};
</script>

<style scoped>
h3.header-message {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.v-btn__content {
  font-size: 1rem;
}
</style>
