<template>
  <div>
    <v-app-bar>
      <v-row align="center">
        <v-col cols="5">
          <v-app-bar-title>
            {{ classAndSubclass }}
            <div class="total-skill-points" v-if="classAndSubclassSelected">
              {{ 'Required Level: ' + totalSkillPointsSpent }}
            </div>
          </v-app-bar-title>
        </v-col>
        <v-col cols="7">
          <v-tabs fixed-tabs v-model="tab">
            <v-tab value="Skills" @click="toggleTab">Skills</v-tab>
            <v-tab value="Analytics" @click="toggleTab">Analytics</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-toolbar-items>
        <v-btn color="blue" @click="copyLink" v-if="isSavedBuild">
          <v-icon large color="blue">mdi-share-variant-outline</v-icon>
          Share
        </v-btn>
        <v-btn color="white" @click="newHeroBuild">
          <v-icon large color="white">mdi-plus-box-outline</v-icon>
          New
        </v-btn>
        <v-btn
          color="green"
          v-if="userIsBuildOwner"
          @click="openSaveUpdateDialog"
        >
          <v-icon large color="green">mdi-content-save</v-icon>
          Update
        </v-btn>
        <v-btn
          :disabled="!user"
          color="green"
          v-if="!userIsBuildOwner && classAndSubclassSelected"
          @click="openSaveUpdateDialog"
        >
          <v-icon large color="green">mdi-content-save</v-icon>
          Save
        </v-btn>
        <v-btn
          color="red"
          v-if="classAndSubclassSelected && !isSavedBuild"
          @click="resetSkills"
        >
          <v-icon large color="red">mdi-restart</v-icon>
          Reset
        </v-btn>
        <v-btn color="red" v-if="userIsBuildOwner" @click="openDeleteDialog">
          <v-icon large color="red">mdi-delete</v-icon>
          Delete
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <save-update-dialog
      v-model="showSaveUpdateDialog"
      :isSavedBuild="isSavedBuild"
      :userIsBuildOwner="userIsBuildOwner"
      :buildTitle="buildTitle"
      :buildTags="buildTags"
      @close-dialog="closeSaveUpdateDialog"
      @save-build="saveHeroBuild"
      @update-build="updateHeroBuild"
    >
    </save-update-dialog>

    <delete-confirmation-dialog
      v-model="showDeleteConfirmationDialog"
      @closeDeleteDialog="closeDeleteDialog"
      @confirmDeleteBuild="deleteBuild"
    >
    </delete-confirmation-dialog>
  </div>
</template>

<script>
import { db, serverTimestamp } from '@/firebase/config';
import { doc, collection, addDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { useRoute } from 'vue-router';

import getUser from '../../../composables/getUser';
import SaveUpdateDialog from '../../dialogs/SaveUpdateDialog.vue';
import DeleteConfirmationDialog from '../../dialogs/DeleteConfirmationDialog.vue';

export default {
  components: {
    SaveUpdateDialog,
    DeleteConfirmationDialog,
  },
  setup() {
    const user = getUser();
    const route = useRoute();
    const routePath = route.path;

    return { user, routePath };
  },
  props: {
    isSavedBuild: Boolean,
    userIsBuildOwner: Boolean,
    buildTitle: String,
    buildTags: Array,
  },
  data() {
    return {
      tab: null,
      showSaveUpdateDialog: false,
      showDeleteConfirmationDialog: false,
    };
  },
  computed: {
    selectedClass() {
      return this.$store.getters['heroBuild/selectedClass'];
    },
    selectedSubclass() {
      return this.$store.getters['heroBuild/selectedSubclass'];
    },
    selectionExists() {
      return this.$store.getters['heroBuild/classSelectionExists'];
    },
    classAndSubclass() {
      if (this.classAndSubclassSelected) {
        return (
          this.selectedClass.className + ' / ' + this.selectedSubclass.className
        );
      }

      if (this.selectionExists) {
        return this.selectedClass.className;
      }

      return '';
    },
    classAndSubclassSelected() {
      return this.$store.getters['heroBuild/classAndSubclassSelected'];
    },
    classSkillPointsSpent() {
      return this.$store.getters['heroBuild/classSkillPointsSpent'];
    },
    subclassSkillPointsSpent() {
      return this.$store.getters['heroBuild/subclassSkillPointsSpent'];
    },
    totalSkillPointsSpent() {
      return this.classSkillPointsSpent + this.subclassSkillPointsSpent;
    },
    selectedClassSkillIds() {
      return this.$store.getters['heroBuild/selectedClassSkillIds'];
    },
    selectedSubclassSkillIds() {
      return this.$store.getters['heroBuild/selectedSubclassSkillIds'];
    },
  },
  methods: {
    resetSkills() {
      this.$store.dispatch('heroBuild/resetSelectedSkills');
    },
    newHeroBuild() {
      if (this.routePath === '/skill-calculator/new') {
        this.$store.dispatch('heroBuild/resetHeroBuild');
      } else {
        this.$router.push({ name: 'skill-calculator' });
      }
    },
    saveHeroBuild(value) {
      const heroBuild = {
        title: value.title,
        tags: value.tags,
        heroClass: this.selectedClass.className,
        heroSubclass: this.selectedSubclass.className,
        selectedClassSkills: this.selectedClassSkillIds,
        selectedSubclassSkills: this.selectedSubclassSkillIds,
        requiredLevel: this.totalSkillPointsSpent,
        createdBy: this.user.uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      addDoc(collection(db, 'heroBuilds'), heroBuild)
        .then((result) => {
          this.$store.dispatch('snackbar/showMessage', {
            content: 'The hero build was successfully saved!',
            color: 'success',
          });
          this.$router.push({ name: 'hero-build', params: { id: result.id } });
        })
        .catch((error) => {
          this.$store.dispatch('snackbar/showMessage', {
            content: 'Error saving the hero build!',
            color: 'red',
          });
          console.error('Error saving the hero build!', error);
        });
    },
    updateHeroBuild(value) {
      const heroBuild = {
        title: value.title,
        tags: value.tags,
        selectedClassSkills: this.selectedClassSkillIds,
        selectedSubclassSkills: this.selectedSubclassSkillIds,
        requiredLevel: this.totalSkillPointsSpent,
        updatedAt: serverTimestamp(),
      };

      const docRef = doc(db, 'heroBuilds', this.$route.params.id);
      setDoc(docRef, heroBuild, { merge: true })
        .then(() => {
          this.$store.dispatch('snackbar/showMessage', {
            content: 'The hero build was successfully updated!',
            color: 'success',
          });
        })
        .catch((error) => {
          this.$store.dispatch('snackbar/showMessage', {
            content: 'Error updating the hero build!',
            color: 'red',
          });
          console.error(
            'Error updating the hero build: ' + this.$route.params.id,
            error
          );
        });
    },
    deleteBuild() {
      deleteDoc(doc(db, 'heroBuilds', this.$route.params.id)).then(() => {
        this.newHeroBuild();
        this.$store.dispatch('snackbar/showMessage', {
          content: 'The hero build was successfully deleted!',
          color: 'orange',
        });
      });
    },
    copyLink() {
      var blob = new Blob([window.location.href], { type: 'text/plain' });
      var item = new ClipboardItem({ 'text/plain': blob });

      navigator.clipboard.write([item]).then(() => {
        this.$gtag.event('share', { method: 'HeroBuildLink' });
        this.$store.dispatch('snackbar/showMessage', {
          content: 'Shareable link copied to clipboard!',
          color: 'blue',
        });
      });
    },
    toggleTab() {
      this.$emit('toggleTab', this.tab);
    },
    closeSaveUpdateDialog() {
      this.showSaveUpdateDialog = false;
    },
    openSaveUpdateDialog() {
      this.showSaveUpdateDialog = true;
    },
    openDeleteDialog() {
      this.showDeleteConfirmationDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteConfirmationDialog = false;
    },
  },
};
</script>

<style scoped>
div.total-skill-points {
  color: orangered;
}
</style>
