<template>
  <div>
    <v-tooltip
      activator="parent"
      location="end"
      :max-width="maxWidth"
      theme="light"
      :arrow="true"
    >
      <p>{{ text }}</p>
      <template v-slot:activator="{ props }">
        <v-icon v-bind="props" :color="iconColor" :size="iconSize">
          {{ icon }}
        </v-icon>
      </template>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'mdi-information-outline',
    },
    iconSize: {
      type: String,
      default: '30',
    },
    iconColor: {
      type: String,
      default: 'grey',
    },
    text: String,
    maxWidth: {
      type: Number,
      default: 400,
    },
  },
};
</script>

<style scoped>
.v-tooltip p {
  white-space: pre-wrap;
  color: white;
}
</style>
