<template>
  <v-container>
    <h1>Login</h1>
    <v-form v-model="isFormValid" @submit.prevent="login">
      <v-text-field
        outlined
        v-model="email"
        label="Email"
        type="email"
        :rules="emailRules"
      >
      </v-text-field>
      <v-text-field
        outlined
        v-model="password"
        label="Password"
        type="password"
        :rules="passwordRules"
      >
      </v-text-field>
      <v-btn :disabled="!isFormValid" type="submit" color="primary"
        >Login</v-btn
      >
      <div class="error-message">{{ error }}</div>
    </v-form>
    <div id="sign-up">
      <span>No account yet?</span>
      <v-btn variant="plain" color="primary" @click="$emit('toggle')"
        >Sign Up</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

export default {
  emits: ['toggle'],
  data() {
    return {
      email: '',
      emailRules: [
        (value) => {
          if (
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
              value
            )
          )
            return true;

          return 'Must be a valid e-mail.';
        },
      ],
      password: '',
      passwordRules: [
        (value) => {
          if (value?.length > 5) return true;

          return 'Password must be at least 6 characters.';
        },
      ],
      isFormValid: false,
      error: null,
    };
  },
  methods: {
    login() {
      const auth = getAuth();

      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          this.resetFormData();

          if (!userCredential) {
            this.$store.dispatch('snackbar/showMessage', {
              content: 'Could not complete the login!',
              color: 'red',
            });
            throw new Error('Could not complete the login!');
          }

          this.$gtag.event('login', {
            method: 'EmailAndPassword',
          });

          this.$router.push({ name: 'home' });

          this.$store.dispatch('snackbar/showMessage', {
            content: 'You have successfully logged in!',
            color: 'success',
          });
        })
        .catch(() => {
          this.error = 'Wrong email and/or password';
        });
    },
    resetFormData() {
      this.email = '';
      this.password = '';
      this.error = null;
    },
  },
};
</script>

<style scoped>
.v-container {
  max-width: 40%;
}

#sign-up {
  margin-top: 10px;
}

h1 {
  text-align: center;
  margin-bottom: 10px;
}

.error-message {
  color: red;
  margin-top: 2px;
}
</style>
