export default {
  className: 'Scholar',
  disciplines: [
    {
      name: 'Observation',
      description:
        'Scholars who walk the path of Observation embody the primal step of any scientific endeavor: to observe the world in unerring detail. Yet Observation isn’t mere idle watching; it is an active, tactile skill. These are the keen minds who gaze upon a battlefield and discern the invisible patterns of enemy formations or scrutinize the gentle eddies in a spellcaster’s aura to foretell which incantation will be invoked next. They see the hidden runes in ancient architecture, the infinitesimal twitch that betrays a liar, and the telltale signs of a trap-laden corridor. To an Observation Scholar, the rules of nature and magic exist for a reason. It is their solemn duty to understand those rules deeply and effect change within their confines. When an Observation Scholar raises their quill, it’s not merely to document but to set the stage for transformation, all within the bounds of cosmic law.',
    },
    {
      name: 'Investigation',
      description:
        'While Observers are the gatherers of data, Investigators are its interpreters, the ones who ask the perilous ‘why’ and ‘how.’ Investigators will ask questions, even when conventional wisdom or taboos suggest they shouldn’t. Every page turned, every inquiry made, is a stride closer to decoding the world’s most labyrinthine enigmas. Yet caution is their constant companion. A Scholar who asks too many questions may unsettle powers that are best left undisturbed. After all, to look behind certain veils may be to never see the world the same way again. The allure of the forbidden is strong, but every Investigation Scholar must ask: Is it worth it?',
    },
    {
      name: 'Experimentation',
      description:
        'For those who say “yes”, there is but only one discipline. The tempestuous discipline of Experimentation beckons those Scholars insatiable in their quest for truth. They are the avant-garde architects of intellectual landscapes, unafraid to challenge established facts and paradigms. Building upon the sturdy foundations laid by Observation and Investigation, they push the boundaries of knowledge into uncharted terrains — often heedless of the risks involved. It’s not enough for these Scholars to understand the natural order; they aim to stretch, subvert, and redefine it. Consequences be damned; if a veil exists, they shall pierce it, if a stone lies unturned, they shall flip it. For them, the thrill of intellectual achievement often transcends prudence and caution. The allure of forbidden or hidden truths is almost irresistible, and many are those who have paid the price for such reckless thirst. Yet, without these brave — or perhaps foolhardy — souls, the realm of knowledge would be a stagnant pool rather than a raging river.',
    },
  ],
  statGrowth: {
    STR: 30,
    DEX: 30,
    AGI: 30,
    VIT: 55,
    END: 55,
    INT: 70,
    WIS: 80,
    LCK: 50,
  },
  healthGrowth: {
    Small: 35,
    Medium: 40,
    Large: 25,
  },
  manaGrowth: {
    Small: 15,
    Medium: 35,
    Large: 50,
  },
  basicAttackStats: [],
  accuracyStats: [],
  defenseStats: [],
  criticalStrikeStats: [],
  skills: {
    tier1: [
      {
        id: 'scholar-t1-1',
        tier: 1,
        skillPoints: 1,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT', 'WIS'],
        ailments: [],
        ailmentsDuration: 0,
        buffs: ['Heal'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Observation',
        name: 'Test',
        imageSrc: '',
        description: [
          'Deal magical damage to target enemy equal to (X*SPELL + X*INT + X*WIS).',
          'Heal a random ally equal to (X%) of the damage dealt.',
          'Generate X charges of Arcane Power.',
          'COMBO: If this skill successfully deals Critical Strike damage, generate X additional Arcane Power.',
        ],
      },
      {
        id: 'scholar-t1-2',
        tier: 1,
        skillPoints: 1,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT', 'INT'],
        ailments: ['Reduce Magical Defense'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Investigation',
        name: 'Measure',
        imageSrc: '',
        description: [
          'Deal magical damage to target enemy equal to (X*SPELL + X*INT).',
          "(X + X*INT)% chance to reduce target's M.DEF by X% for a Duration of X.",
          'Generate X charges of Arcane Power.',
          'COMBO: If this skill successfully deals Critical Strike damage, generate X additional Arcane Power.',
        ],
      },
      {
        id: 'scholar-t1-3',
        tier: 1,
        skillPoints: 1,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal', 'Magical Ward'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Heal',
        damageType: '-',
        discipline: 'Experimentation',
        name: 'Practice',
        imageSrc: '',
        description: [
          'Heal target party member equal to (X*SPELL + X*WIS).',
          'Generate X charges of Arcane Power.',
          'COMBO: If target is already protected by any type of Barrier, generate X additional charges of Arcane Power.',
        ],
      },
    ],
    tier2: [
      {
        id: 'scholar-t2-1',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Heal',
        damageType: '-',
        discipline: 'Observation',
        name: 'Observation',
        imageSrc: '',
        description: [
          'Each instance an ally falls below X% HP in battle, gain a X% chance to automatically spend (X + X*LVL) Mana to heal ally for (X*SPELL).',
          'If successful, generate X charge of Arcane Power.',
        ],
      },
      {
        id: 'scholar-t2-2',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Increase BASIC', 'Increase SPELL'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Investigation',
        name: 'Investigation',
        imageSrc: '',
        description: [
          "At the beginning of each ally's Turn, ally gains a X% chance to increase BASIC and SPELL by X% for that Turn.",
          'If successful, generate X charge of Arcane Power.',
        ],
      },
      {
        id: 'scholar-t2-3',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Reduce Mana Cost of Next Action'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Experimentation',
        name: 'Experimentation',
        imageSrc: '',
        description: [
          "At the beginning of each ally's Turn, ally gains a X% chance to reduce the mana cost of its next action by X%.",
          'If successful, generate X charge of Arcane Power.',
        ],
      },
    ],
    tier3: [
      {
        id: 'scholar-t3-1',
        tier: 3,
        skillPoints: 3,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT', 'INT'],
        ailments: ['Reduce Damage Dealt'],
        ailmentsDuration: 0,
        buffs: ['Allies Gain X Charge of Arcane Power'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Observation',
        name: 'Notion',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Deal magical damage to each enemy equal to (X*SPELL + X*INT).',
          'X + X*INT)% chance to reduce all damage that each target enemy deals by X% (X = X + X*AP) for a Duration of X.',
          'COMBO: If "Observation" is active, increase the damage dealt by an additional (X*AP)%',
          'UNLOCK X: All Party Members gain X charge of Arcane Power.',
        ],
      },
      {
        id: 'scholar-t3-2',
        tier: 3,
        skillPoints: 3,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT', 'INT'],
        ailments: ['Reduce Magical Defense'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Investigation',
        name: 'Questionable Legitimacy',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Deal magical damage to target enemy equal to (X*SPELL + X*INT).',
          "(X + X*INT)% chance to reduce target's M.DEF by X% (X = X + X*AP) for a Duration of X.",
          'COMBO: If "Experimentation" is active, increase the damage dealt by an additional (X*AP)%.',
          'UNLOCK X: Target enemy loses up to X charges of Arcane Power.',
        ],
      },
      {
        id: 'scholar-t3-3',
        tier: 3,
        skillPoints: 3,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal', 'Magic Defense'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Heal',
        damageType: '-',
        discipline: 'Experimentation',
        name: 'Preparation',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Heal each party member equal to (X*SPELL + X*WIS).',
          'Each party member gains X% M.DEF( X = X + X*AP) for a Duration of X.',
          'COMBO: If "Investigation" is active, increase heal potency by an additional (X*AP)%.',
          'UNLOCK X: Generate X charges of Arcane Power.',
        ],
      },
    ],
    tier4: [
      {
        id: 'scholar-t4-1',
        tier: 4,
        skillPoints: 4,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT'],
        ailments: ['Root'],
        ailmentsDuration: 0,
        buffs: ['Magical Barrier'],
        buffsDuration: null,
        debuffs: ['Amnesia Systematic Observation'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Observation',
        name: 'Systematic Observation',
        imageSrc: '',
        description: [
          'Deal magical damage to each enemy equal to (X*SPELL + X*INT).',
          'X% chance to Root each target enemy for a Duration of X.',
          'Generate X charge of Arcane Power for each enemy that becomes Rooted.',
          'COMBO: If this spell successfully critical strikes, generate X additional Arcane Power per critical strike.',
          'Amnesia X.',
          'UNLOCK X:' +
            'Each party member gains a magical barrier with HP equal to X% of the damage that is dealt.',
        ],
      },
      {
        id: 'scholar-t4-2',
        tier: 4,
        skillPoints: 4,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT'],
        ailments: [],
        ailmentsDuration: 0,
        buffs: ['Heal', 'Heal Allies'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Investigation',
        name: 'Fringe Research',
        imageSrc: '',
        description: [
          'Channel for a Duration of X.',
          'Deal magical damage to target enemy equal to (X*SPELL + X*INT).',
          'Heal this Hero for X% of the damage dealt.',
          'Generate X charges of Arcane Power.',
          'COMBO: If this spell successfully critical strikes, generate X additional Arcane Power.',
          'Amnesia X.',
          'UNLOCK X: Each ally is healed for X% of the damage dealt.',
        ],
      },
      {
        id: 'scholar-t4-3',
        tier: 4,
        skillPoints: 4,
        range: 'ALLY',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT', 'INT', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Increase BASIC', 'Increase SPELL', 'Increase Range'],
        buffsDuration: 0,
        debuffs: ['Blind', 'Confuse'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Ally'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Experimentation',
        name: 'Promising Results',
        imageSrc: '',
        description: [
          'Deal damage to target ally equal to (X*SPELL - X*INT).',
          'Targeted party member gains:\n' +
            '(X + X*INT)% chance to increase BASIC by X% for a Duration of X.\n' +
            '(X + X*INT)% chance to increase SPELL by X% for a Duration of X.\n' +
            '(X + X*INT)% chance to increase Range by X for a Duration of X.\n' +
            '(X - X*INT)% chance to Blind target ally.\n' +
            '(X - X*INT)% chance to Confuse target ally.',
          'COMBO: Gain X charges of Arcane power for each successful effect.',
          'Amnesia X.',
        ],
      },
    ],
    tier5: [
      {
        id: 'scholar-t5-1',
        tier: 5,
        skillPoints: 5,
        range: 'ALLY',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Critical Strike Chance', 'Repeat Attack', 'Increase Range'],
        buffsDuration: 0,
        debuffs: ['Amnesia Catalyze'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Ally'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Observation',
        name: 'Catalyze',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Target party member gains (X*AP)% CSC for a Duration of X.',
          "Targeted Party Member's next attack gains Repeat (X*AP rounded down).",
          'UNLOCK X:\n' +
            "Targeted Party Member's attacks gain (X*AP rounded down) range for a Duration of X.",
          'Amnesia X.',
        ],
      },
      {
        id: 'scholar-t5-2',
        tier: 5,
        skillPoints: 5,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT'],
        ailments: ['Fear', 'Exhaust', 'Stun'],
        ailmentsDuration: null,
        buffs: ['Evasion'],
        buffsDuration: 0,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Investigation',
        name: 'Glimpse the Void',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Channel for a Duration of X. During Channel, gain X% EVA.',
          'Deal magical damage to target enemy in P1 equal to [(X*AP)*SPELL + X*INT)].',
          '(X + X*INT)% chance apply a stack of Chill to target.',
          '(X + X*AP)% chance to Fear target enemy for a Duration of X.',
          '(X + X*AP)% chance to Exhaust this Hero for a Duration of X.',
          'UNLOCK X:\n' +
            '(X + X*AP)% chance to Stun target enemy in P1 for a Duration of X.',
          'UNLOCK X:\n' +
            'X + X*AP)% chance to deal magical damage equal to [(X*AP)*SPELL + X*INT)] to target enemy in P2.',
          'Amnesia X',
        ],
      },
      {
        id: 'scholar-t5-3',
        tier: 5,
        skillPoints: 5,
        range: 'ALLY',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Pure Barrier'],
        buffsDuration: 0,
        debuffs: ['Amnesia Side Effects'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Ally'],
        skillType: 'Barrier',
        damageType: '-',
        discipline: 'Experimentation',
        name: 'Side Effects',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Target party member gains a pure barrier with HP equal to [(X*AP)*SPELL + X*INT + X*WIS].',
          '(X - X*INT)% chance to Poison targeted party member.',
          'UNLOCK X: Delay X, Cleanse targeted party member.',
          'UNLOCK X: Delay X, Heal targeted party member for [(X*AP)*SPELL + X*WIS].',
          'Amnesia X.',
        ],
      },
    ],
    tier6: [
      {
        id: 'scholar-t6-1',
        tier: 6,
        skillPoints: 8,
        range: 'ALLIES',
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: true,
        dependentSkill: 'scholar-t2-1',
        comboSkills: [],
        comboEffects: ['Heal'],
        scalingStats: ['WIS'],
        ailments: [],
        ailmentsDuration: 0,
        buffs: ['Increase Agility'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Observation',
        name: 'Observation +',
        imageSrc: '',
        description: [
          "Each instance an ally is healed to a full 100% HP in battle, gain a X% chance to automatically spend (X + X*LVL) Mana to increase ally's AGI by (X*WIS) for a Duration of X.",
          'When successful, generate X charge of Arcane Power.',
        ],
      },
      {
        id: 'scholar-t6-2',
        tier: 6,
        skillPoints: 7,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'scholar-t3-1',
        comboSkills: ['scholar-t2-1', 'scholar-t6-1'],
        comboEffects: [],
        scalingStats: ['INT', 'INT'],
        ailments: ['Reduce Damage Dealt', 'Root'],
        ailmentsDuration: 0,
        buffs: ['Allies Gain X Charges of AP'],
        buffsDuration: null,
        debuffs: ['Amnesia Notion +'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Observation',
        name: 'Notion +',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Deal magical damage to each enemy equal to [(X*AP)*SPELL + (X*AP)*INT].',
          '(X + X*INT)% chance to reduce all damage that each target enemy deals by X% (X = X + X*AP) for a Duration of 1.',
          'COMBO: If "Observation" is active, each successful hit has a (X + X*INT)% chance to Root each target enemy for a Duration of X.',
          'UNLOCK X: All Party Members gain X charges of Arcane Power.',
          'Amnesia X.',
        ],
      },
      {
        id: 'scholar-t6-3',
        tier: 6,
        skillPoints: 8,
        range: 3,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: 'scholar-t2-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Decrease BASIC', 'Decrease SPELL'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Debuff',
        damageType: '-',
        discipline: 'Investigation',
        name: 'Investigation +',
        imageSrc: '',
        description: [
          "At the beginning of each enemy's Turn, enemy gains a X% chance to decrease its BASIC and SPELL by X% for a Duration of X.",
          'If successful, generate X charges of Arcane Power.',
        ],
      },
      {
        id: 'scholar-t6-4',
        tier: 6,
        skillPoints: 7,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'scholar-t3-2',
        comboSkills: ['scholar-t2-2', 'scholar-t6-3'],
        comboEffects: [],
        scalingStats: ['INT', 'INT'],
        ailments: [
          'Reduce Magical Defense',
          'Silence',
          'Loose X charges of AP',
        ],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Investigation',
        name: 'Questionable Legitimacy +',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Deal magical damage to target enemy equal to [(X*AP)*SPELL + (X*AP)*INT].',
          "(X + X*INT)% chance to reduce target's M.DEF by X% (X = X + X*AP) for a Duration of X.",
          'COMBO: If "Experimentation" is active, (X + X*INT)% chance to Silence target enemy for a Duration of X.',
          'UNLOCK X: Target enemy loses up to X charges of Arcane Power.',
        ],
      },
      {
        id: 'scholar-t6-5',
        tier: 6,
        skillPoints: 8,
        range: 'ALLIES',
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: 'scholar-t2-3',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Reduce Mana Cost of Next Action', 'Generate X Charges of AP'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Experimentation',
        name: 'Experimentation +',
        imageSrc: '',
        description: [
          "At the beginning of each party member's Turn, party member gains a X% chance to reduce the mana cost of its next action by X%.",
          'When successful, generate X charges of Arcane Power.',
        ],
      },
      {
        id: 'scholar-t6-6',
        tier: 6,
        skillPoints: 7,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'scholar-t3-3',
        comboSkills: ['scholar-t2-3', 'scholar-t6-5'],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal', 'Magical Ward', 'Increase Magical Defense'],
        buffsDuration: 0,
        debuffs: ['Amnesia Preparation +'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Heal',
        damageType: '-',
        discipline: 'Experimentation',
        name: 'Preparation +',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Heal each party member equal to [(X*AP)*SPELL + (X*AP)*WIS].',
          'Each party member gains a X% chance to gain a magical Ward for a Duration of X.',
          'COMBO: If "Investigation" is active, Delay X, each party member gains X% M.DEF( X = X + X*AP) for a Duration of X.',
          'UNLOCK X: Generate X charges of Arcane Power.',
          'Amnesia X.',
        ],
      },
    ],
    tier7: [
      {
        id: 'scholar-t7-1',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT'],
        ailments: ['Redirect non-Basic Actions to Random Enemy'],
        ailmentsDuration: 0,
        buffs: ['Gain X Charges of AP'],
        buffsDuration: null,
        debuffs: ['Amnesia It Depends'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Observation',
        name: 'It Depends',
        imageSrc: '',
        description: [
          'Deal magical damage to target enemy equal to (X*SPELL + X*INT).',
          '99% chance to inflict the following status effect:\n For a Duration of X, each non-Basic action target enemy takes has a X% chance to target a random enemy. Can only occur a maximum of X instances over the Duration.',
          'Whenever this effect occurs, gain X charges of Arcane Power.',
          'Amnesia X.',
        ],
      },
      {
        id: 'scholar-t7-2',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT'],
        ailments: ['Amnesia non-Basic Action'],
        ailmentsDuration: 0,
        buffs: ['Critical Strike', 'Repeat'],
        buffsDuration: null,
        debuffs: ['Amnesia Take Knowledge'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Observation',
        name: 'Take Knowledge',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Deal magical damage to target enemy equal to [(X*AP)*SPELL + X*INT)].',
          '99% chance to inflict the following status effect:\n For a Duration of X, each non-Basic action target enemy takes has a X% chance to be inflicted with Amnesia X (X = X + X*AP, Rounded Down). Can only occur a maximum of X instances over the Duration.',
          'Whenever this effect occurs, gain X charges of Arcane Power.',
        ],
      },
      {
        id: 'scholar-t7-3',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT'],
        ailments: ['Negate Delayed Action', 'Confuse'],
        ailmentsDuration: 0,
        buffs: ['Gain X Charges of AP'],
        buffsDuration: null,
        debuffs: ['Amnesia Actually'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Observation',
        name: 'Actually',
        imageSrc: '',
        description: [
          'Deal magical damage to target enemy equal to (X*SPELL + X*INT).',
          '(X + X*INT)% chance to Negate a single random Delayed action previously cast by targeted enemy.',
          'COMBO: If Delayed action is Negated, 99% chance to Confuse target enemy for a Duration of X and gain X charges of Arcane Power.',
          'Amnesia X.',
        ],
      },
      {
        id: 'scholar-t7-4',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Dispel'],
        ailmentsDuration: null,
        buffs: ['Remove Random Amnesia'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Investigation',
        name: 'Forbidden Knowledge',
        imageSrc: '',
        description: [
          'Deal magical damage to target enemy equal to (X*SPELL + X*INT).',
          '(X + X*INT)% chance to Dispel target enemy.',
          'COMBO: If a Status Effect is successfully Dispelled, remove a random instance of Amnesia from this Hero and gain X charges of Arcane Power.',
        ],
      },
      {
        id: 'scholar-t7-5',
        tier: 7,
        skillPoints: 10,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['scholar-t2-2', 'scholar-t6-2'],
        comboEffects: [],
        scalingStats: ['WIS'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Heal',
          'Magical Ward',
          'Increase Magical Defense',
          'Generate X Charges of AP',
        ],
        buffsDuration: 0,
        debuffs: ['Amnesia Forgotten Knowledge'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Experimentation',
        name: 'Forgotten Knowledge',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Heal each party member equal to [(X*AP)*SPELL + (X*AP)*WIS].',
          'Each party member gains a X% chance to gain a magical Ward for a Duration of X.',
          'COMBO: If "Investigation" is active, Delay X, each party member gains X% M.DEF( X = X + X*AP) for a Duration of X.',
          'UNLOCK X: Generate X charges of Arcane Power.',
          'Amnesia X.',
        ],
      },
      {
        id: 'scholar-t7-6',
        tier: 7,
        skillPoints: 10,
        range: 'Ally',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Repeat X'],
        buffsDuration: null,
        debuffs: ['Delay X Next Skill', 'Amnesia Peer Review'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Ally'],
        skillType: 'Utility',
        damageType: '-',
        discipline: 'Investigation',
        name: 'Peer Review',
        imageSrc: '',
        description: [
          'Delay X, the next skill target Ally uses gains Repeat X.',
          'Amnesia X.',
        ],
      },
      {
        id: 'scholar-t7-7',
        tier: 7,
        skillPoints: 10,
        range: 'Ally',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT', 'WIS', 'LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Increase Physical Defense',
          'Increase Magical Defense',
          'Increase Evasion',
          'Cleanse',
          'Heal',
        ],
        buffsDuration: 0,
        debuffs: ['Confused'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Ally'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Experimentation',
        name: 'Augment State',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Target Party Member gains:',
          '(X*AP + X*INT)% P.DEF for a Duration of X.',
          '(X*AP + X*WIS)% M.DEF for a Duration of X.',
          '(X*AP + X*LCK)% EVA for a Duration of X.',
          '(X - X*LCK)% chance to Confuse target Party Member for a Duration of X.',
          'UNLOCK X: Delay X, Cleanse target party member.',
          'UNLOCK X: Delay X, heal target party member equal to [(X*AP)*SPELL + X*WIS].',
          'Amnesia X.',
        ],
      },
      {
        id: 'scholar-t7-8',
        tier: 7,
        skillPoints: 10,
        range: 'ALLY',
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Heal'],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Ally'],
        skillType: 'Utility',
        damageType: '-',
        discipline: 'Experimentation',
        name: 'Infuse Possibility',
        imageSrc: '',
        description: [
          'For a Duration of X, each instance of healing target party member receives causes damage equal to X% of the HP restored divided evenly amongst all enemies.',
          'Amnesia X.',
        ],
      },
      {
        id: 'scholar-t7-9',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: 0,
        buffs: ['Heal'],
        buffsDuration: 0,
        debuffs: ['Amnesia Optimize Potential'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Ally'],
        skillType: 'Utility',
        damageType: 'Magical',
        discipline: 'Experimentation',
        name: 'Optimize Potential',
        imageSrc: '',
        description: [
          'For a Duration of X, each instance of damage targeted enemy receives causes a heal equal to X% of the damage dealt divided evenly amongst all Party Members.',
          'Amnesia X.',
        ],
      },
    ],
    tier8: [
      {
        id: 'scholar-t8-1',
        tier: 8,
        skillPoints: 15,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Confused'],
        ailmentsDuration: 0,
        buffs: [
          'Generate X Charges of AP',
          'Increase Magical Defense',
          'Remove Random Amnesia',
          'Heal',
          'Generate X Charges of AP',
        ],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Investigation',
        name: 'Answers',
        imageSrc: '',
        description: [
          'Each instance this Hero receives damage, gain:',
          'X% chance to generate X charges of Arcane Power.',
          'X% chance to gain X% M.DEF for the remainder of battle.',
          'X% chance to remove a random instance of Amnesia from this Hero.',
          'X% chance to heal this Hero for (X*SPELL).',
          'X% chance to inflict attacking enemy with Confusion for a Duration of X.\n',
          'For each successful triggered effect, generate X charges of Arcane Power.',
        ],
      },
    ],
  },
};
