<template>
  <div>
    <v-row>
      <v-col cols="12" md="2"></v-col>
      <v-col cols="12" md="8">
        <h1 class="d-flex justify-center">Skill Calculator</h1>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <v-list-item>
          The Skill Calculator is a kind of a "sandbox" where you can experiment
          with hero codex builds. It provides information about the codex skills
          and enforces the rules and limitations of picking hero skills. The
          system will disable skills which are not available for selection or
          deselection because of restrictions that we are going to talk next.
          This is done to ensure that in the end, you will have a valid hero
          build.
        </v-list-item>
        <v-list-item>
          Another great thing about this feature is that you can view hero
          analytics, based on the selected codex skills. The "Analytics" tab
          includes many widgets, displaying various build aspects like which
          hero stats does the build scale the best with, etc.
        </v-list-item>
        <v-list-item>
          This feature does not require an account to play with, but if you want
          to save and/or share the build you are creating, you need to register
          or login to your account first.
        </v-list-item>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <h2 class="d-flex justify-center">Restrictions</h2>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <v-list-item>
          The main restriction is the maximum hero level cap of 100. Heroes get
          1 skill point for every hero level in total maximum of
          <strong>100 skill points</strong> to spend for the hero build. You
          could spend all 100 skill points on hero's main class codex, since
          there are not enough skill points to buy all available main codex
          skills. Optionally, you could spend up to
          <strong>15 skill points</strong> on hero's subclass codex, if your
          hero's class and subclass are not matching.
        </v-list-item>
        <h4 class="d-flex justify-center">Main Codex</h4>
        <v-list-item
          v-for="(restriction, index) in mainCodexRestrictions"
          :key="index"
          >{{ restriction }}
        </v-list-item>
        <h4 class="d-flex justify-center">Subclass Codex</h4>
        <v-list-item
          v-for="(restriction, index) in subclassCodexRestrictions"
          :key="index"
          >{{ restriction }}
        </v-list-item>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <h2 class="d-flex justify-center">Step by Step Guide</h2>
        <v-container>
          <v-divider></v-divider>
        </v-container>
        <image-with-description
          v-for="(item, index) in stepByStepData"
          :key="index"
          :imagePath="item.imagePath"
          :altText="item.altText"
          :description="item.description"
        ></image-with-description>
      </v-col>
      <v-col cols="12" md="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import ImageWithDescription from './ImageWithDescription.vue';
import skillCalculatorData from '../../../data/get-started/how-to/skillCalculatorData';

export default {
  components: {
    ImageWithDescription,
  },
  data() {
    return {
      mainCodexRestrictions: [
        'TIERS 1-5: only one skill can be selected from each tier. Tier selection goes from Tier 1 to Tier 5, so you need to select a skill in the previous tier to unlock the next. To deselect a skill, you need to deselect first the skill in the upper tier.',
        'TIER 6: the tier is unlocked once a Tier 5 skill is selected. You need to have the basic version of the skill selected in Tiers 1-5, in order to have its upgraded version available. You can select as many Tier 6 skills as you have available. Skill cost varies between 5 and 9 skill points, depending on the cost of the basic skill. The earliest you can have a Tier 6 skill is at hero level 20, if you have an "upgradeable" Tier 5 skill selected.',
        'TIER 7: the tier is unlocked once a Tier 5 skill is selected, you do not have to select Tier 6 skill first. You could select as many Tier 7 skills as you want and have skill points to spend. Skills cost 10 skill points and the earliest you can have a Tier 7 skill is at hero level 25.',
        'TIER 8: this tier is available only for heroes that have matching class and subclass (pure class heroes). This tier is unlocked once a Tier 5 skill is selected, you do not have to select Tier 6 or 7 skill first. This "capstone" skill costs 15 skill points and the earliest you can have it is at hero level 30.',
      ],
      subclassCodexRestrictions: [
        'Only Tiers 1-7 are available for the subclass codex.',
        'To "unlock" picking skills from subclass Tiers 1-7, you need to have at least 1 skill selected in the main codex, corresponding to the same subclass codex tier. If you deselect the last skill from a tier in the main codex, this will automatically deselect subclass codex skills from the same tier. ',
        'There is no pick order for the subclass codex skills. You could select a Tier 7 skill directly if you satisfy the conditions in the upper',
        'TIER 6: you need to have the basic version of the skill selected in Tiers 1-5, in order to have its "upgraded" version available for selection in Tier 6.',
        'TIERS 1-7: only one skill can be selected from each tier up to 15 skill points spent on the subclass codex.',
      ],
      stepByStepData: skillCalculatorData,
    };
  },
};
</script>

<style scoped>
.h1 {
  font-size: 2.2rem;
}

.h2 {
  font-size: 1.7rem;
}

.h4 {
  font-size: 1.2rem;
}
</style>
