export default {
  className: 'Thief',
  disciplines: [
    {
      name: 'Sneak',
      description:
        "The Sneak discipline focuses on the Thief's inherent agile nature and quickness to deal damage and evade attacks. Whether it's a twisted dagger skillfully placed just so in the side of an enemy, or a thrifty opportunity capitalized upon, a Thief always looks for ways to cause a little bit of mischief. That mischief often comes at the expense of an already beaten-down opponent. A Thief that has mastered the art of staying hidden in plain sight really knows how to take advantage of a fool on the back foot, never scared to cause a little bleed.",
    },
    {
      name: 'Savvy',
      description:
        "At its core, the Savvy discipline focuses on the Thief's ability to use the environment around them to turn the tables in their favor. Traps, poisons, smoke bombs, and tripwires, these are the tools a clever Thief knows can be the difference between escape, capture, and even death. Oh, and pocket sand. Never leave home without some of that.",
    },
    {
      name: 'Thug',
      description:
        "The Thug forgoes any overture of deception or intrigue by simply finding the opportune moment to send a very clear message: don't mess with me and my team. Sabotage, brute force, and intimidation are the not-so-subtle methods of choice for damage dealing and convincing an enemy to think twice before acting. But a Thug isn't born, a Thug is made. From pocket picking to straight-up extortion, the Thug understands how to manipulate a situation to take control of the outcome, and how to keep a target quiet.",
    },
  ],
  statGrowth: {
    STR: 55,
    DEX: 55,
    AGI: 70,
    VIT: 50,
    END: 45,
    INT: 25,
    WIS: 35,
    LCK: 65,
  },
  healthGrowth: {
    Small: 25,
    Medium: 50,
    Large: 25,
  },
  manaGrowth: {
    Small: 30,
    Medium: 40,
    Large: 30,
  },
  basicAttackStats: [],
  accuracyStats: [],
  defenseStats: [],
  criticalStrikeStats: [],
  skills: {
    tier1: [
      {
        id: 'thief-t1-1',
        tier: 1,
        skillPoints: 1,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'STR'],
        ailments: ['Bleed'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Sneak',
        name: 'Twisted Dagger',
        imageSrc: '',
        description: [
          'Deal physical damage to the target enemy equal to (X*BASIC + X*DEX + X*STR).',
          '(X + X*DEX)% chance to inflict target enemy with Bleed.',
        ],
      },
      {
        id: 'thief-t1-2',
        tier: 1,
        skillPoints: 1,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK'],
        ailments: ['Poison'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Savvy',
        name: 'Toxic Trap',
        imageSrc: '',
        description: [
          'Place a trap on target party member that lasts for a Duration of X.',
          'When that party member is attacked and after incoming damage resolves, deal physical damage to attacking enemy equal to (X*BASIC + X*LCK).',
          'This attack has a (X + X*LCK)% chance to Poison the attacking enemy.',
          'Only activates once.',
        ],
      },
      {
        id: 'thief-t1-3',
        tier: 1,
        skillPoints: 1,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['AGI'],
        ailments: ['Sabotage', 'Decrease Evasion'],
        ailmentsDuration: 0,
        buffs: ['Evasion'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Utility',
        damageType: '-',
        discipline: 'Thug',
        name: 'Pickpocket',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (0).',
          'X% chance to Sabotage.',
          "(X + X*AGI)% chance to reduce target enemy's EVA by X% for a Duration of X.",
          'If successful, gain X% EVA for a Duration of X.',
        ],
      },
    ],
    tier2: [
      {
        id: 'thief-t2-1',
        tier: 2,
        skillPoints: 2,
        range: 'SELF',
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX'],
        ailments: ['Decrease Dexterity'],
        ailmentsDuration: null,
        buffs: ['Dexterity'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Sneak',
        name: 'Sneak',
        imageSrc: '',
        description: [
          'At the start of Battle, reduce (X + X*DEX)% DEX from the enemy with the highest DEX value.',
          'Gain effective DEX equal to the value reduced.',
        ],
      },
      {
        id: 'thief-t2-2',
        tier: 2,
        skillPoints: 2,
        range: 'SELF',
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Luck'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Savvy',
        name: 'Savvy',
        imageSrc: '',
        description: [
          'At the start of Battle, reduce (X + X*LCK)% LCK from the enemy with the highest LCK value.',
          'Gain effective LCK equal to the value reduced.',
        ],
      },
      {
        id: 'thief-t2-3',
        tier: 2,
        skillPoints: 2,
        range: 'SELF',
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Strength'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Debuff',
        damageType: '-',
        discipline: 'Thug',
        name: 'Thug',
        imageSrc: '',
        description: [
          'At the start of Battle, a random enemy receives a death mark.',
          'When attacking the target, deal (X + X*STR)% additional physical damage.',
          'When target enemy dies, each party member gains effective STR equal to (X + X*Target_STR).',
        ],
      },
    ],
    tier3: [
      {
        id: 'thief-t3-1',
        tier: 3,
        skillPoints: 3,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'thief-t1-1',
          'thief-t3-1',
          'thief-t4-1',
          'thief-t5-1',
          'thief-t6-1',
          'thief-t6-2',
          'thief-t7-2',
          'thief-t7-3',
        ],
        comboEffects: ['Daze', 'Blind', 'Exhaust'],
        scalingStats: ['DEX'],
        ailments: ['Bleed'],
        ailmentsDuration: null,
        buffs: ['Critical Strike'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Sneak',
        name: 'Backstab',
        imageSrc: '',
        description: [
          'Deal physical damage equal to (X*BASIC + X*DEX + X*STR) to target enemy.',
          'If target is Dazed, Blinded, or Exhausted, gain CSC equal to (X + X*DEX)%.',
          'COMBO: If this Hero used a "Sneak" Discipline skill on its last Turn, this attack gains a (X + X*DEX)% chance to inflict target enemy with Bleed',
        ],
      },
      {
        id: 'thief-t3-2',
        tier: 3,
        skillPoints: 3,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['thief-t1-2', 'thief-t6-3'],
        comboEffects: [],
        scalingStats: ['DEX', 'LCK'],
        ailments: ['Blind', 'Confuse'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Amnesia Pocket Sand'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Savvy',
        name: 'Pocket Sand',
        imageSrc: '',
        description: [
          'Deal physical damage equal to (X*BASIC + X*DEX + X*LCK).',
          '(X + X*DEX)% chance to Blind target enemy for a Duration of X.',
          'COMBO: If "Toxic Trap" was used on this Hero\'s last Turn, gain a (X + X*LCK)% chance to Confuse target.',
          'Amnesia X, "Pocket Sand".',
        ],
      },
      {
        id: 'thief-t3-3',
        tier: 3,
        skillPoints: 3,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['thief-t1-3', 'thief-t6-5'],
        comboEffects: [],
        scalingStats: ['STR', 'DEX', 'AGI'],
        ailments: ['Reduce Evasion', 'Silence'],
        ailmentsDuration: 0,
        buffs: ['Evasion'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Thug',
        name: 'Ambush',
        imageSrc: '',
        description: [
          'Deal damage to target enemy in P3 equal to (X*BASIC + X*STR + X*DEX).',
          'X% chance to Sabotage.',
          "(X + X*STR)% chance to reduce target enemy's EVA by (X + X*AGI)% for a Duration of X.",
          'If successful, gain (X + X*AGI)% EVA for a Duration of X.',
          'COMBO: If "Pickpocket" was used on this Hero\'s last Turn, gain (X + STR)% chance to Silence target for a Duration of X.',
        ],
      },
    ],
    tier4: [
      {
        id: 'thief-t4-1',
        tier: 4,
        skillPoints: 4,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['thief-t3-3', 'thief-t6-6'],
        comboEffects: [],
        scalingStats: ['DEX', 'DEX', 'AGI', 'STR'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Evasion'],
        buffsDuration: 0,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Sneak',
        name: 'Hidden Blade',
        imageSrc: '',
        description: [
          'Channel for a Duration of X.',
          'During Channel, gain (X + X*DEX + X*AGI)% EVA.',
          'Deal physical damage to target enemy equal to (X*BASIC + X*DEX + X*STR).',
          '(X + X*DEX)% chance to inflict target with Bleed.',
          'COMBO: If "Ambush" was used on this Hero\'s last Turn, gain a(X + X*DEX)% chance to Silence target for a Duration of X.',
        ],
      },
      {
        id: 'thief-t4-2',
        tier: 4,
        skillPoints: 4,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['thief-t3-2'],
        comboEffects: [],
        scalingStats: ['DEX', 'LCK'],
        ailments: ['Reduce Accuracy'],
        ailmentsDuration: 0,
        buffs: ['Ignore Barrier'],
        buffsDuration: null,
        debuffs: ['Amnesia Dose'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Savvy',
        name: 'Dose',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (0).',
          'This attack ignores Barriers.',
          '(X + X*DEX)% chance to Poison target enemy.',
          'Delay X, deal physical damage to target enemy equal to (X*BASIC + X*LCK).',
          'COMBO: If "Pocket Sand" was used on this Hero\'s last Turn, target enemy\'s accuracy is reduced by (X + X*DEX)% for a Duration of X.',
          'Amnesia X, "Dose"',
        ],
      },
      {
        id: 'thief-t4-3',
        tier: 4,
        skillPoints: 4,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['thief-t3-1'],
        comboEffects: [],
        scalingStats: ['STR', 'STR'],
        ailments: ['Silence', 'Exhaust'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Thug',
        name: 'Extort',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to X (X = X*BASIC + X*STR).',
          'If target enemy is unable to deal damage greater than (X*X) within a Duration of X, target receives physical damage equal to (X*BASIC + X*STR) and gains a X% chance to be Silenced for a Duration of X.',
          'COMBO: If "Backstab" was used on this Hero\'s last turn, gain a (X + X*STR)% chance to Exhaust target for a Duration of X.',
        ],
      },
    ],
    tier5: [
      {
        id: 'thief-t5-1',
        tier: 5,
        skillPoints: 5,
        range: 2,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Bleed'],
        scalingStats: ['DEX', 'STR', 'AGI'],
        ailments: ['Execute'],
        ailmentsDuration: null,
        buffs: ['Evasion'],
        buffsDuration: 0,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Single Target'],
        skillType: 'Execute',
        damageType: 'Physical',
        discipline: 'Sneak',
        name: 'Go for the Throat',
        imageSrc: '',
        description: [
          'Channel for a Duration of X.',
          'During Channel, gain (X + X*DEX + X*AGI)% EVA.',
          'Deal physical damage to target enemy equal to (X*BASIC + X*DEX +X*STR).',
          'Execute: If a target is below X% of max HP after damage resolves, target dies.',
          'COMBO: If target is currently afflicted with Bleed, increase Execute threshold to X% of max HP.',
        ],
      },
      {
        id: 'thief-t5-2',
        tier: 5,
        skillPoints: 5,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'LCK', 'LCK'],
        ailments: ['Blind', 'Poison'],
        ailmentsDuration: null,
        buffs: ['Untargetable for Party Members'],
        buffsDuration: 0,
        debuffs: ['Amnesia Miasma Bomb'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Savvy',
        name: 'Miasma Bomb',
        imageSrc: '',
        description: [
          'Deal physical damage equal to (X*BASIC + X*DEX + X*LCK) to all enemies, divided evenly.',
          '(X + X*LCK)% chance to Blind all enemies.',
          '(X + X*LCK)% chance to Poison all enemies.',
          '(X + X*LCK)% chance for all party members to gain Untargetable for a Duration of X.',
          'Amnesia X, "Miasma Bomb".',
        ],
      },
      {
        id: 'thief-t5-3',
        tier: 5,
        skillPoints: 5,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Exhaust', 'Confuse', 'Silence'],
        scalingStats: ['STR', 'STR', 'DEX'],
        ailments: ['Sabotage', 'Reduce Battle Budget'],
        ailmentsDuration: 0,
        buffs: ['Increase Battle Budget'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Thug',
        name: 'Mug',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*BASIC + X*STR).',
          'X% chance to Sabotage.',
          "If Sabotage successfully reduces target's Battle Budget by X, restore X party Battle Budget.",
          'COMBO: If target is Exhausted, Confused, or Silenced, gain a (X + X*STR + X*DEX)% chance to reduce the current Mana of target enemy by (X + X*STR)%.',
        ],
      },
    ],
    tier6: [
      {
        id: 'thief-t6-1',
        tier: 6,
        skillPoints: 9,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'thief-t1-1',
        comboSkills: [],
        comboEffects: ['Bleed'],
        scalingStats: ['DEX', 'DEX'],
        ailments: ['Bleed', 'Daze'],
        ailmentsDuration: null,
        buffs: ['Pierce'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Sneak',
        name: 'Twisted Dagger +',
        imageSrc: '',
        description: [
          'Deal physical damage to the target enemy equal to (X*BASIC + X*DEX).',
          'This attack has X% Pierce.',
          '(X + X*DEX)% chance to inflict target enemy with Bleed.',
          'COMBO: If the target is already inflicted with Bleed, gain (X + DEX)% chance to Daze target.',
        ],
      },
      {
        id: 'thief-t6-2',
        tier: 6,
        skillPoints: 7,
        range: 2,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'thief-t3-1',
        comboSkills: [
          'thief-t1-1',
          'thief-t3-1',
          'thief-t4-1',
          'thief-t5-1',
          'thief-t6-1',
          'thief-t6-2',
          'thief-t7-2',
          'thief-t7-3',
        ],
        comboEffects: ['Daze', 'Blind', 'Exhaust'],
        scalingStats: ['STR', 'DEX'],
        ailments: ['Bleed'],
        ailmentsDuration: null,
        buffs: ['Critical Strike', 'Pierce'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Sneak',
        name: 'Backstab +',
        imageSrc: '',
        description: [
          'Deal physical damage equal to (X*BASIC + X*DEX + X*STR) to target enemy.\n',
          'If target is Dazed, Blinded, or Exhausted, gain CSC equal to (X + X*DEX)%.',
          'This attack has X% Pierce.',
          'COMBO: If this Hero used a "Sneak" Discipline skill on its last Turn, this attack gains a (X + X*DEX)% chance to inflict target enemy with Bleed.',
        ],
      },
      {
        id: 'thief-t6-3',
        tier: 6,
        skillPoints: 9,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'thief-t1-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK'],
        ailments: ['Poison'],
        ailmentsDuration: null,
        buffs: ['Critical Strike'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Savvy',
        name: 'Toxic Trap +',
        imageSrc: '',
        description: [
          'Place a trap on target party member that lasts for a Duration of X.',
          'When that party member is attacked and after incoming damage resolves, deal physical damage to attacking enemy equal to (X*BASIC + X*LCK).',
          'This attack has a (X + X*LCK)% chance to Poison the attacking enemy.',
          'This attack has an increase CSC of X%.',
          'Only activates once.',
        ],
      },
      {
        id: 'thief-t6-4',
        tier: 6,
        skillPoints: 6,
        range: 2,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'thief-t4-2',
        comboSkills: ['thief-t3-2'],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Poison', 'Daze', 'Blind', 'Reduce Accuracy'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Amnesia Dose +'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Savvy',
        name: 'Dose +',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (0).',
          '(X + X*DEX)% chance to Poison target enemy.',
          'Delay 1, deal physical damage to target enemy equal to (0).\n' +
            '(X + X*DEX)% chance to Daze target enemy.',
          'Delay 2, deal physical damage to target enemy equal to (0).\n' +
            '(X + X*DEX)% chance to Blind target enemy.',
          'Delay X, deal physical damage to target enemy equal to (X*BASIC + X*LCK).',
          'COMBO: If "Pocket Sand" was used on this Hero\'s last Turn, target enemy\'s accuracy is reduced by (X + X*DEX)% for a Duration of X.',
          'Amnesia X, "Dose +"',
        ],
      },
      {
        id: 'thief-t6-5',
        tier: 6,
        skillPoints: 9,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'thief-t1-3',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK'],
        ailments: ['Sabotage', 'Reduce Evasion', 'Confuse'],
        ailmentsDuration: 0,
        buffs: ['Evasion'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Utility',
        damageType: '-',
        discipline: 'Thug',
        name: 'Pickpocket +',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (0).',
          'X% chance to Sabotage.',
          "(X + X*AGI)% chance to reduce target enemy's EVA by X% for a Duration of X.",
          'If successful, gain X% EVA for a Duration of X.',
          '(X + X*AGI)% chance to Confuse target enemy for a Duration of X.',
        ],
      },
      {
        id: 'thief-t6-6',
        tier: 6,
        skillPoints: 7,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'thief-t3-3',
        comboSkills: ['thief-t1-3', 'thief-t6-5'],
        comboEffects: [],
        scalingStats: ['STR', 'DEX', 'AGI'],
        ailments: ['Sabotage', 'Reduce Evasion', 'Silence', 'Confuse'],
        ailmentsDuration: 0,
        buffs: ['Evasion'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Thug',
        name: 'Ambush +',
        imageSrc: '',
        description: [
          'Deal damage to target enemy in P3 equal to (X*BASIC + X*STR + X*DEX).',
          'X% chance to Sabotage.',
          "(X + X*STR)% chance to reduce target enemy's EVA by (X + X*AGI)% for a Duration of X.",
          'If successful, gain X EVA for a Duration of X, where X equals the value reduced.',
          '(X + X*AGI)% chance to Silence target enemy for Duration of X.',
          'COMBO: If "Pickpocket" was used on this Hero\'s last Turn, gain (X + STR)% chance to Confuse target for a Duration of X.',
        ],
      },
    ],
    tier7: [
      {
        id: 'thief-t7-1',
        tier: 7,
        skillPoints: 10,
        range: 'SELF',
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Bleed'],
        ailmentsDuration: null,
        buffs: ['Increased Attack Range', 'Attacks Inflict Bleed', 'Evasion'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Sneak',
        name: 'Sneak, Stab, Hide',
        imageSrc: '',
        description: [
          'Every X Durations, this Hero receives a random single status effect that lasts for a Duration of X from this list:',
          '1: Increase attack Range by X.',
          '2: All attacks to gain X% chance to inflict Bleed.',
          '3: X% EVA.',
        ],
      },
      {
        id: 'thief-t7-2',
        tier: 7,
        skillPoints: 10,
        range: 2,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'thief-t1-1',
          'thief-t3-1',
          'thief-t4-1',
          'thief-t5-1',
          'thief-t6-1',
          'thief-t6-2',
          'thief-t7-2',
          'thief-t7-3',
        ],
        comboEffects: [],
        scalingStats: ['LCK', 'DEX', 'DEX', 'WIS'],
        ailments: ['Sabotage', 'Reduce Mana'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Amnesia Swindle'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Sneak',
        name: 'Swindle',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (0).',
          'X% chance to Sabotage.',
          '(X + X*LCK + X*DEX)% chance to reduce the current Mana of target enemy by (X + X*LCK + X*DEX + X*WIS)%.',
          'COMBO: If a "Sneak" Discipline skill was used on this Hero\'s last Turn, this attack gains:\n' +
            'Deal physical damage to target enemy equal to (X*BASIC + X*DEX).',
          'Amnesia X, "Swindle".',
        ],
      },
      {
        id: 'thief-t7-3',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'AGI', 'DEX', 'AGI', 'LCK'],
        ailments: [
          'Reduce Physical Defense',
          'Reduce Magical Defense',
          'Reduce Physical Accuracy',
          'Sabotage',
        ],
        ailmentsDuration: 999,
        buffs: [
          'Evasion',
          'Physical Defense',
          'Magical Defense',
          'Physical Accuracy',
        ],
        buffsDuration: 999,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Single Target'],
        skillType: 'Debuff',
        damageType: 'Physical',
        discipline: 'Sneak',
        name: 'Steal',
        imageSrc: '',
        description: [
          'Channel for a Duration of X.',
          'During Channel, gain (X + X*DEX + X*AGI)% EVA.',
          "While Channeling, at the start of each of this Hero's Turn, roll to cause up to X of the below effects to occur.\n" +
            'If successful, these effects last for the remainder of Battle or until Interrupted.',
          "1:(X + X*AGI + X*DEX)% chance to reduce a random enemy's P.DEF by (X + X*LCK + X*DEX)%.\n" +
            "If successful, increase a random party member's P.DEF by the value reduced.",
          "2:(X + X*AGI + X*DEX)% chance to reduce a random enemy's M.DEF by (X + X*LCK + X*DEX)%.\n" +
            "If successful, increase a random party member's M.DEF by the value reduced.",
          "3:(X + X*AGI + X*DEX)% chance to reduce a random enemy's current P.ACC by (X + X*LCK + X*DEX)%.\n" +
            "If successful, increase a random party member's current P.ACC by the value reduced.",
          '4: X% chance to Sabotage.',
        ],
      },
      {
        id: 'thief-t7-4',
        tier: 7,
        skillPoints: 10,
        range: 'ALLIES',
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK'],
        ailments: [],
        ailmentsDuration: 0,
        buffs: ['Replicate Enemy Consumable Effect'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Savvy',
        name: 'Syphon',
        imageSrc: '',
        description: [
          'Each time the enemy team uses a consumable, gain a (X + X*LCK)% chance to gain the same effects on a random party member.',
        ],
      },
      {
        id: 'thief-t7-5',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK', 'DEX', 'WIS'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Critical Strike',
          'Evasion',
          'Pierce',
          'Less Physical Damage Received from Thief, Pirate, Bard, Ninja, and Warrior',
        ],
        buffsDuration: 0,
        debuffs: [
          'Less Physical Damage Dealt to Thief, Pirate, Bard, Ninja, and Warrior',
          'Amnesia Thick as Thieves',
        ],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Savvy',
        name: 'Thick as Thieves',
        imageSrc: '',
        description: [
          'Activate to gain CSC equal to (X + X*LCK + X*DEX + X*WIS)% for a Duration of X.',
          "This Hero's attacks gain X% Pierce for a Duration of X.",
          'All Thieves on the battlefield gain EVA equal to (X + X*LCK + X*DEX + X*WIS)% for a Duration of X.',
          'This Hero deals X% less physical damage to Heroes with Thief, Pirate, Bard, Ninja, and Warrior main classes for a Duration of X.',
          'This Hero receives X% less physical damage from Heroes with Thief, Pirate, Bard, Ninja, and Warrior main classes for a Duration of X.',
          'Amnesia X, "Thick as Thieves".',
          'LIMIT 1: Only one instance of the ability can be in effect at one time, and if any Thief uses this ability, it will override the previous results.',
        ],
      },
      {
        id: 'thief-t7-6',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Push', 'Pull'],
        scalingStats: [],
        ailments: ['Tripwire', 'Stun'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Amnesia Tripwire'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Utility',
        damageType: '-',
        discipline: 'Savvy',
        name: 'Tripwire',
        imageSrc: '',
        description: [
          'Place a Tripwire on target enemy.',
          'If target enemy is Pushed or Pulled, they are Stunned for a Duration of X.',
          'Can be Cleansed.',
          'Amnesia X, "Tripwire".',
        ],
      },
      {
        id: 'thief-t7-7',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Chance Skill Costs No Mana'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Thug',
        name: "Crime Doesn't Pay",
        imageSrc: '',
        description: [
          'Every action this Hero takes that requires Mana has a (X + X*LCK)% chance to cost (0) Mana.',
        ],
      },
      {
        id: 'thief-t7-8',
        tier: 7,
        skillPoints: 10,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Barrier'],
        scalingStats: ['STR', 'DEX', 'AGI'],
        ailments: [
          'Sabotage',
          'Reduce Strength',
          'Reduce Agility',
          'Destroy Barrier',
        ],
        ailmentsDuration: null,
        buffs: ['Strength', 'Agility', 'Barrier'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Thug',
        name: 'Rob From the Rich',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*BASIC + X*STR + X*DEX).',
          'X% chance to Sabotage.',
          "(X + X*AGI)% chance to reduce target enemy's STR by (X + X*AGI)% for a Duration of X.",
          'If successful, gain STR equal to the amount reduced for a Duration of X.',
          "(X + X*STR)% chance to reduce target enemy's AGI by (X + X*STR)% for a Duration of X.",
          'If successful, gain AGI equal to the amount reduced for a Duration of X.',
          'If target enemy is protected by a Physical, Magical, or Pure Barrier, gain:\n' +
            "(X + X*AGI)% chance to destroy target enemy's Barrier.",
          'If successful, deploy a Barrier of the same type and HP value to random party member.',
        ],
      },
      {
        id: 'thief-t7-9',
        tier: 7,
        skillPoints: 10,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['AGI'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Evasion', 'Endurance', 'Strength', 'Agility', 'Lifesteal'],
        buffsDuration: null,
        debuffs: ['Decreased Accuracy', 'Amnesia Give to the Poor'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Thug',
        name: 'Give to the Poor',
        imageSrc: '',
        description: [
          'Activate to effect the party member with the least HP for a Duration of X.',
          'Target party member gains EVA equal to (X + X*AGI)%.',
          'Target ally:\n' +
            'If below 75% HP: gain X% effective END.\n' +
            'If below 50% HP: gain X% effective STR.\n' +
            'If below 25% HP: gain X% effective AGI.\n' +
            'If below 10% HP: gain X% Lifesteal, chance to hit decreased by X%.',
          'Amnesia X, "Give to the Poor".',
        ],
      },
    ],
    tier8: [
      {
        id: 'thief-t8-1',
        tier: 8,
        skillPoints: 15,
        range: 3,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK', 'DEX', 'AGI'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Untargetable'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Sneak',
        name: 'Ghost',
        imageSrc: '',
        description: [
          'If this Hero dealt damage to an enemy this Turn, gain (X + X*LCK + X*DEX + X*AGI)% chance to become Untargetable for a Duration of X.',
        ],
      },
    ],
  },
};
