export default {
  selectClass(context, data) {
    context.commit('selectClass', data.heroClass);
  },
  selectSubclass(context, data) {
    context.commit('selectSubclass', data.heroSubclass);
  },
  addSelectedSkill(context, data) {
    context.commit('addSelectedSkill', data);
  },
  removeSelectedSkill(context, data) {
    context.commit('removeSelectedSkill', data);
  },
  resetSelectedSkills(context) {
    context.commit('resetSelectedSkills');
  },
  resetHeroBuild(context) {
    context.commit('selectClass', null);
    context.commit('selectSubclass', null);
    context.commit('resetSelectedSkills');
  },
};
