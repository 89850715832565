<template>
  <v-container>
    <header>
      <v-row class="d-flex align-end flex-md-row">
        <v-col cols="12" md="5">
          <div class="text-center text-md-right">
            <h2>
              <v-icon size="x-small" icon="mdi-sword-cross"></v-icon>
              DFK Combat
            </h2>
          </div>
        </v-col>
        <v-col cols="12" md="2">
          <div class="d-flex justify-center">
            <v-img
              src="https://game.defikingdoms.com/static/media/dfk_landing_logo.f1e4bc754da0632af892.png"
              :width="300"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="12" md="5">
          <div class="text-center text-md-left">
            <h6>partner and third party site</h6>
          </div>
        </v-col>
      </v-row>
      <h6 class="created-by">
        Created by iknowkungfu, a DeFi Kingdoms community member
      </h6>
    </header>
    <p class="welcome">Hello and welcome to the DFK Combat web application!</p>
    <v-row>
      <v-col cols="12" md="7">
        <v-container>
          <p>
            This application is a hobby project, that provides a free service to
            the <strong>DeFi Kingdoms</strong> community. The main goal is to
            enable players to build and share combat strategies and access all
            information relevant to combat in one convenient location.
          </p>
          <p>
            You are new to DFK Combat? Check out the
            <router-link to="getting-started/how-to/overview"
              >How to Use This Tool
            </router-link>
            at the <strong>Getting Started</strong> section.
          </p>
          <p>
            If you have ideas or suggestions about DFK Combat, you can contact
            me at
            <a
              href="https://twitter.com/iknowkungfu2023"
              target="_blank"
              rel="noopener"
              >Twitter/X
            </a>
            or at the
            <a
              href="https://discord.gg/defikingdoms"
              target="_blank"
              rel="noopener"
              >DeFi Kingdoms's Discord
            </a>
            (iknowkungfu#8599).
          </p>
          <p>
            You are not familiar with DeFi Kingdoms? In a nutshell, it is a
            blockchain game with a decentralized finance (DeFi) aspect. You can
            check out the following resources to get started:
          </p>
          <div class="beginner-resources">
            <div v-for="resource in getStartedResources" :key="resource.title">
              <a :href="resource.url" target="_blank" rel="noopener">{{
                resource.title
              }}</a>
              <p>{{ resource.description }}</p>
            </div>
          </div>
        </v-container>
      </v-col>
      <v-col cols="12" md="5">
        <h3>Latest Release Notes:</h3>
        <release-notes
          class="release-notes-container"
          :releaseNotesList="lastReleaseNotes"
        ></release-notes>
        <v-container class="d-flex justify-center">
          <v-btn to="/release-notes" variant="plain" color="primary"
            >View All Release Notes
          </v-btn>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import resources from '../data/get-started/resources.js';
import dfkCombatNews from '../data/releaseNotes.js';
import ReleaseNotes from '../components/shared/ReleaseNotes.vue';

export default {
  components: {
    ReleaseNotes,
  },
  data() {
    return {
      getStartedResources: resources,
    };
  },
  computed: {
    lastReleaseNotes() {
      return dfkCombatNews.slice(0, 2);
    },
  },
};
</script>

<style scoped>
header {
  margin-bottom: 20px;
  text-align: center;
}

h2 {
  font-size: 3rem;
  line-height: 100%;
  margin: 0;
}

h3 {
  text-align: center;
}

h6 {
  font-size: 1rem;
}

p {
  margin-bottom: 10px;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.welcome {
  font-size: 2rem;
  text-align: center;
}

.v-col {
  padding: 5px;
}

.beginner-resources {
  margin-top: 20px;
}

.release-notes-container {
  max-height: calc(
    100vh - 500px
  ); /* 500px is the total height of your header and footer */
  overflow-y: auto;
}

.created-by {
  padding-top: 10px;
}
</style>
