export default {
  className: 'Pirate',
  disciplines: [
    {
      name: 'Busster',
      description:
        "The Busster path focuses on dealing punishing ranged attacks with a fearsome blunderbuss pistol. No small amount of dexterity is required to master this path, which allows the Pirate to take out enemies from a distance with their bombastic dragon. Whether it's a strafing warning shot that puts an opponent in its place, a deafening wide blast that deals physical damage to multiple enemies, or an overwhelming barrier-busting explosion, the Pirate is no joke from behind a scope.",
    },
    {
      name: 'The Pirate Code',
      description:
        "The Pirate Code path reflects the defining elements of what it means to be a swashbuckling rogue that lives outside the inconvenient confines of the law. An unsheathed cutlass and a major measure of strength are required in such a hostile environment of up close and personal diplomatic discourse. An unlucky Jenkins could find themselves quickly on the backfoot from a swift slash that, unless properly treated, could be a matter of life or death. And if that's not enough to do the job, there's always a nice walk off the plank to meet one's maker in the murky depths below.",
    },
    {
      name: 'Luck of the Sea',
      description:
        'The Luck of the Sea path is about trusting in the heart of the ocean and relying on the mysteries that piracy has unearthed over generations of gamblers and ruffians. Wit, good fortune, and of course a giant kraken are always reliable resources to turn the tide of battle. But what boons the sea provides can also be ripped away. In turn, this path is by far the most perilous and fool-hardy of all paths, but it also conceals the greatest rewards for those with enough courage to embrace the dark depths and claim its bounty.',
    },
  ],
  statGrowth: {
    STR: 70,
    DEX: 70,
    AGI: 50,
    VIT: 60,
    END: 55,
    INT: 20,
    WIS: 20,
    LCK: 55,
  },
  healthGrowth: {
    Small: 15,
    Medium: 45,
    Large: 40,
  },
  manaGrowth: {
    Small: 45,
    Medium: 40,
    Large: 15,
  },
  basicAttackStats: [],
  accuracyStats: [],
  defenseStats: [],
  criticalStrikeStats: [],
  skills: {
    tier1: [
      {
        id: 'pirate-t1-1',
        tier: 1,
        skillPoints: 1,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Dexterity'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Busster',
        name: 'Full Metal',
        imageSrc: '',
        description: [
          'Roll a D20 at the start of a Battle and re-roll every X Durations.',
          "Increase this Hero's effective DEX by X% in combat.",
          'X = D20 roll value.',
        ],
      },
      {
        id: 'pirate-t1-2',
        tier: 1,
        skillPoints: 1,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Strength'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'The Pirate Code',
        name: 'The Pirate Code',
        imageSrc: '',
        description: [
          'Roll a D20 at the start of a Battle and re-roll every X Durations.',
          "Increase this Hero's effective STR by X% in combat.",
          'X = D20 roll value.',
        ],
      },
      {
        id: 'pirate-t1-3',
        tier: 1,
        skillPoints: 1,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Luck'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Luck of the Sea',
        name: 'Luck of the Sea',
        imageSrc: '',
        description: [
          'Roll a D20 at the start of a Battle and re-roll every X Durations.',
          "Increase this Hero's effective LCK by X% in combat.','X = D20 roll value.",
        ],
      },
    ],
    tier2: [
      {
        id: 'pirate-t2-1',
        tier: 2,
        skillPoints: 2,
        range: 2,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'pirate-t3-1',
          'pirate-t4-1',
          'pirate-t5-1',
          'pirate-t6-1',
          'pirate-t6-2',
          'pirate-t7-1',
          'pirate-t7-2',
          'pirate-t7-3',
        ],
        comboEffects: [],
        scalingStats: ['DEX'],
        ailments: ['Daze'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Busster',
        name: 'Warning Shot',
        imageSrc: '',
        description: [
          'Fire a single gun shot at a target enemy in either P1 or P2.',
          'Deal physical damage equal to (X*BASIC + X*DEX).',
          'COMBO: If this Hero cast a "Busster" ability last turn, gain:\n' +
            'X% + (X*DEX) chance to Daze for a Duration of X.',
        ],
      },
      {
        id: 'pirate-t2-2',
        tier: 2,
        skillPoints: 2,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'LCK'],
        ailments: ['Bleed'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'The Pirate Code',
        name: 'Unlucky Jenkins',
        imageSrc: '',
        description: [
          'Unsheath a cutlass and roll a D20.',
          'Slash target enemy dealing physical damage equal to X (X = ((X*D20)*BASIC) + X*STR + X*LCK).',
          'X% Pierce.',
          'COMBO: If this Hero cast a "Pirate Code" ability last turn, gain:\n' +
            'X% + (X*STR) chance to inflict X% Bleed for a Duration X.',
        ],
      },
      {
        id: 'pirate-t2-3',
        tier: 2,
        skillPoints: 2,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'pirate-t3-3',
          'pirate-t4-3',
          'pirate-t5-3',
          'pirate-t6-5',
          'pirate-t6-6',
          'pirate-t7-8',
          'pirate-t7-9',
        ],
        comboEffects: [],
        scalingStats: ['LCK', 'AGI'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Agility', 'Damage'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Luck of the Sea',
        name: 'Full Sail',
        imageSrc: '',
        description: [
          'Flip an coin from an ancient forbidden treasure horde.',
          "Heads:\nRally crew morale, increasing each ally's effective AGI by X (X = X*LCK) for a Duration of X.",
          "Tails:\nRally crew morale, increasing each ally's damage dealt by X% for a Duration of X.",
          'COMBO: If this Hero cast a "Luck of the Sea" ability last turn, gain:\n' +
            'X% + (X*LCK) chance to increase the Duration of the effects from X to X.',
        ],
      },
    ],
    tier3: [
      {
        id: 'pirate-t3-1',
        tier: 3,
        skillPoints: 3,
        range: 2,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'LCK'],
        ailments: ['Destroy Barrier', 'Daze'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Busster',
        name: 'Blunderbusster',
        imageSrc: '',
        description: [
          'Fire a blunderbuss blast.',
          'X% + (X*DEX) chance to destroy target Barriers on targets in P1 and P2.',
          'Deal physical damage to enemies in P1 and P2 equal to (X*Basic + X*LCK) each.',
          'X% + (X*DEX) chance to Daze target enemy for a Duration of X.',
        ],
      },
      {
        id: 'pirate-t3-2',
        tier: 3,
        skillPoints: 3,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['pirate-t2-2', 'pirate-t6-3'],
        comboEffects: [],
        scalingStats: ['STR', 'STR'],
        ailments: ['Silence', 'Bleed'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'The Pirate Code',
        name: 'Disabling Blow',
        imageSrc: '',
        description: [
          'Slash target enemy with a cutlass.',
          'Deal physical damage equal to (X*BASIC + X*STR).',
          'X% + (X*STR) chance to Silence target for a Duration of X.',
          'COMBO: If "Unlucky Jenkins" was used on this Hero\'s last turn, gain:\n' +
            'X% + (X*STR) chance to inflict X% Bleed for a Duration X.',
        ],
      },
      {
        id: 'pirate-t3-3',
        tier: 3,
        skillPoints: 3,
        range: 3,
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Evasion'],
        buffsDuration: 0,
        debuffs: ['Channel'],
        debuffsDuration: null,
        channelDuration: 'X Rounds',
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Luck of the Sea',
        name: 'Starboard Cannons',
        imageSrc: '',
        description: [
          'Ready the cannons and Channel for a Duration of X.',
          'During Channel, gain X% EVA.',
          'Command the ship to fire all starboard cannons.',
          'Deal physical damage to each enemy equal to (X*Basic + X*LCK).',
        ],
      },
    ],
    tier4: [
      {
        id: 'pirate-t4-1',
        tier: 4,
        skillPoints: 4,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['pirate-t2-1'],
        comboEffects: [],
        scalingStats: ['DEX', 'DEX'],
        ailments: ['Slow', 'Stun'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Busster',
        name: 'Cheapshot',
        imageSrc: '',
        description: [
          'Shoot target enemy in the leg.',
          'Deal physical damage equal to (X*BASIC + X*DEX).',
          'X% + (X*DEX) chance to Slow target enemy by X% for a Duration of X.',
          'COMBO: If "Warning Shot" was used on this Hero\'s last Turn, gain:\n' +
            'X% + (X*DEX) chance to Stun target enemy for a Duration of X.',
        ],
      },
      {
        id: 'pirate-t4-2',
        tier: 4,
        skillPoints: 4,
        range: 1,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['First Strike'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'The Pirate Code',
        name: 'All Hands On Deck',
        imageSrc: '',
        description: [
          'This Hero and each ally consecutively perform a Basic Attack dealing physical damage to target enemy in P1.',
          "This Hero gains First Strike on this Hero's next Turn.",
        ],
      },
      {
        id: 'pirate-t4-3',
        tier: 4,
        skillPoints: 4,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['pirate-t3-3', 'pirate-t6-5'],
        comboEffects: [],
        scalingStats: ['LCK', 'LCK'],
        ailments: ['Fear', 'Slow'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Delay'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Luck of the Sea',
        name: 'Curse of the Sea',
        imageSrc: '',
        description: [
          'Call the Kraken.',
          "Delay X (X = D6 Roll).','The Kraken's tentacles coil around the enemy's board.",
          'Deal physical damage to each enemy equal to (X*Basic + X*LCK).',
          'X% + (X*LCK) chance to Fear the enemy with the lowest current HP value.',
          'COMBO: If "Starboard Cannons" was used on this Hero\'s last turn, gain:\n' +
            'X% + (X*LCK) chance to Slow each enemy by X% for a Duration of X.',
        ],
      },
    ],
    tier5: [
      {
        id: 'pirate-t5-1',
        tier: 5,
        skillPoints: 5,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Evasion', 'Redirect Attacks'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Busster',
        name: 'First Mate',
        imageSrc: '',
        description: [
          'Target ally gains X% EVA (X = X + X*DEX + X*LCK) for a Duration of X.',
          'The next X attacks that target this Hero are redirected to that Ally.',
          'X = D6 divided by X, rounded down.',
        ],
      },
      {
        id: 'pirate-t5-2',
        tier: 5,
        skillPoints: 5,
        range: 1,
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'DEX'],
        ailments: ['Execute'],
        ailmentsDuration: null,
        buffs: ['First Strike'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Execute',
        damageType: 'Physical',
        discipline: 'The Pirate Code',
        name: 'Walk the Plank',
        imageSrc: '',
        description: [
          'Deal physical damage to target Hero equal to (X*Basic + X*STR + X*DEX).',
          'If a target is below X% HP, execute target.',
          "If target is executed, gain First Strike on this Hero's next Turn.",
        ],
      },
      {
        id: 'pirate-t5-3',
        tier: 5,
        skillPoints: 5,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK', 'WIS'],
        ailments: ['Receive Additional Damage'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Receive Additional Damage'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Luck of the Sea',
        name: 'Parley',
        imageSrc: '',
        description: [
          'This Hero and target enemy roll a D20.',
          'Whichever Hero rolls a lower sum value (X*D20 + X*LCK) receives X% additional damage for a Duration of X.',
          'Deal physical damage to target enemy equal to (X*BASIC + X*WIS + X*LCK).',
        ],
      },
    ],
    tier6: [
      {
        id: 'pirate-t6-1',
        tier: 6,
        skillPoints: 7,
        range: 2,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'pirate-t3-1',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'LCK'],
        ailments: ['Destroy Barrier', 'Stun'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Busster',
        name: 'Blunderbusster +',
        imageSrc: '',
        description: [
          'Fire a blunderbuss blast.',
          'X% + (X*DEX) chance to destroy target Barriers on targets in P1 and P2.',
          'Deal physical damage to enemies in P1 and P2 equal to (X*Basic + X*LCK) each.',
          'X% + (X*DEX) chance to Stun target enemy for a Duration of X.',
        ],
      },
      {
        id: 'pirate-t6-2',
        tier: 6,
        skillPoints: 6,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'pirate-t4-1',
        comboSkills: ['pirate-t2-1'],
        comboEffects: [],
        scalingStats: ['DEX', 'DEX'],
        ailments: ['Slow', 'Stun'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Busster',
        name: 'Cheapshot +',
        imageSrc: '',
        description: [
          'Shoot target enemy in the leg.',
          'Deal physical damage equal to (X*BASIC + X*DEX).',
          'X% + (X*DEX) chance to Slow target enemy by X% for a Duration of X.',
          'COMBO: If "Warning Shot" was used on this Hero\'s last Turn, gain:\n' +
            'X% + (X*DEX) chance to Stun target enemy for a Duration of X.',
        ],
      },
      {
        id: 'pirate-t6-3',
        tier: 6,
        skillPoints: 7,
        range: 1,
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'pirate-t2-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'LCK'],
        ailments: ['Bleed'],
        ailmentsDuration: 0,
        buffs: ['Pierce'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: [],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'The Pirate Code',
        name: 'Unlucky Jenkins +',
        imageSrc: '',
        description: [
          'Unsheath a cutlass and roll a D20.',
          'Slash target enemy dealing physical damage equal to X (X = ((X*D20)*BASIC) + X*STR + X*LCK).',
          'X% Pierce.',
          'COMBO: If this Hero cast a "Pirate Code" ability last turn, gain:\n' +
            'X% + (X*STR) chance to inflict X% Bleed for a Duration of X.',
        ],
      },
      {
        id: 'pirate-t6-4',
        tier: 6,
        skillPoints: 7,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'pirate-t3-2',
        comboSkills: ['pirate-t2-2', 'pirate-t6-3'],
        comboEffects: [],
        scalingStats: ['STR', 'STR'],
        ailments: ['Destroy Barrier', 'Silence', 'Bleed'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'The Pirate Code',
        name: 'Disabling Blow +',
        imageSrc: '',
        description: [
          'Slash target enemy with a cutlass.',
          "X% + (X*STR) chance to destroy target's Barrier.",
          'Deal physical damage equal to (X*BASIC + X*STR).',
          'X% + (X*STR) chance to Silence target for a Duration of X.',
          'COMBO: If "Unlucky Jenkins" was used on this Hero\'s last turn, gain:\n' +
            'X% + (X*STR) chance to inflict X% Bleed for a Duration of X.',
        ],
      },
      {
        id: 'pirate-t6-5',
        tier: 6,
        skillPoints: 7,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'pirate-t3-3',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK'],
        ailments: ['Stun'],
        ailmentsDuration: null,
        buffs: ['Evasion', 'First Strike'],
        buffsDuration: 0,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Luck of the Sea',
        name: 'Starboard Cannons +',
        imageSrc: '',
        description: [
          'Ready the cannons and Channel for a Duration of X.',
          'During Channel, gain X% EVA.',
          'Command the ship to fire all starboard cannons.',
          'Deal physical damage to each enemy equal to (X*Basic + X*LCK).',
          'X% + (X*LCK) chance to Stun 1 random enemy.',
          "This Hero gains First Strike on this Hero's next Turn.",
        ],
      },
      {
        id: 'pirate-t6-6',
        tier: 6,
        skillPoints: 6,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'pirate-t4-3',
        comboSkills: ['pirate-t3-3', 'pirate-t6-5'],
        comboEffects: [],
        scalingStats: ['LCK', 'LCK'],
        ailments: ['Slow', 'Fear', 'Silence'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Delay'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Luck of the Sea',
        name: 'Curse of the Sea +',
        imageSrc: '',
        description: [
          'Call the Kraken.',
          'Delay X (X = D6 Roll).',
          "The Kraken coils its tentacles around the enemy's entire board.",
          'Deal physical damage to each enemy equal to (X*Basic + X*LCK).',
          'X% + (X*LCK) chance to Fear the enemy with the least current HP for a Duration of X.',
          'X% + (X*LCK) chance to Silence the enemy with the greatest current HP for a Duration of X.',
          'COMBO: If "Starboard Cannons" was used on this Hero\'s last turn, gain:\n' +
            'X% + (X*LCK) chance to Slow each enemy by X% for a Duration of X.',
          'Repeat X (X = D20 Roll, 1-19 = 0, 20 = 1).',
        ],
      },
    ],
    tier7: [
      {
        id: 'pirate-t7-1',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['pirate-t7-6'],
        comboEffects: [],
        scalingStats: ['DEX', 'DEX'],
        ailments: ['Fear', 'Silence', 'Banish'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Busster',
        name: 'Into The Drink',
        imageSrc: '',
        description: [
          'Fire a blunderbuss blast dealing physical damage equal to (X*BASIC + X*DEX) to all enemies.',
          'X% + (X*DEX) chance to Fear target in P1 for a Duration of X.',
          'X% + (X*DEX) chance to Silence target in P2 for a Duration of X.',
          'X% + (X*DEX) chance to Banish target in P3 for a Duration of X.',
          'COMBO: If "The Captain\'s Hook" was used last turn, increase initial integer chance to Fear, Silence, and Banish by X%.',
        ],
      },
      {
        id: 'pirate-t7-2',
        tier: 7,
        skillPoints: 10,
        range: 1,
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Critical Strike'],
        buffsDuration: null,
        debuffs: ['Exhaust', 'Cannot Cast Buster Ability for X Rounds'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Busster',
        name: 'One in the Chamber',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*BASIC + X*DEX).',
          'This attack has an increased Crit Chance of X% (X = X + X*DEX).',
          'Become Exhausted.',
          'Amnesia: Busster. This Hero cannot cast a "Busster" ability for a Duration of X.',
        ],
      },
      {
        id: 'pirate-t7-3',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'DEX'],
        ailments: ['Destroy Barrier', 'Slow', 'Stun'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Busster',
        name: 'Death from Above',
        imageSrc: '',
        description: [
          'Fire every blunderbuss shot in the barrel into the air and rain shrapnel upon the enemy team.',
          'Deal physical damage equal to (X*Basic + X*DEX) to each enemy.',
          'X% + (X*DEX) chance to destroy all hit enemy Barriers.',
          'X% + (X*DEX) chance to Slow all hit enemies by X% for a Duration of X.',
          'X% + (X*DEX) chance to Stun all hit enemies.',
        ],
      },
      {
        id: 'pirate-t7-4',
        tier: 7,
        skillPoints: 10,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['pirate-t3-3', 'pirate-t6-5'],
        comboEffects: [],
        scalingStats: ['VIT', 'WIS', 'INT', 'END', 'END', 'END', 'END', 'END'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Physical Barrier',
          'Resistance to Push',
          'Resistance to Pull',
          'Resistance to Fear',
          'Resistance to Silence',
          'Resistance to Stun',
          'Block',
        ],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Barrier',
        damageType: '-',
        discipline: 'The Pirate Code',
        name: 'Batten Down The Hatches',
        imageSrc: '',
        description: [
          'Brace for the oncoming storm. Tie a rope around the wastes of this Hero and all allies.',
          "All allies gain a Physical Barrier equal to X% of target's Max HP (X = X + X*WIS + X*INT) for a Duration of X.",
          'Allies gain X% (X = X% + X*END) resistance to the below STA for a Duration of X:',
          'Push',
          'Pull',
          'Fear',
          'Silence',
          'Stun',
          'COMBO: If "Starboard Cannons" was cast this Battle, entire party gains X% BLK for a Duration of X.',
        ],
      },
      {
        id: 'pirate-t7-5',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['VIT'],
        ailments: [
          'Block Decrease',
          'Physical Defense Decrease',
          'Accuracy Decrease',
          'Dispel',
        ],
        ailmentsDuration: 0,
        buffs: [
          'Evasion',
          'Evasion',
          'Block',
          'Physical Defense',
          'Physical Barrier',
        ],
        buffsDuration: 0,
        debuffs: [
          'Evasion',
          'Enemy Evasion Increase',
          'Enemy Gains Physical Barrier',
        ],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Team'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'The Pirate Code',
        name: 'A Share of the Treasure',
        imageSrc: '',
        description: [
          'A cursed treasure has been found by the crew.',
          'The loot is to be divided "evenly" amongst the crew for a Duration of X.',
          'All party members and all enemies roll a totally-not-weighted D6 and receive the blessing or curse that lies within.',
          'Allies:\n' +
            '1 = -X% effective EVA decrease.\n' +
            '2 = +X% effective EVA increase.\n' +
            '3 = +X% effective BLK increase.\n' +
            '4 = +X% effective P.DEF increase.\n' +
            '5 = Gain Physical Barrier equal to X% of Max HP.\n' +
            '6 = -X% effective EVA decrease.',
          'Enemies:\n' +
            '1 = +X% effective EVA increase.\n' +
            '2 = -X% effective BLK decrease.\n' +
            '3 = -X% effective P.DEF decrease.\n' +
            '4 = -X% effective ACC decrease.\n' +
            '5 = Dispel Hero.\n' +
            '6 = Gain Physical Barrier equal to X% of Max HP.',
          'This Hero:\n' +
            '1 = -X% effective EVA decrease.\n' +
            '2 = +X% effective EVA increase.\n' +
            '3 = +X% effective EVA increase.\n' +
            '4 = +X% effective EVA increase.\n' +
            '5 = +X% effective EVA increase.\n' +
            '6 = +X% effective EVA increase.\n' +
            'LIMIT 1: Only one instance of the ability can be in effect at one time, and if any pirate uses this ability, it will override the previous results.',
        ],
      },
      {
        id: 'pirate-t7-6',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'STR', 'DEX'],
        ailments: ['Pull', 'Daze'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'The Pirate Code',
        name: "The Captain's Hook",
        imageSrc: '',
        description: [
          'Deal damage to target enemy equal to (X*BASIC + X*STR).',
          'X% + (X*STR + X*DEX) chance to Pull target X.',
          'X% + (X*STR + X*DEX) chance to Daze target for a Duration of X.',
        ],
      },
      {
        id: 'pirate-t7-7',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['LCK'],
        ailments: ['Fear', 'Banish'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'The Pirate Code',
        name: "Davy Jones's Locker",
        imageSrc: '',
        description: [
          'Target enemy rolls a D20.',
          'If target rolls 11 or less, deal damage to target enemy equal to (X*BASIC + X*LCK).',
          'Inflict Fear for a Duration of X.',
          'If target rolls 12 or more, deal damage to target enemy equal to (X*BASIC + X*LCK).',
          'Inflict Banish for a Duration of X.',
        ],
      },
      {
        id: 'pirate-t7-8',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['pirate-t2-3'],
        comboEffects: [],
        scalingStats: ['LCK', 'LCK'],
        ailments: ['Slow', 'Daze', 'Confuse'],
        ailmentsDuration: null,
        buffs: ['Repeat'],
        buffsDuration: null,
        debuffs: ['Delay'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Luck of the Sea',
        name: 'Kraken Strike',
        imageSrc: '',
        description: [
          'Call the Kraken.',
          'Delay X (X = D6 Roll).',
          'The Kraken slams a tentacle onto the Battlefield.',
          'Deal damage to each enemy equal to (X*Basic + X*LCK).',
          'X% + (X*LCK) chance to Slow all enemies by X% for a Duration of X.',
          'X% + (X*LCK) chance to Daze all enemies for a Duration of X.',
          'X% + (X*LCK) chance to Confuse all enemies for a Duration of X.',
          'COMBO: If "Full Sail" is active, increase Duration by X, to X total.\n' +
            'Repeat X (X = D20 Roll, 1-19 = 0, 20 = 1).',
        ],
      },
      {
        id: 'pirate-t7-9',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['pirate-t7-7'],
        comboEffects: [],
        scalingStats: ['DEX', 'STR', 'LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Repeat'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Luck of the Sea',
        name: 'The Call of the Deep',
        imageSrc: '',
        description: [
          'Call the Kraken.',
          'Delay X (X = D6 Roll).',
          'A single tentacle grabs target Hero and begins to crush the Hero.',
          'Deal damage equal to (X*BASIC + X*DEX + X*STR + X*LCK) each individual Turn to target Hero for a Duration of X.',
          'Kraken will release target if Cleansed, Repositioned, or made Unstoppable.',
          'COMBO: If "Davy Jones\'s Locker" was cast this battle, Kraken can only be released by Repositioning.\n' +
            'Repeat X (X = D20 Roll, 1-19 = 0, 20 = 1).',
        ],
      },
    ],
    tier8: [
      {
        id: 'pirate-t8-1',
        tier: 8,
        skillPoints: 15,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'DEX', 'LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Increase Attack Range',
          'Add +X to all D20 Rolls',
          'Strength',
          'Dexterity',
          'Luck',
          'Evasion',
          'Block',
          'Spell Block',
        ],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Luck of the Sea',
        name: 'The Pirate Code',
        imageSrc: '',
        description: [
          'Gain an array of effects:',
          'Increase attack Range by X.',
          'Add +X to all D20 rolls.',
          'X% effective STR',
          'X% effective DEX',
          'X% effective LCK',
          'X% EVA',
          'X% Block',
          'X% Spell Block',
        ],
      },
    ],
  },
};
