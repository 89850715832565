<template>
  <div>
    <div v-if="isSkillSelected">
      <v-row no-gutters>
        <v-col :cols="smallAnalyticColumnSize">
          <v-sheet>
            <scaling-stats></scaling-stats>
          </v-sheet>
        </v-col>
        <v-col :cols="smallAnalyticColumnSize">
          <v-sheet>
            <skill-types :skillTypes="skillTypes"></skill-types>
          </v-sheet>
        </v-col>
        <v-col :cols="smallAnalyticColumnSize">
          <v-sheet>
            <active-passive
              :skillsActivePassive="skillsActivePassive"
            ></active-passive>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col :cols="smallAnalyticColumnSize">
          <v-sheet>
            <attack-range :skillsAttackRange="skillsAttackRange"></attack-range>
          </v-sheet>
        </v-col>
        <v-col :cols="smallAnalyticColumnSize">
          <v-sheet>
            <attack-area :skillsAttackArea="skillsAttackArea"></attack-area>
          </v-sheet>
        </v-col>
        <v-col :cols="smallAnalyticColumnSize">
          <v-sheet>
            <attack-damage-type
              :skillsDamageType="skillsDamageType"
            ></attack-damage-type>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col v-if="!isMobile" cols="2"></v-col>
        <v-col :cols="smallAnalyticColumnSize">
          <v-sheet>
            <buff-skills :skillBuffs="skillBuffs"></buff-skills>
          </v-sheet>
        </v-col>
        <v-col :cols="smallAnalyticColumnSize">
          <v-sheet>
            <debuff-skills :skillDebuffs="skillDebuffs"></debuff-skills>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col :cols="largeAnalyticColumnSize">
          <v-sheet class="table-analytics">
            <combo-skills :selectedSkills="selectedSkills"></combo-skills>
          </v-sheet>
        </v-col>
        <v-col :cols="largeAnalyticColumnSize">
          <v-sheet class="table-analytics">
            <combo-effects
              :skillsWithComboEffects="skillsWithComboEffects"
              :buffsAndAilments="buffsAndAilments"
            >
            </combo-effects>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
    <v-container v-else
      >There are no skills selected. Please select at least one skill in order
      to view analytics!</v-container
    >
  </div>
</template>

<script>
import ScalingStats from './analytics/ScalingStats.vue';
import SkillTypes from '../../shared/analytics/SkillTypes.vue';
import ActivePassive from '../../shared/analytics/ActivePassive.vue';
import BuffSkills from '../../shared/analytics/BuffSkills.vue';
import DebuffSkills from '../../shared/analytics/DebuffSkills.vue';
import AttackArea from '../../shared/analytics/AttackArea.vue';
import AttackRange from '../../shared/analytics/AttackRange.vue';
import AttackDamageType from '../../shared/analytics/AttackDamageType.vue';
import ComboSkills from '../../shared/analytics/ComboSkills.vue';
import ComboEffects from '../../shared/analytics/ComboEffects.vue';
import resolutionHelper from '../../../utils/resolutionHelper';

export default {
  components: {
    ScalingStats,
    BuffSkills,
    ActivePassive,
    DebuffSkills,
    SkillTypes,
    AttackArea,
    AttackRange,
    AttackDamageType,
    ComboSkills,
    ComboEffects,
  },
  computed: {
    isMobile() {
      return resolutionHelper.isMobile();
    },
    selectedSkills() {
      return this.$store.getters['heroBuild/selectedSkillObjects'];
    },
    isSkillSelected() {
      return this.selectedSkills.length !== 0;
    },
    skillsActivePassive() {
      return this.$store.getters['heroBuild/skillsActivePassive'];
    },
    skillsAttackArea() {
      return this.$store.getters['heroBuild/skillsAttackArea'];
    },
    skillsDamageType() {
      return this.$store.getters['heroBuild/skillAttackDamageType'];
    },
    skillsAttackRange() {
      return this.$store.getters['heroBuild/skillsAttackRange'];
    },
    skillBuffs() {
      return this.$store.getters['heroBuild/skillBuffs'];
    },
    skillDebuffs() {
      return this.$store.getters['heroBuild/skillDebuffs'];
    },
    skillTypes() {
      return this.$store.getters['heroBuild/skillTypes'];
    },
    skillsWithComboEffects() {
      return this.$store.getters['heroBuild/skillsWithComboEffects'];
    },
    buffsAndAilments() {
      return this.$store.getters['heroBuild/buffsAndAilments'];
    },
    smallAnalyticColumnSize() {
      return this.isMobile ? 6 : 4;
    },
    largeAnalyticColumnSize() {
      return this.isMobile ? 12 : 6;
    },
  },
};
</script>

<style scoped>
.v-sheet {
  margin: 10px 10px 10px 0;
}

.table-analytics {
  padding-bottom: 10px;
}
</style>
