<template>
  <div>
    <under-construction
      pageHeader="The Community Page is under construction"
    ></under-construction>
  </div>
</template>

<script>
import UnderConstruction from '../components/ui/UnderConstruction.vue';

export default {
  components: {
    UnderConstruction,
  },
};
</script>
