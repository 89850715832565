<template>
  <div>
    <v-container>
      <h1 class="d-flex justify-center">Getting Started</h1>
      <v-container>
        <v-divider></v-divider>
      </v-container>
      <v-container>
        This part of the application contains a Table of Contents, where you can
        find information about this tool and various other sections of combat
        related data and tools.
      </v-container>
      <v-container>
        Check out the navigation section on the right with the Table of
        Contents.
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
  font-size: 2.2rem;
}
</style>
