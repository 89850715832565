<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col cols="11">
          {{ `Attack Damage Type (${damageTypeCount})` }}
        </v-col>
        <v-col cols="1">
          <tooltip-icon class="information-tooltip" :text="tooltipText">
          </tooltip-icon>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="damageTypeCount !== 0">
      <Doughnut
        :data="attackDamageTypeChartData"
        :options="attackDamageTypeChartOptions"
      />
    </div>
    <div v-else>There are no attack skills selected</div>
  </div>
</template>

<script>
import TooltipIcon from '../common/TooltipIcon.vue';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'vue-chartjs';

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  components: {
    TooltipIcon,
    Doughnut,
  },
  props: {
    skillsDamageType: Object,
  },
  data() {
    return {
      tooltipText:
        'The chart shows the mix between physical and magical damage done by the selected skills.\n\nTips:\n- do not forget that this chart is about skills and you need to take into account other damage sources and their damage type to get a more complete picture e.g. Basic Attacks, Active Traits, etc.\n- depending on what you are up against, you may want to emphasize physical over magical damage and vice versa\n- if you are looking to "nuke" squishy casters, probably Physical Damage and high Attack Range will do the trick\n- Physical Attack damage skill from a subclass codex, may be abused by a caster class with high mana pool, because of potential lower mana cost',
    };
  },
  computed: {
    damageTypeCount() {
      let count = 0;

      Object.values(this.skillsDamageType).forEach((c) => (count += c));

      return count;
    },
    attackDamageTypeChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
      };
    },
    attackDamageTypeChartData() {
      return {
        labels: Object.keys(this.skillsDamageType),
        datasets: [
          {
            backgroundColor: ['#cc0000', '#0099cc', '#ffffff'],
            data: Object.values(this.skillsDamageType),
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
@media (max-width: 900px) {
  .information-tooltip {
    font-size: 0.7rem;
  }
}
</style>
