<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col cols="11">
          {{ `Skill Types (${skillTypesCount})` }}
        </v-col>
        <v-col cols="1">
          <tooltip-icon class="information-tooltip" :text="tooltipText">
          </tooltip-icon>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="skillTypesCount !== 0">
      <bar :data="skillTypesChartData" :options="skillTypesChartOptions" />
    </div>
    <div v-else>There are no skills selected</div>
  </div>
</template>

<script>
import TooltipIcon from '../common/TooltipIcon.vue';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { Bar } from 'vue-chartjs';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default {
  components: {
    TooltipIcon,
    Bar,
  },
  props: {
    skillTypes: Object,
  },
  data() {
    return {
      tooltipText:
        'The chart shows the mix of skill types this build provides like Attack, Debuff, Heal, etc.\n\nTips:\n- all selected active and passive skills are included in the chart\n- you can easily check if your build misses important skill type for your strategy\n- the count represents the number of selected skills of the corresponding type and you can determine if you have a lack or abundance of certain skill type for your strategy\n- attack skill heavy builds may lack the necessary mana pool to "spam" those skills',
    };
  },
  computed: {
    skillTypesCount() {
      let count = 0;

      Object.values(this.skillTypes).forEach((v) => (count += v));

      return count;
    },
    chartLabels() {
      return this.skillTypes.forEach((t) => t.name);
    },
    chartData() {
      return this.skillTypes.forEach((t) => t.count);
    },
    skillTypesChartOptions() {
      return {
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
    skillTypesChartData() {
      return {
        labels: Object.keys(this.skillTypes),
        datasets: [
          {
            backgroundColor: [
              '#ffffff',
              '#009933',
              '#ff6600',
              '#0099cc',
              '#996633',
              '#0033cc',
              '#cc0000',
              '#ffff4d',
            ],
            data: Object.values(this.skillTypes),
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
@media (max-width: 900px) {
  .information-tooltip {
    font-size: 0.7rem;
  }
}
</style>
