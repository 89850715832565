<template>
  <div>
    <select-class v-if="!classAndSubclassSelected"></select-class>
    <select-skills
      :isSavedBuild="false"
      :userIsBuildOwner="false"
      :buildTitle="''"
      :buildTags="[]"
      v-else
    >
    </select-skills>
  </div>
</template>

<script>
import SelectClass from '../components/skill-calculator/select-class/SelectClass.vue';
import SelectSkills from '../components/skill-calculator/select-skills/SelectSkills.vue';

export default {
  components: {
    SelectClass,
    SelectSkills,
  },
  created() {
    this.$store.dispatch('heroBuild/resetHeroBuild');
  },
  computed: {
    classAndSubclassSelected() {
      return this.$store.getters['heroBuild/classAndSubclassSelected'];
    },
  },
};
</script>
