import {
  openHeaderMenu,
  saveBuildDialog,
  updateBuildDialog,
  deleteBuildConfirmation,
  savedBuildActionButtons,
} from './commonData';

export default [
  openHeaderMenu,
  {
    imagePath: require('../../../assets/how-to/compose-team/select-compose-team-from-menu.png'),
    altText: 'Select the compose team menu option',
    description: ['Select the "Compose Team" menu option.'],
  },
  {
    imagePath: require('../../../assets/how-to/compose-team/select-team-heroes.png'),
    altText: 'Select team heroes',
    description: [
      'You should see the Compose Team page.',
      'There are three positions: Position 3 (P3) Backline, Position 2 (P2) Midline, and Position 1 (P1) Frontline.',
      'You need to click the "+" icon for each position and select a hero build for it.',
      'Once you have selected a build for a position, you have a few action buttons as options: "VIEW", "CHANGE", and "REMOVE".',
      'The "VIEW" button will open the hero build in a new browser tab.',
      'The "CHANGE" button will allow you to change the hero build with another.',
      'The "REMOVE" button will remove the selected hero build for the position.',
    ],
  },
  {
    imagePath: require('../../../assets/how-to/compose-team/select-hero-build-dialog.png'),
    altText: 'Select hero build dialog',
    description: [
      'Once you click on the "+" icon to select a hero build for a position, the "Select Hero Build" dialog is displayed.',
      'You can search in your saved builds by build title, tags, and classes for matches.',
      'After you choose the build you are looking for, the "SELECT" button will become enabled and you can apply the selected hero for the position.',
      'You can click the "CANCEL" button to cancel the hero build selection.',
    ],
  },
  {
    imagePath: require('../../../assets/how-to/compose-team/action-buttons.png'),
    altText: 'Action buttons in the subheader',
    description: [
      'There are two action buttons in the subheader: "NEW" and "SAVE".',
      '"The "NEW" button will discard any changes to the hero build and let you start from the beginning.',
      'The "SAVE" button is inactive until you select a hero build for each position.',
      'Once you selected heroes for each team position, you can click the "SAVE" button and the "Save Build" dialog will appear.',
    ],
  },
  saveBuildDialog,
  savedBuildActionButtons,
  updateBuildDialog,
  deleteBuildConfirmation,
  {
    imagePath: require('../../../assets/how-to/compose-team/skills-tab.png'),
    altText: 'Skills tab',
    description: [
      'The skills tab contains a list of the selected hero skills for each position in the team.',
      'The skill cards contain all the information about the selected skill.',
      'Positions that do not have assigned heroes will be empty.',
    ],
  },
  {
    imagePath: require('../../../assets/how-to/compose-team/analytics-tab.png'),
    altText: 'Analytics tab',
    description: [
      'Once you select the the team composition, you can switch to the "Analytics" tab. You need to select at least one hero to view analytics.',
      'There are various analytics widgets with tooltip information about their purpose and some useful tips.',
      'Based on the analytics, you can tweak your team build and optimize it, depending on your goals and strategy.',
    ],
  },
];
