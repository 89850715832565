import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import SkillCalculator from '../views/SkillCalculator.vue';
import HeroBuild from '../views/HeroBuild.vue';
import ComposeTeam from '../views/ComposeTeam.vue';
import TeamBuild from '../views/TeamBuild.vue';
import TierLists from '../views/TierLists.vue';
import MyHeroBuilds from '../views/MyHeroBuilds.vue';
import MyTeamBuilds from '../views/MyTeamBuilds.vue';
import CommunityView from '../views/CommunityView.vue';
import LoginView from '../views/LoginView.vue';
import GettingStartedView from '../views/GettingStartedView.vue';
import HeroClass from '../components/getting-started/HeroClass.vue';
import ReleaseNotesView from '../views/ReleaseNotesView.vue';
import HowToOverview from '../components/getting-started/how-to/HowToOverview.vue';
import HowToSkillCalculator from '../components/getting-started/how-to/HowToSkillCalculator.vue';
import HowToComposeTeam from '../components/getting-started/how-to/HowToComposeTeam.vue';
import HowToViewBuilds from '../components/getting-started/how-to/HowToViewBuilds.vue';
import ContestsView from '../views/ContestsView.vue';
import UnauthorizedView from '../views/UnauthorizedView.vue';
import NotFound from '../views/NotFound.vue';

import { getAuth } from 'firebase/auth';

const auth = getAuth();

const requireAuth = (to, from, next) => {
  let user = auth.currentUser;

  if (user) {
    next();
  } else {
    next({ name: 'unauthorized' });
  }
};

const requireNoUser = (to, from, next) => {
  let user = auth.currentUser;

  if (!user) {
    next();
  } else {
    next({ name: 'home' });
  }
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/skill-calculator/new',
    name: 'skill-calculator',
    component: SkillCalculator,
  },
  {
    path: '/my-hero-builds',
    name: 'my-hero-builds',
    component: MyHeroBuilds,
    beforeEnter: requireAuth,
  },
  {
    path: '/hero-build/:id',
    name: 'hero-build',
    component: HeroBuild,
  },
  {
    path: '/compose-team/new',
    name: 'compose-team',
    component: ComposeTeam,
    beforeEnter: requireAuth,
  },
  {
    path: '/my-team-builds',
    name: 'my-team-builds',
    component: MyTeamBuilds,
    beforeEnter: requireAuth,
  },
  {
    path: '/team-build/:id',
    name: 'team-build',
    component: TeamBuild,
  },
  {
    path: '/tier-lists',
    name: 'tier-lists',
    component: TierLists,
  },
  {
    path: '/community',
    name: 'community',
    component: CommunityView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter: requireNoUser,
  },
  {
    path: '/getting-started',
    name: 'getting-started',
    component: GettingStartedView,
    children: [
      {
        path: 'hero-classes/:id',
        name: 'hero-class',
        component: HeroClass,
      },
      {
        path: 'how-to/overview',
        name: 'how-to-overview',
        component: HowToOverview,
      },
      {
        path: 'how-to/skill-calculator',
        name: 'how-to-skill-calculator',
        component: HowToSkillCalculator,
      },
      {
        path: 'how-to/compose-team',
        name: 'how-to-compose-team',
        component: HowToComposeTeam,
      },
      {
        path: 'how-to/view-builds',
        name: 'how-to-view-builds',
        component: HowToViewBuilds,
      },
    ],
  },
  {
    path: '/contests',
    name: 'contests',
    component: ContestsView,
  },
  {
    path: '/release-notes',
    name: 'release-notes',
    component: ReleaseNotesView,
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: UnauthorizedView,
  },
  {
    path: '/:notFound(.*)',
    name: 'not-found',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
