import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      teamSize: 3,
      /* heroBuildPositions contains a list of objects e.g.
      {
        position: 1,
        heroBuild: {
          heroClass: 'Priest',
          heroSubclass 'Archer',
          requiredLevel: 2,
          selectedClassSkills: ['priest-t1-1', etc.],
          selectedSubclassSkills: ['archer-t1-1', etc.],
          tags: [],
          title: String,
          createdBy: user.uid,
          createdAt: timeStamp,
          updatedAt: timeStamp    
        }     
      }
      */
      heroBuildPositions: [],
    };
  },
  mutations,
  actions,
  getters,
};
