export default {
  className: 'Warrior',
  disciplines: [
    {
      name: 'Gladiator',
      description:
        'The Gladiator leverages their considerable strength to overwhelm and crush their enemies with heavy physical blows. This path focuses on both aggressive and powerful single target attacks and whirlwind spins hastily executed to cut through enemy teams, leaving nothing but destruction and mayhem in their wake.',
    },
    {
      name: 'Juggernaut',
      description:
        'Fully-equipped with an ever-present look of "come at me", the endurance and raw defense of a Juggernaut allows them to shrug off the most powerful of blows and even become unstoppable in the face of a formidable opponent. Unbridled instinct and utter defiance take over to mitigate physical attacks and to resist any incoming onslaught with ease, making the Juggernaut a wall of defense for their allies that can also stop enemies dead in their tracks.',
    },
    {
      name: 'Bloodthirst',
      description:
        'Pure rage and frenzied fury adds fuel to the fires of battle, rewarding these Warriors for their excellent bladework and hardened bloodlust. Intimidation, strength, and a touch of madness are enough to execute any weakened foe, but are never enough to stay the hand of this battle-obsessed and hard-nosed Warrior. Look to the bloodthirsty to never relent once the battle has begun, but most certainly expect the Warrior to strike the final blow to end it.',
    },
  ],
  statGrowth: {
    STR: 75,
    DEX: 70,
    AGI: 50,
    VIT: 65,
    END: 65,
    INT: 20,
    WIS: 20,
    LCK: 35,
  },
  healthGrowth: {
    Small: 15,
    Medium: 40,
    Large: 45,
  },
  manaGrowth: {
    Small: 50,
    Medium: 35,
    Large: 15,
  },
  basicAttackStats: [],
  accuracyStats: [],
  defenseStats: [],
  criticalStrikeStats: [],
  skills: {
    tier1: [
      {
        id: 'warrior-t1-1',
        tier: 1,
        skillPoints: 1,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Health'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Gladiator',
        name: 'Gladiator',
        imageSrc: '',
        description: ['X% effective HP increase in combat.'],
      },
      {
        id: 'warrior-t1-2',
        tier: 1,
        skillPoints: 1,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Reduce Damage Taken'],
        buffsDuration: 0,
        debuffs: ['Reduce Damage Dealt'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Juggernaut',
        name: 'Buff',
        imageSrc: '',
        description: [
          'Reduce damage taken from all sources by X%.',
          'Reduce damage dealt by X%.',
        ],
      },
      {
        id: 'warrior-t1-3',
        tier: 1,
        skillPoints: 1,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Lifesteal'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Bloodthirst',
        name: 'Bloodthirst',
        imageSrc: '',
        description: ['Gain X% Lifesteal.'],
      },
    ],
    tier2: [
      {
        id: 'warrior-t2-1',
        tier: 2,
        skillPoints: 2,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['warrior-t4-1', 'warrior-t6-2'],
        comboEffects: [],
        scalingStats: ['STR', 'END'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Gladiator',
        name: 'Gladiator Strike',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*Basic + X*STR + X*END).',
          'COMBO Blade Flurry: Deal physical damage to target enemy equal to (X*Basic + X*STR + X*END).',
        ],
      },
      {
        id: 'warrior-t2-2',
        tier: 2,
        skillPoints: 2,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Riposte', 'Reduce Damage Taken'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Juggernaut',
        name: 'Ruff',
        imageSrc: '',
        description: [
          'Gain X% Riposte.',
          'Reduce damage taken by X% for a Duration of X.',
        ],
      },
      {
        id: 'warrior-t2-3',
        tier: 2,
        skillPoints: 2,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Intimidate'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Bloodthirst',
        name: 'War Cry',
        imageSrc: '',
        description: [
          'Deal physical damage to all target enemies equal to (X*Basic).',
          'Intimidate all enemies for X%.',
        ],
      },
    ],
    tier3: [
      {
        id: 'warrior-t3-1',
        tier: 3,
        skillPoints: 3,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Gladiator',
        name: 'Whirlwind',
        imageSrc: '',
        description: [
          'Spin through the enemy team.',
          'Deal physical damage equal to (X*Basic + X*DEX + X*LCK) to all enemies.',
        ],
      },
      {
        id: 'warrior-t3-2',
        tier: 3,
        skillPoints: 3,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Unstoppable', 'Reduce Physical Damage Taken'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Juggernaut',
        name: 'Gruff',
        imageSrc: '',
        description: [
          'Become Unstoppable.',
          'Reduce Physical damage received by X%.',
        ],
      },
      {
        id: 'warrior-t3-3',
        tier: 3,
        skillPoints: 3,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['warrior-t4-3'],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Bleed'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Bloodthirst',
        name: 'Twist the Blade',
        imageSrc: '',
        description: [
          'Deal physical damage to target enemy equal to (X*Basic).',
          'COMBO Bloodthirst: Target Hero receives X% Bleed for a Duration of X.',
        ],
      },
    ],
    tier4: [
      {
        id: 'warrior-t4-1',
        tier: 4,
        skillPoints: 4,
        range: 1,
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target', 'Multi-Hit'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Gladiator',
        name: 'Blade Flurry',
        imageSrc: '',
        description: [
          'Unleash a flurry of Basic Attacks that hit a single enemy target X times.',
          'Each strike deals Physical damage equal to (X*Basic).',
        ],
      },
      {
        id: 'warrior-t4-2',
        tier: 4,
        skillPoints: 4,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['END', 'AGI'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Chance for Reduced Damage Taken'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Juggernaut',
        name: 'Tuff',
        imageSrc: '',
        description: [
          'All incoming attacks have a X% chance to deal X% reduced damage to this Hero, where X = (X*END + X*AGI + X*FATE).',
        ],
      },
      {
        id: 'warrior-t4-3',
        tier: 4,
        skillPoints: 4,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Daze', 'Stun', 'Silence', 'Blind'],
        scalingStats: ['STR'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Bloodthirst',
        name: 'Unyielding Blade',
        imageSrc: '',
        description: [
          'Deal damage equal to (X*Basic) to target enemy.',
          'If target enemy is Dazed, Stunned, Silenced, or Blinded, deal damage equal to (X*Basic + X*STR) instead.',
        ],
      },
    ],
    tier5: [
      {
        id: 'warrior-t5-1',
        tier: 5,
        skillPoints: 5,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Cleave'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Gladiator',
        name: 'Furious Cleave',
        imageSrc: '',
        description: [
          "This Hero's Basic Attacks gain Cleave.",
          'Each Basic Attack deals damage equal (X*Basic) to enemies in P1 and P2.',
        ],
      },
      {
        id: 'warrior-t5-2',
        tier: 5,
        skillPoints: 5,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'warrior-t1-2',
          'warrior-t2-2',
          'warrior-t3-2',
          'warrior-t4-2',
          'warrior-t5-2',
          'warrior-t6-3',
          'warrior-t6-4',
          'warrior-t7-5',
          'warrior-t7-6',
          'warrior-t7-7',
        ],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Redirect X% Physical Damage to This Hero',
          'Reduce Physical Damage Received',
        ],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Juggernaut',
        name: 'Nuff',
        imageSrc: '',
        description: [
          'Redirect X% of Physical damage dealt by enemies to this Hero.',
          'COMBO Juggernaut: Reduce Physical damage received by X%.',
        ],
      },
      {
        id: 'warrior-t5-3',
        tier: 5,
        skillPoints: 5,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR'],
        ailments: ['Execute'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Execute',
        damageType: 'Physical',
        discipline: 'Bloodthirst',
        name: 'Final Blow',
        imageSrc: '',
        description: [
          'If target enemy has less than X% of max HP, Execute target.',
          'Otherwise, deal damage to target enemy equal to (X*Basic + X*STR).',
        ],
      },
    ],
    tier6: [
      {
        id: 'warrior-t6-1',
        tier: 6,
        skillPoints: 7,
        range: 3,
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'warrior-t3-1',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['DEX', 'LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Gladiator',
        name: 'Whirlwind +',
        imageSrc: '',
        description: [
          'Spin through the enemy team.',
          'Deal Physical damage equal to (X*Basic + X*DEX + X*LCK) to all enemies.',
        ],
      },
      {
        id: 'warrior-t6-2',
        tier: 6,
        skillPoints: 6,
        range: 3,
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'warrior-t4-1',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Daze'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target', 'Multi-Hit'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Gladiator',
        name: 'Blade Flurry +',
        imageSrc: '',
        description: [
          'Unleash a flurry of Basic Attacks that hit a single enemy target X times.',
          'Each strike deals damage equal to (X*Basic).',
          'X% chance to inflict Daze per hit.',
        ],
      },
      {
        id: 'warrior-t6-3',
        tier: 6,
        skillPoints: 7,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'warrior-t3-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Unstoppable', 'Reduce Physical Damage Taken'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Juggernaut',
        name: 'Gruff +',
        imageSrc: '',
        description: [
          'Become Unstoppable.',
          'Reduce Physical damage received by X%.',
        ],
      },
      {
        id: 'warrior-t6-4',
        tier: 6,
        skillPoints: 6,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: 'warrior-t4-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['END', 'DEX', 'AGI', 'LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Block'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: [],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Juggernaut',
        name: 'Tuff +',
        imageSrc: '',
        description: [
          'Gain Block equal to +X%, where X = (X*END + X*DEX + X*AGI + X*LCK)',
        ],
      },
      {
        id: 'warrior-t6-5',
        tier: 6,
        skillPoints: 8,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'warrior-t2-3',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Intimidate'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Bloodthirst',
        name: 'War Cry +',
        imageSrc: '',
        description: [
          'Deal physical damage to all target enemies equal to (X*Basic).',
          'Intimidate all enemies for X%.',
        ],
      },
      {
        id: 'warrior-t6-6',
        tier: 6,
        skillPoints: 6,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'warrior-t4-3',
        comboSkills: [],
        comboEffects: ['Daze', 'Stun', 'Silence', 'Blind'],
        scalingStats: ['STR'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Bloodthirst',
        name: 'Unyielding Blade +',
        imageSrc: '',
        description: [
          'Deal damage equal to (X*Basic) to target enemy.',
          'If target enemy is Dazed, Stunned, Silenced, or Blinded, deal damage equal to (X*Basic + X*STR) instead.',
        ],
      },
    ],
    tier7: [
      {
        id: 'warrior-t7-1',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Basic Attack Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Gladiator',
        name: 'Adrenaline Rush',
        imageSrc: '',
        description: [
          'If this Hero took damage last Round, increase Basic Attack damage by X% this Round.',
        ],
      },
      {
        id: 'warrior-t7-2',
        tier: 7,
        skillPoints: 10,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['warrior-t4-1', 'warrior-t6-2', 'warrior-t2-1'],
        comboEffects: [],
        scalingStats: ['STR'],
        ailments: ['Daze'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Gladiator',
        name: 'Mighty Strike',
        imageSrc: '',
        description: [
          'Deal damage to target equal to (X*Basic).',
          'Daze target enemy.',
          'COMBO Gladiator Strike Last Turn, Blade Flurry the Turn Before: Deal damage to target equal to (X*Basic + X*STR).',
        ],
      },
      {
        id: 'warrior-t7-3',
        tier: 7,
        skillPoints: 10,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Barrier'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Barrier',
        damageType: '-',
        discipline: 'Gladiator',
        name: 'Battle Forged',
        imageSrc: '',
        description: ['Activate to gain a Barrier equal to X% of max HP.'],
      },
      {
        id: 'warrior-t7-4',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Duel Warrior to Death'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Gladiator',
        name: 'Highlander',
        imageSrc: '',
        description: [
          'Target enemy Warrior, challenging target to a 1v1 duel.',
          'Both Warriors deal alternating Basic Attacks rapidly until one Warrior dies',
        ],
      },
      {
        id: 'warrior-t7-5',
        tier: 7,
        skillPoints: 10,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Stun'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Juggernaut',
        name: 'Cuff',
        imageSrc: '',
        description: [
          'Deal damage to target equal to (X*Basic).',
          'Stun target.',
        ],
      },
      {
        id: 'warrior-t7-6',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'warrior-t1-2',
          'warrior-t2-2',
          'warrior-t3-2',
          'warrior-t4-2',
          'warrior-t5-2',
          'warrior-t6-3',
          'warrior-t6-4',
          'warrior-t7-5',
          'warrior-t7-6',
          'warrior-t7-7',
        ],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Daze', 'Stun', 'Slow'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Juggernaut',
        name: 'Slam',
        imageSrc: '',
        description: [
          'Deal damage to all enemies equal to (X*Basic).',
          'X% chance to inflict Daze on hit.',
          'X% chance to inflict Stun on hit.',
          'COMBO Juggernaut: Inflict X% Slow on hit for a Duration of X.',
        ],
      },
      {
        id: 'warrior-t7-7',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'END', 'DEX'],
        ailments: ['Push', 'Pull', 'Daze', 'Silence', 'Stun'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Exhaust'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Juggernaut',
        name: 'Spin',
        imageSrc: '',
        description: [
          'Deal damage to all enemies equal to (X*Basic + X*STR + X*END + X*DEX).',
          'X% chance to Push P1 to P2.',
          'X% chance to Pull P3 to P2.',
          'X% chance to inflict Daze on hit.',
          'X% chance to inflict Silence on hit.',
          'X% chance to inflict Stun on hit.',
          'Become Exhausted.',
        ],
      },
      {
        id: 'warrior-t7-8',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Bloodthirst',
        name: 'Fury',
        imageSrc: '',
        description: [
          'Each instance this Hero does Basic Attack damage, gain X Fury.',
          "At X stacks of Fury, this Hero's next Basic Attack deals damage equal to (X*Basic).",
        ],
      },
      {
        id: 'warrior-t7-9',
        tier: 7,
        skillPoints: 10,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Riposte'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Bloodthirst',
        name: 'Defiance',
        imageSrc: '',
        description: ['Gain X% Riposte.'],
      },
    ],
    tier8: [
      {
        id: 'warrior-t8-1',
        tier: 8,
        skillPoints: 15,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Heal',
          'Reduce Physical Damage Taken',
          'Prevent Next Lethal Damage',
        ],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Heal',
        damageType: '-',
        discipline: 'Bloodthirst',
        name: 'Way of the Warrior',
        imageSrc: '',
        description: [
          'Instantly heal for X% of missing HP.',
          'Reduce Physical damage taken by X% for a Duration of X.',
          'The next instance of lethal damage this Hero receives is prevented, but HP is reduced to X.',
        ],
      },
    ],
  },
};
