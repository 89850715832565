<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <getting-started-home
            v-if="isGettingStartedHome"
          ></getting-started-home>
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-container>
    <table-of-contents
      :navigationSections="sections"
      :openSections="openSections"
    ></table-of-contents>
  </div>
</template>

<script>
import GettingStartedHome from '../components/getting-started/GettingStartedHome.vue';
import TableOfContents from '../components/shared/common/TableOfContents.vue';
import { useRoute } from 'vue-router';

export default {
  components: {
    GettingStartedHome,
    TableOfContents,
  },
  setup() {
    const route = useRoute();
    const isGettingStartedHome = route.path === '/getting-started';

    return { isGettingStartedHome };
  },
  data() {
    return {
      sections: [
        {
          title: 'How to Use This Tool',
          subsections: [
            {
              title: 'Overview',
              to: '/getting-started/how-to/overview',
            },
            {
              title: 'Skill Calculator',
              to: '/getting-started/how-to/skill-calculator',
            },
            {
              title: 'Compose Team',
              to: '/getting-started/how-to/compose-team',
            },
            {
              title: 'View Builds',
              to: '/getting-started/how-to/view-builds',
            },
          ],
        },
        {
          title: 'Hero Classes',
          subsections: [
            {
              title: 'Archer',
              to: '/getting-started/hero-classes/archer',
            },
            {
              title: 'Berserker',
              to: '/getting-started/hero-classes/berserker',
            },
            {
              title: 'Knight',
              to: '/getting-started/hero-classes/knight',
            },
            {
              title: 'Legionnaire',
              to: '/getting-started/hero-classes/legionnaire',
            },
            {
              title: 'Monk',
              to: '/getting-started/hero-classes/monk',
            },
            {
              title: 'Pirate',
              to: '/getting-started/hero-classes/pirate',
            },
            {
              title: 'Priest',
              to: '/getting-started/hero-classes/priest',
            },
            {
              title: 'Scholar',
              to: '/getting-started/hero-classes/scholar',
            },
            {
              title: 'Seer',
              to: '/getting-started/hero-classes/seer',
            },
            {
              title: 'Thief',
              to: '/getting-started/hero-classes/thief',
            },
            {
              title: 'Warrior',
              to: '/getting-started/hero-classes/warrior',
            },
            {
              title: 'Wizard',
              to: '/getting-started/hero-classes/wizard',
            },
          ],
        },
      ],
      openSections: ['How to Use This Tool'],
    };
  },
};
</script>

<style scoped>
.v-list-item__content .v-icon {
  float: right;
}

.subsection {
  margin-left: 15px;
}
</style>
