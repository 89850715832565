export default {
  className: 'Seer',
  disciplines: [
    {
      name: 'Oracle',
      description:
        'Time means nothing, and everything, to the Oracle. At early stages of study, a Seer focuses on defense, prediction, and enabling allies. However, some Oracles may even choose to do no harm, committing instead to guide their allies through difficult situations and to steer them safely through conflict before it even happens. As the Oracle masters their craft, time begins to bend to their will allowing them to manipulate the flow of time and chain a seemingly endless number of spells together to form countless combinations.',
    },
    {
      name: 'Astrologer',
      description:
        'These Seers can read the stars to determine the best time to take action, and use the heavens to navigate through treacherous terrain, turning that very terrain into an advantage through insight into the vulnerabilities and weaknesses of their enemies, striking devastating damage by pulling the heavens down to earth. The Astrologer path is often preferred by those who value strategy and planning in their quest to defeat their enemies.',
    },
    {
      name: 'Artificer',
      description:
        'The Artificer is able to channel and wield the power embedded deep within the great seeing stones of the realm. Rather than tapping into their own well of mana, the Artificer serves as a conduit to each stone in their arsenal, unlocking the ocean of energy stored within each stone. This energy is used to protect their allies and harm those standing in their way. The Artificer path is often preferred by those who value understanding the arcane arts in their quest to defeat their enemies.',
    },
  ],
  statGrowth: {
    STR: 30,
    DEX: 30,
    AGI: 55,
    VIT: 50,
    END: 50,
    INT: 70,
    WIS: 80,
    LCK: 35,
  },
  healthGrowth: {
    Small: 35,
    Medium: 40,
    Large: 25,
  },
  manaGrowth: {
    Small: 15,
    Medium: 35,
    Large: 50,
  },
  basicAttackStats: [],
  accuracyStats: [],
  defenseStats: [],
  criticalStrikeStats: [],
  skills: {
    tier1: [
      {
        id: 'seer-t1-1',
        tier: 1,
        skillPoints: 1,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Evasion', 'Heal'],
        buffsDuration: 0,
        debuffs: ['Delay'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: '-',
        discipline: 'Oracle',
        name: 'Future Sight',
        imageSrc: '',
        description: [
          'Target ally gains X% EVA for a Duration of X.',
          'Delay X, in a Duration of X, target is healed equal to (X*SPELL + X*WIS).',
        ],
      },
      {
        id: 'seer-t1-2',
        tier: 1,
        skillPoints: 1,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Agility'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Astrologer',
        name: 'Haste',
        imageSrc: '',
        description: [
          'Increase the effective AGI of target by X (X = X*WIS + X*INT) for a Duration of X.',
        ],
      },
      {
        id: 'seer-t1-3',
        tier: 1,
        skillPoints: 1,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Artificer',
        name: 'War Stone',
        imageSrc: '',
        description: [
          'Channel for a Duration of X.',
          'Deal magical damage to target enemy for (X*SPELL + X*WIS + X*INT).',
        ],
      },
    ],
    tier2: [
      {
        id: 'seer-t2-1',
        tier: 2,
        skillPoints: 2,
        range: 'SELF',
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['First Strike'],
        buffsDuration: null,
        debuffs: ['Cannot Deal Damage'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Oracle',
        name: 'The Oracle',
        imageSrc: '',
        description: [
          'This Hero cannot attack or deal physical or magical damage to enemies this battle.',
          'If this Hero casts an ability that would deal damage, that damage is reduced to 0.',
          'This Hero gains First Strike.',
          'If there are two or more Seers on the battlefield with First Strike, the Seer with highest AGI acts first.',
        ],
      },
      {
        id: 'seer-t2-2',
        tier: 2,
        skillPoints: 2,
        range: 'ALLIES',
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['AGI'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Agility for Allies'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Astrologer',
        name: 'The Astrologer',
        imageSrc: '',
        description: ['All allies gain X% effective AGI.'],
      },
      {
        id: 'seer-t2-3',
        tier: 2,
        skillPoints: 2,
        range: 'ALLIES',
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Barrier'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Team'],
        skillType: 'Barrier',
        damageType: '-',
        discipline: 'Artificer',
        name: 'The Artificer',
        imageSrc: '',
        description: [
          "Starting from this Hero's first turn and re-applying every X Durations for the remainder of battle,",
          "This Hero's entire party gains a Barrier with a value equal to X% (X = X + X*WIS) of this Hero's max HP.",
        ],
      },
    ],
    tier3: [
      {
        id: 'seer-t3-1',
        tier: 3,
        skillPoints: 3,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal', 'Block'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Oracle',
        name: 'Sanctuary',
        imageSrc: '',
        description: [
          'Target ally gains X% Block for a Duration of X.',
          'Delay X, target is healed equal to (X*SPELL + X*WIS + X*INT).',
        ],
      },
      {
        id: 'seer-t3-2',
        tier: 3,
        skillPoints: 3,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Slow'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: 0,
        debuffs: ['Delay'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Astrologer',
        name: 'Time Sink',
        imageSrc: '',
        description: [
          'Slow target by X% for a Duration of X.',
          'Delay X, deal magical damage to target equal to (X*SPELL + X*Targets_AGI).',
        ],
      },
      {
        id: 'seer-t3-3',
        tier: 3,
        skillPoints: 3,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal', 'Cleanse'],
        buffsDuration: 0,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Artificer',
        name: 'Purification Stone',
        imageSrc: '',
        description: [
          'Channel for a Duration of X.',
          'Cleanse target ally.',
          'Target is healed equal to (X*SPELL + X*WIS + X*INT).',
        ],
      },
    ],
    tier4: [
      {
        id: 'seer-t4-1',
        tier: 4,
        skillPoints: 4,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Next Enemy Magical Ability Does not Resolve', 'Heal'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Defense',
        damageType: 'Magical',
        discipline: 'Oracle',
        name: 'Counterspell',
        imageSrc: '',
        description: [
          'The next enemy magical damage ability to target this ally over a Duration of X does not resolve.',
          'Once targeted, this ally is healed equal to (X*SPELL + X*WIS + X*LCK).',
        ],
      },
      {
        id: 'seer-t4-2',
        tier: 4,
        skillPoints: 4,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT'],
        ailments: ['Dispel'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: 0,
        debuffs: ['Delay'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Astrologer',
        name: 'Chrono Purge',
        imageSrc: '',
        description: [
          'Dispel target enemy.',
          'Delay X, deal magical damage to target equal to (X*SPELL + X*INT).',
        ],
      },
      {
        id: 'seer-t4-3',
        tier: 4,
        skillPoints: 4,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal'],
        buffsDuration: 0,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Artificer',
        name: 'Rejuvenation Stone',
        imageSrc: '',
        description: [
          'Channel for a Duration of X.',
          'Heal target for (X*Spell + X*WIS + X*INT).',
        ],
      },
    ],
    tier5: [
      {
        id: 'seer-t5-1',
        tier: 5,
        skillPoints: 5,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Next Ability Costs Less Mana', 'Heal'],
        buffsDuration: 0,
        debuffs: ['Delay'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Oracle',
        name: 'Divination',
        imageSrc: '',
        description: [
          'The next ability target ally casts costs X% less mana.',
          'Delay X, heal target for (X*Spell + X*WIS + X*INT).',
        ],
      },
      {
        id: 'seer-t5-2',
        tier: 5,
        skillPoints: 5,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['X Abilities Cost Less Mana', 'Pierce'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Buff',
        damageType: 'Magical',
        discipline: 'Astrologer',
        name: 'Clairvoyance',
        imageSrc: '',
        description: [
          'The next X abilities target ally casts cost X% less mana.',
          'Target ally gains X% Pierce for a Duration of X.',
        ],
      },
      {
        id: 'seer-t5-3',
        tier: 5,
        skillPoints: 5,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Heal', 'Barrier'],
        buffsDuration: 0,
        debuffs: ['Channel', 'Delay'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Single Target'],
        skillType: 'Barrier',
        damageType: 'Magical',
        discipline: 'Artificer',
        name: 'Barrier Stone',
        imageSrc: '',
        description: [
          'Channel for a Duration of X.',
          "Target gains a Barrier equal to X% of target's Max HP (X = X*WIS).",
          'Delay X, target is healed equal to the same value as the applied Barrier.',
        ],
      },
    ],
    tier6: [
      {
        id: 'seer-t6-1',
        tier: 6,
        skillPoints: 9,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'seer-t1-1',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Evasion', 'Heal'],
        buffsDuration: 0,
        debuffs: ['Delay'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Oracle',
        name: 'Future Sight +',
        imageSrc: '',
        description: [
          'Target ally gains X% EVA for a Duration of X.',
          'Delay X, target is healed equal to (X*Spell + X*WIS + X*INT).',
        ],
      },
      {
        id: 'seer-t6-2',
        tier: 6,
        skillPoints: 5,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'seer-t5-1',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Next Ability Costs Less Mana', 'Heal'],
        buffsDuration: 0,
        debuffs: ['Delay'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Oracle',
        name: 'Divination +',
        imageSrc: '',
        description: [
          'The next X abilities target casts cost X mana.',
          'Delay X, heal target for (X*Spell + X*WIS + X*INT).',
        ],
      },
      {
        id: 'seer-t6-3',
        tier: 6,
        skillPoints: 9,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'seer-t1-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT', 'WIS'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Agility'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Buff',
        damageType: 'Magical',
        discipline: 'Astrologer',
        name: 'Haste +',
        imageSrc: '',
        description: [
          'Increase the effective AGI of target by X (X = X*WIS + X*INT) for a Duration of X.',
        ],
      },
      {
        id: 'seer-t6-4',
        tier: 6,
        skillPoints: 7,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'seer-t3-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: ['Slow'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Delay'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Oracle',
        name: 'Time Sink +',
        imageSrc: '',
        description: [
          'Slow target by X% for a Duration of X.',
          'Delay X, deal magical damage to target equal to (X*SPELL + X*Target_AGI).',
        ],
      },
      {
        id: 'seer-t6-5',
        tier: 6,
        skillPoints: 7,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'seer-t3-3',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['X% Resistance to Status Effects', 'Cleanse', 'Heal'],
        buffsDuration: 0,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Allies'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Oracle',
        name: 'Purification Stone +',
        imageSrc: '',
        description: [
          'Channel for a Duration of X, gain X% resistance to Status Effects during Channel.',
          'Cleanse all allies.',
          'All allies are healed equal to (X*SPELL + X*WIS + X*INT).',
        ],
      },
      {
        id: 'seer-t6-6',
        tier: 6,
        skillPoints: 6,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'seer-t4-3',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['X% Resistance to Status Effects', 'Heal'],
        buffsDuration: 0,
        debuffs: ['Channel'],
        debuffsDuration: null,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Allies'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Oracle',
        name: 'Rejuvenation Stone +',
        imageSrc: '',
        description: [
          'Channel for a Duration of X, gain X% resistance to Status Effects during Channel.',
          'Heal all allies for (X*SPELL + X*WIS + X*INT).',
        ],
      },
    ],
    tier7: [
      {
        id: 'seer-t7-1',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['X Actions Next Turn'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Oracle',
        name: 'Time Warp',
        imageSrc: '',
        description: [
          "Target ally may take X Actions on target's next Turn.",
          'If target dies before its next turn, refund the mana spent and cast it for free on a random ally.',
        ],
      },
      {
        id: 'seer-t7-2',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['seer-t1-1', 'seer-t6-1'],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Cannot be Blocked',
          'Cannot be Spell Blocked',
          'Cannot be Evaded',
        ],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Oracle',
        name: 'Bend Time',
        imageSrc: '',
        description: [
          "Target ally's next X Actions cannot be Blocked or Spell Blocked.",
          'COMBO: If "Future Sight" was used by this Hero last turn, target ally\'s Actions cannot be Evaded or Blocked, or Spell Blocked.',
        ],
      },
      {
        id: 'seer-t7-3',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['seer-t7-1', 'seer-t7-2'],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Set Health to Last Turn Value'],
        ailmentsDuration: null,
        buffs: ['Set Health to Last Turn Value', 'Additional Action'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Debuff',
        damageType: 'Magical',
        discipline: 'Oracle',
        name: 'Restore the Timeline',
        imageSrc: '',
        description: [
          "Set target ally or enemy's HP to where it was last Round.",
          'COMBO: If "Bend Time" was cast on this Hero\'s last turn and "Time Warp" was cast the turn before,',
          'This Hero immediately takes an additional Action this Turn.',
        ],
      },
      {
        id: 'seer-t7-4',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['seer-t7-3', 'seer-t1-1', 'seer-t6-1'],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Shroud the Battlefield',
          'Resistance to Fear',
          'Resistance to Stun',
          'Resistance to Daze',
          'Resistance to Slow',
          'Evasion',
          'Block',
          'Spell Block',
        ],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Oracle',
        name: 'Phantasmal Secrets',
        imageSrc: '',
        description: [
          'Shroud all allies in secrecy for a Duration of X.',
          "Enemies are unable to see ally's Mana and HP values.",
          'The Battle Log has a X% chance of not showing an action for the enemy.',
          'For a Duration of X, Allies gain X% resistance to:\n' +
            'Fear\n' +
            'Stun\n' +
            'Daze\n' +
            'Slow',
          'COMBO: If "Restore the Timeline" and "Future Sight" were cast this battle, for a Duration of X, all allies gain:\n' +
            'X% EVA\n' +
            'X% Block\n' +
            'X% Spell Block',
        ],
      },
      {
        id: 'seer-t7-5',
        tier: 7,
        skillPoints: 10,
        range: 'ALLIES',
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: ['seer-t2-2'],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Evasion'],
        buffsDuration: null,
        debuffs: ['Reduce Agility'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Defense',
        damageType: 'Magical',
        discipline: 'Astrologer',
        name: 'Sphere of Influence',
        imageSrc: '',
        description: [
          'All allies gain X% EVA.',
          'COMBO: If The Astrologer is active, allies gain an additional X% EVA, to a total of X% EVA.',
        ],
      },
      {
        id: 'seer-t7-6',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['seer-t7-5'],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: ['Dispel'],
        ailmentsDuration: null,
        buffs: ['X% Resistance to Status Effects'],
        buffsDuration: 0,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Astrologer',
        name: 'Astral Barrage',
        imageSrc: '',
        description: [
          'Channel for a Duration of X, gain X% resistance to Status Effects during Channel.',
          'Deal damage to all enemies equal to (X*SPELL + X*WIS + X*INT).',
          'COMBO: If "Sphere of Influence" is active, Dispel all enemies.',
        ],
      },
      {
        id: 'seer-t7-7',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['seer-t5-2'],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Ethereal', 'Heal', 'Ally Abilities Cost Less Mana'],
        buffsDuration: 0,
        debuffs: ['Ethereal'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Heal',
        damageType: 'Magical',
        discipline: 'Astrologer',
        name: 'Astral Projection',
        imageSrc: '',
        description: [
          'Move all allies into an Ethereal state for a Duration of X.',
          'Delay X, heal all allies equal to (X*Spell + X*WIS + X*INT).',
          'COMBO: If "Clairvoyance" was cast on this Hero\'s last turn, gain:\n' +
            'While in an Ethereal states, Ally abilities cost X% less mana.',
        ],
      },
      {
        id: 'seer-t7-8',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['seer-t5-3'],
        comboEffects: [],
        scalingStats: ['END'],
        ailments: ['Banish', 'Slow'],
        ailmentsDuration: null,
        buffs: ['Barrier'],
        buffsDuration: 0,
        debuffs: ['Delay'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Debuff',
        damageType: 'Magical',
        discipline: 'Artificer',
        name: 'Banishing Stone',
        imageSrc: '',
        description: [
          'Target is Banished for a Duration of X.',
          'Delay X, target is Slowed by X% for a Duration of X.',
          'COMBO: If "Barrier Stone" was used on this Hero\'s last turn, gain:\n' +
            "All allies gain a Barrier equal to X% of target Banished enemy's Max HP (X = X*END).",
        ],
      },
      {
        id: 'seer-t7-9',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT'],
        ailments: ['Berserk'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Delay'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Debuff',
        damageType: 'Magical',
        discipline: 'Artificer',
        name: 'Berserker Stone',
        imageSrc: '',
        description: [
          'Target enemy Hero goes Berserk for a Duration of X.',
          'Delay X, if Berserk hits, deal magical damage to target equal to (X*SPELL + X*INT).',
          'CONDITION: If target commits murder (kills another enemy), cast "Berserker Stone" again, for free, on a random enemy or ally.',
        ],
      },
    ],
    tier8: [
      {
        id: 'seer-t8-1',
        tier: 8,
        skillPoints: 15,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'seer-t7-7',
          'seer-t5-3',
          'seer-t1-3',
          'seer-t3-3',
          'seer-t6-5',
        ],
        comboEffects: [],
        scalingStats: ['WIS', 'INT', 'WIS', 'INT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Evasion',
          'Backfire',
          'Damage',
          'X% Resistance to Status Effects',
        ],
        buffsDuration: 0,
        debuffs: ['Cannot Be Controlled'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Artificer',
        name: 'Dream Stone',
        imageSrc: '',
        description: [
          'Activate the Dream Stone to enter a meditative dream-like state.',
          "Seer's actions are unable to be controlled while the Dream Stone is active.",
          'Gain X% EVA.',
          "At the start of each enemy's Turn, deal magical damage to that enemy equal to (X*SPELL + X*WIS + X*INT).",
          'This meditative state lasts until interrupted.',
          'COMBO: If "Astral Projection" was cast this battle, Gain an additional X% EVA.',
          'COMBO: If "Barrier Stone" was cast this battle, Gain X% Backfire.',
          'COMBO: If "War Stone" was cast this battle, increase magical damage dealt to (X*SPELL + X*WIS + X*INT).',
          'COMBO: If "Purification Stone" was cast this battle, gain a X% resistance to status effects.',
        ],
      },
    ],
  },
};
