import heroSkillsHelper from '../../../utils/heroSkillsHelper.js';

export default {
  selectedClass(state) {
    return state.selectedClass;
  },
  selectedSubclass(state) {
    return state.selectedSubclass;
  },
  classSelectionExists(state) {
    return state.selectedClass !== null || state.selectedSubclass !== null;
  },
  classAndSubclassSelected(state) {
    return state.selectedClass !== null && state.selectedSubclass !== null;
  },
  classAndSubclassMatch(state) {
    return state.selectedClass.className === state.selectedSubclass.className;
  },
  classSkillPointsSpent(_, getters) {
    // Process class selected skills and return their total
    let skillPoints = 0;

    getters.selectedClassSkillObjects.forEach((s) => {
      skillPoints += s.skillPoints;
    });

    return skillPoints;
  },
  subclassSkillPointsSpent(_, getters) {
    // Process subclass selected skills and return their total
    let skillPoints = 0;

    getters.selectedSubclassSkillObjects.forEach((s) => {
      skillPoints += s.skillPoints;
    });

    return skillPoints;
  },
  selectedClassSkillObjects(state) {
    return heroSkillsHelper.getSkillsAsList(state.selectedClassSkills);
  },
  selectedSubclassSkillObjects(state) {
    return heroSkillsHelper.getSkillsAsList(state.selectedSubclassSkills);
  },
  selectedSkillObjects(_, getters) {
    // list with selected skills
    const skills = getters.selectedClassSkillObjects.concat(
      getters.selectedSubclassSkillObjects
    );
    // list with dependent skills that selected tier6 skills have
    const dependentSkills = skills.map((skill) => skill.dependentSkill);
    // list with filtered basic skills from tier1-5 which have an upgrade in tier6
    const filteredSkills = skills.filter(
      (skill) => !dependentSkills.includes(skill.id)
    );

    return filteredSkills;
  },
  selectedClassSkillIds(_, getters) {
    return getters.selectedClassSkillObjects.map((s) => s.id);
  },
  selectedSubclassSkillIds(_, getters) {
    return getters.selectedSubclassSkillObjects.map((s) => s.id);
  },
  selectedSkillIds(_, getters) {
    return getters.selectedClassSkillIds.concat(
      getters.selectedSubclassSkillIds
    );
  },
  skillScalingStats(_, getters) {
    let scalingStatsObject = {
      STR: 0,
      DEX: 0,
      AGI: 0,
      VIT: 0,
      END: 0,
      INT: 0,
      WIS: 0,
      LCK: 0,
    };

    const skillObjects = getters.selectedSkillObjects;

    skillObjects.forEach((skill) => {
      skill.scalingStats.forEach((stat) => {
        scalingStatsObject[stat] += 1;
      });
    });

    return scalingStatsObject;
  },
  skillTypes(_, getters) {
    return heroSkillsHelper.getSkillTypes(getters.selectedSkillObjects);
  },
  attackSkills(_, getters) {
    return heroSkillsHelper.getAttackSkills(getters.selectedSkillObjects);
  },
  skillsAttackArea(_, getters) {
    return heroSkillsHelper.getSkillsAttackArea(getters.attackSkills);
  },
  skillAttackDamageType(_, getters) {
    return heroSkillsHelper.getSkillsAttackDamageType(getters.attackSkills);
  },
  skillsAttackRange(_, getters) {
    return heroSkillsHelper.getSkillsAttackRange(getters.attackSkills);
  },
  skillBuffs(_, getters) {
    return heroSkillsHelper.getSkillBuffs(getters.selectedSkillObjects);
  },
  skillDebuffs(_, getters) {
    return heroSkillsHelper.getSkillDebuffs(getters.selectedSkillObjects);
  },
  skillsActivePassive(_, getters) {
    return heroSkillsHelper.getActivePassiveSkills(
      getters.selectedSkillObjects
    );
  },
  skillsWithComboEffects(_, getters) {
    return heroSkillsHelper.getSkillsWithComboEffects(
      getters.selectedSkillObjects
    );
  },
  buffsAndAilments(_, getters) {
    const buffs = getters.skillBuffs;
    const ailments = getters.skillDebuffs;

    return { ...buffs, ...ailments };
  },
  selectedClassSkills(state) {
    return state.selectedClassSkills;
  },

  selectedSubclassSkills(state) {
    return state.selectedSubclassSkills;
  },
};
