export default {
  className: 'Knight',
  disciplines: [
    {
      name: 'Honor',
      description: 'The Honor path emphasizes defense and protecting allies.',
    },
    {
      name: 'Valor',
      description: 'The path of Valor focuses on strength and vigilance.',
    },
    {
      name: 'Iron',
      description:
        'The path of Iron emphasizes debuffing and disrupting the enemy.',
    },
  ],
  statGrowth: {
    STR: 70,
    DEX: 55,
    AGI: 45,
    VIT: 75,
    END: 75,
    INT: 20,
    WIS: 25,
    LCK: 35,
  },
  healthGrowth: {
    Small: 15,
    Medium: 35,
    Large: 50,
  },
  manaGrowth: {
    Small: 40,
    Medium: 40,
    Large: 20,
  },
  basicAttackStats: [],
  accuracyStats: [],
  defenseStats: [],
  criticalStrikeStats: [],
  skills: {
    tier1: [
      {
        id: 'knight-t1-1',
        tier: 1,
        skillPoints: 1,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Health'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Honor',
        name: 'Honor and Glory',
        imageSrc:
          'https://game.defikingdoms.com/assets/Attacks/knight/honor-and-glory.png',
        description: ['5% effective HP increase in combat.'],
      },
      {
        id: 'knight-t1-2',
        tier: 1,
        skillPoints: 1,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Strength'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Valor',
        name: 'Valor and Truth',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/knight/valor-and-truth.png',
        description: ['5% effective STR increase in combat.'],
      },
      {
        id: 'knight-t1-3',
        tier: 1,
        skillPoints: 1,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['END'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Endurance'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Iron',
        name: 'Iron Chivalry',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/knight/iron-chivalry.png',
        description: ['5% effective END increase in combat.'],
      },
    ],
    tier2: [
      {
        id: 'knight-t2-1',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['END'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Reduce Ranged Physical Damage Taken for Allies'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Honor',
        name: 'For Honor',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/knight/for-honor.png',
        description: [
          'Reduce Ranged Physical damage taken by all allies equal to X% (X = 0.06*END)',
        ],
      },
      {
        id: 'knight-t2-2',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['END'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Reduce Physical Damage Taken'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Valor',
        name: 'For Valor',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/knight/for-valor.png',
        description: [
          'Reduce Physical damage taken equal to X% (X = 0.1*END).',
        ],
      },
      {
        id: 'knight-t2-3',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'END'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Strength', 'Endurance'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Iron',
        name: 'For the King',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/knight/for-the-king.png',
        description: [
          'Increase effective STR by 10%.',
          'Increase effective END by 10%.',
        ],
      },
    ],
    tier3: [
      {
        id: 'knight-t3-1',
        tier: 3,
        skillPoints: 3,
        range: 1,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['knight-t2-1'],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Daze'],
        ailmentsDuration: null,
        buffs: ['Daze Resistance for Allies'],
        buffsDuration: 3,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Honor',
        name: 'Forever Vigilant',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/knight/forever-vigilant.png',
        description: [
          'Deal damage to target enemy equal to (1.1*Basic).',
          'Daze target enemy.',
          'Allies gain 50% resistance to Daze for 3 Rounds.',
          'COMBO: If "For Honor" is active, allies gain 80% resistance to Daze for 3 Rounds.',
        ],
      },
      {
        id: 'knight-t3-2',
        tier: 3,
        skillPoints: 3,
        range: 1,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['knight-t2-2'],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Push'],
        ailmentsDuration: null,
        buffs: ['Resistance to Push and Pull for Allies'],
        buffsDuration: 3,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Valor',
        name: 'Hold the Line',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/knight/hold-line.png',
        description: [
          'Deal damage to target enemy equal to (1.1*Basic).',
          'Push target enemy 1.',
          'Allies gain 40% resistance to Push and Pull for 3 Rounds.',
          'COMBO: If "For Valor" is active, allies gain 70% resistance to Push and Pull for 3 Rounds.',
        ],
      },
      {
        id: 'knight-t3-3',
        tier: 3,
        skillPoints: 3,
        range: 1,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['knight-t2-3', 'knight-t6-5'],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Silence'],
        ailmentsDuration: 1,
        buffs: ['Resistance to Silence for Allies'],
        buffsDuration: 3,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Iron',
        name: 'Iron Will',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/knight/iron-will.png',
        description: [
          'Deal damage to target enemy equal to (1.1*Basic).',
          'Silence target enemy for 1 Round.',
          'Allies gain 40% resistance to Silence for 3 Rounds.',
          'COMBO: If "For the King" is active, allies gain 70% resistance to Silence for 3 Rounds.',
        ],
      },
    ],
    tier4: [
      {
        id: 'knight-t4-1',
        tier: 4,
        skillPoints: 4,
        range: 1,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Redirect X% of All Physical Damage from Enemy at P1'],
        buffsDuration: 999,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Honor',
        name: 'Interception',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/knight/interception.png',
        description: [
          'Deal damage equal to (1.0*Basic) to target enemy in P1.',
          'Redirect 50% of all Physical damage dealt by enemies in P1 to this Hero for the remainder of Battle.',
        ],
      },
      {
        id: 'knight-t4-2',
        tier: 4,
        skillPoints: 4,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Basic Attack Damage', 'Block'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Valor',
        name: 'Battle Ready',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/knight/battle-ready.png',
        description: [
          'Gain 10% Block.',
          'Increase Basic Attack damage by 20%.',
        ],
      },
      {
        id: 'knight-t4-3',
        tier: 4,
        skillPoints: 4,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [
          'knight-t1-3',
          'knight-t2-3',
          'knight-t6-5',
          'knight-t5-3',
          'knight-t6-6',
          'knight-t7-2',
          'knight-t7-7',
        ],
        comboEffects: [],
        scalingStats: ['END'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Reduce Physical Damage Taken'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Iron',
        name: 'Ironclad Defense',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/knight/iron-clad-defence.png',
        description: [
          'Reduce physical damage taken equal to X% (X = 0.2*END) from enemies inf P2 and P3.',
          'COMBO: If another Iron ability is active, (X = 0.3*END).',
        ],
      },
    ],
    tier5: [
      {
        id: 'knight-t5-1',
        tier: 5,
        skillPoints: 5,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Reduce Physical Damage Taken', 'Redirect Status Effects'],
        buffsDuration: 4,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Honor',
        name: 'Royal Guard',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/knight/royal-guard.png',
        description: [
          'Reduce Physical damage taken by 15% and redirect all Status Effects targeting ally in P2 to this Hero for 4 Rounds.',
        ],
      },
      {
        id: 'knight-t5-2',
        tier: 5,
        skillPoints: 5,
        range: 1,
        mana: 10,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Stun'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Valor',
        name: 'Shield Smash',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/knight/shield-smash.png',
        description: [
          'Deal damage to target enemy equal to (1.1*Basic).',
          'Stun enemy target.',
        ],
      },
      {
        id: 'knight-t5-3',
        tier: 5,
        skillPoints: 5,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [
          'knight-t1-3',
          'knight-t2-3',
          'knight-t6-5',
          'knight-t4-3',
          'knight-t7-2',
          'knight-t7-7',
        ],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Reduce Physical Damage Taken',
          'Cannot be Pushed',
          'Cannot be Pulled',
        ],
        buffsDuration: 4,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Iron',
        name: 'Iron Shield',
        imageSrc:
          'https://game.defikingdoms.com//assets/Attacks/knight/iron-shield.png',
        description: [
          'Activate to reduce Physical damage taken by 20% for 4 Rounds.',
          'COMBO: If another Iron ability is active, reduce damage taken by 30% and this Hero cannot be Pushed or Pulled.',
        ],
      },
    ],
    tier6: [
      {
        id: 'knight-t6-1',
        tier: 6,
        skillPoints: 7,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'knight-t3-1',
        comboSkills: ['knight-t2-1'],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Daze'],
        ailmentsDuration: null,
        buffs: ['Resistance to Daze for Allies'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Honor',
        name: 'Forever Vigilant +',
        imageSrc: '',
        description: [
          'Deal damage to target enemy equal to (X*Basic).',
          'Daze target enemy.',
          'Allies gain X% resistance to Daze for X Rounds.',
          'COMBO: If "For Honor" is active, allies gain X% resistance to Daze for X Rounds.',
        ],
      },
      {
        id: 'knight-t6-2',
        tier: 6,
        skillPoints: 6,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'knight-t4-1',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Redirect Physical Damage from enemies in P1 and P2'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Honor',
        name: 'Interception +',
        imageSrc: '',
        description: [
          'Deal damage equal to (X*Basic) to target enemy in P1.',
          'Redirect X% of all Physical damage from enemies in P1 and P2 to this Hero for X Rounds.',
        ],
      },
      {
        id: 'knight-t6-3',
        tier: 6,
        skillPoints: 6,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: 'knight-t4-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Damage', 'Block'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: [],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Valor',
        name: 'Battle Ready +',
        imageSrc: '',
        description: ['Gain X% Block.', 'Increase Basic Attack damage by X%.'],
      },
      {
        id: 'knight-t6-4',
        tier: 6,
        skillPoints: 5,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'knight-t5-2',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: ['Stun', 'Push'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Valor',
        name: 'Shield Smash +',
        imageSrc: '',
        description: [
          'Deal damage to target enemy equal to (X*Basic).',
          'Stun enemy target.',
          'Push target X.',
        ],
      },
      {
        id: 'knight-t6-5',
        tier: 6,
        skillPoints: 8,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: 'knight-t2-3',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'END'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Strength', 'Endurance', 'Riposte'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Iron',
        name: 'For the King +',
        imageSrc: '',
        description: [
          'Increase effective STR by X%.',
          'Increase effective END by X%.',
          'Gain X% Riposte.',
        ],
      },
      {
        id: 'knight-t6-6',
        tier: 6,
        skillPoints: 5,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'knight-t5-3',
        comboSkills: [
          'knight-t1-3',
          'knight-t2-3',
          'knight-t6-5',
          'knight-t4-3',
          'knight-t7-2',
          'knight-t7-7',
        ],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Reduce Physical Damage Taken', 'Daze Resistance for Allies'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Iron',
        name: 'Iron Shield +',
        imageSrc: '',
        description: [
          'Activate to reduce Physical damage taken by X% for X Rounds.',
          'COMBO: If another Iron ability is active, allies gain X% resistance to Daze for X Rounds.',
        ],
      },
    ],
    tier7: [
      {
        id: 'knight-t7-1',
        tier: 7,
        skillPoints: 10,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['END'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Strength'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Valor',
        name: 'Inspirational Words',
        imageSrc: '',
        description: [
          'All allies gain effective STR equal to X% (X = X*END) for X Rounds.',
        ],
      },
      {
        id: 'knight-t7-2',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [
          'knight-t1-3',
          'knight-t2-3',
          'knight-t6-5',
          'knight-t5-3',
          'knight-t6-6',
          'knight-t4-3',
          'knight-t7-7',
        ],
        comboEffects: [],
        scalingStats: ['VIT', 'STR'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Endurance'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: [],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Iron',
        name: 'Cloak of Iron',
        imageSrc: '',
        description: [
          'If X Iron abilities are active, this Hero gains effective END equal to X% (X = X*VIT + X*STR).',
        ],
      },
      {
        id: 'knight-t7-3',
        tier: 7,
        skillPoints: 10,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Agility for Allies'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Valor',
        name: 'Commanding Presence',
        imageSrc: '',
        description: [
          'All allies gain effective AGI equal to X% (X = X*STR) for X Rounds.',
        ],
      },
      {
        id: 'knight-t7-4',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['END'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Dexterity'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: [],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Honor',
        name: 'Superior Technique',
        imageSrc: '',
        description: ['Increase effective DEX equal to X%', '(X = X*END).'],
      },
      {
        id: 'knight-t7-5',
        tier: 7,
        skillPoints: 10,
        range: 1,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Pierce'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Honor',
        name: 'True Strike',
        imageSrc: '',
        description: [
          'Deal Physical damage to target enemy equal to (X*Basic) with X% Pierce.',
        ],
      },
      {
        id: 'knight-t7-6',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Physical Damage Reduction for Allies Behind this Hero'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Valor',
        name: 'One for All',
        imageSrc: '',
        description: [
          'Deal damage to target enemy in P1 (X*Basic + X*DEX), P2 (X*Basic + X*DEX), P3 (X*Basic + X*DEX).',
        ],
      },
      {
        id: 'knight-t7-7',
        tier: 7,
        skillPoints: 10,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Reduce Damage Taken for Allies in P2 and P3'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Defense',
        damageType: 'Physical',
        discipline: 'Iron',
        name: 'Shield of the People',
        imageSrc: '',
        description: [
          'Reduce damage taken by allies in P2 and P3 by X% for X rounds.',
        ],
      },
      {
        id: 'knight-t7-8',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Critical Strike'],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: 'Physical',
        discipline: 'Honor',
        name: 'A Knight to Remember',
        imageSrc: '',
        description: [
          'When below X% HP, all Basic Attacks and Abilities gain X% increased critical strike chance.',
        ],
      },
      {
        id: 'knight-t7-9',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Redirect Status Effects targeting Allies to This Hero'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Defense',
        damageType: '-',
        discipline: 'Valor',
        name: 'Live to Serve',
        imageSrc: '',
        description: [
          'Redirect all Status Effects targeting allies to this Hero.',
        ],
      },
    ],
    tier8: [
      {
        id: 'knight-t8-1',
        tier: 8,
        skillPoints: 15,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['STR', 'DEX'],
        ailments: ['Push', 'Pull', 'Stun'],
        ailmentsDuration: null,
        buffs: [],
        buffsDuration: 0,
        debuffs: ['Exhausted'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Physical',
        discipline: 'Honor',
        name: 'Knight King',
        imageSrc: '',
        description: [
          'Deal damage to P1 equal to ((X*Basic)+(X*STR + X*DEX)) and X% of that value to P2 and P3.',
          'Push P1 to P2, Pull P2 to P1, and Stun P3.',
          'Become Exhausted.',
        ],
      },
    ],
  },
};
