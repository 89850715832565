<template>
  <div>
    <v-container>
      <h1 class="d-flex justify-center">Contests</h1>
      <v-list-item>
        <h3 class="centered-text">
          You can find below all the information about a pending contest, if
          there is one at the moment. In general, there will be a short
          description of the contest, submission period, rules, awards, and
          instructions how to submit an entry.
        </h3>
      </v-list-item>
      <v-list-item>
        <h3 class="centered-text">
          If you want to be informed about contests, you can follow me on
          <a
            href="https://twitter.com/iknowkungfu2023"
            target="_blank"
            rel="noopener"
            >Twitter/X
          </a>
          and/or join the #pvp-discussion channel at
          <a
            href="https://discord.gg/defikingdoms"
            target="_blank"
            rel="noopener"
            >DeFi Kingdoms Discord </a
          >.
        </h3>
      </v-list-item>
    </v-container>

    <v-container v-if="currentContestAvailable">
      <h2 class="d-flex justify-center contest-title">
        {{ contestData.title }}
      </h2>
      <v-container>
        <v-divider></v-divider>
      </v-container>
      <v-container>
        <h3 class="centered-text">
          Let's celebrate the release of the codices for all basic classes AND
          the DeFi Kingdoms partnership with Dragon's Crossing!
        </h3>
      </v-container>
      <v-container class="subtitle">Description: </v-container>
      <v-list-item
        v-for="(item, index) in contestData.description"
        :key="index"
      >
        {{ item }}
      </v-list-item>
      <v-container class="subtitle"
        >End date: {{ contestData.period }}
      </v-container>
      <v-container class="subtitle">Awards:</v-container>
      <v-list-item
        >1st place:
        <a
          href="https://dfk-adventures.herokuapp.com/heroes/2000000021047/"
          target="_blank"
          rel="noopener"
          >Mythic DFK hero 2000000021047</a
        >,
        <a
          href="https://dfk-adventures.herokuapp.com/pets/1000000094195/"
          target="_blank"
          rel="noopener"
          >Mythic DFK pet 1000000094195</a
        >,
        <a
          href="https://opensea.io/assets/arbitrum/0xfdac927e174fe3eea7f1492feb37f323e29d56a9/3045"
          target="_blank"
          rel="noopener"
          >DC ranger hero</a
        >, 20 USDC
      </v-list-item>
      <v-list-item
        >2nd place:
        <a
          href="https://dfk-adventures.herokuapp.com/heroes/1000000119574/"
          target="_blank"
          rel="noopener"
          >Legendary DFK hero 1000000119574</a
        >,
        <a
          href="https://dfk-adventures.herokuapp.com/pets/2000000035284/"
          target="_blank"
          rel="noopener"
          >Legendary DFK pet 2000000035284</a
        >,
        <a
          href="https://opensea.io/assets/arbitrum/0xfdac927e174fe3eea7f1492feb37f323e29d56a9/2916"
          target="_blank"
          rel="noopener"
          >DC mage hero</a
        >, 20 USDC
      </v-list-item>
      <v-list-item
        >3rd place:
        <a
          href="https://dfk-adventures.herokuapp.com/heroes/1000000029400/"
          target="_blank"
          rel="noopener"
          >Rare DFK hero 1000000029400</a
        >,
        <a
          href="https://opensea.io/assets/arbitrum/0xfdac927e174fe3eea7f1492feb37f323e29d56a9/3046"
          target="_blank"
          rel="noopener"
          >DC mage hero</a
        >, 20 USDC
      </v-list-item>
      <v-list-item
        >4th place:
        <a
          href="https://dfk-adventures.herokuapp.com/heroes/2000000153787/"
          target="_blank"
          rel="noopener"
          >Expert Leatherworker DFK hero 2000000153787</a
        >,
        <a
          href="https://opensea.io/assets/arbitrum/0xfdac927e174fe3eea7f1492feb37f323e29d56a9/2900"
          target="_blank"
          rel="noopener"
          >DC ranger hero</a
        >, 10 USDC
      </v-list-item>
      <v-list-item
        >5th place:
        <a
          href="https://dfk-adventures.herokuapp.com/heroes/2000000151470/"
          target="_blank"
          rel="noopener"
          >Expert Armorsmith DFK hero 2000000151470</a
        >,
        <a
          href="https://opensea.io/assets/arbitrum/0xfdac927e174fe3eea7f1492feb37f323e29d56a9/3049"
          target="_blank"
          rel="noopener"
          >DC ranger hero</a
        >, 10 USDC
      </v-list-item>
      <v-list-item>
        Participation award: a random person, who submitted a valid team
        composition, will be selected to receive
        <a
          href="https://dfk-adventures.herokuapp.com/heroes/2000000019465/"
          target="_blank"
          rel="noopener"
          >Legendary DFK hero 2000000019465</a
        >,
        <a
          href="https://dfk-adventures.herokuapp.com/pets/1000000097071/"
          target="_blank"
          rel="noopener"
          >Legendary DFK pet 1000000097071</a
        >,
        <a
          href="https://opensea.io/assets/arbitrum/0xfdac927e174fe3eea7f1492feb37f323e29d56a9/3043"
          target="_blank"
          rel="noopener"
          >DC warrior hero</a
        >, 20 USDC
      </v-list-item>
      <v-container class="subtitle">Rules:</v-container>
      <v-list-item v-for="(item, index) in contestData.rules" :key="index">
        {{ item }}
      </v-list-item>
      <v-container class="subtitle">How to participate:</v-container>
      <v-list-item
        v-for="(item, index) in contestData.participateDescription"
        :key="index"
      >
        {{ `${index + 1}. ${item}` }}
      </v-list-item>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="6" md="4">
            <v-btn
              color="primary"
              block
              :href="contestData.link"
              target="_blank"
            >
              Submit Contest Entry
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container v-else class="no-contests-container">
      <v-row class="text-container">
        <h2 class="contest-title">There is no active contest at the moment</h2>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentContestAvailable: false,
      contestData: {
        title: '3v3 PVP Basic Team Composition Contest',
        description: [
          "The rewards will be given to the best team compositions built with the 'Compose Team' feature.",
          'If you encounter any difficulties while using the tool, you can visit the "Getting Started" page and refer to the "How to Use This Tool" section.',
          'All submitted entries will be reviewed, and winners will be selected from the valid entries,',
          'Winners will be picked on a live stream shortly after the contest ends. Stay tuned for more details.',
        ],
        period: 'Extended with 5 more days to 09/17/2023 11:59 PM ET',
        rules: [
          " - Team compositions will be evaluated based on the following criteria: PVP viability for the team composition, Heroes' positioning and roles, Heroes' synergy, Originality and innovation",
          ' - Only one contest entry is allowed per account.',
          ' - A contest submission is considered valid if you have a team of exactly 3 heroes, and each hero is level 20 or lower. If there is a hero above level 20, your submission will be not valid.',
          ' - Only BASIC heroes are allowed to be used in your team. The allowed classes and subclasses are: Archer, Berserker, Knight, Legionnaire, Monk, Pirate, Priest, Scholar, Seer, Thief, Warrior, and Wizard.',
          ' - Contest submissions will be accepted until the end of the contest period. Any entries submitted after that will not qualify.',
          ' - You can submit a team for the contest and make changes to it until the submission time is over, in case you change your mind. Once you finalize your team for the contest, do not make any further changes to it or to the heroes you used to compose the team.',
          ' - If there are two or more identical team compositions submitted, only the person that submitted first will qualify for the contest.',
        ],
        participateDescription: [
          'Log in or sign up with your DFK Combat account. If there are multiple entries submitted by the same person, only the first one will count.',
          'Use the "Skill calculator" to create and save hero builds, that you will use later to compose a team.',
          'Utilize the "Compose Team" feature to create and save a team of 3 heroes that conform to the contest rules.',
          'Once your team is saved, click the "Share" button to copy the link to submit it for the contest.',
          'To submit an entry for the contest, click the "Submit Contest Entry" button at the bottom of the page and provide: Team Composition Link (required), Discord ID (required, full ID with the #), Description (optional, but highly recommended, to understand better the strategy behind your team composition).',
        ],
        link: 'https://docs.google.com/forms/d/e/1FAIpQLSdlmc6nzPo7HohOEYcnk5DYq4rO6yVzLL3v6gpKxSMYrrZEKA/viewform?usp=sf_link',
      },
    };
  },
};
</script>
<style scoped>
h1 {
  font-size: 2.2rem;
}

.no-contests-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.contest-title {
  font-size: 1.5rem;
  color: orangered;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>
