export default {
  setTeamSize(context, data) {
    context.commit('setTeamSize', data.teamSize);
  },
  addHeroPosition(context, data) {
    context.commit('addHeroPosition', data);
  },
  removeHeroPosition(context, data) {
    context.commit('removeHeroPosition', data.position);
  },
  resetTeamBuild(context) {
    context.commit('resetTeamBuild');
  },
};
