<template>
  <v-dialog :value="showDeleteDialog" :max-width="isMobile ? '50%' : '30%'">
    <v-card>
      <v-card-title>Profile Settings</v-card-title>
      <v-divider></v-divider>
      <v-form>
        <v-text-field
          outlined
          v-model="displayName"
          label="Change Your Display Name"
          type="text"
          :rules="displayNameRules"
        ></v-text-field>
      </v-form>
      <v-divider></v-divider>
      <v-container class="action-buttons">
        <v-row justify="center">
          <v-btn
            min-width="40%"
            variant="tonal"
            color="primary"
            @click="closeProfileSettings()"
          >
            Cancel
          </v-btn>
          <v-btn
            min-width="40%"
            :disabled="!isSaveEnabled"
            variant="tonal"
            color="red"
            @click="saveProfileSettings()"
          >
            Save
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import resolutionHelper from '../../utils/resolutionHelper';

export default {
  props: {
    showDeleteDialog: Boolean,
    currentDisplayName: String,
  },
  emits: ['closeProfileSettings', 'saveProfileSettings'],
  data() {
    return {
      displayName: this.currentDisplayName,
      displayNameRules: [
        (value) => {
          if (value?.length > 1 && value?.length < 21) return true;

          return 'Display Name must be between 2 and 20 characters.';
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return resolutionHelper.isMobile();
    },
    isSaveEnabled() {
      return this.displayName.length > 1 && this.displayName.length < 21;
    },
  },
  methods: {
    closeProfileSettings() {
      this.$emit('closeProfileSettings');
    },
    saveProfileSettings() {
      this.$emit('saveProfileSettings', this.displayName);
    },
  },
};
</script>

<style scoped>
.v-card-title {
  text-align: center;
}

.v-divider {
  margin-bottom: 10px;
}

.action-buttons {
  margin-bottom: 10px;
}

.action-buttons .v-btn {
  margin: 5px;
}

.v-container p {
  margin-bottom: 10px;
}

div.v-input {
  margin: 0 20px;
}
</style>
