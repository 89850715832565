import heroSkillsHelper from './heroSkillsHelper';

export default {
  getClassSkills(hero) {
    let selectedClassSkills = {
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: null,
      tier5: null,
      tier6: [],
      tier7: [],
      tier8: null,
    };

    const heroClass = heroSkillsHelper.getClassObject(hero.heroBuild.heroClass);

    hero.heroBuild.selectedClassSkills.forEach((skill) => {
      const tierNumber = parseInt(skill.match(/\d+/)[0]);
      const tier = `tier${tierNumber}`;

      const heroSkill = heroClass.skills[`tier${tierNumber}`].find(
        (s) => s.id === skill
      );

      if (tierNumber < 6 || tierNumber === 8) {
        selectedClassSkills[tier] = heroSkill;
      } else {
        selectedClassSkills[tier].push(heroSkill);
      }
    });

    return selectedClassSkills;
  },
  getSubclassSkills(hero) {
    let selectedSubclassSkills = {
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: null,
      tier5: null,
      tier6: [],
      tier7: [],
    };

    const heroClass = heroSkillsHelper.getClassObject(
      hero.heroBuild.heroSubclass
    );

    hero.heroBuild.selectedSubclassSkills.forEach((skill) => {
      const tierNumber = parseInt(skill.match(/\d+/)[0]);
      const tier = `tier${tierNumber}`;

      const heroSkill = heroClass.skills[`tier${tierNumber}`].find(
        (s) => s.id === skill
      );

      if (tierNumber < 6) {
        selectedSubclassSkills[tier] = heroSkill;
      } else {
        selectedSubclassSkills[tier].push(heroSkill);
      }
    });

    return selectedSubclassSkills;
  },
};
