<template>
  <div>
    <v-app-bar>
      <v-row align="center">
        <v-col cols="12">
          <v-tabs fixed-tabs v-model="tab">
            <v-tab value="select-team" @click="toggleTab"> Team </v-tab>
            <v-tab value="team-skills" @click="toggleTab"> Skills </v-tab>
            <v-tab value="team-analytics" @click="toggleTab"> Analytics </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-toolbar-items>
        <v-btn color="blue" @click="copyLink" v-if="isSavedBuild">
          <v-icon large color="blue">mdi-share-variant-outline</v-icon>
          Share
        </v-btn>
        <v-btn :disabled="!user" color="white" @click="newTeamBuild">
          <v-icon large color="white">mdi-plus-box-outline</v-icon>
          New
        </v-btn>
        <v-btn
          :disabled="!user || !teamSizeMatchesSelectedHeroes"
          color="green"
          v-if="userIsBuildOwner"
          @click="openSaveUpdateDialog"
        >
          <v-icon large color="green">mdi-content-save</v-icon>
          Update
        </v-btn>
        <v-btn
          :disabled="!user || !teamSizeMatchesSelectedHeroes"
          color="green"
          v-else
          @click="openSaveUpdateDialog"
        >
          <v-icon large color="green">mdi-content-save</v-icon>
          Save
        </v-btn>
        <v-btn color="red" v-if="userIsBuildOwner" @click="openDeleteDialog">
          <v-icon large color="red">mdi-delete</v-icon>
          Delete
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <save-update-dialog
      v-model="showSaveUpdateDialog"
      :isSavedBuild="isSavedBuild"
      :userIsBuildOwner="userIsBuildOwner"
      :buildTitle="teamBuildTitle"
      :buildTags="teamBuildTags"
      @close-dialog="closeSaveUpdateDialog"
      @save-build="saveTeamBuild"
      @update-build="updateTeamBuild"
    >
    </save-update-dialog>

    <delete-confirmation-dialog
      v-model="showDeleteConfirmationDialog"
      @closeDeleteDialog="closeDeleteDialog"
      @confirmDeleteBuild="deleteTeamBuild"
    >
    </delete-confirmation-dialog>
  </div>
</template>

<script>
import { db, serverTimestamp } from '@/firebase/config';
import { doc, collection, addDoc, setDoc, deleteDoc } from 'firebase/firestore';

import getUser from '../../composables/getUser.js';
import SaveUpdateDialog from '../dialogs/SaveUpdateDialog.vue';
import DeleteConfirmationDialog from '../dialogs/DeleteConfirmationDialog.vue';

export default {
  setup() {
    const user = getUser();

    return { user };
  },
  props: {
    isSavedBuild: Boolean,
    userIsBuildOwner: Boolean,
    teamBuildTitle: String,
    teamBuildTags: Array,
  },
  components: {
    SaveUpdateDialog,
    DeleteConfirmationDialog,
  },
  data() {
    return {
      tab: null,
      showSaveUpdateDialog: false,
      showDeleteConfirmationDialog: false,
    };
  },
  computed: {
    teamSize() {
      return this.$store.getters['teamBuild/getTeamSize'];
    },
    teamHeroPositions() {
      return this.$store.getters['teamBuild/getHeroPositions'];
    },
    dbHeroPositions() {
      let heroPositions = [];

      this.teamHeroPositions.forEach((p) => {
        heroPositions.push({
          position: p.position,
          heroBuildId: p.heroBuild.id,
          heroClass: p.heroBuild.heroClass,
          heroSubclass: p.heroBuild.heroSubclass,
        });
      });

      return heroPositions;
    },
    teamSizeMatchesSelectedHeroes() {
      return this.teamSize === this.teamHeroPositions.length;
    },
  },
  methods: {
    toggleTab() {
      this.$emit('toggleTab', this.tab);
    },
    copyLink() {
      var blob = new Blob([window.location.href], { type: 'text/plain' });
      var item = new ClipboardItem({ 'text/plain': blob });

      navigator.clipboard.write([item]).then(() => {
        this.$gtag.event('share', { method: 'TeamBuildLink' });
        this.$store.dispatch('snackbar/showMessage', {
          content: 'Shareable link copied to clipboard!',
          color: 'blue',
        });
      });
    },
    newTeamBuild() {
      this.$store.dispatch('teamBuild/resetTeamBuild');
      this.$router.push({ name: 'compose-team' });
    },
    saveTeamBuild(value) {
      const teamSize = this.teamSize;
      const selectedHeroesCount = this.teamHeroPositions.length;
      const errorMessage = `Error saving the team build! The team size is ${teamSize}, but selected heroes are ${selectedHeroesCount}`;

      if (!this.teamSizeMatchesSelectedHeroes) {
        this.$store.dispatch('snackbar/showMessage', {
          content: errorMessage,
          color: 'red',
        });
        console.error(errorMessage);
      } else {
        const teamBuild = {
          title: value.title,
          tags: value.tags,
          teamSize: teamSize,
          heroPositions: this.dbHeroPositions,
          createdBy: this.user.uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        };

        addDoc(collection(db, 'teamBuilds'), teamBuild)
          .then((result) => {
            this.$store.dispatch('snackbar/showMessage', {
              content: 'The team build was successfully saved!',
              color: 'success',
            });
            this.$router.push({
              name: 'team-build',
              params: { id: result.id },
            });
          })
          .catch((error) => {
            this.$store.dispatch('snackbar/showMessage', {
              content: 'Error saving the team build!',
              color: 'red',
            });
            console.error('Error saving the team build!', error);
          });
      }
    },
    updateTeamBuild(value) {
      const teamSize = this.teamSize;
      const selectedHeroesCount = this.teamHeroPositions.length;
      const errorMessage = `Error saving the team build! The team size is ${teamSize}, but selected heroes are ${selectedHeroesCount}`;

      if (!this.teamSizeMatchesSelectedHeroes) {
        this.$store.dispatch('snackbar/showMessage', {
          content: errorMessage,
          color: 'red',
        });
        console.error(errorMessage);
      } else {
        const teamBuild = {
          title: value.title,
          tags: value.tags,
          teamSize: this.teamSize,
          heroPositions: this.dbHeroPositions,
          updatedAt: serverTimestamp(),
        };

        const docRef = doc(db, 'teamBuilds', this.$route.params.id);
        setDoc(docRef, teamBuild, { merge: true })
          .then(() => {
            this.$store.dispatch('snackbar/showMessage', {
              content: 'The team build was successfully updated!',
              color: 'success',
            });
          })
          .catch((error) => {
            this.$store.dispatch('snackbar/showMessage', {
              content: 'Error updating the team build!',
              color: 'red',
            });
            console.error(
              'Error updating the team build: ' + this.$route.params.id,
              error
            );
          });
      }
    },
    deleteTeamBuild() {
      deleteDoc(doc(db, 'teamBuilds', this.$route.params.id)).then(() => {
        this.newTeamBuild();
        this.$store.dispatch('snackbar/showMessage', {
          content: 'The team build was successfully deleted!',
          color: 'orange',
        });
      });
    },
    openSaveUpdateDialog() {
      this.showSaveUpdateDialog = true;
    },
    closeSaveUpdateDialog() {
      this.showSaveUpdateDialog = false;
    },
    openDeleteDialog() {
      this.showDeleteConfirmationDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteConfirmationDialog = false;
    },
  },
};
</script>
