export default {
  className: 'Wizard',
  disciplines: [
    {
      name: 'Pyromancer',
      description:
        'The Pyromancer’s spells are focused on dealing massive amounts of damage and setting their foes ablaze. As a master of flame and destruction, the Pyromancer harnesses the raw power of fire to incinerate their enemies and add insult to injury.',
    },
    {
      name: 'Cryomancer',
      description:
        'A Cryomancer can freeze enemies in their tracks, immobilizing them with the icy grip of winter, or launch razor-sharp shards of ice that slice through armor and flesh alike. Their spells are focused on dealing damage and applying Chill, which slows their enemies and reduces their effectiveness in combat.',
    },
    {
      name: 'Arcanist',
      description:
        'An Arcanist wields a power that is beyond comprehension, tapping into the deepest mysteries of the universe to attack the mind and project magical energy fields. They can erect protective barriers, cloak themselves in impenetrable force fields, or reach into the minds of their enemies, shattering their wills and leaving them powerless to resist an incoming attack.',
    },
  ],
  statGrowth: {
    STR: 30,
    DEX: 30,
    AGI: 40,
    VIT: 50,
    END: 50,
    INT: 80,
    WIS: 80,
    LCK: 40,
  },
  healthGrowth: {
    Small: 35,
    Medium: 40,
    Large: 25,
  },
  manaGrowth: {
    Small: 15,
    Medium: 35,
    Large: 50,
  },
  basicAttackStats: [],
  accuracyStats: [],
  defenseStats: [],
  criticalStrikeStats: [],
  skills: {
    tier1: [
      {
        id: 'wizard-t1-1',
        tier: 1,
        skillPoints: 1,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Burn'],
        scalingStats: ['INT', 'INT'],
        ailments: ['Burn'],
        ailmentsDuration: 0,
        buffs: ['Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Pyromancer',
        name: 'Fire Bolt',
        imageSrc: '',
        description: [
          'Deal magical damage to target enemy equal to (X*SPELL + X*INT).',
          '(X + X*INT)% chance to apply a stack of Burn to target with magical damage equal to (X*INT).',
          'Generate X charges of Arcane Power.',
          'COMBO: If target is already inflicted with Burn, deal X% increased magical damage.',
        ],
      },
      {
        id: 'wizard-t1-2',
        tier: 1,
        skillPoints: 1,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Chill'],
        scalingStats: ['INT', 'INT'],
        ailments: ['Chill'],
        ailmentsDuration: null,
        buffs: ['Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Cryomancer',
        name: 'Frost Bolt',
        imageSrc: '',
        description: [
          'Deal magical damage to target enemy equal to (X*SPELL + X*INT).',
          '(X + X*INT)% chance apply a stack of Chill to target.',
          'Generate X stacks of Arcane Power.',
          'COMBO: If target is already inflicted with Chill, deal X% increased magical damage.',
        ],
      },
      {
        id: 'wizard-t1-3',
        tier: 1,
        skillPoints: 1,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Barrier'],
        scalingStats: ['WIS', 'VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Pure Barrier'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Barrier',
        damageType: 'Magical',
        discipline: 'Arcanist',
        name: 'Raw Arcana',
        imageSrc: '',
        description: [
          "Activate to gain a Pure Barrier equal to (X + X*WIS)% of this Hero's maximum HP, up to X%.",
          'Generate X charges of Arcane Power.',
          'COMBO: If target is already protected by any type of Barrier, generate X additional charges of Arcane Power.',
        ],
      },
    ],
    tier2: [
      {
        id: 'wizard-t2-1',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Burn'],
        scalingStats: ['LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Critical Strike'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Pyromancer',
        name: 'Pyro Mastery',
        imageSrc: '',
        description: [
          'All Pyromancer Abilities gain:',
          '(X*LCK)% increased Critical Strike Chance (CSC) when dealing damage to targets that are inflicted with Burn.',
          'Successful attacks generate X charges of Arcane Power.',
        ],
      },
      {
        id: 'wizard-t2-2',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Chill'],
        scalingStats: ['LCK'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Critical Strike'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Cryomancer',
        name: 'Cryo Mastery',
        imageSrc: '',
        description: [
          'All Cryomancer Abilities gain:',
          '(X*LCK)% increased CSC when dealing damage to targets that are inflicted with Chill.',
          'Successful attacks generate X charges of Arcane Power.',
        ],
      },
      {
        id: 'wizard-t2-3',
        tier: 2,
        skillPoints: 2,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Barrier'],
        scalingStats: ['WIS'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Silence Resistance'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Arcanist',
        name: 'Arcane Mastery',
        imageSrc: '',
        description: [
          'This Hero gains resistance to Silence equal to (X + X*WIS)%.',
          'All successful Arcane attacks generate X additional charges of Arcane Power.',
          'If this Hero is already protected by any type of Barrier at the beginning of its Turn, generate X charges of Arcane Power.',
        ],
      },
    ],
    tier3: [
      {
        id: 'wizard-t3-1',
        tier: 3,
        skillPoints: 3,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Burn'],
        scalingStats: ['INT', 'INT', 'INT'],
        ailments: ['Burn', 'Daze', 'Exhaust'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Pyromancer',
        name: 'Fireball',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Deal magical damage to target enemy equal to [(X*SPELL) + ((X*AP)*INT)].',
          'If target is already inflicted with Burn, deal X% increased magical damage.',
          '(X + X*INT)% chance to apply a stack of Burn with magical damage equal to (X*INT).',
          'UNLOCKS when over X charges of Arcane Power (LOCKS when below X AP charges):\n' +
            '(X + X*INT)% chance to Daze target enemy.',
          'UNLOCKS when over X charges of Arcane Power (LOCKS when below X AP charges):\n' +
            '(X + X*INT)% chance to Exhaust target enemy.',
        ],
      },
      {
        id: 'wizard-t3-2',
        tier: 3,
        skillPoints: 3,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Chill'],
        scalingStats: ['INT'],
        ailments: ['Chill'],
        ailmentsDuration: null,
        buffs: ['Pierce'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Cryomancer',
        name: 'Frost Pierce',
        imageSrc: '',
        description: [
          'Deal magical damage to target enemy in P1 equal to [(X*SPELL) + ((X*AP)*INT)].',
          'If target is already inflicted with Chill, this attack gains X% Pierce.',
          '(X + X*INT)% chance apply a stack of Chill to target.',
          'This attack has X% Pierce.',
          'If Blocked, the Frost Pierce stops its trajectory.',
          'UNLOCK X:\n' +
            'Deal magical damage to target enemy in P2 equal to [(X*SPELL) + ((X*AP)*INT)].\n' +
            '(X + X*INT)% chance apply a stack of Chill to target.',
          'UNLOCK X:\n' +
            'Deal magical damage to target enemy in P3 equal to [(X*SPELL) + ((X*AP)*INT)].\n' +
            '(X + X*INT)% chance apply a stack of Chill to target.',
        ],
      },
      {
        id: 'wizard-t3-3',
        tier: 3,
        skillPoints: 3,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'WIS', 'VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: [
          'Pure Barrier',
          'Evasion',
          'Silence Resistance',
          'Fear Resistance',
          'Confusion Resistance',
        ],
        buffsDuration: 0,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Barrier',
        damageType: 'Magical',
        discipline: 'Arcanist',
        name: 'Arcane Barrier',
        imageSrc: '',
        description: [
          'Activate to consume X charges of Arcane Power (AP).',
          "All party members gain a Pure Barrier with HP equal to [X + ((X*AP)*WIS)]% of this Hero's maximum HP.",
          'Generate X charges of Arcane Power.',
          'UNLOCK X:\n' +
            'All party members gain EVA equal to (X + 0.05*WIS)% for a Duration of X. This effect Refreshes (does not stack).',
          'UNLOCKS X:\n' +
            'All party members gain Resistance to Silence, Fear, and Confusion equal to (X + X*WIS)% for a Duration of X.',
          'This effect Refreshes (does not stack).',
        ],
      },
    ],
    tier4: [
      {
        id: 'wizard-t4-1',
        tier: 4,
        skillPoints: 4,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT', 'INT'],
        ailments: ['Fear', 'Burn'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Pyromancer',
        name: 'Fire Storm',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Channel for a Duration of X.',
          'Deal magical damage to each enemy equal to [(X*AP)*SPELL) + (X*INT)].',
          '(X + X*INT)% chance to apply a stack of Burn with magical damage equal to (X*INT) to each hit target.',
          'Generate X charges of Arcane Power per hit.',
          'UNLOCK X:\n' +
            'Gain an additional X% chance added to the initial application of Burn to each target.',
          'UNLOCKS X:\n' +
            '(X + AP + X*INT)% chance to apply an additional stack of Burn with magical damage equal to (X*INT) to each hit target.',
          'UNLOCKS X:\n' +
            '(X + AP + X*INT)% chance to Fear a single random hit enemy.',
        ],
      },
      {
        id: 'wizard-t4-2',
        tier: 4,
        skillPoints: 4,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT', 'INT'],
        ailments: ['Chill'],
        ailmentsDuration: 0,
        buffs: [],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Cryomancer',
        name: 'Frost Lance',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP), AP applies to each instance of damage.',
          'Deal magical damage to target enemy in P1 equal to [(X*AP)*SPELL + (X*INT)].',
          'Deal magical damage to target enemy P2 equal to [(X*AP)*SPELL + (X*INT)].',
          'Deal magical damage to target enemy in P3 equal to [(X*AP)*SPELL + (X*INT)].',
          '(X + X*INT)% chance apply a stack of Chill to each hit target.',
          'If Blocked, the Frost Lance stops its trajectory.',
          'Generate X charges of Arcane Power per hit.',
          'UNLOCK X:\n' +
            'Gain an additional X% chance added to the initial application of Chill to each target.',
          'UNLOCK X:\n' +
            '(X + AP + X*INT)% chance to apply an additional stack of Chill to each hit target.',
          'UNLOCK X:\n' + 'Frost Lance cannot be Blocked.',
        ],
      },
      {
        id: 'wizard-t4-3',
        tier: 4,
        skillPoints: 4,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Burn', 'Chill'],
        scalingStats: ['WIS', 'INT'],
        ailments: ['Confuse', 'Amnesia'],
        ailmentsDuration: null,
        buffs: ['Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Arcanist',
        name: 'Mind Break',
        imageSrc: '',
        description: [
          'Deal damage to target enemy equal to (X*SPELL + X*INT).',
          'Generate X charges of Arcane Power.',
          'COMBO: If target is inflicted with Chill or Burn, gain (X + X*WIS)% chance to deal X% additional magical damage.',
          'UNLOCK X:\n' +
            '(X + X*WIS)% chance to Confuse target enemy for a Duration of X.',
          'UNLOCK X:\n' +
            '(X + X*WIS)% chance to Amnesia a random target enemy skill for a Duration of X.',
        ],
      },
    ],
    tier5: [
      {
        id: 'wizard-t5-1',
        tier: 5,
        skillPoints: 5,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Chill'],
        scalingStats: [],
        ailments: ['Burn', 'Exhaust', 'Fear'],
        ailmentsDuration: 0,
        buffs: ['Reduce Skill Mana Cost'],
        buffsDuration: null,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: '(X*AP) Rounds',
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Pyromancer',
        name: 'Pyroclast Explosion',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Channel for a Duration of (X*AP) rounded up.',
          'Deal magical damage to each enemy equal to [(X*AP)*SPELL + X*INT].',
          '(X + X*INT)% chance to apply a stack of Burn with magical damage equal to (X*INT) to each target.',
          'For each enemy hit, generate X charges of Arcane Power.',
          'COMBO: If target is inflicted with Chill, gain (X + X*INT)% chance to reduce the mana cost of this skill by X%.',
          'UNLOCK X:\n' +
            '(X + AP + X*INT)% chance to Exhaust a single random hit enemy.',
          'UNLOCK X:\n' +
            '(X + AP + X*INT)% chance to Fear a single random hit enemy.',
        ],
      },
      {
        id: 'wizard-t5-2',
        tier: 5,
        skillPoints: 5,
        range: 2,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Burn'],
        scalingStats: ['INT', 'INT'],
        ailments: ['Chill', 'Stun'],
        ailmentsDuration: null,
        buffs: ['Reduce Skill Mana Cost'],
        buffsDuration: null,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: '(X*AP) Rounds',
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Cryomancer',
        name: "Frost's Grip",
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Channel for a Duration of (X*AP) rounded up.',
          'Deal magical damage to target enemy equal to [(X*AP)*SPELL + X*INT].',
          '(X + X*INT)% chance apply a stack of Chill to target.',
          'Generate X charges of Arcane Power.',
          'COMBO: If target is inflicted with Burn, gain (X + X*INT)% chance to reduce the mana cost of this skill by X%.',
          'UNLOCK X:\n' +
            '(X + AP + X*INT)% chance to Stun target enemy for a Duration of X.',
          'UNLOCK X:\n' + 'Frost Grip targets enemies in both P1 and P2.',
        ],
      },
      {
        id: 'wizard-t5-3',
        tier: 5,
        skillPoints: 5,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'VIT'],
        ailments: ['Reflect Damage'],
        ailmentsDuration: null,
        buffs: ['Pure Barrier', 'Redirect Enemy Attacks to Barrier'],
        buffsDuration: 0,
        debuffs: ['Amnesia Reflective Barrier'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Barrier',
        damageType: 'Magical',
        discipline: 'Arcanist',
        name: 'Reflective Barrier',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'The HP of the Pure Barrier is equal to [(X*AP)*SPELL + X*WIS + X*VIT].',
          'The Barrier lasts for a Duration of X or until destroyed.',
          'While the Barrier exists, all enemy attacks are redirected to the Pure Barrier.',
          'Generate X charges of Arcane Power.',
          'UNLOCK X:\n' +
            'Delay X, (X + AP)% of the damage the Pure Barrier receives is Reflected amongst all enemies, divided evenly.',
          'UNLOCK X:\n' +
            'Delay X, (X + AP + X*WIS)% of the damage the Pure Barrier receives is Reflected amongst all enemies, divided evenly.',
          'Amnesia X, "Reflective Barrier".',
        ],
      },
    ],
    tier6: [
      {
        id: 'wizard-t6-1',
        tier: 6,
        skillPoints: 9,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'wizard-t1-1',
        comboSkills: [],
        comboEffects: ['Burn'],
        scalingStats: ['INT'],
        ailments: ['Burn'],
        ailmentsDuration: 0,
        buffs: ['Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Pyromancer',
        name: 'Fire Bolt +',
        imageSrc: '',
        description: [
          'Deal magical damage to target enemy equal to (X*SPELL + X*INT).',
          '(X + X*INT)% chance apply a stack of Burn to target.',
          'Generate X charges of Arcane Power.',
          'COMBO: If target is already inflicted with Burn, deal X% increased magical damage.',
        ],
      },
      {
        id: 'wizard-t6-2',
        tier: 6,
        skillPoints: 7,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'wizard-t3-1',
        comboSkills: [],
        comboEffects: ['Burn'],
        scalingStats: ['INT', 'INT'],
        ailments: ['Burn', 'Daze', 'Exhaust'],
        ailmentsDuration: 0,
        buffs: ['Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Pyromancer',
        name: 'Fireball +',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Deal magical damage to target enemy equal to ([(X*SPELL) + ((X*AP)*INT)]).',
          '(X + X*INT)% chance to apply a stack of Burn dealing magical damage equal to (X*INT) to target.',
          'Generate X charges of Arcane Power.',
          'UNLOCK X:\n' + '(X + AP + X*INT)% chance to Daze target enemy.',
          'UNLOCK X:\n' + '(X + AP + X*INT)% chance to Exhaust target enemy.',
          'COMBO: If target is already inflicted with Burn, deal X% increased magical damage.',
        ],
      },
      {
        id: 'wizard-t6-3',
        tier: 6,
        skillPoints: 9,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'wizard-t1-2',
        comboSkills: [],
        comboEffects: ['Chill'],
        scalingStats: ['INT'],
        ailments: ['Chill'],
        ailmentsDuration: 0,
        buffs: ['Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Cryomancer',
        name: 'Frost Bolt +',
        imageSrc: '',
        description: [
          'Deal magical damage to target enemy equal to (X*SPELL + X*INT).',
          '(X + X*INT)% chance apply a stack of Chill to target.',
          'Generate X charges of Arcane Power.',
          'COMBO: If target is already inflicted with Chill, deal X% increased magical damage.',
        ],
      },
      {
        id: 'wizard-t6-4',
        tier: 6,
        skillPoints: 7,
        range: 1,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: 'wizard-t3-2',
        comboSkills: [],
        comboEffects: ['Chill'],
        scalingStats: ['INT'],
        ailments: ['Chill'],
        ailmentsDuration: 0,
        buffs: ['Pierce'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Cryomancer',
        name: 'Frost Pierce +',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Deal magical damage to target enemy in P1 equal to [(X*SPELL) + ((X*AP)*INT)].',
          '(X + X*INT)% chance apply a stack of Chill to target.',
          'This attack has X% Pierce.',
          'Generate X charges of Arcane Power for each enemy hit.',
          'If Blocked, the Frost Pierce stops its trajectory.',
          'COMBO: If target is already inflicted with Chill, this attack gains x% Pierce.',
          'UNLOCK x:\n' +
            'Deal magical damage to target enemy in P2 equal to [(X*SPELL) + ((X*AP)*INT)].\n' +
            '(X + AP + X*INT)% chance apply a stack of Chill to target.',
          'UNLOCK X:\n' +
            'Deal magical damage to target enemy in P3 equal to [(X*SPELL) + ((X*AP)*INT)].\n' +
            '(X + AP + X*INT)% chance apply a stack of Chill to target.',
        ],
      },
      {
        id: 'wizard-t6-5',
        tier: 6,
        skillPoints: 9,
        range: 'SELF',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'wizard-t1-3',
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'VIT'],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Pure Barrier'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Barrier',
        damageType: '-',
        discipline: 'Arcanist',
        name: 'Raw Arcana +',
        imageSrc: '',
        description: [
          "Activate to gain a Pure Barrier equal to (X + X*WIS + X*VIT)% of this Hero's maximum HP, up to X%.",
          'Generate X charges of Arcane Power.',
        ],
      },
      {
        id: 'wizard-t6-6',
        tier: 6,
        skillPoints: 7,
        range: 'ALLIES',
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: 'wizard-t3-3',
        comboSkills: [],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Pure Barrier'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Allies'],
        skillType: 'Barrier',
        damageType: '-',
        discipline: 'Arcanist',
        name: 'Arcane Barrier +',
        imageSrc: '',
        description: [
          'Activate to consume X charges of Arcane Power (AP).',
          "All party members gain a Pure Barrier with HP equal to [X + ((X*AP)*WIS)]% of this Hero's maximum HP.",
          'Generate X charges of Arcane Power.',
          'UNLOCK X:\n' +
            'All party members gain EVA equal to (X% + X*WIS) for a Duration of X. This effect Refreshes (does not stack).',
          'UNLOCKS X:\n' +
            'All party members gain Resistance to Silence, Fear, and Confusion equal to (X + AP + X*WIS)% for a Duration of X.',
          'This effect Refreshes (does not stack).',
        ],
      },
    ],
    tier7: [
      {
        id: 'wizard-t7-1',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT', 'WIS'],
        ailments: ['Burn'],
        ailmentsDuration: 0,
        buffs: ['Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Pyromancer',
        name: 'Pyro Weaving',
        imageSrc: '',
        description: [
          "This Hero's magical skills gain:",
          '(X + X*INT)% chance to apply Burn equal to (X*INT) to target enemy. This effect does not stack.',
          'Upon application, each stack of Burn has a (X + X*WIS)% chance to increase Duration by X.',
          'Generate X charges of Arcane Power.',
        ],
      },
      {
        id: 'wizard-t7-2',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['wizard-t7-4'],
        comboEffects: [],
        scalingStats: ['INT', 'LCK'],
        ailments: ['Burn'],
        ailmentsDuration: 0,
        buffs: ['Critical Strike', 'Repeat'],
        buffsDuration: null,
        debuffs: ['Amnesia Chain Reaction', 'Delay'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Pyromancer',
        name: 'Chain Reaction',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Delay X, deal magical damage to target enemy equal to [(X*AP)*SPELL + X*INT)].',
          '(X + AP + X*INT)% chance to apply a stack of Burn with magical damage equal to (X*INT) to target enemy.',
          'This attack has an additional (AP + X*LCK)% CSC.',
          'Generate X charges of Arcane Power.',
          'Delay X, deal magical damage to a random enemy equal to [(X*AP)*SPELL + X*INT)].',
          '(X + AP + X*INT)% chance to apply a stack of Burn with magical damage equal to (X*INT) to target enemy.',
          'This attack has an additional (AP + X*LCK)% CSC.',
          'Generate X charges of Arcane Power.',
          'Delay X, deal magical damage to a random enemy equal to [(X*AP)*SPELL + X*INT)].',
          '(X + AP + X*INT)% chance to apply a stack of Burn with magical damage equal to (X*INT) to target enemy.',
          'This attack has an additional (AP + X*LCK)% CSC.',
          'Generate X charges of Arcane Power.',
          'COMBO: If "Crystalize" was cast last turn, increase the CSC of each hit by X%.',
          'UNLOCK X:\n' + '(X*AP)% REPEAT X.',
          'UNLOCK X:\n' + '(X*AP)% REPEAT X.',
          'Amnesia X, "Chain Reaction".',
        ],
      },
      {
        id: 'wizard-t7-3',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT', 'WIS'],
        ailments: ['Chill'],
        ailmentsDuration: 0,
        buffs: ['Damage'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Cryomancer',
        name: 'Cryo Weaving',
        imageSrc: '',
        description: [
          "This Hero's magical skills gain:",
          '(X + X*INT)% chance to apply one stack of Chill to target enemy. This effect does not stack.',
          'Upon application, each stack of Chill has a (X + X*WIS)% chance to increase Duration by X.',
          'Generate X charges of Arcane Power.',
        ],
      },
      {
        id: 'wizard-t7-4',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: ['wizard-t7-2'],
        comboEffects: [],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Pure Barrier', 'Block', 'Backfire', 'Riposte', 'Heal'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Barrier',
        damageType: '-',
        discipline: 'Cryomancer',
        name: 'Crystalize',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X Arcane Power stacks (AP).',
          'Empower this skill by consuming X Mana (MANA).',
          'Gain a Pure Barrier with HP equal to [(X*AP)*SPELL + MANA].',
          'While the Pure Barrier persists, gain an array of positive effects:\n' +
            'X% BLK.\n' +
            'X% Backfire.\n' +
            'X% Riposte.',
          'Generate X charges of Arcane Power equal to (X*MANA) per Duration.',
          'COMBO: If "Chain Reaction" was cast this Battle, heal for X% of Max HP for each hit by "Chain Reaction".',
          'UNLOCK X:\n' + 'Gain an additional X% BLK.',
          'UNLOCK X:\n' + 'X% Backfire.\n' + '% Riposte.',
        ],
      },
      {
        id: 'wizard-t7-5',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: null,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['INT'],
        ailments: [
          'Reduced Evasion',
          'Reduced Damage',
          'Skills Cost Additional Mana',
          'Reduced Cleanse Resistance',
          'Pull',
        ],
        ailmentsDuration: 0,
        buffs: ['Steal Target Resistances'],
        buffsDuration: null,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Single Target'],
        skillType: 'Debuff',
        damageType: 'Magical',
        discipline: 'Arcanist',
        name: 'Mind Control',
        imageSrc: '',
        description: [
          'The accuracy of this attack is increased or decreased by X%.',
          "X = This Hero's INT subtracted by Target Hero's INT",
          'Channel for a Duration of X (X = Arcane Power charges consumed).',
          'Over that duration, target enemy gains an array of negative effects:',
          'EVA is reduced to X.',
          'Damage dealt is reduced by X%.',
          'All skills cost X% additional Mana.',
          'X% Resistance to Cleanse.',
          "X% chance to Pull X at the start of each of target Hero's Turn.",
          'Over that duration, this Hero gains an array of positive effects:',
          "Any resistance that target enemy has active is added to this Hero's resistances.",
          "Generate X charges of Arcane Power on each Hero's Turn.",
          "At the beginning of each of target enemy's Turn, target enemy has a chance equal to (X*INT)% to break Mind Control.",
        ],
      },
      {
        id: 'wizard-t7-6',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['wizard-t4-3'],
        comboEffects: [],
        scalingStats: ['INT', 'INT'],
        ailments: ['Chill', 'Burn', 'Daze', 'Exhaust', 'Confuse'],
        ailmentsDuration: null,
        buffs: ['Pierce'],
        buffsDuration: null,
        debuffs: ['Amnesia Mind Bullets'],
        debuffsDuration: 0,
        channelDuration: null,
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Arcanist',
        name: 'Mind Bullets',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Deal damage to target enemy equal to [(X*AP)*SPELL + X*INT)].',
          '(X + AP + X*INT)% chance to apply one stack of Chill to target enemy.',
          'Generate X charges of Arcane Power.',
          'COMBO: If this Hero cast "Mind Break" last Turn, this attack and all UNLOCKS gain X% Pierce.',
          'Amnesia X, "Mind Bullets".',
          'UNLOCK X:\n' +
            'Delay X, deal damage to target enemy equal to [(X*AP)*SPELL + X*INT)].\n' +
            '(X + AP + X*INT)% chance to apply one stack of Burn with magical damage equal to (X*INT) to target enemy.',
          'UNLOCK X:\n' +
            'Delay X, deal damage to target enemy equal to [(X*AP)*SPELL + X*INT)].\n' +
            '(X + AP + X*INT)% chance to Daze target enemy.\n' +
            'Generate X charges of Arcane Power.',
          'UNLOCK X:\n' +
            'Delay X, deal damage to target enemy equal to [(X*AP)*SPELL + X*INT)].\n' +
            '(X + X*INT)% to Exhaust target enemy for a Duration of X.\n' +
            'Generate X charges of Arcane Power.',
          'UNLOCK X:\n' +
            'Delay X, deal damage to target enemy equal to [(X*AP)*SPELL + X*INT)].\n' +
            '(X + X*INT)% to Confuse target enemy for a Duration of X.\n' +
            'Generate X charges of Arcane Power.',
        ],
      },
      {
        id: 'wizard-t7-7',
        tier: 7,
        skillPoints: 10,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Murder Enemy'],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Skills Cost Less Mana', 'Damage'],
        buffsDuration: 0,
        debuffs: ['Exhausted'],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Arcanist',
        name: 'Overload',
        imageSrc: '',
        description: [
          'If any action this Hero takes misses, this Hero overloads and goes Berserk for a Duration of X.',
          'However, while Berserk, this Hero gains an array of positive effects, cannot spend Arcane Power, but Unlocked AP thresholds are honored:',
          'Gain X charges of Arcane Power per Duration.',
          'All skills cost X% less Mana.',
          'All skills deal X% additional magical damage.',
          'When Berserk ends, become Exhausted.',
          'COMBO: If, while in a Berserker state, this Hero commits murder (kills an ally), this Hero gains the base benefits of the Skill for the remainder of Battle.',
          'UNLOCK X:\n' +
            'All skills cost X% additional less Mana.\n' +
            'All skills deal X% additional magical damage.',
          'UNLOCK X:\n' +
            'All skills cost X% additional less Mana.\n' +
            'All skills deal X% additional magical damage.',
          'UNLOCK X:\n' +
            'All skills cost X% additional less Mana.\n' +
            'All skills deal X% additional magical damage.',
          'UNLOCK X:\n' +
            'All skills cost X% additional less Mana.\n' +
            'All skills deal X% additional magical damage.',
        ],
      },
      {
        id: 'wizard-t7-8',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: 0,
        isPassive: false,
        dependentSkill: null,
        comboSkills: ['wizard-t4-3', 'wizard-t7-6'],
        comboEffects: [],
        scalingStats: ['WIS', 'INT'],
        ailments: ['Silence', 'Amnesia'],
        ailmentsDuration: 0,
        buffs: ['Damage'],
        buffsDuration: null,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['Single Target'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Arcanist',
        name: 'Arcano Lock',
        imageSrc: '',
        description: [
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'Channel for a Duration of X.',
          'Deal magical damage to target enemy equal to [(X*AP)*SPELL + X*WIS + X*INT)].',
          '(X + AP + X*INT)% chance to Silence target enemy for a Duration of X.',
          'Generate X charges of Arcane Power.',
          'COMBO: If "Mind Bullets" or "Mind Break" were cast on this Hero\'s last Turn, gain a (X + X*WIS)% chance to Amnesia a random enemy target, that target gains Amnesia on one random skill for a Duration of X.',
          'UNLOCK X:\n' +
            '(X + AP + X*INT)% chance to Silence target enemy for an additional Duration of X.\n' +
            'Generate X charges of Arcane Power.',
          'UNLOCK X:\n' +
            '(X + AP + X*WIS)% chance to Amnesia a random skill of target enemy for a Duration of X.\n' +
            'Generate X charges of Arcane Power.',
        ],
      },
      {
        id: 'wizard-t7-9',
        tier: 7,
        skillPoints: 10,
        range: 3,
        mana: 0,
        degreeOfDifficulty: null,
        isPassive: false,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: [],
        scalingStats: ['WIS', 'LCK'],
        ailments: ['Stun', 'Amnesia'],
        ailmentsDuration: 0,
        buffs: ['Pure Barrier', 'Evasion'],
        buffsDuration: 0,
        debuffs: ['Channel'],
        debuffsDuration: 0,
        channelDuration: 'X Rounds',
        areaOfEffect: ['AOE'],
        skillType: 'Attack',
        damageType: 'Magical',
        discipline: 'Arcanist',
        name: 'Meteor',
        imageSrc: '',
        description: [
          'Channel for a Duration of X.',
          'Empower this skill by consuming X charges of Arcane Power (AP).',
          'During Channel, gain a Pure Barrier with HP equal to [(X*AP)*WIS)].',
          'Delay X, Deal magical damage equal to [(X*AP)*SPELL + (X*LCK)] divided evenly amongst the enemy party.',
          'UNLOCK X:\n' + 'Gain (X + X*AP)% EVA while Channeling.',
          'UNLOCK X:\n' +
            'Upon impact, (X + AP + X*WIS)% chance to Stun a random target enemy for a Duration of X.',
          'UNLOCK X:\n' +
            'Upon impact, (X + AP + X*WIS)% chance to Amnesia a random target enemy skill of a random target enemy for a Duration of X.',
          'UNLOCK X:\n' +
            'Upon impact, (X + AP + X*WIS)% chance to Stun all enemies for a Duration of X.',
        ],
      },
    ],
    tier8: [
      {
        id: 'wizard-t8-1',
        tier: 8,
        skillPoints: 15,
        range: null,
        mana: null,
        degreeOfDifficulty: null,
        isPassive: true,
        dependentSkill: null,
        comboSkills: [],
        comboEffects: ['Burn', 'Chill'],
        scalingStats: [],
        ailments: [],
        ailmentsDuration: null,
        buffs: ['Generate X Charges of AP'],
        buffsDuration: null,
        debuffs: [],
        debuffsDuration: null,
        channelDuration: null,
        areaOfEffect: ['Self'],
        skillType: 'Buff',
        damageType: '-',
        discipline: 'Arcanist',
        name: 'Elementalist',
        imageSrc: '',
        description: [
          "For each enemy with an active stack of Burn at the beginning of this Hero's turn, generate X charges of Arcane Power.",
          "For each enemy with an active stack of Chill at the beginning of this Hero's turn, generate X charges of Arcane Power.",
        ],
      },
    ],
  },
};
