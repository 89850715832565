<template>
  <v-dialog
    :value="showSaveUpdateDialog"
    persistent
    :max-width="isMobile ? '50%' : '30%'"
  >
    <v-card>
      <v-card-title>{{ `${dialogLabel} Build` }}</v-card-title>
      <v-divider></v-divider>
      <v-form
        @submit.prevent="submitForm"
        @keydown.enter.prevent="addDialogTag"
      >
        <v-text-field
          v-model="dialogTitle"
          :rules="dialogTitleRules"
          label="* Title"
        ></v-text-field>
        <v-text-field
          v-model="tag"
          :rules="tagRules"
          label="Type a tag and press Enter"
        ></v-text-field>
        <div class="tags" v-if="dialogTags.length !== 0">
          <v-chip
            closable
            v-for="tag in dialogTags"
            :key="tag"
            @click="closeDialogTag(tag)"
          >
            {{ tag }}
          </v-chip>
        </div>
        <v-divider></v-divider>
        <v-container class="action-buttons">
          <v-row justify="center">
            <v-btn
              min-width="40%"
              :disabled="!isFormValid"
              type="submit"
              variant="tonal"
              color="primary"
            >
              {{ dialogLabel }}
            </v-btn>
            <v-btn
              min-width="40%"
              variant="tonal"
              color="secondary"
              @click="closeDialog()"
            >
              Cancel
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import resolutionHelper from '../../utils/resolutionHelper';

export default {
  props: {
    showSaveUpdateDialog: Boolean,
    isSavedBuild: Boolean,
    userIsBuildOwner: Boolean,
    buildTitle: String,
    buildTags: Array,
  },
  emits: ['save-build', 'update-build', 'close-dialog'],
  data() {
    return {
      dialogTitle: this.userIsBuildOwner ? this.buildTitle : '',
      dialogTitleRules: [
        (value) => {
          if (value?.length > 2 && value?.length < 61) return true;

          return 'Title must be between 3 and 60 characters.';
        },
      ],
      tag: '',
      tagRules: [
        (value) => {
          if (this.dialogTags.includes(value)) {
            return 'The tag already exists.';
          }

          if (value?.length < 31) return true;

          return 'Tag must be between 2 and 30 characters.';
        },
      ],
      dialogTags: this.userIsBuildOwner ? this.buildTags : [],
    };
  },
  computed: {
    dialogLabel() {
      return this.userIsBuildOwner ? 'Update' : 'Save';
    },
    isFormValid() {
      return this.dialogTitle.length > 2 && this.dialogTitle.length < 61;
    },
    isMobile() {
      return resolutionHelper.isMobile();
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },
    closeDialogTag(tag) {
      this.dialogTags = this.dialogTags.filter((t) => t !== tag);
    },
    addDialogTag() {
      if (this.tag !== '' && !this.dialogTags.includes(this.tag)) {
        this.dialogTags.push(this.tag);
        this.tag = '';
      }
    },
    submitForm() {
      this.closeDialog();

      if (this.isSavedBuild && this.userIsBuildOwner) {
        this.$emit('update-build', {
          title: this.dialogTitle,
          tags: this.dialogTags,
        });
      } else {
        this.$emit('save-build', {
          title: this.dialogTitle,
          tags: this.dialogTags,
        });
      }
    },
  },
};
</script>

<style scoped>
.v-card-title {
  text-align: center;
}

div.v-input {
  margin: 0 20px;
}

.tags {
  margin: 0 20px 0 20px;
}

.v-chip {
  margin: 5px;
}

.v-divider {
  margin-bottom: 10px;
}

.action-buttons {
  margin-bottom: 10px;
}

.action-buttons .v-btn {
  margin: 5px;
}
</style>
