<template>
  <v-container>
    <h1>Sign up</h1>
    <v-form v-model="isFormValid" @submit.prevent="signUp">
      <v-text-field
        outlined
        v-model="displayName"
        label="Display Name"
        type="text"
        :rules="displayNameRules"
      >
      </v-text-field>
      <v-text-field
        outlined
        v-model="email"
        label="Email"
        type="email"
        :rules="emailRules"
      >
      </v-text-field>
      <v-text-field
        outlined
        v-model="password"
        label="Password"
        type="password"
        :rules="passwordRules"
      >
      </v-text-field>
      <v-btn :disabled="!isFormValid" color="primary" type="submit"
        >Sign Up</v-btn
      >
      <div class="error-message">{{ error }}</div>
    </v-form>
    <div id="login">
      <span>Already registered?</span>
      <v-btn variant="plain" color="primary" @click="$emit('toggle')"
        >Sign In</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';

export default {
  emits: ['toggle'],
  data() {
    return {
      displayName: '',
      displayNameRules: [
        (value) => {
          if (value?.length > 1 && value?.length < 21) return true;

          return 'Display Name must be between 2 and 20 characters.';
        },
      ],
      email: '',
      emailRules: [
        (value) => {
          if (
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
              value
            )
          )
            return true;

          return 'Must be a valid e-mail.';
        },
      ],
      password: '',
      passwordRules: [
        (value) => {
          if (value?.length > 5) return true;

          return 'Password must be at least 6 characters.';
        },
      ],
      isFormValid: false,
      error: null,
    };
  },
  methods: {
    signUp() {
      const auth = getAuth();

      createUserWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          if (!userCredential) {
            this.$store.dispatch('snackbar/showMessage', {
              content: 'Could not complete the signup!',
              color: 'red',
            });
            throw new Error('Could not complete the signup!');
          }

          this.$gtag.event('sign_up', {
            method: 'EmailAndPassword',
          });

          updateProfile(auth.currentUser, {
            displayName: this.displayName,
          })
            .then(() => {
              this.resetFormData();

              this.$router.push({ name: 'home' });

              this.$store.dispatch('snackbar/showMessage', {
                content: 'You have successfully signed up!',
                color: 'success',
              });
            })
            .catch((error) => {
              this.error = error.message;
            });
        })
        .catch((error) => {
          switch (error.code) {
            case 'auth/email-already-in-use':
              this.error = `User with email ${this.email} is already registered`;
              break;
            default:
              this.error = error.message;
          }
        });
    },
    resetFormData() {
      this.displayName = '';
      this.email = '';
      this.password = '';
      this.error = null;
    },
  },
};
</script>

<style scoped>
.v-container {
  max-width: 40%;
}

#login {
  margin-top: 10px;
}

h1 {
  text-align: center;
  margin-bottom: 10px;
}
.error-message {
  color: red;
  margin-top: 2px;
}
</style>
