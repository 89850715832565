<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col cols="11">
          <strong>{{ `Buffs (${skillBuffsCount})` }}</strong>
        </v-col>
        <v-col cols="1">
          <tooltip-icon class="information-tooltip" :text="tooltipText">
          </tooltip-icon>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="skillBuffsCount !== 0">
      <Bar :data="skillTypesChartData" :options="skillBuffsChartOptions" />
    </div>
    <div v-else>There are no buff skills selected</div>
  </div>
</template>

<script>
import TooltipIcon from '../common/TooltipIcon.vue';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { Bar } from 'vue-chartjs';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default {
  components: {
    TooltipIcon,
    Bar,
  },
  props: {
    skillBuffs: Object,
  },
  data() {
    return {
      tooltipText:
        'The chart shows the buffs this build provides.\n\nTips:\n- buffs come not only from passive skills, but from active ones as well\n- you can easily check the list of buffs to determine if you are missing something important for your build\n- the count of a buff represents the number of skills that provide it\n- careful not to have too many skills that provide the same or similar buff, unless this is intended\n- some skills have additional effects which depend on certain buffs - check the "Skills With Combo Effects" chart',
    };
  },
  computed: {
    skillBuffsCount() {
      let count = 0;

      Object.values(this.skillBuffs).forEach((v) => (count += v));

      return count;
    },
    skillBuffsChartOptions() {
      return {
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
    skillTypesChartData() {
      return {
        labels: Object.keys(this.skillBuffs),
        datasets: [
          {
            backgroundColor: [
              '#ffffff',
              '#009933',
              '#ff6600',
              '#0099cc',
              '#996633',
              '#0033cc',
              '#cc0000',
              '#ffff4d',
            ],
            data: Object.values(this.skillBuffs),
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
@media (max-width: 900px) {
  .information-tooltip {
    font-size: 0.7rem;
  }
}
</style>
