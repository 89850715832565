export default {
  // Checks for match of the search word in the build's title, class, subclass, tags and level value
  hasSearchWord(build, searchWord, isHeroBuild) {
    if (searchWord === '') {
      return true;
    } else if (isHeroBuild) {
      const tags = build.tags.toString();
      const level = build.requiredLevel.toString();

      const matchFound =
        build.title.toLowerCase().includes(searchWord.toLowerCase()) ||
        build.heroClass.toLowerCase().includes(searchWord.toLowerCase()) ||
        build.heroSubclass.toLowerCase().includes(searchWord.toLowerCase()) ||
        tags.toLowerCase().includes(searchWord.toLowerCase()) ||
        level.includes(searchWord.toLowerCase());

      return matchFound;
    } else {
      const tags = build.tags.toString();
      const size = build.teamSize.toString();

      const buildClasses = build.heroPositions.map((p) => p.heroClass);
      const buildSubclasses = build.heroPositions.map((p) => p.heroSubclass);
      const classes = buildClasses.toString();
      const subclasses = buildSubclasses.toString();

      const matchFound =
        build.title.toLowerCase().includes(searchWord.toLowerCase()) ||
        classes.toLowerCase().includes(searchWord.toLowerCase()) ||
        subclasses.toLowerCase().includes(searchWord.toLowerCase()) ||
        tags.toLowerCase().includes(searchWord.toLowerCase()) ||
        size.includes(searchWord.toLowerCase());

      return matchFound;
    }
  },
  hasClass(build, searchClasses, isHeroBuild) {
    if (searchClasses.length === 0) {
      return true;
    } else if (isHeroBuild) {
      return searchClasses.includes(build.heroClass);
    } else {
      const buildClasses = build.heroPositions.map((p) => p.heroClass);

      for (var i in buildClasses) {
        if (searchClasses.indexOf(buildClasses[i]) !== -1) {
          return true;
        }
      }

      return false;
    }
  },
  hasSubclass(build, searchSubclasses, isHeroBuild) {
    if (searchSubclasses.length === 0) {
      return true;
    } else if (isHeroBuild) {
      return searchSubclasses.includes(build.heroSubclass);
    } else {
      const buildSubclasses = build.heroPositions.map((p) => p.heroSubclass);

      for (var i in buildSubclasses) {
        if (searchSubclasses.indexOf(buildSubclasses[i]) !== -1) {
          return true;
        }
      }

      return false;
    }
  },
  hasTag(buildTags, searchTags) {
    if (searchTags.length === 0) {
      return true;
    } else if (searchTags.length !== 0 && buildTags.length !== 0) {
      for (var i in searchTags) {
        if (buildTags.indexOf(searchTags[i]) !== -1) {
          return true;
        }
      }
    } else {
      return false;
    }
  },
};
